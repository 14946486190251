define('frontend/helpers/format-decimal', ['exports', 'ember'], function (exports, _ember) {
   var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

   exports.formatDecimal = formatDecimal;

   function formatDecimal(_ref) {
      var _ref2 = _slicedToArray(_ref, 4);

      var number = _ref2[0];
      var precision = _ref2[1];
      var nullValue = _ref2[2];
      var units = _ref2[3];

      var lowestValue = -242.0;

      if (nullValue === undefined) {
         // || isNaN(nullValue)) {
         nullValue = -999.9;
      }
      if (number === undefined || isNaN(number) || number <= lowestValue) {
         return nullValue;
      }
      if (precision === undefined || isNaN(precision) || precision < 1) {
         precision = 0;
      }
      return (Math.round(number * 100) / 100).toFixed(precision) + units;
   }

   exports['default'] = _ember['default'].Helper.helper(formatDecimal);
});