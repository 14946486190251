define('frontend/components/dx-file-uploader', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
   exports['default'] = _ember['default'].Component.extend({
      imageUploaded: false,
      resetUpload: false,
      attributeBindings: ['imageUploaded', 'resetUpload'],

      _initialize: (function () {
         var self = this;
         var host = this.get('host');
         var userID = this.get('userID');

         this.$("#dxFileUploaderContainer").dxFileUploader({
            inputAttr: { id: this.get('idAttr') },
            disabled: this.get('disabled'),
            hint: this.get('hint'),
            selectButtonText: 'Select Image',
            labelText: 'or Drop File Here',
            multiple: false,
            accept: "image/*",
            uploadMethod: 'POST',
            uploadMode: 'useButtons',
            uploadUrl: host + '/users/image/' + userID,
            uploadedMessage: this.get('uploadedMessage'),

            onValueChanged: function onValueChanged(e) {
               _ember['default'].$("#selected-files .selected-item").remove();
               var files = e.value;
               if (files.length > 0) {
                  _ember['default'].$.each(files, function (i, file) {
                     var $selectedItem = _ember['default'].$("<div />").addClass("selected-item");
                     if (file) {
                        (function () {
                           var img = new Image();
                           img.src = window.URL.createObjectURL(file);
                           img.onload = function () {
                              var width = img.naturalWidth;
                              var height = img.naturalHeight;

                              window.URL.revokeObjectURL(img.src);

                              if (width > 300 || height > 300) {
                                 _ember['default'].$('#dxFileUploaderContainer').dxFileUploader('instance').reset();
                                 _ember['default'].$("#selected-files .selected-item").remove();
                                 $selectedItem = _ember['default'].$("<div />").addClass("selected-item");
                                 $selectedItem.append(_ember['default'].$("<span class='text-danger'>").html("Maximum image size is 300 x 300px</span><br/>"));
                                 //e.element.find(".dx-fileuploader-files-container .dx-fileuploader-cancel-button").eq(0).trigger("dxclick");
                                 $selectedItem.appendTo(_ember['default'].$("#selected-files"));
                                 _ember['default'].$("#selected-files").show();
                              }
                           };
                        })();
                     }
                     if (file.size > 102400) {
                        $selectedItem.append(_ember['default'].$("<span class='text-danger'>").html("Image is too big (max 100kb)</span><br/>"));
                        _ember['default'].$('#dxFileUploaderContainer').dxFileUploader('instance').reset();
                     } else {
                        $selectedItem.append(_ember['default'].$("<span />").html("Name: " + file.name + "<br/>"), _ember['default'].$("<span />").html("Size: " + file.size + " bytes" + "<br/>"), _ember['default'].$("<span />").html("Type: " + file.type + "<br/>"), _ember['default'].$("<span />").html("Last Modified Date: " + (0, _moment['default'])(file.lastModifiedDate).format("DD/MM/YYYY HH:mm")));
                     }
                     $selectedItem.appendTo(_ember['default'].$("#selected-files"));
                  });
                  _ember['default'].$("#selected-files").show();
               } else {
                  _ember['default'].$("#selected-files").hide();
               }
            },
            onUploadStarted: function onUploadStarted() {
               self.set('imageUploaded', false);
            },
            onUploaded: function onUploaded() {
               self.set('imageUploaded', true);
               self.$("#dxFileUploaderContainer").dxFileUploader('instance').reset();
            },
            onUploadError: function onUploadError(e) {
               var xhttp = e.request;
               if (xhttp.readyState === 4 && xhttp.status === 0) {
                  console.log("Upload Error: Connection refused");
               }
               self.set('imageUploaded', false);
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxFileUploaderContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxFileUploaderContainer").dxFileUploader('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _resetUploadChanged: (function () {
         if (this.resetUpload) {
            this.$("#dxFileUploaderContainer").dxFileUploader('instance').reset();
            this.set('resetUpload', false);
         }
      }).observes('resetUpload')
   });
});