define('frontend/controllers/home/account/subscriptions', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      adminResult: false,
      invalidInput: false,
      isAddMode: false,
      isEditing: false,
      isNotEditing: _ember['default'].computed.not('isEditing'),
      subscriptionGroup: 'subscriptionGroup',

      actions: {
         authComplete: function authComplete() {
            this.set('isEditing', this.get('adminResult'));
         },
         edit: function edit() {
            this.showAdminPopup();
         },
         cancel: function cancel() {
            this.resetToolbar();
         },
         save: function save() {
            //this.saveModel();
            this.send("refreshModel");
         },
         remove: function remove(item) {
            var self = this;
            var result = DevExpress.ui.dialog.confirm("Are you sure you want to delete this Subscription?", "Confirm Delete");

            result.done(function (dialogResult) {
               if (dialogResult) {
                  (function () {
                     DevExpress.ui.notify("Removing Subscription...", "info", 2500);
                     var subs = self.get('model.subscriptions');
                     if (subs) {
                        if (item) {
                           item.destroyRecord().then(function () {
                              subs.removeObject(item);
                              DevExpress.ui.notify("Subscription Removed", "success", 2500);
                              self.send("refreshModel");
                           })['catch'](function (error) {
                              DevExpress.ui.notify("Error Removing Subscription: " + error, "error", 2500);
                           });
                        }
                        self.resetToolbar();
                     }
                  })();
               }
            });
         },
         goBack: function goBack() {
            this.transitionToRoute("home.account.billing");
         }
      },
      resetToolbar: function resetToolbar() {
         this.set('isEditing', false);
      },
      showAdminPopup: function showAdminPopup() {
         if (this.get('adminResult')) {
            this.set('isEditing', true);
         } else {
            _ember['default'].$("#dxPopupAdminContainer").dxPopup("instance").show();
         }
      }
   });
});