define("frontend/helpers/alarm-type", ["exports", "@ember/component/helper"], function (exports, _emberComponentHelper) {
   exports.alarmType = alarmType;

   function alarmType(params /*, hash*/) {
      var key = params[0];

      if (key === 0) {
         return "Battery SOC";
      } else if (key === 1) {
         return "Battery Voltage";
      } else if (key === 2) {
         return "Humidity";
      } else if (key === 3) {
         return "Temperature";
      } else if (key === 4) {
         return "Temperature (AUX)";
      } else if (key === 5) {
         return "Temperature (USB)";
         /*} else if (key === 6) {
            return "Current";*/
      } else {
            return "Unknown";
         }
   }

   exports["default"] = (0, _emberComponentHelper.helper)(alarmType);
});