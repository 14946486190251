define('frontend/components/dx-text-password', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      min: 8,
      max: 32,
      capsLockOn: false,
      isInvalid: false,
      isError: false,
      hasStrength: false,
      strength: 0,
      errorMessage: null,
      attributeBindings: ['isInvalid', 'isCapsOn', 'strength'],

      _initialize: (function () {
         var self = this;
         var validationGroup = this.get('group');

         this.$("#dxPasswordContainer").dxTextBox({
            activeStateEnabled: true,
            inputAttr: { id: this.get('idAttr') },
            disabled: this.get('disabled'),
            readOnly: this.get('readOnly'),
            hint: this.get('hint'),
            mode: this.get('mode'),
            maxLength: self.get('max'),
            placeholder: this.get('placeholder'),
            fontFamily: 'Arial',
            fontSize: 24,
            validationMessageMode: 'always',
            value: this.get('value'),
            buttons: [{
               name: 'password',
               location: 'after',
               stylingMode: 'text',
               type: 'normal',
               options: {
                  icon: 'fas fa-eye',
                  onClick: function onClick() {
                     var mode = self.get('mode');
                     if (mode === 'text') {
                        mode = 'password';
                        this.option('icon', 'fas fa-eye');
                     } else {
                        mode = 'text';
                        this.option('icon', 'fas fa-eye-slash');
                     }
                     self.set('mode', mode);
                  }
               }
            }],
            onInput: function onInput() {
               self.clearError();
               if (self.hasStrength) {
                  var val = self.$("#dxPasswordContainer").dxTextBox('option', 'text');
                  var strength = self.passwordStrength(val);
                  self.set('strength', strength);
               }
            },
            onValueChanged: function onValueChanged(e) {
               self.clearError();
               self.set('value', e.value);
               if (self.hasStrength) {
                  var strength = self.passwordStrength(e.value);
                  self.set('strength', strength);
               }
            },
            onFocusOut: function onFocusOut() {
               self.$("#dxPasswordContainer").dxValidator('instance').validate();
            }
         }).dxValidator({
            validationGroup: validationGroup,
            validationRules: [{ type: 'required', message: 'Password is required' }, { type: 'stringLength', min: self.get('min'), max: self.get('max'), message: 'Password length must be at least 8 characters!' }, { type: 'pattern', pattern: '(?=.*?[a-z])', message: 'Password must contain at least 1 lowercase character!' }, { type: 'pattern', pattern: '(?=.*?[A-Z])', message: 'Password must contain at least 1 uppercase character!' }, { type: 'pattern', pattern: '(?=.*?[0-9])', message: 'Password must contain at least 1 digit!' }, { type: 'pattern', pattern: '^\\S*$', message: 'Password must NOT contain any whitespace!' }, { type: 'pattern', pattern: '(?=.*?[!@#\\$%\\^&\\*\\(\\)\\-\\+\\_\\{\\}\\[\\]|:;,\\.\\<\\>\\?\\/])', message: 'Password must contain at least 1 special character!' }, { type: 'custom', reevaluate: true, validationCallback: function validationCallback(options) {
                  if (self.get('isError')) {
                     options.rule.message = self.get('errorMessage');
                     return false;
                  } else {
                     options.rule.message = null;
                     return true;
                  }
               } }],
            onValidated: function onValidated(e) {
               try {
                  if (self.get('isDestroyed') || self.get('isDestroying')) {
                     return;
                  }
                  if (e.isValid) {
                     self.set('isInvalid', false);
                     self.$("#dxPasswordContainer.dx-texteditor .dx-texteditor-container").addClass("dx-valid");
                  } else {
                     self.set('isInvalid', true);
                     self.$("#dxPasswordContainer.dx-texteditor .dx-texteditor-container").removeClass("dx-valid");
                  }
               } catch (exp) {
                  console.log("Exception Error: " + exp);
               }
            }
         }).keypress(function (e) {
            /*let s = String.fromCharCode(e.which);
            if (s.toUpperCase() === s && s.toLowerCase() !== s && !e.shiftKey) {
               self.set('capsLockOn', true);
            } else {
               self.set('capsLockOn', false);
            }*/
            self.set('capsLockOn', self.isCapslock(e));
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxPasswordContainer").remove();
      }).on('willDestroyElement'),

      clearError: function clearError() {
         if (this.get('isError')) {
            this.set('errorMessage', null);
            this.set('isError', false);
            this.$("#dxPasswordContainer").dxValidator('instance').validate();
         }
      },
      isCapslock: function isCapslock(e) {
         e = e ? e : window.event;

         var charCode = false;
         if (e.which) {
            charCode = e.which;
         } else if (e.keyCode) {
            charCode = e.keyCode;
         }
         var shiftOn = false;
         if (e.shiftKey) {
            shiftOn = e.shiftKey;
         } else if (e.modifiers) {
            shiftOn = !!(e.modifiers & 4);
         }
         if (charCode >= 97 && charCode <= 122 && shiftOn || charCode >= 65 && charCode <= 90 && !shiftOn) {
            return true;
         }
         return false;
      },
      passwordStrength: function passwordStrength(password) {
         var strength = 0;
         var points = 0;
         var minLength = this.get('min');

         if (password === null || password === undefined || password.length === 0) {
            return strength;
         }
         // Simple algorithm of password strength calculation
         var rx = undefined;

         // If password is longer than 8 symbols then add 1 point
         if (password.length >= minLength) {
            points++;
         }
         // If password has at least one lower case character then add 1 point
         rx = new RegExp('(?=.*?[a-z])');
         if (rx.test(password)) {
            points++;
         }
         // If password has at least one upper case character then add 1 point
         rx = new RegExp('(?=.*?[A-Z])');
         if (rx.test(password)) {
            points++;
         }
         // If password has at least one digit then add 1 point
         rx = new RegExp('(?=.*?[0-9])');
         if (rx.test(password)) {
            points++;
         }
         // Add 1 point in case if password contains at least one special char
         rx = new RegExp('(?=.*?[\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\-\\+\\_\\{\\}\\[\\]\\|\\:\\;\\,\\.\\<\\>\\?\\/])');
         if (rx.test(password)) {
            points++;
         }
         // If password has NO whitespace then add 1 point
         rx = new RegExp('^\\S*$');
         if (rx.test(password)) {
            points++;
         }
         // Add 1 point if password is longer than 12 symbols
         if (password.length > 12) {
            points++;
         }
         strength = points;
         return strength;
      },
      _disabledChanged: (function () {
         this.$("#dxPasswordContainer").dxTextBox('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _modeChanged: (function () {
         this.$("#dxPasswordContainer").dxTextBox('option', 'mode', this.get('mode'));
      }).observes('mode'),

      _valueChanged: (function () {
         var val = this.get('value');
         if (val) {
            this.$("#dxPasswordContainer").dxTextBox('option', 'value', val);
         } else {
            this.$("#dxPasswordContainer").dxValidator('instance').reset();
         }
      }).observes('value'),

      _isErrorChanged: (function () {
         if (this.get('isError')) {
            this.$("#dxPasswordContainer").dxValidator('instance').validate();
            this.$("#dxPasswordContainer").dxValidator('instance').focus();
         }
      }).observes('isError')
   });
});