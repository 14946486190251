define('frontend/components/dx-map-component', ['exports', 'ember'], function (exports, _ember) {

   // Location for PwrOn (40 Burlingon Circuit, Townsville QLD 4814)
   var DEFAULT_LAT = -19.266700;
   var DEFAULT_LNG = 146.734940;

   exports['default'] = _ember['default'].Component.extend({
      currentPosition: null,
      lat: DEFAULT_LAT,
      lng: DEFAULT_LNG,
      mapType: 'roadmap',
      position: '[' + DEFAULT_LAT + ', ' + DEFAULT_LNG + ']',
      provider: 'google',
      attributeBindings: ['lat', 'lng'],

      _init: (function () {
         this.checkInitialPosition();
      }).on('init'),

      _initialize: (function () {
         var self = this;

         this.$("#dxMapContainer").dxMap({
            controls: true,
            disabled: this.get('disabled'),
            hint: this.get('hint'),
            center: this.get('currentPosition'),
            key: {
               bing: '',
               google: this.get('key'),
               googleStatic: ''
            },
            width: '100%',
            height: 400,
            zoom: 13,
            provider: this.get('provider'),
            mapType: this.get('mapType'),
            markers: [this.get('currentPosition')],

            onReady: function onReady() {
               //let map = e.originalMap;
               self.checkCurrentPosition();

               /*
               var input = /!** @type {!HTMLInputElement} *!/(
                  document.getElementById('pac-input'));
                var types = document.getElementById('type-selector');
               map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
               map.controls[google.maps.ControlPosition.TOP_LEFT].push(types);
                let autocomplete = new google.maps.places.Autocomplete(address);
               autocomplete.bindTo('bounds', map);
                let infowindow = new google.maps.InfoWindow();
               var marker = new google.maps.Marker({
                  map: map,
                  anchorPoint: new google.maps.Point(0, -29)
               });
                autocomplete.addListener('place_changed', function () {
                  infowindow.close();
                  //marker.setVisible(false);
                  let place = autocomplete.getPlace();
                  if (!place.geometry) {
                     window.alert("Auto-complete's returned place contains no geometry");
                     return;
                  }
                   // If the place has a geometry, then present it on a map.
                  if (place.geometry.viewport) {
                     map.fitBounds(place.geometry.viewport);
                  } else {
                     map.setCenter(place.geometry.location);
                     map.setZoom(17);
                  }
                  /*marker.setIcon(
                     /!** @type {google.maps.Icon} *!/({
                     url: place.icon,
                     size: new google.maps.Size(71, 71),
                     origin: new google.maps.Point(0, 0),
                     anchor: new google.maps.Point(17, 34),
                     scaledSize: new google.maps.Size(35, 35)
                  }));
                  marker.setPosition(place.geometry.location);
                  marker.setVisible(true);
                   let address = '';
                  if (place.address_components) {
                     address = [
                        (place.address_components[0] && place.address_components[0].short_name || ''),
                        (place.address_components[1] && place.address_components[1].short_name || ''),
                        (place.address_components[2] && place.address_components[2].short_name || '')
                     ].join(' ');
                  }
                  infowindow.setContent('<div><strong>' + place.name + '</strong><br>' + address);
                  infowindow.open(map, marker);
               });
                // Sets a listener on a radio button to change the filter type on Places Auto-complete.
               function setupClickListener(id, types) {
                  var radioButton = document.getElementById(id);
                  radioButton.addEventListener('click', function () {
                     autocomplete.setTypes(types);
                  });
               }
                setupClickListener('changetype-all', []);
               setupClickListener('changetype-address', ['address']);
               setupClickListener('changetype-establishment', ['establishment']);
               setupClickListener('changetype-geocode', ['geocode']);
               */
            },
            onClick: function onClick(e) {
               //DevExpress.ui.notify("Location: " + e.location.lat + " " + e.location.lng, "success", 2500);
               var map = e.component;

               map.removeMarker(self.currentPosition);

               if (e.location) {
                  self.setCoordinates(e.location.lat, e.location.lng, false);
                  map.addMarker(self.currentPosition).done(function (marker) {
                     //marker.setIcon('images/blue-dot.png');
                     marker.setDraggable(true);
                     google.maps.event.addListener(marker, 'dragend', function (e) {
                        if (e.latLng) {
                           self.setCoordinates(e.latLng.lat(), e.latLng.lng(), false);
                        }
                     });
                  });
               }
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxMapContainer").remove();
      }).on('willDestroyElement'),

      handleNoGeolocation: function handleNoGeolocation(browserHasGeolocation) {
         var message = browserHasGeolocation ? 'Error: The Geolocation service failed.' : 'Error: Your browser doesn\'t support geolocation.';
         DevExpress.ui.notify(message, 'error', 3000);
      },
      checkInitialPosition: function checkInitialPosition() {
         var lat = this.get('lat');
         if (!lat) {
            lat = DEFAULT_LAT;
            this.set('lat', lat);
         }
         var lng = this.get('lng');
         if (!lng) {
            lng = DEFAULT_LNG;
            this.set('lng', lng);
         }
         this.set('position', '[' + lat + ', ' + lng + ']');
         var location = { text: 'Home',
            location: [lat, lng],
            tooltip: 'Location: ' + lat + '; ' + lng };

         this.set('currentPosition', location);
      },
      checkCurrentPosition: function checkCurrentPosition() {
         var self = this;
         var lat = this.get('lat');
         var lng = this.get('lng');

         if (lat && lng) {
            self.setCoordinates(lat, lng, true);
         } else {
            if (navigator && navigator.geolocation) {
               navigator.geolocation.getCurrentPosition(function (position) {
                  //console.log("Navigator: [" + position.coords.latitude + ", " + position.coords.longitude + "]");
                  self.setCoordinates(position.coords.latitude, position.coords.longitude, true);
               }, function () {
                  // Error with Geolocation navigator
                  self.handleNoGeolocation(true);
                  self.setCoordinates(DEFAULT_LAT, DEFAULT_LNG, true);
               });
            } else {
               // Browser doesn't support Geolocation
               self.handleNoGeolocation(false);
               self.setCoordinates(DEFAULT_LAT, DEFAULT_LNG, true);
            }
         }
      },
      setCoordinates: function setCoordinates(latitude, longitude, setMarker) {
         var lat = Number(latitude);
         if (lat) {
            lat = lat.toPrecision(8);
         }
         var lng = Number(longitude);
         if (lng) {
            lng = lng.toPrecision(8);
         }
         this.set('lat', lat);
         this.set('lng', lng);
         this.set('position', '[' + lat + ', ' + lng + ']');

         if (setMarker) {
            this.set('currentPosition', {
               text: 'Home',
               location: [lat, lng],
               tooltip: 'Location: ' + lat + '; ' + lng
            });
            this.$("#dxMapContainer").dxMap('option', 'markers', [this.get('currentPosition')]);
            this.$("#dxMapContainer").dxMap('option', 'center', this.get('currentPosition.location'));
         } else {
            var pos = this.get('currentPosition');
            if (pos) {
               this.set('currentPosition.location', [lat, lng]);
            }
         }
         this.sendAction('updateLocation');
      },

      _disabledChanged: (function () {
         this.$("#dxMapContainer").dxMap('option', 'disabled', this.get('disabled'));
         if (this.get('disabled') === false) {
            this.checkCurrentPosition();
         }
      }).observes('disabled')
   });
});