define("frontend/helpers/equality-type", ["exports", "@ember/component/helper"], function (exports, _emberComponentHelper) {
   exports.equalityType = equalityType;

   function equalityType(params /*, hash*/) {
      var key = params[0];

      if (key === 0) {
         return "Equal-to";
      } else if (key === 1) {
         return "Greater-than";
      } else if (key === 2) {
         return "Greater-than or Equal-to";
      } else if (key === 3) {
         return "Less-than";
      } else if (key === 4) {
         return "Less-than or Equal-to";
      } else {
         return "Unknown";
      }
   }

   exports["default"] = (0, _emberComponentHelper.helper)(equalityType);
});