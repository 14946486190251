define('frontend/controllers/home/account/invoices', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      invalidInput: false,
      isEditing: false,
      isNotEditing: _ember['default'].computed.not('isEditing'),
      actions: {
         cancel: function cancel() {
            this.resetToolbar();
         },
         goBack: function goBack() {
            this.transitionToRoute("home.account.subscriptions");
         }
      },
      resetToolbar: function resetToolbar() {
         this.set('isEditing', false);
      }
   });
});