define('frontend/components/dx-toolbar-hamburger', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      _initialize: (function () {
         var self = this;
         this.$("#dxToolbarHamburgerContainer").dxToolbar({
            disabled: self.get('disabled'),
            items: [{
               widget: "dxButton",
               options: {
                  icon: "menu",
                  onClick: function onClick() {
                     //Ember.$('#dxDrawerMenuContainer').toggle();
                     var menu = _ember['default'].$('#dxDrawerMenuContainer').dxDrawer('instance');
                     var width = menu.option('width');
                     console.log('Got width: ' + width);
                     if (width === undefined || width > 100 || width === '25%') {
                        _ember['default'].$('#dx-drawer-panel-list').addClass('collapsed');
                        _ember['default'].$('#dx-drawer-view').removeClass('push-right');
                        menu.option('width', 72);
                        menu.option('opened', false);
                     } else {
                        _ember['default'].$('#dx-drawer-panel-list').removeClass('collapsed');
                        _ember['default'].$('#dx-drawer-view').addClass('push-right');
                        menu.option('width', "25%");
                        menu.option('opened', true);
                     }
                  }
               }
            }]
         });
      }).on('didInsertElement'),

      _disabledChanged: (function () {
         this.$("#dxToolbarHamburgerContainer").dxToolbar('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _destroy: (function () {
         this.$("#dxToolbarHamburgerContainer").remove();
      }).on('willDestroyElement')
   });
});