define('frontend/controllers/home/device/recipients', ['exports', 'ember'], function (exports, _ember) {

   var MAX_RECIPIENTS = 1000;

   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      dvc: null,
      deviceName: null,
      recipientsGroup: 'recipientsGroup',
      queryParams: ['dvc'],
      adminResult: false,
      invalidInput: false,
      isAdding: false,
      isNotAdding: _ember['default'].computed.not('isAdding'),
      isEditing: false,
      isNotEditing: _ember['default'].computed.not('isEditing'),

      maxRecipients: MAX_RECIPIENTS,
      newRecFirstName: null,
      newRecLastName: null,
      newRecCallingCode: null,
      newRecPhone: null,
      newRecEmail: null,
      newRecAlarmType: null,
      newRecAlarmRepeat: 0,

      init: function init() {
         this._super();
         this.clearData();
      },
      actions: {
         authComplete: function authComplete() {
            this.set('isEditing', this.get('adminResult'));
         },
         add: function add() {
            if (this.get('model.recipients').get('length') < MAX_RECIPIENTS) {
               this.set('isAdding', true);
            } else {
               DevExpress.ui.notify("You can't add any more than " + MAX_RECIPIENTS + " Recipients.", 'error', 2500);
            }
         },
         edit: function edit() {
            this.set('isEditing', true);
         },
         cancel: function cancel() {
            this.resetToolbar();
            this.clearData();
         },
         save: function save() {
            this.saveModel();
         },
         remove: function remove() {},
         goBack: function goBack() {
            this.transitionToRoute("home.device.settings", { queryParams: { dvc: this.get('dvc') } });
         }
      },
      clearData: function clearData() {
         this.set('newRecFirstName', null);
         this.set('newRecLastName', null);
         this.set('newRecCallingCode', "+61");
         this.set('newRecPhone', null);
         this.set('newRecEmail', null);
         this.set('newRecAlarmType', 'SMS');
         this.set('newRecAlarmRepeat', 0);
      },
      resetToolbar: function resetToolbar() {
         this.set('isAdding', false);
         this.set('isEditing', false);
      },
      saveModel: function saveModel() {
         var self = this;
         var result = DevExpress.validationEngine.validateGroup(this.get('recipientsGroup'));

         if (result && result.isValid) {
            if (this.get('isAdding')) {
               var newRec = this.store.createRecord('notificationrecipient', { device: this.get('model.device') });
               if (newRec) {
                  newRec.first_name = this.get('newRecFirstName');
                  newRec.last_name = this.get('newRecLastName');
                  newRec.callingCode = this.get('newRecCallingCode');
                  newRec.number = this.get('newRecPhone');
                  newRec.email = this.get('newRecEmail');
                  newRec.alarm_type = this.get('newRecAlarmType');
                  newRec.alarm_repeat = this.get('newRecAlarmRepeat');

                  DevExpress.ui.notify("Saving New Recipient ...", "info", 2500);
                  newRec.save().then(function () {
                     DevExpress.ui.notify("New Recipient Saved", "success", 2500);
                     self.resetToolbar();
                  })['catch'](function (error) {
                     console.log("Error Saving Recipient: " + error);
                     DevExpress.ui.notify("Error Saving Recipient: " + error, "error", 2500);
                  });
               } else {
                  console.log("Error Saving Recipient: New record wasn't created!");
                  DevExpress.ui.notify("Error Saving Recipient: New record wasn't created!", "error", 2500);
               }
            } else {
               DevExpress.ui.notify("Saving Recipients ...", "info", 2500);
               var recipients = this.get('model.recipients');
               if (recipients) {
                  recipients.save().then(function () {
                     DevExpress.ui.notify("Recipient details saved successfully", "success", 2500);
                     self.resetToolbar();
                  })['catch'](function (error) {
                     console.log("Error Saving Recipients: " + error);
                     DevExpress.ui.notify("Error Saving Recipients: " + error, "error", 2500);
                  });
               }
            }
            self.clearData();
         }
      }
   });
});