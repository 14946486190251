define('frontend/routes/home/account/details', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model() {
         var host = this.get('store').adapterFor('application').get('host');
         var user_id = this.get('authManager').get('getUser.id');

         return _ember['default'].RSVP.hash({
            user: this.get('store').findRecord('user', user_id),
            cards: this.newQuery(host, 'billing/cards', user_id).then(function (results) {
               if (results) {
                  return results.data;
               } else {
                  return null;
               }
            })['catch'](function (err) {
               console.log("Error getting Cards: " + err);
               return null;
            }),
            invoices: this.newQuery(host, 'billing/invoices/customer', user_id).then(function (results) {
               if (results) {
                  return results.data;
               } else {
                  return null;
               }
            })['catch'](function (err) {
               console.log("Error getting Invoices: " + err);
               return null;
            }),
            nextInvoice: this.newQuery(host, 'billing/invoices/upcoming/customer', user_id).then(function (nextInvoice) {
               return nextInvoice;
            })['catch'](function (err) {
               console.log("Error getting Upcoming Invoice: " + err);
               return null;
            }),
            subscriptions: this.get('store').findAll('subscription').then(function (subscriptions) {
               var subscriptionArray = [];

               if (subscriptions) {
                  var numSubscriptions = subscriptions.get('length');
                  if (numSubscriptions > 0) {
                     //console.log("# of Subscriptions: " + numSubscriptions);
                     return subscriptions;
                  } else {
                     console.log("No Subscriptions found!");
                     return subscriptionArray;
                  }
               } else {
                  console.log("No Subscriptions defined!");
                  return subscriptionArray;
               }
            })['catch'](function (err) {
               console.log("Error getting Subscriptions: " + err);
               return null;
            })
         });
      },
      afterModel: function afterModel(model) {
         // Wait for asynchronous calls to back-end to resolve ...
         var controller = this.controllerFor('home.account.details');
         if (model) {
            controller.set('model', model);
            //console.log("Invoices: " + JSON.stringify(model.invoices,null,2));
            //console.log("Upcoming: " + JSON.stringify(model.nextInvoice));
         }
      },
      actions: {
         error: function error(reason) {
            DevExpress.ui.notify("Model Error: " + reason);
            // Can transition to another route here, e.g. this.transitionTo('index');
            // Bubble this error event ...
            return true;
         },
         willTransition: function willTransition() {
            this.controllerFor('home.account.details').resetToolbar();
         },
         didTransition: function didTransition() {
            var homeController = this.controllerFor('home');
            if (homeController) {
               homeController.set('title', 'My PwrOn Account');
               homeController.set('selectedMenuIndex', 4);
               homeController.set('subMenuIndex', 0);
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      },
      newQuery: function newQuery(host, path, user_id) {
         return new _ember['default'].RSVP.Promise(function (resolve, reject) {
            var url = [host, path, user_id].join('/');
            _ember['default'].$.get({
               url: url,
               crossDomain: true,
               success: function success(results) {
                  if (results) {
                     resolve(results);
                  } else {
                     resolve(null);
                  }
               },
               error: function error(err) {
                  reject(err.responseText);
               }
            });
         });
      }
   });
});