define('frontend/routes/home/account/billing', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model() {
         var host = this.get('store').adapterFor('application').get('host');
         var user_id = this.get('authManager').get('getUser.id');

         return _ember['default'].RSVP.hash({
            user: this.get('store').findRecord('user', user_id),
            cards: this.newQuery(host, 'billing/cards', user_id).then(function (cards) {
               if (cards) {
                  return cards.data;
               } else {
                  return null;
               }
            })['catch'](function (err) {
               console.log("Error getting Cards: " + err);
               return null;
            }),
            defaultCard: this.newQuery(host, 'billing/cards/default', user_id).then(function (card) {
               return card;
            })['catch'](function (err) {
               console.log("Error getting Cards: " + err);
               return null;
            })
         });
      },
      actions: {
         willTransition: function willTransition() {
            this.controllerFor('home.account.billing').resetToolbar();
         },
         didTransition: function didTransition() {
            var homeController = this.controllerFor('home');
            if (homeController) {
               homeController.set('title', 'Billing Information');
               homeController.set('selectedMenuIndex', 4);
               homeController.set('subMenuIndex', 1);
            }
         },
         didInsertElement: function didInsertElement() {
            //this.controllerFor('home.account.billing').renderElements();
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      },
      newQuery: function newQuery(host, path, user_id) {
         return new _ember['default'].RSVP.Promise(function (resolve, reject) {
            var url = [host, path, user_id].join('/');
            _ember['default'].$.get({
               url: url,
               crossDomain: true,
               success: function success(results) {
                  if (results) {
                     resolve(results);
                  } else {
                     resolve(null);
                  }
               },
               error: function error(err) {
                  reject(err.responseText);
               }
            });
         });
      }
   });
});