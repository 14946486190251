define('frontend/routes/home/devices', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model() {
         var self = this;
         var user_id = this.get('authManager').get('getUser.id');
         if (user_id) {
            return _ember['default'].RSVP.hash({
               user: self.store.findRecord('user', user_id),
               appsettings: self.store.queryRecord('appsettings', { user: user_id }).then(function (setting) {
                  if (setting) {
                     return setting;
                  } else {
                     return null;
                  }
               })['catch'](function (error) {
                  console.log("DEVICES: Find App Settings error: " + error.message);
                  return null;
               }),
               devices: self.store.findAll('device').then(function (devices) {
                  if (devices) {
                     return devices;
                  } else {
                     return [];
                  }
               })['catch'](function (error) {
                  console.log("DEVICES: Find All Devices Error: " + error.message);
                  return null;
               }),
               subscriptions: self.store.findAll('subscription').then(function (subscriptions) {
                  if (subscriptions) {
                     var numSubscriptions = subscriptions.get('length');
                     if (numSubscriptions > 0) {
                        //console.log("DEVICES: # of Subscriptions: " + numSubscriptions);
                        return subscriptions;
                     }
                  } else {
                     console.log("DEVICES: No Subscriptions Found!");
                     return [];
                  }
               })['catch'](function (error) {
                  console.log("DEVICES: Find All Subscriptions Error: " + error.message);
                  return null;
               })
            });
         } else {
            this.transitionTo('users.logout');
            return null;
         }
      },
      afterModel: function afterModel(model) {
         if (model.appsettings) {
            console.log("DEVICES: Application Settings found!");
         } else {
            model.appsettings = this.store.createRecord('appsettings', {
               menu_index: 0,
               sub_menu_index: -1,
               tab_index: 0,
               device_index: 0,
               device_id: null,
               device_uuid: null,
               user: model.user
            });
            console.log('DEVICES: Created new Application Settings');
         }
      },
      setupController: function setupController(controller, model) {
         // Call _super for default behavior - sets the model to the controller
         this._super(controller, model);
         console.log('DEVICES: setupController started');
         // Custom controller setup ...
         if (model.appsettings) {
            controller.set('selectedTabIndex', model.appsettings.get('tab_index'));
         }
         // Update Devices list and set selected Device ...
         controller.updateJSONDevices();
         controller.getSelectedDevice();
         controller.setDeviceID();
         controller.waitAndRefresh();

         // Enable periodic page updates ...
         if (controller.get('enableUpdates') !== true) {
            controller.set('enableUpdates', true);
         }
         console.log('DEVICES: setupController finished');
      },
      actions: {
         willTransition: function willTransition() {
            var controller = this.controllerFor('home.devices');
            if (controller) {
               controller.set('enableUpdates', false);
            }
         },
         didTransition: function didTransition() {
            console.log('DEVICES: didTransition');
            var homeController = this.controllerFor('home');
            if (homeController) {
               homeController.set('title', 'My PwrOn Devices');
               homeController.set('subMenuIndex', -1);
               homeController.set('selectedMenuIndex', 1);
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});