define('frontend/models/subscription', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-uuid', 'frontend/config/environment'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships, _emberUuid, _frontendConfigEnvironment) {
   exports['default'] = _emberDataModel['default'].extend({
      name: (0, _emberDataAttr['default'])('string', {
         // Default name based upon unique identifier. Can be changed by user
         // Todo: is a name required? Could just use billing or object id
         defaultValue: function defaultValue() {
            var uuid = (0, _emberUuid.v4)();return 'sub-' + uuid;
         }
      }),
      plan_id: (0, _emberDataAttr['default'])('string', {
         // Default value is the free plan ...
         defaultValue: function defaultValue() {
            return _frontendConfigEnvironment['default'].APP.DEFAULT_PLAN;
         }
      }),
      plan_name: (0, _emberDataAttr['default'])('string'),
      status: (0, _emberDataAttr['default'])('string', {
         defaultValue: function defaultValue() {
            return 'in-active';
         }
      }),
      created: (0, _emberDataAttr['default'])('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      last_updated: (0, _emberDataAttr['default'])('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      amount: (0, _emberDataAttr['default'])('number-zero'),
      credits: (0, _emberDataAttr['default'])('number-zero', {
         defaultValue: function defaultValue() {
            return _frontendConfigEnvironment['default'].APP.MAX_SMS_CREDITS;
         }
      }),
      threshold: (0, _emberDataAttr['default'])('number-zero', {
         defaultValue: function defaultValue() {
            return _frontendConfigEnvironment['default'].APP.DEFAULT_THRESHOLD;
         }
      }),
      topup_pending: (0, _emberDataAttr['default'])('boolean'),
      user: (0, _emberDataAttr['default'])('string'),

      billing_id: (0, _emberDataAttr['default'])('string', {
         // Default is no billing setup (stripe)
         defaultValue: function defaultValue() {
            return null;
         }
      }),
      customer_id: (0, _emberDataAttr['default'])('string'),
      canceled_at: (0, _emberDataAttr['default'])('date'),
      current_period_end: (0, _emberDataAttr['default'])('date'),
      current_period_start: (0, _emberDataAttr['default'])('date'),
      ended_at: (0, _emberDataAttr['default'])('date'),
      start: (0, _emberDataAttr['default'])('date'),

      device: (0, _emberDataRelationships.belongsTo)('device')
   });
});