define('frontend/components/dx-list-notifications', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
   exports['default'] = _ember['default'].Component.extend({
      grouped: false,
      height: '100%',
      selectionMode: 'single',
      selectedNotification: null,
      /*dataSource: [
         {  "_id": "0",
            "created" : "2021-09-05T08:03:41.628+0000",
            "device_name": "My TALS Sensor",
            "severity": 2,
            "message": "Battery is LOW",
            "model": "TALS",
            "notification_type": "Email"
         },{
            "_id": "1",
            "created" : "2021-09-12T08:03:51.628+0000",
            "device_name": "My TALS Sensor",
            "severity": 3,
            "message": "Temperature is 3.4°C which is less than the threshold of 4.0°C",
            "model": "TALS",
            "notification_type": "SMS"
         },{
            "_id": "2",
            "created" : "2021-09-12T08:04:01.628+0000",
            "device_name": "My TALS Sensor",
            "severity": 1,
            "message": "Temperature is 4.1°C which is greater than the threshold of 4.0°C",
            "model": "TALS",
            "notification_type": "Email + SMS"
         }
      ],*/
      attributeBindings: ['dataSource', 'selectedNotification'],

      _initialize: (function () {
         var self = this;
         this.$("#dxListNotificationsContainer").dxList({
            dataSource: new DevExpress.data.DataSource({
               store: {
                  type: 'array',
                  data: self.get('dataSource')
               },
               paginate: true,
               pageSize: 10
            }),
            pageLoadMode: 'nextButton',
            noDataText: 'No Notifications Found!',
            activeStateEnabled: false,
            allowItemDeleting: true,
            allowReordering: false,
            bounceEnabled: false,
            collapsibleGroups: true,
            grouped: self.get('grouped'),
            height: self.get('height'),
            hint: 'Device Notifications',
            hoverStateEnabled: true,
            focusStateEnabled: false,
            indicateLoading: true,
            itemDeleteMode: 'slideItem',
            pullRefreshEnabled: false,
            refreshingText: 'Reloading Notifications ...',
            searchEnabled: true,
            searchExpr: ["message", "alarm.device.name"],
            searchMode: 'contains',
            selectionMode: self.get('selectionMode'),

            groupTemplate: function groupTemplate(data) {
               if (!data.alarm || !data.alarm.device) {
                  return self.$("<div>Name: Unknown Device</div>");
               } else {
                  return self.$("<div>Name: " + data.alarm.device.name + "</div>");
               }
            },
            itemTemplate: function itemTemplate(data, index, element) {
               var result = undefined;
               var timestamp = (0, _moment['default'])(data.created).format('DD/MM/YYYY h:mm:ss a');
               var alarm = data.alarm;
               if (alarm.severity === 2) {
                  // 'warning'
                  result = self.$("<div class='dx-list-notification-panel panel-warning'>");
                  result.append(self.$("<i class='fal fa-bell-exclamation text-warning'></i>"));
               } else if (alarm.severity === 3) {
                  // 'error'
                  result = self.$("<div class='dx-list-notification-panel panel-danger'>");
                  result.append(self.$("<span class='fa-stack text-danger'>" + "<i class='fal fa-bell fa-stack-2x'></i>" + "<i class='fal fa-times fa-stack-1x'></i></span>"));
               } else if (alarm.severity === 1) {
                  // 'success'
                  result = self.$("<div class='dx-list-notification-panel panel-success'>");
                  result.append(self.$("<span class='fa-stack text-success'>" + "<i class='fal fa-bell fa-stack-2x'></i>" + "<i class='fal fa-check fa-stack-1x'></i></span>"));
               } else {
                  result = self.$("<div class='dx-list-notification-panel panel-info'>");
                  result.append(self.$("<i class='fal fa-bell'></i>"));
               }
               if (!alarm.device) {
                  result.append(self.$("<label class='text-message'>Unknown Device</label>"));
               } else {
                  result.append(self.$("<label class='text-message'>" + alarm.device.name + "</label>"));
               }
               result.append(self.$("<label class='far fa-arrow-right horiz-margin-md'></label>"));
               result.append(self.$("<label class='fal fa-stack'><label class='fal fa-shield fa-stack-2x'></label><label class='fal fa-stack-1x'>" + data.code + "</label></label>"));
               result.append(self.$("<label class='text-message horiz-margin-sm'>" + data.message + "</label>"));
               //result.append(self.$("<label class='text-message circle horiz-margin-md'>" + data.code + "</label>"));
               if (alarm.triggered) {
                  result.append(self.$("<label class='fal fa-check-circle fa-pull-right fa-2x top-margin-xxs'></label>"));
               } else {
                  result.append(self.$("<label class='fal fa-times-circle fa-pull-right fa-2x top-margin-xxs'></label>"));
               }
               result.append(self.$("<label class='text-timestamp'>" + timestamp + "</label>"));
               //result.append(self.$("<div class='text-timestamp'>" + data.notification_type + "</div>"));
               result.append('</div>');
               element.append(result);
            },
            onContentReady: function onContentReady() {
               /*if (!self.get('selectedNotification')) {
                  // Select first row (NOTE: If grouped, first data row index is 1, first group is 0)
                  self.selectNotification(0);
               }*/
               console.log("Notification List: Content Ready for %d Notifications in %d Groups", getNotificationCount(), getGroupCount());
            },
            onSelectionChanged: function onSelectionChanged(e) {
               if (e.addedItems && e.addedItems.length > 0) {
                  self.setSelectedNotification(e.addedItems[0]);
               } else {
                  self.setSelectedNotification(null);
               }
            },
            onItemClick: function onItemClick(e) {
               console.log("Notification Clicked Index: " + e.itemIndex);
               // Not need to do this if selection mode = 'single'
               //self.selectItem(e.itemIndex);
            },
            onItemSwipe: function onItemSwipe(e) {
               console.log("Notification Swiped Index: " + e.itemIndex);
               this.selectItem(e.itemIndex);
            },
            onItemDeleting: function onItemDeleting(e) {
               /*let d = $.Deferred();
               DevExpress.ui.dialog.confirm("Do you really want to delete the notification?", 'Delete Notification')
                  .done(function(value) {
                     self.sendAction('deleteNotification');
                     d.resolve(!value);
                  })
                  .fail(d.reject);
               e.cancel = d.promise();*/
               e.cancel = false;
               self.sendAction('deleteNotification');
            },
            onItemDeleted: function onItemDeleted(e) {
               self.refreshList();
            }
         });
         function getNotificationCount() {
            var data = self.get('dataSource');
            if (data) {
               return DevExpress.data.query(data).toArray().length;
            } else {
               return 0;
            }
         }
         function getGroupCount(groupField) {
            var data = self.get('dataSource');
            if (data) {
               return DevExpress.data.query(data).groupBy(groupField).toArray().length;
            } else {
               return 0;
            }
         }
      }).on('didInsertElement'),

      /*selectNotification: function(index) {
         if (index > 0) {
            let list = this.$("#dxListNotificationsContainer").dxList('instance');
            if (list) {
               list.selectItem(index);
            }
         }
      },*/
      setSelectedNotification: function setSelectedNotification(notification) {
         if (notification) {
            this.set('selectedNotification', notification);
            console.log("Selected Notification: " + notification.message);
         } else {
            this.set('selectedNotification', null);
            console.log("Selected Notification: None");
         }
      },
      refreshList: function refreshList() {
         this.$("#dxListNotificationsContainer").dxList('instance').repaint();
      },

      _destroy: (function () {
         this.$("#dxListNotificationsContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxListNotificationsContainer").dxList('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _dataSourceChanged: (function () {
         this.$("#dxListNotificationsContainer").dxList('option', 'dataSource', this.get('dataSource')); //.load();
         console.log("Notification List: DataSource changed");
      }).observes('dataSource')
   });
});