define('frontend/components/dx-slider-control', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      sliderValue: 0,
      attributeBindings: ['sliderValue'],

      _initialize: (function () {
         var self = this;
         var dataSource = this.get('dataSource');

         this.$("#dxSliderContainer").dxSlider({
            disabled: this.get('disabled'),
            inputAttr: { id: this.get('idAttr') },
            hint: this.get('hint'),
            min: this.get('min'),
            max: this.get('max'),
            step: this.get('step'),
            value: this.get('sliderValue'),
            label: {
               visible: true,
               format: {
                  formatter: function formatter(index) {
                     if (dataSource) {
                        return dataSource[index].label;
                     } else {
                        return '';
                     }
                  }
               }
            },
            tooltip: {
               enabled: true,
               showMode: 'always',
               format: {
                  formatter: function formatter(index) {
                     if (dataSource) {
                        return dataSource[index].value;
                     } else {
                        return index;
                     }
                  },
                  parser: function parser(e) {
                     if (dataSource) {
                        for (var i = 0; i < dataSource.get('length'); i++) {
                           if (dataSource[i].value === e.value) {
                              return i;
                           }
                        }
                     } else {
                        return e.value;
                     }
                  }
               }
            },
            onValueChanged: function onValueChanged(e) {
               self.set('sliderValue', e.value);
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxSliderContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxSliderContainer").dxSlider('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});