define('frontend/controllers/home/appsettings', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      enableUpdates: false,

      startUpdateTimer: function startUpdateTimer() {
         _ember['default'].run.later(this, function () {
            if (this.get('enableUpdates')) {
               /*let devices = this.get('model.devices');
               if (devices) {
                  for (let i = 0; i < devices.get('length'); i++) {
                     let dvc = devices.objectAt(i);
                     if (dvc) {
                        dvc.reload();
                     }
                  }
               }*/
               this.updateProperties();
               this.startUpdateTimer();
            }
         }, 180000); // every 3 minutes
      },

      updateProperties: function updateProperties() {},

      _enableUpdatesChanged: (function () {
         if (this.get('enableUpdates') === true) {
            this.updateProperties();
            this.startUpdateTimer();
         }
      }).observes('enableUpdates')
   });
});