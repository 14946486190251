define('frontend/components/dx-toolbar-settings', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      editMode: false,
      notEditMode: _ember['default'].computed.not('editMode'),
      addOption: false,
      backOption: true,
      nextOption: false,
      editOption: true,
      cancelOption: false,
      removeOption: false,
      saveOption: false,
      title: 'Settings',
      titleLocation: 'center',
      goBackHint: 'Go Back',
      goNextHint: 'Go Forward',
      attributeBindings: ['editMode', 'showAddRemove', 'title'],

      goBack: function goBack() {
         this.sendAction('goBack');
      },
      goNext: function goNext() {
         this.sendAction('goNext');
      },
      doAdd: function doAdd() {
         this.sendAction('doAdd');
      },
      doRemove: function doRemove() {
         this.sendAction('doRemove');
      },
      doEdit: function doEdit() {
         this.sendAction('doEdit');
      },
      doSave: function doSave() {
         this.sendAction('doSave');
      },
      doCancel: function doCancel() {
         this.sendAction('doCancel');
      },
      _initialize: (function () {
         var self = this;
         _ember['default'].$("#dxToolbarSettingsContainer").dxToolbar({
            disabled: this.get('disabled'),
            items: [{
               location: 'before',
               locateInMenu: 'never',
               widget: 'dxButton',
               visible: self.backOption,
               options: {
                  type: 'back',
                  text: 'Back',
                  hint: self.get('goBackHint'),
                  onClick: function onClick() {
                     self.goBack();
                  }
               }
            }, {
               location: "before",
               locateInMenu: 'auto',
               showText: 'inMenu',
               widget: 'dxButton',
               disabled: false,
               visible: self.addOption,
               options: {
                  text: 'Add',
                  hint: 'Add New Item',
                  icon: 'plus',
                  onClick: function onClick() {
                     DevExpress.ui.notify("Adding a new Item ...", "info", 2500);
                     self.doAdd();
                  }
               }
            }, {
               location: "after",
               locateInMenu: 'never',
               showText: 'always',
               widget: 'dxButton',
               disabled: self.editMode,
               visible: self.editOption,
               options: {
                  text: 'Edit',
                  hint: 'Edit Settings',
                  icon: 'edit',
                  onClick: function onClick() {
                     self.doEdit();
                  }
               }
            }, {
               location: "after",
               locateInMenu: 'auto',
               showText: 'always',
               widget: 'dxButton',
               disabled: false,
               visible: false,
               options: {
                  text: 'Save',
                  hint: 'Save the Settings',
                  icon: 'save',
                  onClick: function onClick() {
                     self.doSave();
                  }
               }
            }, {
               location: "after",
               locateInMenu: 'never',
               showText: 'inMenu',
               widget: 'dxButton',
               disabled: self.notEditMode,
               visible: self.cancelOption,
               options: {
                  text: 'Cancel',
                  hint: 'Cancel Edit Settings',
                  icon: 'close',
                  onClick: function onClick() {
                     self.set('editMode', false);
                     self.doCancel();
                  }
               }

            }, {
               location: "after",
               locateInMenu: 'auto',
               showText: 'inMenu',
               widget: 'dxButton',
               disabled: false,
               visible: self.removeOption,
               options: {
                  text: 'Remove',
                  hint: 'Delete Seleted Item',
                  icon: 'trash',
                  onClick: function onClick() {
                     DevExpress.ui.notify("Removing selected item ...", "info", 2500);
                     self.doRemove();
                  }
               }
            }, {
               location: 'after',
               locateInMenu: 'never',
               widget: 'dxButton',
               visible: self.nextOption,
               options: {
                  icon: 'chevronright',
                  type: 'Normal',
                  hint: self.get('goNextHint'),
                  onClick: function onClick() {
                     self.goNext();
                  }
               }
            }, {
               location: self.titleLocation,
               locateInMenu: 'never',
               showText: 'always',
               text: self.get('title'),
               template: function template(itemData, itemIndex, itemElement) {
                  itemElement.append("<div class='header-text'><h2>" + itemData.text + "</h2></div>");
               }
            }]
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxToolbarSettingsContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxToolbarSettingsContainer").dxToolbar('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _titleChanged: (function () {
         console.log('Repainting Toolbar ...');
         var itemsArray = this.$("#dxToolbarSettingsContainer").dxToolbar('option', 'items');
         if (itemsArray) {
            var num = itemsArray.length - 1;
            itemsArray[num].text = this.get('title');
         }
         this.$("#dxToolbarSettingsContainer").dxToolbar("instance").repaint();
      }).observes('title'),

      _editModeChanged: (function () {
         var isEditing = this.get('editMode');
         var itemsArray = this.$("#dxToolbarSettingsContainer").dxToolbar('option', 'items');

         if (this.get('editOption')) {
            itemsArray[2].disabled = isEditing;
            itemsArray[2].visible = this.get('notEditMode');
         }
         if (this.get('saveOption')) {
            itemsArray[3].visible = isEditing;
         }
         itemsArray[4].disabled = this.get('notEditMode');
         itemsArray[4].visible = isEditing;

         this.$("#dxToolbarSettingsContainer").dxToolbar("instance").repaint();
      }).observes('editMode')
   });
});