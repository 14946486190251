define('frontend/helpers/date-display', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
   exports.dateDisplay = dateDisplay;

   function dateDisplay(params) {
      var date = params[0];
      var format = params[1];
      var rel = params[2];

      if (rel === 'hour') {
         return (0, _moment['default'])(date).startOf('hour').fromNow();
      } else if (rel === 'unix') {
         return _moment['default'].unix(date).local().format(format);
      } else {
         return (0, _moment['default'])(date).local().format(format);
      }
   }

   exports['default'] = _ember['default'].Helper.helper(dateDisplay);
});