define("frontend/controllers/home/device/settings", ["exports", "ember"], function (exports, _ember) {

   var DEFAULT_DEVICE_NAME = "my new device";
   var DEVICE_NAME = "device name";
   var JUST_NAME = "name";

   exports["default"] = _ember["default"].Controller.extend({
      appController: _ember["default"].inject.controller('application'),
      screen: _ember["default"].inject.service(),
      store: _ember["default"].inject.service(),
      dvc: null,
      deviceName: null,
      queryParams: ['dvc'],
      alarmsJSON: [],
      deletedAlarmIds: [],
      selectedAlarm: null,
      settingsGroup: 'settingsGroup',
      adminResult: false,
      invalidInput: false,
      isEditing: false,
      isNotEditing: _ember["default"].computed.not('isEditing'),
      isAdvancedVisible: true,
      isSensorDevice: false,
      oldName: '',
      newName: '',
      groupList: null,
      typeList: null,
      commsTypeList: null,
      notificationTypes: null,
      pingSensitivityList: null,
      postFrequencyList: null,
      updateCheckList: null,
      alarmSeverity: null,
      alarmEquality: null,
      sensorTypes: null,
      isNameError: false,
      errorMessages: null,
      temperatureUnits: [{ key: 0, label: "Celcius", value: "°C" }, //'&deg;C'
      { key: 1, label: "Fahrenheit", value: "°F" } //'&deg;F'
      ],
      pingSensitivities: [{ key: 0, label: "Very Fast", value: "1 min" }, { key: 1, label: "Fast", value: "3 min" }, { key: 2, label: "Medium", value: "5 min" }, { key: 3, label: "Slow", value: "10 min" }],
      init: function init() {
         this._super();
         this.set('groupList', this.get('appController.deviceGroups'));
         this.set('typeList', this.get('appController.deviceTypes'));
         this.set('commsTypeList', this.get('appController.commsTypes'));
         this.set('notificationTypes', this.get('appController.notificationTypes'));
         this.set('pingSensitivityList', this.get('pingSensitivities'));
         this.set('postFrequencyList', this.get('appController.postFrequencies'));
         this.set('updateCheckList', this.get('appController.updateChecks'));
         this.set('alarmSeverity', this.get('appController.alarmSeverity'));
         this.set('alarmEquality', this.get('appController.alarmEquality'));
         this.set('sensorTypes', this.get('appController.sensorTypes'));
      },
      actions: {
         authComplete: function authComplete() {
            this.set('isEditing', this.get('adminResult'));
         },
         edit: function edit() {
            this.set('isEditing', true);
         },
         cancel: function cancel() {
            this.resetToolbar();
            this.send('refreshModel');
            this.transitionToRoute("home.device.settings", { queryParams: { dvc: this.get('dvc') } });
            console.log("SETTINGS: Cancel and reset");
         },
         deleteAlarm: function deleteAlarm() {
            var selectedAlarm = this.get('selectedAlarm');
            if (selectedAlarm) {
               console.log('Deleting Alarm: %s', selectedAlarm.id);
               var alarm = this.getAlarmFromModel(selectedAlarm.id);
               if (alarm) {
                  alarm.deleteRecord();
                  alarm.save();
               } else {
                  console.log('Unable to get Alarm from Model');
               }
            } else {
               console.log('Unable to delete Alarm: Not selected!');
            }
         },
         insertAlarm: function insertAlarm() {
            var _this = this;

            var device = this.get('model.device');
            var selectedAlarm = this.get('selectedAlarm');
            if (selectedAlarm) {
               (function () {
                  var newAlarm = _this.store.createRecord('alarm', selectedAlarm);
                  //console.log('SETTINGS: Alarm record created in store: %s', JSON.stringify(newAlarm));
                  newAlarm.set('device', device);
                  newAlarm.save().then(function () {
                     console.log('SETTINGS: New Alarm saved: %s', newAlarm.get('id'));
                  })["catch"](function (err) {
                     console.log('SETTINGS: Unable to save Alarm: %s', err);
                  });
               })();
            } else {
               console.log('SETTINGS: Alarm not selected!');
            }
         },
         updateAlarm: function updateAlarm() {
            var selectedAlarm = this.get('selectedAlarm');
            if (selectedAlarm) {
               var alarm = this.getAlarmFromModel(selectedAlarm.id);
               if (alarm) {
                  alarm.set('enabled', selectedAlarm.enabled);
                  alarm.set('severity', selectedAlarm.severity);
                  alarm.set('sensor_type', selectedAlarm.sensor_type);
                  alarm.set('equality', selectedAlarm.equality);
                  alarm.set('threshold', selectedAlarm.threshold);
                  alarm.set('notification_type', selectedAlarm.notification_type);
                  alarm.save();
               } else {
                  console.log('Unable to get Alarm from Model');
               }
            }
         },
         save: function save() {
            var grid = _ember["default"].$("#dxGridAlarmsContainer").dxDataGrid("instance");
            if (grid) {
               grid.saveEditData();
               console.log('SETTINGS: Saved grid edit data');
            }
            this.saveModel();
         },
         goBack: function goBack() {
            this.transitionToRoute("home.device.analytics", { queryParams: { dvc: this.get('dvc') } });
         },
         goNext: function goNext() {
            this.transitionToRoute("home.device.recipients", { queryParams: { dvc: this.get('dvc') } });
         }
      },
      resetToolbar: function resetToolbar() {
         this.set('isNameError', false);
         this.set('errorMessages', null);
         this.set('isEditing', false);
      },
      showAdminPopup: function showAdminPopup() {
         if (this.get('adminResult')) {
            this.set('isEditing', true);
         } else {
            _ember["default"].$("#dxPopupAdminContainer").dxPopup("instance").show();
         }
      },
      loadData: function loadData() {
         var alarms = this.get('model.alarms');
         if (alarms) {
            var alarmsJ = alarms.map(function (alarm) {
               var sensorType = alarm.get('sensor_type');
               if (sensorType >= 0 && sensorType <= 7) {
                  return alarm.toJSON({ includeId: true });
               } else {
                  return null;
               }
            });
            this.set('alarmsJSON', alarmsJ);
         }
      },
      getAlarmFromModel: function getAlarmFromModel(id) {
         var foundAlarm = null;
         var alarms = this.get('model.alarms');
         if (alarms) {
            alarms.forEach(function (alarm) {
               if (alarm && alarm.get('id') === id) {
                  console.log('SETTINGS: Found alarm in model: %s', id);
                  foundAlarm = alarm;
               }
            });
         }
         return foundAlarm;
      },
      saveAlarms: function saveAlarms(device) {
         // Save all the Alarm settings ...
         var self = this;
         var alarms = this.get('model.alarms');
         if (alarms) {
            var numAlarms = alarms.get('length');
            if (numAlarms === 0) {
               console.log('SETTINGS: Zero Alarms to save!');
            } else {
               console.log('SETTINGS: Saving %d alarms ...', numAlarms);

               alarms.forEach(function (alarm) {
                  if (alarm) {
                     alarm.set('device', device);
                     alarm.save().then(function () {
                        DevExpress.ui.notify("Alarm saved", 'success', 2500);
                     })["catch"](function (error) {
                        console.log("SETTINGS: Error saving alarm: %s", error);
                     });
                  }
               });
               DevExpress.ui.notify("Alarms saved", 'success', 2500);
               self.resetToolbar();

               /*alarms.save().then(function () {
                  DevExpress.ui.notify("Alarms saved", 'success', 2500);
                  self.resetToolbar();
               }).catch(function (error) {
                  console.log("SETTINGS: Error saving alarms: %s", error);
                  DevExpress.ui.notify("Error saving alarms: " + error, 'error', 2500);
               });*/
            }
            /*if (alarmsJSON.length === 0) {
               console.log('SETTINGS: Zero Alarms to save!');
            } else {
               console.log('SETTINGS: Saving %d alarms ...', alarmsJSON.get('length'));
            }
            alarmsJSON.forEach(function(alarmJ, index) {
               if (alarmJ) {
                  let id = alarmJ.get('id');
                  let data = {
                     sensor_type: alarmJ.get('sensor_type'),
                     equality: alarmJ.get('equality'),
                     severity: alarmJ.get('severity'),
                     notification_type: alarmJ.get('notification_type'),
                     threshold: alarmJ.get('threshold'),
                     enabled: alarmJ.get('enabled'),
                     triggered: alarmJ.get('triggered'),
                     device: device
                  };
                  if (id == null) {
                     let newAlarm = self.store.createRecord('alarm', data);
                     if (newAlarm) {
                        console.log("SETTINGS: Saving new alarm ...");
                        newAlarm.save().then(function() {
                           DevExpress.ui.notify("New alarm saved", 'success', 2500);
                           this.resetToolbar();
                        }).catch(function(error) {
                           console.log("SETTINGS: Error saving alarm: %s", error);
                           DevExpress.ui.notify("Error saving alarm: " + error, 'error', 2500);
                        });
                     } else {
                        let msg = "SETTINGS: Unable to create new Alarm record!";
                        console.log(msg);
                        DevExpress.ui.notify(msg, 'error', 2500);
                     }
                  }
                  else {
                     let existingAlarm = self.getAlarmFromModel(id);
                     if (existingAlarm) {
                        existingAlarm.set('sensor_type', alarmJ.get('sensor_type'));
                        existingAlarm.equality = alarmJ.get('equality');
                        existingAlarm.severity = alarmJ.get('severity');
                        existingAlarm.notification_type = alarmJ.get('notification_type');
                        existingAlarm.threshold = alarmJ.get('threshold');
                        existingAlarm.enabled = alarmJ.get('enabled');
                        existingAlarm.triggered = alarmJ.get('triggered');
                         existingAlarm.save().then(function() {
                           console.log("SETTINGS: Alarm #%d was saved", index);
                        }).catch(function(err) {
                           console.log("SETTINGS: Error saving alarm #%d: %s", index, err);
                        });
                     } else {
                        console.log("SETTINGS: Unable to find Alarm: %s", id);
                     }
                  }
               }
            });*/
         } else {
               console.log('SETTINGS: Unable to save Alarms!');
            }
      },
      saveModel: function saveModel() {
         var _this2 = this;

         var self = this;
         var device = this.get('model.device');
         if (!device) {
            var msg = "The settings can't be saved because the Device is undefined!";
            self.set('errorMessages', msg);
            DevExpress.ui.notify(msg, "error", 2500);
            return;
         }
         // Validate the settings group form ...
         var result = DevExpress.validationEngine.validateGroup(this.get('settingsGroup'));
         if (result && result.isValid) {
            var _ret2 = (function () {
               // Check that device name is valid and hasn't been used already ...
               var oldName = _this2.get('oldName');
               var newName = _this2.get('newName');
               var newNameLower = newName.toLowerCase();
               if (newNameLower === DEFAULT_DEVICE_NAME || newNameLower === DEVICE_NAME || newNameLower === JUST_NAME) {
                  var msg = "The entered Device name cannot be used";
                  self.set('isNameError', true);
                  self.set('errorMessages', msg);
                  DevExpress.ui.notify(msg, "error", 2500);
                  return {
                     v: undefined
                  };
               }
               if (device) {
                  var _ret3 = (function () {
                     var devices = _this2.get('model.devices');
                     if (devices && newName !== oldName) {
                        for (var d = 0; d < devices.get('length'); d++) {
                           if (newName === devices.objectAt(d).get('name')) {
                              var msg = "That Device name is already in use!";
                              self.set('isNameError', true);
                              self.set('errorMessages', msg);
                              DevExpress.ui.notify(msg, "error", 2500);
                              return {
                                 v: {
                                    v: undefined
                                 }
                              };
                           }
                        }
                     }
                     // Save the settings and the device ...
                     DevExpress.ui.notify("Saving Device Settings ...", "info", 2500);
                     var settings = self.get('model.settings');
                     if (settings) {
                        // Set deivce ID for the settings, then save them first ...
                        settings.set('device', device);
                        //console.log("Settings" + JSON.stringify(settings));
                        settings.save().then(function () {
                           // Set settings ID and new name for the Device, then save ...
                           device.set('name', newName);
                           device.set('settings', settings);
                           //console.log("Device" + JSON.stringify(device));
                           device.save().then(function () {
                              self.saveAlarms(device);

                              // Device saved, so not editing anymore ...
                              DevExpress.ui.notify("Device Settings have been saved", "success", 2500);
                              self.set('isEditing', false);
                           })["catch"](function (err) {
                              // Failure ...
                              var msg = "Error Saving Device: " + err;
                              console.log(msg);
                              DevExpress.ui.notify(msg, "error", 2500);
                           });
                        })["catch"](function (err) {
                           var msg = "Error Saving Settings: " + err;
                           console.log(msg);
                           DevExpress.ui.notify(msg, "error", 2500);
                        });
                     } else {
                        var msg = "Error Saving Settings: Model not defined!";
                        console.log(msg);
                        DevExpress.ui.notify(msg, "error", 2500);
                     }
                  })();

                  if (typeof _ret3 === "object") return _ret3.v;
               }
            })();

            if (typeof _ret2 === "object") return _ret2.v;
         }
      }
   });
});