define('frontend/components/dx-text-box', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      clearButton: false,
      isInvalid: false,
      isError: false,
      errorMessage: null,
      attributeBindings: ['isInvalid'],

      _initialize: (function () {
         var self = this;
         var validationGroup = this.get('group');

         this.$("#dxTextContainer").dxTextBox({
            activeStateEnabled: true,
            inputAttr: { id: this.get('idAttr') },
            disabled: this.get('disabled'),
            readOnly: this.get('readOnly'),
            hint: this.get('hint'),
            showClearButton: this.get('clearButton'),
            placeholder: this.get('placeholder'),
            fontFamily: 'Arial',
            maxLength: 32,
            validationMessageMode: 'always',
            value: this.get('value'),
            onInput: function onInput() {
               self.clearError();
            },
            onValueChanged: function onValueChanged(e) {
               self.clearError();
               self.set('value', e.value);
            }
         }).dxValidator({
            validationGroup: validationGroup,
            validationRules: [{ type: 'required', message: 'Value is required' }, { type: 'stringLength', min: 2, max: this.maxLength, message: 'Length must be at least 2 characters!' }, { type: 'pattern', reevaluate: true, pattern: "(^[A-Za-z])", message: "The name must start with a letter!" }, { type: 'custom', reevaluate: true, validationCallback: function validationCallback(options) {
                  if (self.get('isError')) {
                     options.rule.message = self.get('errorMessage');
                     return false;
                  } else {
                     options.rule.message = null;
                     return true;
                  }
               } }],
            onValidated: function onValidated(e) {
               try {
                  if (self.get('isDestroyed') || self.get('isDestroying')) {
                     return;
                  }
                  if (e.isValid) {
                     self.set('isInvalid', false);
                     self.$("#dxTextContainer.dx-texteditor .dx-texteditor-container").addClass("dx-valid");
                  } else {
                     self.set('isInvalid', true);
                     self.$("#dxTextContainer.dx-texteditor .dx-texteditor-container").removeClass("dx-valid");
                  }
               } catch (exp) {
                  console.log("Validation Error (Exp): " + exp);
               }
            }
         });
      }).on('didInsertElement'),

      clearError: function clearError() {
         if (this.get('isError')) {
            this.set('errorMessage', null);
            this.set('isError', false);
            this.$("#dxTextContainer").dxValidator('instance').validate();
         }
      },

      _destroy: (function () {
         this.$("#dxTextContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxTextContainer").dxTextBox('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _isErrorChanged: (function () {
         if (this.get('isError')) {
            this.$("#dxTextContainer").dxValidator('instance').validate();
            this.$("#dxTextContainer").dxValidator('instance').focus();
         }
      }).observes('isError')
   });
});