define('frontend/helpers/cents-to-dollars', ['exports', 'ember'], function (exports, _ember) {
   exports.centsToDollars = centsToDollars;

   function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

   function centsToDollars(_ref, namedArgs) {
      var _ref2 = _toArray(_ref);

      var value = _ref2[0];

      var rest = _ref2.slice(1);

      if (isNaN(value)) {
         return '$0.00';
      }
      var dollars = Math.floor(value / 100.0);
      var cents = value % 100;
      var sign = namedArgs.sign === undefined ? '$' : namedArgs.sign;

      if (cents.toString().length === 1) {
         cents = '0' + cents;
      }
      var result = '' + sign + dollars + '.' + cents;
      //let result = '$' + (value / 100.0).toFixed(2).replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      //console.log('Result: ' + result);
      return result;
   }

   exports['default'] = _ember['default'].Helper.helper(centsToDollars);
});