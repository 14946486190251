define('frontend/routes/home/device/settings', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      routeName: 'HOME.DEVICE.SETTINGS',
      model: function model(params) {
         var self = this;
         var routeName = this.get('routeName');
         if (params && params.dvc) {
            console.log("%s: Device ID: %s", routeName, params.dvc);
            return _ember['default'].RSVP.hash({
               device: self.store.findRecord('device', params.dvc)['catch'](function () {
                  console.log("%s: No device found for ID: %s", routeName, params.dvc);
                  return null;
               }),
               settings: self.store.queryRecord('settings', { device: params.dvc }).then(function (setting) {
                  console.log('%s: Found device settings', routeName);
                  return setting;
               })['catch'](function (error) {
                  console.log('%s: Find device settings error: %s', routeName, error.message);
                  return null;
               }),
               devices: self.store.findAll('device').then(function (devices) {
                  if (devices) {
                     return devices;
                  } else {
                     console.log('%s: No devices Found!', routeName);
                     return [];
                  }
               })['catch'](function (error) {
                  console.log('%s: Find devices error: %s', routeName, error);
                  return [];
               }),
               alarms: self.store.query('alarm', { device: params.dvc }).then(function (alarms) {
                  if (alarms) {
                     var numAlarms = alarms.get('length');
                     console.log('%s: Found %d alarms', routeName, numAlarms);
                     return alarms;
                  } else {
                     console.log('%s: No alarms found!', routeName);
                     return [];
                  }
               })['catch'](function (error) {
                  console.log('%s: Find alarms error: %s', routeName, error);
                  return [];
               })
            });
         } else {
            console.log('%s: No Device parameters!', routeName);
            return null;
         }
      },
      afterModel: function afterModel(model) {
         if (model && model.settings == null) {
            // Old POAS devices will not have a settings record in the database ...
            model.settings = this.store.createRecord('settings', {
               device: model.device,
               show_sensors: false,
               ping_sensitivity: model.device.get('ping_sensitivity'),
               post_frequency: model.device.get('post_frequency')
            });
            console.log('Created new Device Settings');
         }
      },
      setupController: function setupController(controller, model) {
         // Call _super for default behavior - sets the model to the controller
         this._super(controller, model);

         var routeName = this.get('routeName');
         if (model) {
            if (model.device) {
               // Set the device name ...
               var _name = model.device.get('name');
               console.log('%s: Device Name is %s', routeName, _name);
               controller.set('oldName', _name);
               controller.set('newName', _name);
               if (_name) {
                  controller.set('deviceName', _name);
               }
               // Check the Device model type ...
               var dvcModel = model.device.get('model_type');
               if (dvcModel == null) {
                  dvcModel = 'POAS';
                  model.device.set('model_type', dvcModel);
               }
               // Check if the device has sensors ...
               if (dvcModel === 'TALS' || dvcModel === 'tals') {
                  controller.set('isSensorDevice', true);
               } else {
                  controller.set('isSensorDevice', false);
               }
            }
            // Load the alarms ...
            controller.loadData();
         }
      },
      actions: {
         willTransition: function willTransition() {
            this.controllerFor('home.device.settings').resetToolbar();
         },
         didTransition: function didTransition() {
            var controller = this.controllerFor('home.device.settings');
            var homeController = this.controllerFor('home');
            if (homeController) {
               var _name2 = controller.get('deviceName');
               if (_name2) {
                  homeController.set('title', _name2);
               } else {
                  homeController.set('title', 'Device Settings');
               }
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});