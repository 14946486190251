define('frontend/controllers/home/account', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      isDetailsSelected: true,
      isBillingSelected: false,
      isSubscriptionsSelected: false,

      actions: {
         gotoDetails: function gotoDetails() {
            this.transitionToRoute('home.account.details');
            this.resetButton("#dxButtonDetails", 'Account Details');
         },
         gotoBilling: function gotoBilling() {
            this.transitionToRoute('home.account.billing');
            this.resetButton("#dxButtonBilling", 'Billing Details');
         },
         gotoSubscriptions: function gotoSubscriptions() {
            this.transitionToRoute('home.account.subscriptions');
            this.resetButton("#dxButtonSubscriptions", 'Subscriptions');
         },
         gotoInvoices: function gotoInvoices() {
            this.transitionToRoute('home.account.invoices');
            this.resetButton("#dxButtonInvoices", 'Invoices');
         }
      },
      resetButton: function resetButton(id, text) {
         var btn = _ember['default'].$(id + " #dxButtonContainer").dxButton("instance");
         if (btn) {
            btn.option('text', text);
            btn.option('disabled', false);
            if (btn.loadingIndicator) {
               btn.loadingIndicator.option('visible', false);
            }
         }
      }
   });
});
/*selectOption: function (selection) {
   if (selection) {
      if (selection === 'Details') {
         this.set('isDetailsSelected', true);
         this.set('isBillingSelected', false);
         this.set('isSubscriptionsSelected', false);
      } else if (selection === 'Billing') {
         this.set('isDetailsSelected', false);
         this.set('isBillingSelected', true);
         this.set('isSubscriptionsSelected', false);
      } else if (selection === 'Subscriptions') {
         this.set('isDetailsSelected', false);
         this.set('isBillingSelected', false);
         this.set('isSubscriptionsSelected', true);
      } else if (selection === 'Invoices') {
         this.set('isDetailsSelected', false);
         this.set('isBillingSelected', false);
         this.set('isSubscriptionsSelected', false);
      }
   }
}*/