define('frontend/models/alarm', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _emberDataAttr, _emberDataRelationships) {
   exports['default'] = _emberData['default'].Model.extend({
      // Attributes ...
      sensor_type: (0, _emberDataAttr['default'])('number', {
         defaultValue: function defaultValue() {
            return 2;
         }
      }),
      equality: (0, _emberDataAttr['default'])('number', {
         defaultValue: function defaultValue() {
            return 0;
         }
      }),
      severity: (0, _emberDataAttr['default'])('number', {
         defaultValue: function defaultValue() {
            return 2;
         }
      }),
      notification_type: (0, _emberDataAttr['default'])('string', {
         defaultValue: function defaultValue() {
            return 'Both';
         }
      }),
      threshold: (0, _emberDataAttr['default'])('number', {
         defaultValue: function defaultValue() {
            return 0.0;
         }
      }),
      value: (0, _emberDataAttr['default'])('number', {
         defaultValue: function defaultValue() {
            return -999.9;
         }
      }),
      enabled: (0, _emberDataAttr['default'])('boolean', {
         defaultValue: function defaultValue() {
            return true;
         }
      }),
      triggered: (0, _emberDataAttr['default'])('boolean', {
         defaultValue: function defaultValue() {
            return false;
         }
      }),
      units: (function () {
         var sensorType = this.get('sensor_type');
         if (sensorType === 0) {
            return 'A';
         } else if (sensorType === 1) {
            return '%RH';
         } else if (sensorType >= 2 && sensorType <= 4) {
            return '°C';
         } else if (sensorType === 5) {
            return 'mV';
         } else if (sensorType === 6) {
            return '%SOC';
         } else {
            return '';
         }
      }).property('sensor_type'),

      // Timestamps ...
      notified: (0, _emberDataAttr['default'])('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      created: (0, _emberDataAttr['default'])('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      last_updated: (0, _emberDataAttr['default'])('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      // Relationships ...
      device: (0, _emberDataRelationships.belongsTo)('device'),
      //notifications: hasMany('notification'),
      // Properties ...
      sensor_name: (function () {
         var sensorType = this.get('sensor_type');
         switch (sensorType) {
            case 0:
               return "Current";
            case 1:
               return "Humidity";
            case 2:
               return "Temperature";
            case 3:
               return "Temperature (AUX)";
            case 4:
               return "Temperature (EXT)";
            case 5:
               return "Battery Volts";
            case 6:
               return "Battery SOC";
            case 7:
               return "Battery Charger";
            default:
               return "Unknown Sensor";
         }
      }).property('sensor_type')
   });
});