define('frontend/components/dx-toolbar-recipients', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      addMode: false,
      notAddMode: _ember['default'].computed.not('addMode'),
      editMode: false,
      notEditMode: _ember['default'].computed.not('editMode'),
      addOption: false,
      backOption: true,
      editOption: true,
      cancelOption: false,
      removeOption: false,
      saveOption: false,
      goBackHint: 'Go Back',
      attributeBindings: ['addMode', 'editMode'],
      goBack: function goBack() {
         this.sendAction('goBack');
      },
      doAdd: function doAdd() {
         this.sendAction('doAdd');
      },
      doRemove: function doRemove() {
         this.sendAction('doRemove');
      },
      doEdit: function doEdit() {
         this.sendAction('doEdit');
      },
      doSave: function doSave() {
         this.sendAction('doSave');
      },
      doCancel: function doCancel() {
         this.sendAction('doCancel');
      },
      _initialize: (function () {
         var self = this;
         var caption = this.get('title');

         _ember['default'].$("#dxToolbarRecipientsContainer").dxToolbar({
            disabled: this.get('disabled'),
            dataSource: [{
               location: 'before',
               locateInMenu: 'never',
               widget: 'dxButton',
               options: {
                  type: 'back',
                  text: 'Back',
                  hint: self.get('goBackHint'),
                  onClick: function onClick() {
                     self.goBack();
                  }
               }
            }, {
               location: "after",
               locateInMenu: 'auto',
               showText: 'always',
               widget: 'dxButton',
               disabled: self.addMode,
               visible: self.notAddMode,
               options: {
                  text: 'Add',
                  hint: 'Add a New Recipient',
                  icon: 'plus',
                  onClick: function onClick() {
                     //DevExpress.ui.notify("New Recipient", "info", 2500);
                     self.doAdd();
                  }
               }
            }, {
               location: "after",
               locateInMenu: 'auto',
               showText: 'always',
               widget: 'dxButton',
               disabled: self.editMode,
               visible: self.notEditMode,
               options: {
                  text: 'Edit',
                  hint: 'Edit Recipient Details',
                  icon: 'edit',
                  onClick: function onClick() {
                     self.doEdit();
                  }
               }
            }, /*{
               location: "after",
               locateInMenu: 'auto',
               showText: 'inMenu',
               widget: 'dxButton',
               disabled: self.notAddMode,
               visible: self.addMode,
               options: {
                  text: 'Save',
                  hint: 'Save Recipient Details',
                  icon: 'save',
                  onClick: function () {
                     self.doSave();
                  }
               }
               },*/{
               location: "after",
               locateInMenu: 'never',
               showText: 'inMenu',
               widget: 'dxButton',
               disabled: self.notEditMode,
               visible: self.editMode,
               options: {
                  text: 'Cancel',
                  hint: 'Cancel Editing Recipients',
                  icon: 'close',
                  onClick: function onClick() {
                     self.set('editMode', false);
                     self.doCancel();
                  }
               }

            }, {
               location: "after",
               locateInMenu: 'auto',
               showText: 'inMenu',
               widget: 'dxButton',
               disabled: true,
               visible: false,
               options: {
                  text: 'Remove',
                  hint: 'Delete Selected Recipient',
                  icon: 'trash',
                  onClick: function onClick() {
                     DevExpress.ui.notify("Removing Recipient ...", "info", 2500);
                     self.doRemove();
                  }
               }
            }, {
               location: 'center',
               locateInMenu: 'never',
               showText: 'always',
               text: caption,
               template: function template(itemData, itemIndex, itemElement) {
                  itemElement.append("<div class='header-text'><h2>" + itemData.text + "</h2></div>");
               }
            }]
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxToolbarRecipientsContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxToolbarRecipientsContainer").dxToolbar('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _modeChanged: (function () {
         var isAdding = this.get('addMode');
         var isEditing = this.get('editMode');
         var isNotAdding = this.get('notAddMode');
         var isNotEditing = this.get('notEditMode');
         var itemsArray = this.$("#dxToolbarRecipientsContainer").dxToolbar('option', 'items');

         // Item 1 = Add Button
         itemsArray[1].disabled = isEditing || isAdding;
         itemsArray[1].visible = isNotEditing && isNotAdding;
         // Item 2 = Edit Button
         itemsArray[2].disabled = isEditing || isAdding;
         itemsArray[2].visible = isNotEditing && isNotAdding;
         // Item 3 = Save Button
         //itemsArray[3].disabled = (isNotEditing && isNotAdding);
         //itemsArray[3].visible = isAdding;
         // Item 4 = Cancel Button
         itemsArray[3].disabled = isNotEditing && isNotAdding;
         itemsArray[3].visible = isEditing || isAdding;

         this.$("#dxToolbarRecipientsContainer").dxToolbar("instance").repaint();
      }).observes('editMode', 'addMode')
   });
});