define("frontend/services/auth-manager", ["exports", "frontend/models/api_key", "ember", "ember-data", "ember-cli-js-cookie"], function (exports, _frontendModelsApi_key, _ember, _emberData, _emberCliJsCookie) {
   exports["default"] = _ember["default"].Service.extend({
      store: _ember["default"].inject.service(),

      // Load the current user if the cookies exist and is valid
      init: function init() {
         this._super();
         var self = this;
         var authUser = _emberCliJsCookie["default"].get('user');

         if (!_ember["default"].isEmpty(authUser)) {
            self.authenticate(JSON.parse(authUser));
         }
      },

      tryAuthenticate: function tryAuthenticate(email, password, captcha_response, callback) {
         var host = this.get('store').adapterFor('application').get('host');
         var postUrl = [host, 'users/loginuser'].join('/');
         var email_lwc = email;
         if (email_lwc) {
            email_lwc = email_lwc.toLowerCase();
         }
         var data = { 'email': email_lwc, 'password': password, 'captcha_response': captcha_response };
         var self = this;
         /*let results = null;
          let timer1 = setTimeout(function() {
            if (!results || results.status === "false") {
               callback(null);
            }
         }, 10000);*/

         _ember["default"].$.post({
            url: postUrl,
            crossDomain: true,
            data: data,
            success: function success(results) {
               /*if (timer1) {
                  clearTimeout(timer1);
               }*/
               if (results.status === "true") {
                  self.authenticate({ user_email: results.user_email, id: results.id, token: results.token });
               }
               callback(results);
            },
            error: function error(err) {
               //clearTimeout(timer1);
               callback(err.responseJSON);
            }
         });
      },

      tryReAuthenticate: function tryReAuthenticate(email, password, callback) {
         var host = this.get('store').adapterFor('application').get('host');
         var postUrl = [host, 'users/authuser'].join('/');
         var email_lwc = email;
         if (email_lwc) {
            email_lwc = email_lwc.toLowerCase();
         }
         var data = { 'email': email_lwc, 'password': password };

         _ember["default"].$.post({
            url: postUrl,
            crossDomain: true,
            data: data,
            success: function success(results) {
               callback(results);
            },
            error: function error(err) {
               callback(err.responseJSON);
            }
         });
      },

      resendTokenUser: function resendTokenUser(callingCode, phone, callback) {
         var host = this.get('store').adapterFor('application').get('host');
         var postUrl = [host, 'users/reverify'].join('/');
         var data = { 'callingCode': callingCode, 'phone': phone };

         _ember["default"].$.post({
            url: postUrl,
            crossDomain: true,
            data: data,
            success: function success(results) {
               callback(results);
            },
            error: function error(results) {
               callback(results);
            }
         });
      },

      forgotEmail: function forgotEmail(callingCode, phone, callback) {
         var host = this.get('store').adapterFor('application').get('host');
         var postUrl = [host, 'users/forgot-email'].join('/');
         var data = { 'callingCode': callingCode, 'phone': phone };

         _ember["default"].$.post({
            url: postUrl,
            crossDomain: true,
            data: data,
            success: function success(results) {
               callback(results);
            },
            error: function error(results) {
               callback(results);
            }
         });
      },

      forgotPassword: function forgotPassword(email, callingCode, phone, callback) {
         var host = this.get('store').adapterFor('application').get('host');
         var postUrl = [host, 'users/forgot-password'].join('/');
         var email_lwc = email;
         if (email_lwc) {
            email_lwc = email_lwc.toLowerCase();
         }
         var data = { 'email': email_lwc, 'callingCode': callingCode, 'phone': phone };

         _ember["default"].$.post({
            url: postUrl,
            crossDomain: true,
            data: data,
            success: function success(results) {
               callback(results);
            },
            error: function error(results) {
               callback(results);
            }
         });
      },

      verifyTokenUser: function verifyTokenUser(validation, code, callback) {
         var host = this.get('store').adapterFor('application').get('host');
         var postUrl = [host, 'users/verify', validation].join('/');
         var data = { 'code': code };

         _ember["default"].$.post({
            url: postUrl,
            crossDomain: true,
            data: data,
            success: function success(results) {
               callback(results);
            },
            error: function error(results) {
               callback(results);
            }
         });
      },

      verifyTokenPhone: function verifyTokenPhone(validation, code, callback) {
         var host = this.get('store').adapterFor('application').get('host');
         var postUrl = [host, 'users/verify/phone', validation].join('/');
         var data = { 'code': code };

         _ember["default"].$.post({
            url: postUrl,
            crossDomain: true,
            data: data,
            success: function success(results) {
               callback(results);
            },
            error: function error(results) {
               callback(results);
            }
         });
      },

      verifyTokenEmail: function verifyTokenEmail(id, code, callback) {
         var host = this.get('store').adapterFor('application').get('host');
         var postUrl = [host, 'users/forgot-email/verify'].join('/');
         var data = { 'id': id, 'code': code };

         _ember["default"].$.post({
            url: postUrl,
            crossDomain: true,
            data: data,
            success: function success(results) {
               callback(results);
            },
            error: function error(results) {
               callback(results);
            }
         });
      },

      verifyTokenPassword: function verifyTokenPassword(id, code, callback) {
         var host = this.get('store').adapterFor('application').get('host');
         var postUrl = [host, 'users/forgot-password/verify'].join('/');
         var data = { 'id': id, 'code': code };

         _ember["default"].$.post({
            url: postUrl,
            crossDomain: true,
            data: data,
            success: function success(results) {
               callback(results);
            },
            error: function error(results) {
               callback(results);
            }
         });
      },

      getUser: (function () {
         return this.get('apiKey');
      }).property('apiKey'),

      // Determine if the user is currently authenticated.
      isAuthenticated: function isAuthenticated() {
         return !_ember["default"].isEmpty(this.get('apiKey'));
      },

      authenticate: function authenticate(user) {
         _emberCliJsCookie["default"].set('user', JSON.stringify({
            user_email: user.user_email,
            id: user.id,
            token: user.token
         }));

         //ONLY USED IN STATELESS APIS - TOKENS
         _ember["default"].$.ajaxSetup({
            headers: { 'Authorization': 'Bearer ' + user.token }
         });

         var apiKey = _frontendModelsApi_key["default"].create({
            user_email: user.user_email,
            id: user.id,
            token: user.token
         });
         this.set('apiKey', apiKey);
      },

      // Log out the user
      reset: function reset() {
         this.set('apiKey', null);

         //ONLY NEEDED FOR STATELESS AUTH - TOKENS
         _ember["default"].$.ajaxSetup({
            headers: { 'Authorization': 'Bearer none' }
         });
      },

      // Ensure that when the apiKey changes, we store the data in cookies in order for us to load
      // the user when the browser is refreshed.
      apiKeyObserver: (function () {
         var self = this;

         if (_ember["default"].isEmpty(this.get('apiKey'))) {
            _emberCliJsCookie["default"].set('user', null, { expires: -1, path: '/' });
            window.location.reload();
         } else {
            _emberCliJsCookie["default"].set('user', JSON.stringify(self.get('apiKey')));
         }
      }).observes('apiKey')
   });

   // Reset the authentication if any ember data request returns a 401 unauthorized error
   _emberData["default"].rejectionHandler = function (reason) {
      if (reason.status === 401) {
         alert("User Authentication Reset!");
         this.authManager.reset();
      }
      throw reason;
   };
});