define('frontend/models/bill', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _emberDataRelationships) {
   exports['default'] = _emberData['default'].Model.extend({
      status: _emberData['default'].attr('string'), // unpaid, paid, overdue
      isOverDue: _emberData['default'].attr('boolean', {
         defaultValue: false
      }),
      totalAmount: _emberData['default'].attr('number', {
         defaultValue: 0.0
      }),
      newAmount: _emberData['default'].attr('number', {
         defaultValue: 0.0
      }),
      overdueAmount: _emberData['default'].attr('number', {
         defaultValue: 0.0
      }),
      created: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      viewed: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      due: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      paid: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      user: _emberData['default'].attr('string'),
      subscription: (0, _emberDataRelationships.belongsTo)('subscription'),
      payments: (0, _emberDataRelationships.hasMany)('payment')
   });
});