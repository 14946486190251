define('frontend/components/dx-text-login', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      min: 8,
      max: 32,
      capsLockOn: false,
      isInvalid: false,
      isError: false,
      errorMessage: null,
      attributeBindings: ['isInvalid', 'isCapsOn'],

      _initialize: (function () {
         var self = this;
         var validationGroup = this.get('group');

         this.$("#dxTextLoginContainer").dxTextBox({
            activeStateEnabled: true,
            inputAttr: { id: this.get('idAttr') },
            disabled: this.get('disabled'),
            readOnly: this.get('readOnly'),
            hint: this.get('hint'),
            mode: this.get('mode'),
            maxLength: self.get('max'),
            placeholder: this.get('placeholder'),
            fontFamily: 'Arial',
            fontSize: 24,
            validationMessageMode: 'always',
            value: this.get('value'),
            buttons: [{
               name: 'password',
               location: 'after',
               stylingMode: 'text',
               type: 'normal',
               options: {
                  icon: 'fas fa-eye',
                  onClick: function onClick() {
                     var mode = self.get('mode');
                     if (mode === 'text') {
                        mode = 'password';
                        this.option('icon', 'fas fa-eye');
                     } else {
                        mode = 'text';
                        this.option('icon', 'fas fa-eye-slash');
                     }
                     self.set('mode', mode);
                  }
               }
            }],
            onInput: function onInput() {
               self.clearError();
            },
            onValueChanged: function onValueChanged(e) {
               self.clearError();
               self.set('value', e.value);
            }
         }).dxValidator({
            validationGroup: validationGroup,
            validationRules: [{ type: 'required', message: 'Password is required' }, { type: 'custom', reevaluate: true, validationCallback: function validationCallback(options) {
                  if (self.get('isError')) {
                     options.rule.message = self.get('errorMessage');
                     return false;
                  } else {
                     options.rule.message = null;
                     return true;
                  }
               } }],
            onValidated: function onValidated(e) {
               try {
                  if (self.get('isDestroyed') || self.get('isDestroying')) {
                     return;
                  }
                  if (e.isValid) {
                     self.set('isInvalid', false);
                     self.$("#dxTextLoginContainer.dx-texteditor .dx-texteditor-container").addClass("dx-valid");
                  } else {
                     self.set('isInvalid', true);
                     self.$("#dxTextLoginContainer.dx-texteditor .dx-texteditor-container").removeClass("dx-valid");
                  }
               } catch (exp) {
                  console.log("Exception Error: " + exp);
               }
            }
         }).keypress(function (e) {
            self.set('capsLockOn', self.isCapslock(e));
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxTextLoginContainer").remove();
      }).on('willDestroyElement'),

      clearError: function clearError() {
         if (this.get('isError')) {
            this.set('errorMessage', null);
            this.set('isError', false);
            this.$("#dxTextLoginContainer").dxValidator('instance').validate();
         }
      },
      isCapslock: function isCapslock(e) {
         var charCode = false;
         e = e ? e : window.event;

         if (e.which) {
            charCode = e.which;
         } else if (e.keyCode) {
            charCode = e.keyCode;
         }
         var shiftOn = false;
         if (e.shiftKey) {
            shiftOn = e.shiftKey;
         } else if (e.modifiers) {
            shiftOn = !!(e.modifiers & 4);
         }
         if (charCode >= 97 && charCode <= 122 && shiftOn || charCode >= 65 && charCode <= 90 && !shiftOn) {
            return true;
         }
         return false;
      },
      _disabledChanged: (function () {
         this.$("#dxTextLoginContainer").dxTextBox('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _modeChanged: (function () {
         this.$("#dxTextLoginContainer").dxTextBox('option', 'mode', this.get('mode'));
      }).observes('mode'),

      _valueChanged: (function () {
         var val = this.get('value');
         if (val) {
            this.$("#dxTextLoginContainer").dxTextBox('option', 'value', val);
         } else {
            this.$("#dxTextLoginContainer").dxValidator('instance').reset();
         }
      }).observes('value'),

      _isErrorChanged: (function () {
         if (this.get('isError')) {
            this.$("#dxTextLoginContainer").dxValidator('instance').validate();
            this.$("#dxTextLoginContainer").dxValidator('instance').focus();
         }
      }).observes('isError')
   });
});