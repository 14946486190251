define('frontend/components/dx-form-card-details', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      isInvalid: false,
      attributeBindings: ['isInvalid'],

      _initialize: (function () {
         var self = this;
         var month = "August";
         var year = "2020";
         var validationGroup = this.get('group');

         this.$("#dxFormCardDetailsContainer").dxForm({
            disabled: this.get('disabled'),
            readOnly: this.get('readOnly'),
            showColonAfterLabel: false,
            labelLocation: "left",
            minColWidth: 300,
            colCount: 2,
            requiredMark: "*",
            validationGroup: validationGroup,
            items: [{
               colSpan: 3,
               cssClass: this.get('borderClass'),
               dataField: 'customer',
               editorType: 'dxTextBox',
               isRequired: true,
               label: {
                  text: 'Name on Card'
               },
               editorOptions: {
                  hint: 'Enter the Name on the Card',
                  placeholder: 'Name'
               }
            }, {
               colSpan: 3,
               cssClass: this.get('borderClass'),
               dataField: 'number',
               editorType: 'dxTextBox',
               isRequired: true,
               label: {
                  text: 'Card Number'
               },
               editorOptions: {
                  hint: 'Enter the Card Number',
                  mask: "XXXX-XXXX-XXXX-XXXX",
                  maskRules: { "X": /[0-9]/ },
                  maskInvalidMessage: "The credit-card number must have the correct format",
                  useMaskedValue: true,
                  placeholder: 'Card Number'
               }
            }, {
               colSpan: 1,
               cssClass: this.get('borderClass'),
               dataField: 'exp_month',
               editorType: 'dxSelectBox',
               isRequired: true,
               label: {
                  text: 'Expiration Month'
               },
               editorOptions: {
                  placeholder: 'Expiration Month',
                  hint: 'Select the Card Expiration Month',
                  value: month
               }
            }, {
               colSpan: 1,
               cssClass: this.get('borderClass'),
               dataField: 'exp_year',
               editorType: 'dxNumberBox',
               isRequired: true,
               label: {
                  text: 'Expiration Year'
               },
               editorOptions: {
                  placeholder: 'Expiration Year',
                  hint: 'Select the Card Expiration Year',
                  min: 2010,
                  max: 2099,
                  showSpinButtons: true,
                  step: 1,
                  value: year
               }
            }, {
               colSpan: 1,
               cssClass: this.get('borderClass'),
               dataField: 'cvc_check',
               editorType: 'dxTextBox',
               isRequired: true,
               label: {
                  text: 'Security Code'
               },
               editorOptions: {
                  hint: 'Enter the Card Security Code',
                  placeholder: 'CVC'
               }
            }],
            onValidated: function onValidated(e) {
               try {
                  if (self.get('isDestroyed') || self.get('isDestroying')) {
                     return;
                  }
                  if (e.isValid) {
                     self.set('isInvalid', false);
                  } else {
                     self.set('isInvalid', true);
                  }
               } catch (exp) {
                  console.log("Exception Error: " + exp);
               }
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxFormCardDetailsContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxFormCardDetailsContainer").dxForm('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});