define('frontend/components/dx-scrollview-help', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      filePath: '/assets/docs/PWRON_Help.html',
      _initialize: (function () {
         var self = this;
         this.$("#dxScrollViewHelpContainer").dxScrollView({
            disabled: this.get('disabled'),
            scrollByContent: true,
            scrollByThumb: true,
            showScrollbar: 'onScroll',
            onInitialized: function onInitialized(e) {
               var filePath = self.get('filePath');
               var textContent = _ember['default'].$("<div id='helpTextContent' class='text-scrollable'></div>");
               e.element.append(textContent);
               textContent.on("dxmousewheel", function (e) {
                  e.stopPropagation();
               });
               _ember['default'].$("#helpTextContent").load(filePath, function () {
                  console.log('Help Page: Content Loaded');
               });
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxScrollViewHelpContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxScrollViewHelpContainer").dxForm('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});