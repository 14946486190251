define('frontend/app', ['exports', 'ember', 'frontend/resolver', 'ember-load-initializers', 'frontend/config/environment'], function (exports, _ember, _frontendResolver, _emberLoadInitializers, _frontendConfigEnvironment) {

   //Ember.MODEL_FACTORY_INJECTIONS = true;

   var App = _ember['default'].Application.extend({
      modulePrefix: _frontendConfigEnvironment['default'].modulePrefix,
      podModulePrefix: _frontendConfigEnvironment['default'].podModulePrefix,
      Resolver: _frontendResolver['default'],
      customEvents: {
         mousewheel: null,
         touchstart: null,
         touchmove: null
      }
   });
   (0, _emberLoadInitializers['default'])(App, _frontendConfigEnvironment['default'].modulePrefix);

   exports['default'] = App;
});