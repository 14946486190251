define('frontend/components/dx-grid-sensor-logs', ['exports', 'ember'], function (exports, _ember) {
   //import moment from 'moment';
   //import ExcelJS from 'exceljs';

   exports['default'] = _ember['default'].Component.extend({
      allowExport: false,
      deviceName: null,
      isWideMode: true,
      mode: 'single',
      selectedLog: null,
      showAltFields: true,
      dataSource: [],
      attributeBindings: ['dataSource', 'selectedLog'],

      _initialize: (function () {
         var self = this;
         var clearSelectionButton = undefined;
         //let showAltFields = this.get('showAltFields');

         this.$("#dxGridSensorLogsContainer").dxDataGrid({
            dataSource: this.get('dataSource'),
            noDataText: 'No Sensor Logs found!',
            columnAutoWidth: true,
            errorRowEnabled: false,
            loadPanel: {
               enabled: true,
               text: 'Refreshing Sensor Logs...'
            },
            editing: {
               mode: "row",
               allowEditing: false,
               allowUpdating: false,
               allowDeleting: false
            },
            'export': {
               enabled: this.get('allowExport'),
               allowExportSelectedData: true
            },
            /*fileName: 'SensorLogs_' + this.get('deviceName') + '_' + moment(new Date()).format('YYYY_MM_DD_HH_mm')*/
            remoteOperations: {
               sorting: true,
               paging: true
            },
            paging: {
               pageSize: 10
            },
            pager: {
               showPageSizeSelector: true,
               allowedPageSizes: [5, 10, 20, 50],
               showInfo: true
            },
            searchPanel: {
               visible: self.get('isWideMode'),
               width: 150
            },
            selection: {
               mode: self.get('mode'),
               allowSelectAll: this.get('allowSelectAll')
            },
            rowAlternationEnabled: false,
            showColumnLines: true,
            showRowLines: true,
            showBorders: true,
            hoverStateEnabled: true,
            columns: [{
               dataField: 'created', caption: 'Timestamp',
               alignment: 'left', cssClass: 'row-vert-logs',
               minWidth: 180,
               allowEditing: false,
               sortOrder: 'desc'
            }, /*,{
                dataField: "group", caption: "Group",
                alignment: "center", cssClass: "row-vert",
                allowEditing: false,
                groupIndex: 0,
                visible: showAltFields
               },{
                dataField: "type", caption: "Type",
                alignment: "center", cssClass: "row-vert",
                allowEditing: false,
                visible: showAltFields
               },*/{
               dataField: 'humidity',
               alignment: 'center', cssClass: 'row-vert-logs',
               minWidth: 64,
               format: { type: 'fixedPoint', precision: 1 },
               allowEditing: false,
               allowFiltering: false,
               allowSorting: false,
               visible: true
            }, {
               dataField: 'temperature', caption: 'Temperature',
               alignment: 'center', cssClass: 'row-vert-logs',
               minWidth: 64,
               format: { type: 'fixedPoint', precision: 1 },
               allowEditing: false,
               allowFiltering: false,
               allowSorting: false,
               visible: true
            }, {
               dataField: 'temperature_aux', caption: 'Temp. (AUX)',
               alignment: 'center', cssClass: 'row-vert-logs',
               minWidth: 64,
               format: { type: 'fixedPoint', precision: 1 },
               allowEditing: false,
               allowFiltering: false,
               allowSorting: false,
               visible: true
            }, {
               dataField: 'temperature_ext', caption: 'Temp. (EXT)',
               alignment: 'center', cssClass: 'row-vert-logs',
               minWidth: 64,
               format: { type: 'fixedPoint', precision: 1 },
               allowEditing: false,
               allowFiltering: false,
               allowSorting: false,
               visible: true
            }, /*{
                dataField: "current", alignment: "center", cssClass: "row-vert",
                minWidth: 64,
                format: {type: 'fixedPoint', precision: 1},
                allowEditing: false,
                allowFiltering: false,
                allowSorting: false,
                visible: true
               },*/{
               dataField: 'battery_volts', caption: 'Battery (mV)',
               alignment: 'center', cssClass: 'row-vert-logs',
               minWidth: 64,
               format: { type: 'fixedPoint', precision: 1 },
               allowEditing: false,
               allowFiltering: false,
               allowSorting: false,
               visible: true
            }, {
               dataField: 'battery_soc', caption: 'Battery SOC (%)',
               alignment: 'center', cssClass: 'row-vert-logs',
               minWidth: 64,
               format: { type: 'fixedPoint', precision: 1 },
               allowEditing: false,
               allowFiltering: false,
               allowSorting: false,
               visible: true
            }],
            /*onContentReady: function() {
               if (!self.get('selectedLog')) {
                  // Select first row (First data row index is 1. First group is 0) ...
                  self.selectRow(0);
               }
            },*/
            onSelectionChanged: function onSelectionChanged(selectedItems) {
               var data = selectedItems.selectedRowsData;
               if (data.length > 0) {
                  var log = data[0];
                  self.setSelectedLog(log);
               } else {
                  self.setSelectedLog(null);
               }
               clearSelectionButton.option('disabled', !data.length);
            },
            onCellClick: function onCellClick(e) {
               if (e.rowType === 'data') {
                  //self.selectRow(e.rowIndex);
                  console.log("Data Row selected: %d", e.rowIndex);
               }
            },
            /*onExporting: function(e) {
               const workbook = new ExcelJS.Workbook();
               const worksheet = workbook.addWorksheet('Sensor Logs');
                worksheet.columns = [
                  { width: 5 }, { width: 30 }, { width: 25 }, { width: 15 }, { width: 25 }, { width: 40 },
               ];
                // Use DevExpress exporter to 'SaveAs' instead of internal method ...
               DevExpress.excelExporter.exportDataGrid({
                  component: e.component,
                  worksheet: worksheet,
                  keepColumnWidths: false,
                  topLeftCell: { row: 2, column: 2 },
                  loadPanel: {
                     enabled: true,
                     text: 'Exporting Sensor Logs...'
                  },
                  customizeCell: function(options) {
                     const { gridCell, excelCell } = options;
                      if (gridCell.rowType === 'data') {
                        if (gridCell.column.dataField === 'created') {
                           excelCell.alignment = { horizontal: 'left' };
                        } else {
                           excelCell.alignment = { horizontal: 'center' };
                        }
                        if (gridCell.column.dataField === 'Phone') {
                           excelCell.value = parseInt(gridCell.value, 10);
                           excelCell.numFmt = '[<=9999999]###-####;(###) ###-####';
                        }
                        if (gridCell.column.dataField === 'Website') {
                           excelCell.value = { text: gridCell.value, hyperlink: gridCell.value };
                           excelCell.font = { color: { argb: 'FF0000FF' }, underline: true };
                           excelCell.alignment = { horizontal: 'left' };
                        }
                     }
                     if (gridCell.rowType === 'group') {
                        excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } };
                     }
                     if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                        excelCell.font.italic = true;
                     }
                  }
               }).then(function() {
                  let filename = 'SensorLogs_' + self.get('deviceName') + '_' + moment(new Date()).format('YYYY_MM_DD_HH_mm') + '.xlsx';
                  workbook.xlsx.writeBuffer().then(function(buffer) {
                     saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename);
                  });
               });
               e.cancel = true;
            },*/
            onExported: function onExported() {
               DevExpress.ui.notify("Data exported successfully", "success", 2500);
            },
            onToolbarPreparing: function onToolbarPreparing(e) {
               var dataGrid = e.component;
               var logCount = getLogCount('group');
               //let groupCount = getGroupCount('group');
               var logText = logCount === 1 ? 'Log' : 'Logs';
               //let groupText = (groupCount === 1) ? 'Group' : 'Groups';

               e.toolbarOptions.items.unshift({
                  showText: 'inMenu',
                  widget: 'dxButton',
                  location: 'before',
                  options: {
                     disabled: true,
                     hint: 'Clear Selected Logs',
                     icon: 'far fa-times',
                     text: 'Clear Selection',
                     onInitialized: function onInitialized(e) {
                        clearSelectionButton = e.component;
                     },
                     onClick: function onClick() {
                        dataGrid.clearSelection();
                     }
                  }
               }, {
                  location: 'before',
                  locateInMenu: 'never',
                  template: function template() {
                     return _ember['default'].$("<div/>").addClass('informer').append(_ember['default'].$("<h3 />").addClass('device-count').text(logCount), _ember['default'].$("<span />").addClass('name').text(logText));
                  }
               }, /*{
                   location: "before",
                   locateInMenu: 'auto',
                   widget: "dxSelectBox",
                   options: {
                      width: 195,
                      items: [{
                         value: "type",
                         text: "Group by Load Type"
                      }, {
                         value: "group",
                         text: "Group by Device Group"
                      }],
                      displayExpr: "text",
                      valueExpr: "value",
                      value: "group",
                      onValueChanged: function(e) {
                         dataGrid.clearGrouping();
                         dataGrid.columnOption(e.value, "groupIndex", 0);
                         groupCount = getGroupCount(e.value);
                         Ember.$(".informer .total-count").text(groupCount);
                         if (e.value === 'type') {
                            groupText = "Types";
                            if (groupCount === 1) { groupText = "Type"; }
                         } else {
                            groupText = "Groups";
                            if (groupCount === 1) { groupText = "Group"; }
                         }
                         Ember.$(".informer .total-count + .name").text(groupText);
                      }
                   }
                  },{
                   location: "before",
                   locateInMenu: 'auto',
                   showText: 'inMenu',
                   widget: "dxButton",
                   widgetClass: "btn-primary",
                   options: {
                      hint: "Collapse All",
                      icon: "chevrondown",
                      text: "Collapse All",
                      onClick: function (e) {
                         let expanding = e.component.option("icon") === "chevronnext";
                         dataGrid.option("grouping.autoExpandAll", expanding);
                         e.component.option({
                            icon: expanding ? "chevrondown" : "chevronnext",
                            hint: expanding ? "Collapse All" : "Expand All",
                            text: expanding ? "Collapse All" : "Expand All"
                         });
                      }
                   }
                  },{
                   location: 'before',
                   locateInMenu: 'never',
                   template: function () {
                      return Ember.$("<div/>").addClass("informer")
                         .append(Ember.$("<h3 />").addClass("total-count").text(groupCount),
                            Ember.$("<span />").addClass("name").text(groupText)
                         );
                   }
                  },*/{
                  location: 'after',
                  locateInMenu: 'auto',
                  showText: 'inMenu',
                  widget: "dxButton",
                  options: {
                     hint: 'Refresh Sensor Logs',
                     icon: 'refresh',
                     text: 'Refresh',
                     onClick: function onClick() {
                        dataGrid.refresh();
                     }
                  }
               });
            }
         });
         function getLogCount() {
            var data = self.get('dataSource');
            if (data) {
               return DevExpress.data.query(data).toArray().length;
            } else {
               return 0;
            }
         }
         /*function getGroupCount(groupField) {
            let data = self.get('dataSource');
            if (data) {
               return DevExpress.data.query(data).groupBy(groupField).toArray().length;
            } else {
               return 0;
            }
         }*/
      }).on('didInsertElement'),

      selectRow: function selectRow(index) {
         if (index >= 0) {
            var grid = this.$("#dxGridSensorLogsContainer").dxDataGrid('instance');
            if (grid) {
               grid.selectRowsByIndexes([index]);
            }
         }
      },
      setSelectedLog: function setSelectedLog(log) {
         if (log) {
            this.set('selectedLog', log);
         } else {
            this.set('selectedLog', null);
         }
      },

      _destroy: (function () {
         this.$("#dxGridSensorLogsContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxGridSensorLogsContainer").dxDataGrid('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _dataSourceChanged: (function () {
         this.$("#dxGridSensorLogsContainer").dxDataGrid('option', 'dataSource', this.get('dataSource'));
      }).observes('dataSource'),

      _isWideModeChanged: (function () {
         var isWideMode = this.get('isWideMode');
         this.$("#dxGridSensorLogsContainer").dxDataGrid('instance').option('columns[1].visible', isWideMode);
         this.$("#dxGridSensorLogsContainer").dxDataGrid('instance').option('columns[2].visible', isWideMode);
         this.$("#dxGridSensorLogsContainer").dxDataGrid('instance').option('searchPanel.visible', isWideMode);
      }).observes('isWideMode')
   });
});