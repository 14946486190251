define('frontend/components/dx-radio-group-plan', ['exports', 'ember', 'frontend/config/environment'], function (exports, _ember, _frontendConfigEnvironment) {
   exports['default'] = _ember['default'].Component.extend({
      plans: [],
      filteredPlans: [],

      _initialize: (function () {
         var self = this;
         this.refreshPlans();
         //console.log("Plans: " + JSON.stringify(this.get('filteredPlans')));
         this.$("#dxRadioGroupContainer").dxRadioGroup({
            layout: 'horizontal',
            items: this.get('filteredPlans'),
            value: this.get('value'),
            onValueChanged: function onValueChanged(e) {
               self.set('value', e.value);
            },
            itemTemplate: function itemTemplate(itemData, itemIndex, itemElement) {
               var price = (itemData.amount / 100.0).toFixed(2).replace(/(\d)(?=(\d{3})+$)/g, "$1,");
               var units = 'yr';
               if (itemData.id === 'pwron-daily-plan') {
                  units = 'day';
               } else if (itemData.id === 'pwron-weekly-plan') {
                  units = 'wk';
               } else if (itemData.id === 'pwron-free-plan' || itemData.id === 'pwron-monthly-plan') {
                  units = 'mth';
               } else if (itemData.id === 'pwron-free-yearly-plan' || itemData.id === 'pwron-yearly-plan') {
                  units = 'yr';
               }
               itemElement.append('<div class="plan-container center"><h3>' + itemData.name + '</h3><p>Initial SMS: ' + _frontendConfigEnvironment['default'].APP.MAX_SMS_CREDITS + '</p><p>E-mails: unlimited' + '</p><p class="text-large">$' + price + '/' + units + '</p></div>');
               itemElement.parent().addClass(itemData.id.toLowerCase());
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxRadioGroupContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxRadioGroupContainer").dxRadioGroup('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _valueChanged: (function () {
         this.$("#dxRadioGroupContainer").dxRadioGroup('option', 'value', this.get('value'));
      }).observes('value'),

      _plansChanged: (function () {
         this.refreshPlans();
      }).observes('plans'),

      refreshPlans: function refreshPlans() {
         var plans = this.get('plans');
         var filteredPlans = this.get('filteredPlans');

         if (plans) {
            // Clear the existing array ...
            while (filteredPlans.length > 0) {
               filteredPlans.pop();
            }
            // Push only default (pwron-free-yearly-plan) to array ...
            for (var i = 0; i < plans.get('length'); i++) {
               var plan = plans.objectAt(i);
               if (_frontendConfigEnvironment['default'].environment === 'test' || _frontendConfigEnvironment['default'].environment === 'development') {
                  if (plan && plan.id /*=== config.APP.DEFAULT_PLAN || plan.id === 'pwron-free-plan')*/) {
                        filteredPlans.push(plan);
                     }
               } else {
                  if (plan && plan.id === _frontendConfigEnvironment['default'].APP.DEFAULT_PLAN) {
                     filteredPlans.push(plan);
                  }
               }
            }
            // No need to update dxRadioGroup items because
            // filteredPlans is referenced to the main component array.
            // This will actually cause an error because this
            // function is called from initialize().
         }
      }
   });
});