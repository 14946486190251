define('frontend/router', ['exports', 'ember', 'frontend/config/environment'], function (exports, _ember, _frontendConfigEnvironment) {

   var Router = _ember['default'].Router.extend({
      location: _frontendConfigEnvironment['default'].locationType,
      rootURL: _frontendConfigEnvironment['default'].rootURL
   });

   Router.map(function () {
      this.route('index', { path: '/' });
      this.route("fourohfour", { path: "*path" });
      this.route('users', function () {
         this.route('setup');
         this.route('finddevice');
         this.route('adddevice');
         this.route('forgot-email');
         this.route('forgot-password');
         this.route('login');
         this.route('logout');
         this.route('reset-password');
      });

      this.route('home', function () {
         this.route('dashboard');
         this.route('devices');
         this.route('notifications');
         this.route('device', function () {
            this.route('analytics');
            this.route('settings');
            this.route('recipients');
         });
         this.route('profile', function () {
            this.route('administrator');
            this.route('address');
            this.route('password');
         });
         this.route('account', function () {
            this.route('details');
            this.route('billing');
            this.route('subscriptions');
         });
         this.route('appsettings');
         this.route('help');
      });
   });

   exports['default'] = Router;
});