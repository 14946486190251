define('frontend/routes/users/reset-password', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model() {
         var self = this;
         var userID = self.get('authManager').get('getUser.id');

         if (userID) {
            return this.store.findRecord('user', userID).then(function (user) {
               return user;
            })['catch'](function (error) {
               console.log("Can't find User record! Error: %s", error.message);
               self.logOut();
               return null;
            });
         } else {
            console.log("Error: Can't find User record without an ID!");
            self.logOut();
            return null;
         }
      },
      actions: {
         willTransition: function willTransition() {
            var controller = this.controllerFor('users.reset-password');
            controller.clearPasswords();
            controller.clearErrors();
         }
      },
      logOut: function logOut() {
         DevExpress.ui.notify("Error Resetting Password: User Not Authenticated!", "error", 2500);
         //this.get('authManager').reset();
         //this.transitionTo('users.login');
      }
   });
});