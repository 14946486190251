define('frontend/components/dx-text-name', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      autoComp: null,
      autoComplete: false,
      autoCompleteListener: null,
      autoCompleteType: 'city',
      countryCode: '',
      clearButton: false,
      isInvalid: false,
      isError: false,
      errorMessage: null,
      attributeBindings: ['isInvalid'],

      _initialize: (function () {
         var self = this;
         var validationGroup = this.get('group');

         this.$("#dxTextNameContainer").dxTextBox({
            activeStateEnabled: true,
            inputAttr: { id: this.get('idAttr') },
            disabled: this.get('disabled'),
            readOnly: this.get('readOnly'),
            hint: this.get('hint'),
            showClearButton: this.get('clearButton'),
            placeholder: this.get('placeholder'),
            fontFamily: 'Arial',
            maxLength: 48,
            validationMessageMode: 'always',
            value: this.get('value'),
            onInput: function onInput() {
               self.clearError();
            },
            onValueChanged: function onValueChanged(e) {
               self.clearError();
               self.set('value', e.value);
            }
         }).dxValidator({
            validationGroup: validationGroup,
            validationRules: [{ type: 'required', reevaluate: true, message: 'Name is required' }, { type: 'stringLength', reevaluate: true, min: 2, max: this.maxLength, message: 'Name must be at least 2 characters!' }, { type: 'pattern', reevaluate: true, pattern: "(^[A-Za-z])", message: "The name must start with a letter!" }, { type: 'pattern', reevaluate: true, pattern: "(^[A-Za-z][A-Za-z\\-\\'\\`\\s]+$)", message: "May only contain letters, hyphens and spaces!" }, { type: 'custom', reevaluate: true, validationCallback: function validationCallback(options) {
                  if (self.get('isError')) {
                     options.rule.message = self.get('errorMessage');
                     return false;
                  } else {
                     options.rule.message = null;
                     return true;
                  }
               } }],
            onValidated: function onValidated(e) {
               try {
                  if (self.get('isDestroyed') || self.get('isDestroying')) {
                     return;
                  }
                  if (e.isValid) {
                     self.set('isInvalid', false);
                     self.$("#dxTextNameContainer.dx-texteditor .dx-texteditor-container").addClass("dx-valid");
                  } else {
                     self.set('isInvalid', true);
                     self.$("#dxTextNameContainer.dx-texteditor .dx-texteditor-container").removeClass("dx-valid");
                  }
               } catch (exp) {
                  console.log("Validation Error (Exp): " + exp);
               }
            }
         });
         this.setAutoComplete();
      }).on('didInsertElement'),

      clearError: function clearError() {
         if (this.get('isError')) {
            this.set('errorMessage', null);
            this.set('isError', false);
            this.$("#dxTextNameContainer").dxValidator('instance').validate();
         }
      },

      setAutoComplete: function setAutoComplete() {
         var _this = this;

         var self = this;
         var autoComplete = this.get('autoComplete');

         if (autoComplete) {
            (function () {
               var complete = _this.get('autoComp');
               var autoCompleteListener = _this.get('autoCompleteListener');
               var autoCompleteType = _this.get('autoCompleteType');
               var countryCode = _this.get('countryCode');
               var typeCode = '(cities)';
               var options = undefined;
               var input = undefined;

               if (autoCompleteType === 'city') {
                  typeCode = '(cities)';
                  input = document.getElementById('dxCity');
               } else if (autoCompleteType === 'region') {
                  typeCode = '(regions)';
                  input = document.getElementById('dxRegion');
               } else if (autoCompleteType === 'suburb') {
                  typeCode = '(regions)';
                  input = document.getElementById('dxSuburb');
               }
               if (input) {
                  input.placeholder = ''; // Use the dx-placeholder instead
                  if (countryCode === undefined || countryCode === null || countryCode === '') {
                     options = {
                        types: [typeCode],
                        componentRestrictions: { country: [] }
                     };
                  } else {
                     options = {
                        types: [typeCode],
                        componentRestrictions: { country: countryCode }
                     };
                  }
                  if (complete) {
                     google.maps.event.removeListener(autoCompleteListener);
                     google.maps.event.clearInstanceListeners(complete);
                     //Ember.$(".pac-container").remove();
                     complete = null;
                     input.value = '';
                     self.set('value', '');
                  }
                  complete = new google.maps.places.Autocomplete(input, options);
                  autoCompleteListener = complete.addListener('place_changed', function () {
                     var val = self.get('value');
                     // Get place details from auto-complete object ...
                     var place = complete.getPlace();
                     if (place) {
                        var found = false;
                        for (var i = 0; i < place.address_components.length; i++) {
                           var types = place.address_components[i].types;
                           for (var j = 0; j < types.length; j++) {
                              var addressType = types[j];
                              if (autoCompleteType === 'suburb' && addressType === 'locality') {
                                 val = place.address_components[i]['long_name'];
                                 found = true;
                                 break;
                              } else if (autoCompleteType === 'city' && addressType === 'locality') {
                                 val = place.address_components[i]['long_name'];
                                 found = true;
                                 break;
                              } else if (autoCompleteType === 'region' && (addressType === 'locality' || addressType === 'administrative_area_level_1')) {
                                 val = place.address_components[i]['long_name'];
                                 //found = true;
                                 break;
                              }
                           }
                           if (found) {
                              break;
                           }
                        }
                     }
                     input.value = val;
                     self.set('value', val);
                  });
                  self.set('autoComp', complete);
                  self.set('autoCompleteListener', autoCompleteListener);
               }
            })();
         }
      },

      _countryCodeChanged: (function () {
         this.setAutoComplete();
      }).observes('countryCode'),

      _destroy: (function () {
         this.$("#dxTextNameContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxTextNameContainer").dxTextBox('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _valueChanged: (function () {
         this.$("#dxTextNameContainer").dxTextBox('option', 'value', this.get('value'));
      }).observes('value'),

      _isErrorChanged: (function () {
         if (this.get('isError')) {
            this.$("#dxTextNameContainer").dxValidator('instance').validate();
            this.$("#dxTextNameContainer").dxValidator('instance').focus();
         }
      }).observes('isError')
   });
});