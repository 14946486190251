define('frontend/routes/users/setup', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model(params) {
         if (params && params.s) {
            if (this.get('authManager').isAuthenticated() && params.newAccount) {
               //console.log("User already authenticated! - Find Device");
               this.transitionTo('users.finddevice', { queryParams: { s: params.s } });
            } else {
               return this.createAccount();
            }
         } else {
            return this.createAccount();
         }
      },
      createAccount: function createAccount() {
         //let store = this.get('store');
         var user = this.store.createRecord('user');
         var location = this.store.createRecord('location', { user: user });

         return _ember['default'].RSVP.hash({
            user: user,
            location: location
         });
      }
   });
});