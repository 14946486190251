define("frontend/controllers/users/adddevice", ["exports", "ember"], function (exports, _ember) {

   var DEFAULT_DEVICE_NAME = "my new device";
   var DEVICE_NAME = "device name";
   var JUST_NAME = "name";

   exports["default"] = _ember["default"].Controller.extend({
      authManager: _ember["default"].inject.service('auth-manager'),
      appController: _ember["default"].inject.controller('application'),
      queryParams: ['s'],
      s: null, // device hash
      addGroup: 'addGroup',
      groupList: null,
      typeList: null,
      wantsNewAccount: false,
      selectedPlan: null,
      isAdvancedVisible: false,
      invalidInput: false,
      smsChargeConfirmed: false,
      isNameError: false,
      errorMessages: null,
      newDeviceName: null,
      canAddDevice: _ember["default"].computed('selectedPlan', 'smsChargeConfirmed', function () {
         console.log('computed canAddDevice');
         return this.selectedPlan && this.smsChargeConfirmed;
      }),

      init: function init() {
         this._super();
         this.set('groupList', this.get('appController.deviceGroups'));
         this.set('typeList', this.get('appController.deviceTypes'));
      },

      actions: {
         showEULA: function showEULA() {
            _ember["default"].$("#dxPopupEULAContainer").dxPopup("instance").show();
         },
         showTerms: function showTerms() {
            _ember["default"].$("#dxPopupTermsContainer").dxPopup("instance").show();
         },
         showPolicy: function showPolicy() {
            _ember["default"].$("#dxPopupPrivacyContainer").dxPopup("instance").show();
         },
         newAccount: function newAccount() {
            this.transitionToRoute("users.setup", {
               queryParams: {
                  s: this.get('model.device.temp_uuid'),
                  newAccount: true
               }
            });
         },
         /*cancelAndGoHome: function() {
            this.removeDevice();
            this.resetButton('#dxButtonHome', 'Home');
            this.transitionToRoute("home.dashboard");
         },*/
         accountRedirect: function accountRedirect() {
            this.transitionToRoute("home.dashboard");
         },
         loginRedirect: function loginRedirect() {
            //this.get('authManager').reset();
            this.transitionToRoute("users.login", { queryParams: { addDevice: this.get('s') } }); //model.device.temp_uuid')}});
         },
         displayOptions: function displayOptions() {
            this.set('wantsNewAccount', true);
         },
         selectPlan: function selectPlan(plan) {
            this.set('selectedPlan', plan);
         },
         toggleAdvanced: function toggleAdvanced() {
            this.toggleProperty('isAdvancedVisible');
         },
         /*showSensorsChanged: function() {
            this.get('model.device').toggleProperty('show_sensors');
         },*/
         cancelAdd: function cancelAdd() {
            var self = this;
            var result = DevExpress.ui.dialog.confirm("Are you sure you want to cancel adding the Device?", "Confirm Cancel");

            result.done(function (dialogResult) {
               if (dialogResult) {
                  DevExpress.ui.notify("Cancelling Add and Logging Out ...", "info", 2500);
                  self.removeDevice();
                  self.logOut();
               }
               self.resetButton('#dxButtonCancelAdd', 'Cancel');
               self.resetButton('#dxButtonLogout', 'Cancel');
            });
         },
         submit: function submit() {
            var self = this;
            var device = self.get('model.device');
            var settings = self.get('model.settings');
            var subscription = self.get('model.subscription');
            var user_id = self.get('authManager').get('getUser.id');
            var selectedPlan = self.get('selectedPlan');

            if (!user_id) {
               DevExpress.ui.notify("User is NOT Authenticated! Logging Out ...", "error", 2500);
               self.logOut();
            }
            self.resetError();

            if (!selectedPlan) {
               console.log("Add Device: No plan selected");
               DevExpress.ui.notify("Please select a subscription plan", "error", 2500);
               self.resetButton('#dxButtonAddDevice', 'Add Device');
               return;
            }
            if (device) {
               var newName = this.get('newDeviceName');
               if (!newName || newName.length === 0) {
                  console.log("Add Device: No device name entered");
                  DevExpress.ui.notify("Please enter a name for the device", "error", 2500);
                  self.resetButton('#dxButtonAddDevice', 'Add Device');
                  return;
               }
               var newNameLower = newName.toLowerCase();
               if (newNameLower === DEFAULT_DEVICE_NAME || newNameLower === DEVICE_NAME || newNameLower === JUST_NAME) {
                  var msg = "The entered Device name cannot be used";
                  self.set('isNameError', true);
                  self.set('errorMessages', msg);
                  DevExpress.ui.notify(msg, "error", 2500);
                  self.resetButton('#dxButtonAddDevice', 'Add Device');
                  return;
               }
               if (self.checkNameExists(newName)) {
                  var msg = "That Device name already exists!";
                  self.set('isNameError', true);
                  self.set('errorMessages', msg);
                  DevExpress.ui.notify(msg, "error", 2500);
                  self.resetButton('#dxButtonAddDevice', 'Add Device');
                  return;
               }
               device.set('name', newName);
               device.set('user', user_id);

               // Save new Device to the database ...
               device.save().then(function (device) {
                  if (settings) {
                     if (settings.get('device') == null) {
                        settings.set('device', device);
                     }
                     // Save settings to the database ...
                     settings.save().then(function (newSettings) {
                        device.set('settings', newSettings);
                     })["catch"](function (err) {
                        console.log("Add Device: Save Settings Failed");
                        DevExpress.ui.notify("Unable to save Device Settings: " + err, "error", 2500);
                        //self.resetButton('#dxButtonAddDevice', 'Add Device');
                     });
                  }
                  if (subscription) {
                     subscription.set('user', user_id);
                     subscription.set('device', device);

                     // Save subscription to the database ...
                     subscription.save().then(function (newSubscription) {
                        // Re-save new Device with attached subscription ...
                        device.set('newDevice', true);
                        device.set('subscription', newSubscription);
                        device.save().then(function () {
                           self.showNewDevicePopup();
                        })["catch"](function (err) {
                           console.log("Add Device: Save Device Failed");
                           DevExpress.ui.notify("Unable to save New Device: " + err, "error", 2500);
                           self.resetButton('#dxButtonAddDevice', 'Add Device');
                        });
                     })["catch"](function (err) {
                        console.log("Add Device: Save Subscription Failed");
                        DevExpress.ui.notify("Unable to save Subscription: " + err, "error", 2500);
                        self.resetButton('#dxButtonAddDevice', 'Add Device');
                     });
                  } else {
                     console.log("Add Device: No Subscription Found!");
                     DevExpress.ui.notify("Subscription is not defined", "error", 2500);
                     self.resetButton('#dxButtonAddDevice', 'Add Device');
                  }
               })["catch"](function (err) {
                  console.log("Add Device: Save Device Failed");
                  DevExpress.ui.notify("Unable to save Device: " + err, "error", 2500);
                  self.resetButton('#dxButtonAddDevice', 'Add Device');
               });
            } else {
               console.log("Add Device: No Device Found!");
               DevExpress.ui.notify("Unable to add Device: Not Found!", "error", 2500);
               self.set('smsChargeConfirmed', false);
               //self.resetButton('#dxButtonAddDevice', 'Add Device');
            }
         }
      },
      checkNameExists: function checkNameExists(newName) {
         var devices = this.get('model.devices');

         if (devices) {
            for (var i = 0; i < devices.get('length'); i++) {
               var dvc = devices.objectAt(i);
               if (dvc) {
                  var dvcName = dvc.get('name');
                  if (dvcName && dvcName === newName) {
                     return true;
                  }
               }
            }
         }
         return false;
      },
      removeDevice: function removeDevice() {
         var devices = this.get('model.devices');
         var device = this.get('model.device');

         if (device) {
            device.destroyRecord().then(function () {
               if (devices) {
                  devices.removeObject(device);
               }
               DevExpress.ui.notify("Device removed successfully", "success", 2500);
            })["catch"](function (error) {
               DevExpress.ui.notify("Error removing Device: " + error, "error", 5000);
            });
         }
      },
      resetError: function resetError() {
         this.set('errorMessages', null);
         this.set('isNameError', false);
      },
      resetButton: function resetButton(id, text) {
         var btn = _ember["default"].$(id + " > #dxButtonContainer").dxButton("instance");
         if (btn) {
            btn.option('text', text);
            btn.option('disabled', false);
            if (btn.loadingIndicator) {
               btn.loadingIndicator.option('visible', false);
            }
         }
      },
      logOut: function logOut() {
         this.set('newDeviceName', DEVICE_NAME);
         this.set('selectedPlan', null);
         this.transitionToRoute("users.logout");
      },
      showNewDevicePopup: function showNewDevicePopup() {
         var popup = _ember["default"].$("#dxPopupNewDeviceContainer").dxPopup("instance");
         if (popup) {
            popup.show();
         }
      },
      selectedPlanChanged: (function () {
         var subscription = this.get('model.subscription');
         var selectedPlan = this.get('selectedPlan');

         if (subscription && selectedPlan) {
            subscription.set('plan_id', selectedPlan.id);
            subscription.set('plan_name', selectedPlan.name);
            subscription.set('credits', selectedPlan.credits);
            subscription.set('amount', selectedPlan.amount);
         }
      }).observes('selectedPlan')
   });
});