define('frontend/components/dx-popover-credits', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      closeTimer: null,
      _initialize: (function () {
         var self = this;
         this.$("#dxPopoverCreditsContainer").dxPopover({
            minWidth: 310,
            maxWidth: 400,
            height: 'auto',
            width: '100%',
            target: '#dxGaugeSMS > #dxGaugeContainer',
            contentTemplate: function contentTemplate(contentElement) {
               var list = self.$("<div>");
               contentElement.append(list);
               list.dxList({
                  activeStateEnabled: false,
                  focusStateEnabled: false,
                  HoverStateEnabled: false,
                  selectionMode: 'none',
                  dataSource: self.get('dataSource'),
                  itemTemplate: function itemTemplate(itemData, itemIndex, itemElement) {
                     itemElement.append("<span class='text-smallest center-vert'><b>" + itemData.name + "</b></span>" + "<span class='text-smallest horiz-margin-sm pull-right'>Credits: " + "<label class='label-info'>" + itemData.subscription.get('credits') + "</label></span>");
                  }
               });
            },
            onShown: function onShown() {
               self.resetPopup();
            },
            onHiding: function onHiding() {
               self.stopTimer();
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxPopoverCreditsContainer").remove();
      }).on('willDestroyElement'),

      stopTimer: function stopTimer() {
         if (this.closeTimer) {
            clearTimeout(this.closeTimer);
         }
      },
      resetPopup: function resetPopup() {
         var self = this;
         this.stopTimer();
         this.closeTimer = setTimeout(function () {
            self.$("#dxPopoverCreditsContainer").dxPopover("instance").hide();
         }, 10000);
      }
   });
});