define('frontend/components/invoice-item', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      classNames: [''],
      tagName: '', // Override tag name so ember doesn't add a <div> element
      item: null,
      invNumber: _ember['default'].computed(function () {
         return this.get('index') + 1;
      }),
      planName: _ember['default'].computed(function () {
         var item = this.get('item');
         if (item) {
            var data = item.lines.data[0];
            if (data) {
               return data.plan.name;
            }
         }
         return 'Unknown';
      }),
      actions: {
         getInvoice: function getInvoice(item) {
            this.sendAction('getInvoice', item.id);
         }
      }
   });
});