define('frontend/controllers/home/device/analytics', ['exports', 'ember', 'ember-require-module', 'moment'], function (exports, _ember, _emberRequireModule, _moment) {

   (0, _emberRequireModule['default'])('moment-duration-format');

   exports['default'] = _ember['default'].Controller.extend({
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      showChart: true,
      dvc: null,
      deviceName: null,
      queryParams: ['dvc'],
      enableUpdates: false,
      invalidInput: false,
      initComplete: false,
      isEditing: false,
      isNotEditing: _ember['default'].computed.not('isEditing'),
      isSensorDevice: false,
      startDate: new Date(),
      finishDate: new Date(),
      allLogs: [],
      hasStatusLogs: _ember['default'].computed('allLogs', 'isSensorDevice', function () {
         var isSensorDevice = this.get('isSensorDevice');
         var allLogs = this.get('allLogs');
         return !isSensorDevice && allLogs;
      }),
      outageLogs: [],
      resetLogs: [],
      sensorLogsJSON: [],
      accordionID: ['overview', 'outages', 'resets'],
      firmwareVer: '',
      subscriptionStatus: false,
      smsCredits: 0,
      maxOutages: 1,
      maxResets: 1,
      numResets: 0,
      lastOutage: 0,
      lastReset: 0,
      outageDuration: 0,
      onlineDuration: 0,
      temperatureUnits: '°C',
      temperatureTitle: _ember['default'].computed(function () {
         var units = this.get('temperatureUnits');
         return 'Temperature Readings (' + units + ')';
      }),

      seriesTemperature: [{ valueField: "temperature", name: "Internal Temperature", color: "#48B04F" }, { valueField: "temperature_aux", name: "Probe Temperature", color: "royalblue" }, { valueField: "temperature_ext", name: "External Temperature", color: "skyblue" }],
      seriesHumidity: [{ valueField: "humidity", name: "Humidity (%RH)", color: "royalBlue" }],
      seriesBattery: [{ valueField: "battery_soc", name: "Battery Charge (%)", color: "#48B04F" }],
      seriesVoltage: [{ valueField: "battery_volts", name: "Battery Voltage (mV)", color: "Violet" }],
      seriesCurrent: [{ valueField: "current", name: "Current (A)", color: "crimson" }],

      _init: (function () {
         var finish = this.get('finishDate');
         //let start = new Date(finish.getFullYear(), finish.getMonth(), 1);  // Get first day of the month
         var start = new Date().setDate(finish.getDate() - 30); // Last 30 days of logs
         this.set('startDate', start);
         if (!this.get('enableUpdates')) {
            this.set('enableUpdates', true);
         }
         this.set('initComplete', true);
      }).on('init'),

      refreshPage: function refreshPage() {
         var device = this.get('model.device');
         if (device) {
            device.reload();
         }
         var logs = this.get('model.logs');
         this.calculateDurations(logs);
         this.reMapLogs();
         this.checkDateRange();
         this.updateProperties();
      },
      startUpdateTimer: function startUpdateTimer() {
         _ember['default'].run.later(this, function () {
            if (this.get('enableUpdates')) {
               this.refreshPage();
               this.startUpdateTimer();
            }
         }, 180000); // every 3 minutes
      },
      actions: {
         edit: function edit() {
            this.set('isEditing', true);
         },
         cancel: function cancel() {
            this.resetToolbar();
         },
         goBack: function goBack() {
            this.transitionToRoute("home.devices");
         },
         goNext: function goNext() {
            this.transitionToRoute("home.device.settings", { queryParams: { dvc: this.get('dvc') } });
         },
         refresh: function refresh() {
            this.refreshPage();
         },
         slide: function slide(num) {
            var selector = this.get('accordionID')[num];
            if (_ember['default'].$("#item-" + selector).hasClass('in')) {
               _ember['default'].$("#header-" + selector + " .menuClick").removeClass("glyphicon-menu-up");
               _ember['default'].$("#header-" + selector + " .menuClick").addClass("glyphicon-menu-down");
               _ember['default'].$("#header-" + selector + " .lg-header-status").removeClass("hide");
            } else {
               _ember['default'].$("#header-" + selector + " .menuClick").removeClass("glyphicon-menu-down");
               _ember['default'].$("#header-" + selector + " .menuClick").addClass("glyphicon-menu-up");
               _ember['default'].$("#header-" + selector + " .lg-header-status").addClass("hide");
            }
         }
      },
      resetToolbar: function resetToolbar() {
         this.set('isEditing', false);
      },
      updateProperties: function updateProperties() {
         this.set('firmwareVer', this.getFirmwareVer());
         this.set('subscriptionStatus', this.getSubStatus());
         this.set('smsCredits', this.getSMSCredits());
         this.set('maxOutages', this.getMaxOutages());
         this.set('maxResets', this.getMaxResets());
         this.set('numResets', this.getNumResets());
         this.set('lastOutage', this.getLastEntry(this.get('outageLogs')));
         this.set('lastReset', this.getLastEntry(this.get('resetLogs')));
         this.set('outageDuration', this.getOutageDuration());
         this.set('onlineDuration', this.getOnlineDuration());
      },
      getFirmwareVer: function getFirmwareVer() {
         var fwVer = 'Unknown';
         var device = this.get('model.device');

         if (device) {
            fwVer = device.get('firmware_ver');
            if (fwVer) {
               if (fwVer.startsWith('pwron_')) {
                  fwVer = fwVer.substring(6);
               }
               fwVer = fwVer.replace('_', ' ');
            } else {
               fwVer = 'Unknown';
            }
         }
         return fwVer;
      },
      getSubStatus: function getSubStatus() {
         var status = false;
         var device = this.get('model.device');
         if (device) {
            var sub = device.get('subscription');
            if (sub) {
               return sub.get('status');
            }
         }
         return status;
      },
      getSMSCredits: function getSMSCredits() {
         var credits = 0;
         var device = this.get('model.device');
         if (device) {
            var sub = device.get('subscription');
            if (sub) {
               return sub.get('credits');
            }
         }
         return credits;
      },
      getMaxOutages: function getMaxOutages() {
         var logs = this.get('outageLogs');

         // NOTE: Always need at least 1 to render Gauge correctly ...
         if (logs && logs.length > 0) {
            return ((logs.length - 1) / 10 + 1) * 10;
         } else {
            return 1;
         }
      },
      getMaxResets: function getMaxResets() {
         var logs = this.get('resetLogs');

         // NOTE: Always need at least 1 to render Gauge correctly ...
         if (logs && logs.length > 0) {
            return ((logs.length - 1) / 10 + 1) * 10;
         } else {
            return 1;
         }
      },
      getNumResets: function getNumResets() {
         var logs = this.get('resetLogs');
         if (logs) {
            var len = logs.length;
            if (len > 0) {
               return len; // - 1);    // First online status is not a reset
            }
         }
         return 0;
      },
      getLastEntry: function getLastEntry(logs) {
         var lastEntry = null;
         if (logs) {
            lastEntry = logs.get('lastObject');
            if (lastEntry) {
               return (0, _moment['default'])(lastEntry.created).format("DD/MM/YYYY hh:mm:ss a");
            }
         }
         return 'N/A';
      },
      getOutageDuration: function getOutageDuration() {
         var outLogs = this.get('outageLogs');
         if (outLogs) {
            var last = outLogs.get('lastObject');
            if (last) {
               var duration = last.duration;
               if (duration <= 0) {
                  var current = last.created;
                  var next = new Date();
                  duration = (0, _moment['default'])(next) - (0, _moment['default'])(current); // milliseconds
               }
               return this.formatRange(duration);
            }
         }
         return 0;
      },
      getOnlineDuration: function getOnlineDuration() {
         var resetLogs = this.get('resetLogs');
         if (resetLogs) {
            var last = resetLogs.get('lastObject');
            if (last) {
               var duration = last.duration;
               if (duration <= 0) {
                  var current = last.created;
                  var next = new Date();
                  duration = (0, _moment['default'])(next) - (0, _moment['default'])(current); // milliseconds
               }
               return this.formatRange(duration);
            }
         }
         return '0';
      },
      formatRange: function formatRange(ticks) {
         if (ticks === undefined || ticks === null) {
            return 'error!';
         }
         var result = '';
         var duration = _moment['default'].duration(ticks, "ms");
         if (duration) {
            var days = duration.get('days');
            var hours = duration.get('hours');
            var minutes = duration.get('minutes');
            var seconds = duration.get('seconds');

            if (days > 0) {
               result = result + days + "d ";
            }
            if (hours > 0) {
               result = result + hours + "h ";
            }
            if (minutes > 0) {
               result = result + minutes + "m ";
            }
            if (seconds > 0) {
               result = result + seconds + "s";
            }
         }
         return result;
      },
      reMapLogs: function reMapLogs() {
         var model = this.get('model');
         if (model) {
            // Map all log arrays to JSON for controls to render properly ...
            var allLogs = model.logs.map(function (log) {
               return log.toJSON();
            });
            // Add one more dummy entry (with last log's status) so graph lines continue to NOW...
            if (allLogs && allLogs.length > 0) {
               var lastEntry = allLogs.get('lastObject');
               if (lastEntry) {
                  var lastJSON = {
                     created: (0, _moment['default'])().format("YYYY-MM-DD HH:mm:ss"),
                     status: lastEntry.status,
                     device: lastEntry.device
                  };
                  allLogs.push(lastJSON);
               }
               this.set('allLogs', allLogs);
            }
            // Process OUTAGE logs ...
            var outageLogs = model.outageLogs.map(function (log) {
               return log.toJSON();
            });
            this.set('outageLogs', outageLogs);

            // Process RESET logs ...
            var resetLogs = model.resetLogs.map(function (log) {
               return log.toJSON();
            });
            this.set('resetLogs', resetLogs);

            // Process Sensor Logs ...
            var sensorLogs = this.get('model.sensorLogs');
            if (sensorLogs) {
               var sensorLogsJSON = sensorLogs.map(function (log) {
                  return log.toJSON();
               });
               this.set('sensorLogsJSON', sensorLogsJSON);
            }
            console.log("ANALYTICS: Logs Processed");
         }
      },
      checkDateRange: function checkDateRange() {
         var start = this.get('startDate');
         var finish = this.get('finishDate');

         if (this.isSensorDevice) {
            var sensorlogs = this.get('sensorLogsJSON');
            if (sensorlogs) {
               var first = sensorlogs.objectAt(0);
               var last = sensorlogs.objectAt(sensorlogs.get('length') - 1);
               if (first) {
                  var firstDate = new Date(first.created);
                  if (firstDate > start) {
                     this.set('startDate', firstDate);
                  }
               }
               if (last) {
                  var lastDate = new Date(last.created);
                  if (lastDate < finish) {
                     this.set('finishDate', lastDate);
                  }
               }
            }
         } else {
            var logs = this.get('allLogs');
            if (logs) {
               var first = logs.objectAt(0);
               var last = logs.objectAt(logs.get('length') - 1);
               if (first) {
                  var firstDate = new Date(first.created);
                  //console.log("Checking First Date: " + firstDate);
                  if (firstDate > start) {
                     this.set('startDate', firstDate);
                  }
               }
               if (last) {
                  var lastDate = new Date(last.created);
                  //console.log("Checking Last Date: " + lastDate);
                  if (lastDate < finish) {
                     this.set('finishDate', lastDate);
                  }
               }
            }
         }
      },
      processOutageLogs: function processOutageLogs(start, finish) {
         var self = this;
         var logs = this.get('allLogs');
         var logArray = [];

         if (start && finish) {
            //console.log("Processing Outage Logs");
            for (var i = 0; i < logs.get('length'); i++) {
               var log = logs.objectAt(i);
               var d = new Date(log.created);
               if (log.status === false && d >= start && d <= finish) {
                  logArray.push(log);
               }
            }
            self.set('outageLogs', logArray);
         }
      },
      processResetLogs: function processResetLogs(start, finish) {
         var self = this;
         var logs = this.get('allLogs');
         var logArray = [];

         if (start && finish) {
            //console.log("Processing Reset Logs");
            for (var i = 0; i < logs.get('length'); i++) {
               var log = logs.objectAt(i);
               var d = new Date(log.created);
               if (log.status === true && d >= start && d <= finish) {
                  logArray.push(log);
               }
            }
            self.set('resetLogs', logArray);
         }
      },
      calculateDurations: function calculateDurations(logs) {
         if (!logs || logs.get('length') < 1) {
            return;
         }
         var numLogs = logs.get('length');
         var updated = false;
         for (var i = 0; i < numLogs - 1; i++) {
            var log = logs.objectAt(i);
            var current = log.get('created');
            var next = logs.objectAt(i + 1).get('created');
            var range = (0, _moment['default'])(next) - (0, _moment['default'])(current); // milliseconds

            if (log && log.get('duration') <= 0) {
               log.set('duration', range);
               updated = true;
            }
         }
         if (updated) {
            logs.save();
         }
         var last = logs.get('lastObject');
         var lastDuration = (0, _moment['default'])() - (0, _moment['default'])(last.get('created'));
         last.set('duration', lastDuration);
      },
      //TODO: Check this could work on the dashboard as well
      _timeSpanChanged: (function () {
         if (this.get('initComplete')) {
            var start = this.get('startDate');
            var finish = this.get('finishDate');
            this.processOutageLogs(start, finish);
            this.processResetLogs(start, finish);
         }
      }).observes('startDate', 'finishDate'),

      _enableUpdatesChanged: (function () {
         if (this.get('enableUpdates') === true) {
            this.updateProperties();
            this.startUpdateTimer();
         }
      }).observes('enableUpdates')
   });
});