define("frontend/helpers/post-freq", ["exports", "ember"], function (exports, _ember) {
   exports.postFreq = postFreq;

   function postFreq(params /*, hash*/) {
      var key = params[0];

      if (key === 0) {
         return "10 sec";
      } else if (key === 1) {
         return "6 sec";
      } else if (key === 2) {
         return "3 sec";
      } else {
         return "Unknown";
      }
   }

   exports["default"] = _ember["default"].Helper.helper(postFreq);
});