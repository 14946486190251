define('frontend/controllers/home/profile', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      isAdministratorSelected: true,
      isAddressSelected: false,
      isChangePasswordSelected: false,
      actions: {
         gotoAdministrator: function gotoAdministrator() {
            this.transitionToRoute('home.profile.administrator');
            this.resetButton("#dxButtonAdministrator", 'Administrator');
         },
         gotoAddress: function gotoAddress() {
            this.transitionToRoute('home.profile.address');
            this.resetButton('#dxButtonAddress', 'Address');
         },
         gotoChangePassword: function gotoChangePassword() {
            this.transitionToRoute('home.profile.password');
            this.resetButton('#dxButtonChangePassword', 'Password');
         }
      },
      resetButton: function resetButton(id, text) {
         var btn = _ember['default'].$(id + " #dxButtonContainer").dxButton("instance");
         if (btn) {
            btn.option('text', text);
            btn.option('disabled', false);
            if (btn.loadingIndicator) {
               btn.loadingIndicator.option('visible', false);
            }
         }
      }
   });
});