define('frontend/components/dx-popup-help', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      title: 'PwrOn Smart-Plug Help',
      filePath: '/assets/docs/PWRON_Help.html',

      _initialize: (function () {
         var self = this;
         this.$("#dxPopupHelpContainer").dxPopup({
            fullScreen: false,
            closeOnOutsideClick: true,
            dragEnabled: false,
            showCloseButton: true,
            showTitle: true,
            minHeight: 420,
            height: '80%',
            titleTemplate: function titleTemplate(titleElement) {
               titleElement.append("<div class='row' style='max-height: 60px'>" + "<img class='pull-left' width='42' alt='Help' src='/assets/icons/glyphicons-195-question-sign@2x.png'>" + "<h2 class='center'>" + self.get('title') + "</h2></div>");
            },
            contentTemplate: function contentTemplate(contentElement) {
               self.drawTemplate(contentElement);
            },
            animation: {
               show: { type: "pop", from: { opacity: 1, scale: 0 }, to: { scale: 1 } },
               hide: { type: "pop", from: { scale: 1 }, to: { scale: 0 } }
            }
         });
      }).on('didInsertElement'),

      drawTemplate: function drawTemplate(contentElement) {
         var self = this;
         var filePath = this.get('filePath');

         contentElement.append("<div class='center top-margin-sm'><img alt='Pwron Logo' src='/assets/images/pwron-logo-128.jpg' height='60' /></div>");

         var textContent = this.$("<div id='textContentHelp' class='text-scrollable'></div>");
         contentElement.append(textContent);
         this.$("#textContentHelp").load(filePath);
         textContent.on("dxmousewheel", function (e) {
            e.stopPropagation();
         });

         var btnContainer = this.$("<div id='pop-help-btn-container' class='row top-margin-md'></div>");
         contentElement.append(btnContainer);

         btnContainer.append("<div id='pop-help-btn-close' class='col-xs-4 col-xs-offset-4 btn-pwron btn-regular'></div>");
         var closeButton = self.$("#pop-help-btn-close").dxButton({
            text: "Close",
            onClick: function onClick() {
               self.$("#dxPopupHelpContainer").dxPopup("instance").hide();
            }
         });
         btnContainer.append(closeButton);
      },

      _destroy: (function () {
         this.$("#dxPopupHelpContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxPopupHelpContainer").dxPopup('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});