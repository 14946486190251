define('frontend/routes/home/profile/password', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model() {
         var userID = this.get('authManager').get('getUser.id');
         return this.store.findRecord('user', userID);
      },
      actions: {
         willTransition: function willTransition() {
            this.controllerFor('home.profile.password').resetToolbar();
         },
         didTransition: function didTransition() {
            var homeController = this.controllerFor('home');
            if (homeController) {
               homeController.set('title', 'Administrator Password');
               homeController.set('selectedMenuIndex', 3);
               homeController.set('subMenuIndex', 2);
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});