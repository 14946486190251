define('frontend/controllers/users/login', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      queryParams: ['addDevice'],
      addDevice: null,
      email: null,
      password: null,
      invalidInput: true,
      isEmailError: false,
      isPasswordError: false,
      isRecaptchaError: false,
      reCaptchaResponse: null,
      errorMessages: null,

      actions: {
         login: function login() {
            var self = this;
            self.resetError();

            var data = this.getProperties('email', 'password');
            var captcha_response = this.get('reCaptchaResponse');

            if (captcha_response) {
               data.email = data.email.toLowerCase();
               DevExpress.ui.notify("Logging In " + data.email + " ...", "info", 2500);

               this.get('authManager').tryAuthenticate(data.email, data.password, captcha_response, function (response, status) {
                  if (!response) {
                     DevExpress.ui.notify("Authentication Failed! (Timeout: No Response, Status: " + status + ")", "error", 2500);
                     self.resetButton(false);
                  } else if (response.status === "true") {
                     if (self.get('addDevice')) {
                        self.transitionToRoute('users.finddevice', { queryParams: { s: self.get('addDevice') } });
                     } else {
                        self.transitionToRoute('home.dashboard');
                     }
                  } else {
                     self.resetButton(false);

                     var msg = response.reason;
                     if (msg) {
                        DevExpress.ui.notify("Login Failed: " + msg, "error", 2500);
                        var msgLower = msg.toLowerCase();

                        if (msgLower.includes('e-mail') || msgLower.includes('email') || msgLower.includes('account')) {
                           self.set('isEmailError', true);
                        } else if (msgLower.includes('password')) {
                           self.set('isPasswordError', true);
                        } else if (msgLower.includes('captcha')) {
                           self.set('isRecaptchaError', true);
                        }
                        self.set('errorMessages', msg);
                     } else {
                        DevExpress.ui.notify("Authentication Failed!", "error", 2500);
                     }
                     self.resetReCaptcha();
                  }
               });
            } else {
               DevExpress.ui.notify("Please confirm that you're not a Robot", "error", 2500);
               self.resetButton(true);
            }
         },
         onCaptchaResolved: function onCaptchaResolved(reCaptchaResponse) {
            DevExpress.ui.notify("Hey! It looks like you're not a Robot", 'success', 2500);
            this.set('reCaptchaResponse', reCaptchaResponse);
            this.resetButton(false);
         },
         onCaptchaExpired: function onCaptchaExpired() {
            DevExpress.ui.notify("ReCaptcha Expired: Please confirm again", 'warning', 2500);
            this.set('reCaptchaResponse', null);
            this.resetButton(true);
         }
      },
      resetButton: function resetButton(isDisabled) {
         var btn = _ember['default'].$("#dxButtonContainer").dxButton('instance');
         if (btn) {
            btn.option('text', 'Login');
            btn.option('disabled', isDisabled);
            if (btn.loadingIndicator) {
               btn.loadingIndicator.option('visible', false);
            }
         }
      },
      resetError: function resetError() {
         this.set('errorMessages', null);
         this.set('isEmailError', false);
         this.set('isPasswordError', false);
         this.set('isRecaptchaError', false);
      },
      resetReCaptcha: function resetReCaptcha() {
         var captcha = this.get('gRecaptcha');
         if (captcha) {
            captcha.resetReCaptcha();
            this.set('reCaptchaResponse', null);
         }
      }
   });
});