define("frontend/models/customer", ["exports"], function (exports) {});
// NOTE: This model is not required ATM as the User(Admin) contains a customer_id.
// However, may be needed in the future if the Administrator is different from the billing customer.

/*import DS from 'ember-data';
import attr from 'ember-data/attr';
import { belongsTo } from 'ember-data/relationships';

export default DS.Model.extend({
   customer_id: attr('string'),
   first_name: attr('string'),               // First Name
   last_name: attr('string'),                // Last Name
   number: attr(),                           // Mobile phone number
   callingCode: attr('string', {
      defaultValue() { return '+61'; }       // International calling code
   }),
   email: attr('string'),                    // E-mail address
   company: attr('string'),                  // Company or Organisation
   created: attr('date', {
      defaultValue() { return new Date(); }  // Date customer was created
   }),
   location: belongsTo('location'),

   full_name: function() {
      return this.get('first_name') + ' ' + this.get('last_name');
   }.property('first_name', 'last_name'),
});*/