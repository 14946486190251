define('frontend/routes/home/help', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model() {
         var self = this;
         var user_id = this.get('authManager').get('getUser.id');
         if (user_id) {
            return _ember['default'].RSVP.hash({
               user: self.store.findRecord('user', user_id),
               devices: self.store.findAll('device').then(function (devices) {
                  if (devices) {
                     return devices;
                  } else {
                     return [];
                  }
               })['catch'](function (error) {
                  console.log("Help Page: Find Devices Error: " + error.message);
                  return null;
               })
            });
         } else {
            this.transitionTo('users.logout');
            return null;
         }
      },
      afterModel: function afterModel(model) {
         var controller = this.controllerFor('home.help');
         if (controller && model) {
            controller.set('model', model);
         }
      },
      actions: {
         didTransition: function didTransition() {
            var homeController = this.controllerFor('home');
            if (homeController) {
               homeController.set('title', 'PwrOn Help');
               homeController.set('subMenuIndex', -1);
               homeController.set('selectedMenuIndex', 6);
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});