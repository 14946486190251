define("frontend/helpers/ping-sensitivity", ["exports", "ember"], function (exports, _ember) {
   exports.pingSensitivity = pingSensitivity;

   function pingSensitivity(params) {
      var key = params[0];

      if (key === 0) {
         return "minute";
      } else if (key === 1) {
         return "3 mins";
      } else if (key === 2) {
         return "5 mins";
      } else if (key === 3) {
         return "10 mins";
      } else {
         return "Unknown";
      }
   }

   exports["default"] = _ember["default"].Helper.helper(pingSensitivity);
});