define('frontend/routes/home/dashboard', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      //days: 30,
      //finishDate: null,
      //startDate: null,
      //startDateHoursAgo: new Date().setHours(new Date().getHours() - 4),
      model: function model(params) {
         var self = this;
         var host = this.store.adapterFor('application').get('host');
         //let start = new Date().setDate(new Date().getDate() - self.get('days'));

         var user_id = this.get('authManager').get('getUser.id');
         if (user_id) {
            // Check if the parameters are valid ...
            /*if (params && params.dvc) {
               self.set('deviceID', params.dvc);
            }*/
            console.log('DASH: Routed Device ID: %s', params.dvc);
            // Get the model data from the back-end ...
            return _ember['default'].RSVP.hash({
               user: self.store.findRecord('user', user_id),
               appsettings: self.store.queryRecord('appsettings', { user: user_id }).then(function (setting) {
                  if (setting) {
                     console.log('DASH: Application settings found');
                     return setting;
                  } else {
                     console.log('DASH: No Application settings found!');
                     return null;
                  }
               })['catch'](function (error) {
                  console.log('DASH: Find Application settings error: ' + error.message);
                  return null;
               }),
               devices: self.store.query('device', { user: user_id }).then(function (devices) {
                  if (devices) {
                     console.log('DASH: %d Devices found', devices.get('length'));
                     return devices;
                  } else {
                     console.log('DASH: Devices Not Found!');
                     return [];
                  }
               })['catch'](function (error) {
                  console.log('DASH: Find all Devices error: ' + error.message);
                  return null;
               }),
               /*deviceLogs: self.store.query('device', {user: user_id}).then(function(devices) {
                  if (devices) {
                     let numDevices = devices.get('length');
                     if (numDevices > 0) {
                        return devices.objectAt(0).get('logs');
                     }
                  } else {
                     return [];
                  }
               }).catch(function (error) {
                  console.log("DASH: Find Device Logs error: " + error.message);
                  return null;
               }),*/
               lastSensorLog: self.store.query('sensorlog', { device: params.dvc, lastLog: true }, { reload: true }).then(function (sensorLogs) {
                  if (sensorLogs && sensorLogs.get('length') > 0) {
                     console.log("DASH: Found last sensor log for Device: %s", params.dvc);
                     return sensorLogs.get('lastObject');
                  } else {
                     console.log("DASH: Last sensor log Not Found!");
                     return null;
                  }
               })['catch'](function (error) {
                  console.log("DASH: Find last sensor log error: %s", error.message);
                  return null;
               }),
               alarms: self.store.query('alarm', { device: params.dvc }).then(function (alarms) {
                  if (alarms) {
                     var numAlarms = alarms.get('length');
                     console.log('DASH: Found %d alarms', numAlarms);
                     return alarms;
                  } else {
                     console.log('DASH: Alarms Not Found!');
                     return [];
                  }
               })['catch'](function (error) {
                  console.log('DASH: Find alarms error: %s', error.message);
                  return [];
               }),
               sensorLogs: self.store.query('sensorlog', { device: params.dvc, startDate: self.get('startDate'), finishDate: self.get('finishDate') }, { reload: true }).then(function (sensorLogs) {
                  if (sensorLogs && sensorLogs.get('length') > 0) {
                     console.log("DASH: Sensor Logs found: %d", sensorLogs.get('length'));
                     return sensorLogs;
                  } else {
                     console.log("DASH: Sensor Logs Not Found!");
                     return [];
                  }
               })['catch'](function (error) {
                  console.log("DASH: Find all Sensor Logs error: %s", error.message);
                  return [];
               }),
               subscriptions: self.store.findAll('subscription').then(function (subscriptions) {
                  if (subscriptions && subscriptions.get('length') > 0) {
                     return subscriptions;
                  } else {
                     console.log("DASH: Subscriptions Not Found!");
                     return [];
                  }
               })['catch'](function (error) {
                  console.log("DASH: Find all Subscriptions error: " + error.message);
                  return null;
               }),
               cards: self.newQuery(host, 'billing/cards', user_id).then(function (cards) {
                  if (cards) {
                     return cards.data;
                  } else {
                     return [];
                  }
               })['catch'](function (error) {
                  console.log("DASH: Get Cards error: " + error.message);
                  return null;
               }),
               nextInvoice: self.newQuery(host, 'billing/invoices/upcoming/customer', user_id).then(function (nextInvoice) {
                  return nextInvoice;
               })['catch'](function (err) {
                  console.log(err);
                  return null;
               })
            });
         } else {
            console.log('DASH: User is unauthourised!');
            this.transitionTo('users.logout');
            return null;
         }
      },
      beforeModel: function beforeModel() {
         var user_id = this.get('authManager').get('getUser.id');
         if (!user_id) {
            console.log('DASH: User is unauthourised!');
            this.router.transitionTo('users.logout');
         }
         // Get selected device ID from controller ...
         /*let controller = this.controllerFor('home.dashboard');
         if (controller) {
            let deviceID = controller.get('selectedDeviceID');
            this.set('deviceID', deviceID);
            console.log('Before Model: DeviceID: %s', deviceID);
         }*/
         console.log('DASH: beforeModel finished');
      },
      afterModel: function afterModel(model) {
         if (model.appsettings == null) {
            model.appsettings = this.store.createRecord('appsettings', {
               menu_index: 0,
               sub_menu_index: -1,
               tab_index: 0,
               device_index: 0,
               device_id: null,
               device_uuid: null,
               user: model.user
            });
            console.log('DASH: Created new Application Settings');
         }
         console.log('DASH: afterModel finished');
      },
      setupController: function setupController(controller, model) {
         // Call _super for default behavior - sets the model to the controller
         this._super(controller, model);

         // Custom controller setup ...
         console.log('DASH: setupController started');
         if (model.appsettings) {
            controller.set('selectedTabIndex', model.appsettings.get('tab_index'));
         }
         // Update Devices list and set selected Device ...
         if (model.devices) {
            controller.updateJSONDevices();
            controller.getSelectedDevice();
            controller.setDeviceID();
         }
         controller.updateProperties();
         controller.checkAlarmThresholds();
         controller.setDateRangeLast30Days();
         // Enable periodic page updates ...
         if (controller.get('enableUpdates') !== true) {
            controller.set('enableUpdates', true);
         }
         console.log('DASH: setupController finished');
      },
      actions: {
         willTransition: function willTransition() {
            //let selectedDevice = null;
            var controller = this.controllerFor('home.dashboard');
            if (controller) {
               controller.set('enableUpdates', false);
               //selectedDevice = controller.get('selectedDevice');
            }
            /*let devicesController = this.controllerFor('home.devices');
            if (devicesController) {
               devicesController.set('selectedDevice', selectedDevice);
            }*/
         },
         didTransition: function didTransition() {
            console.log('DASH: didTransition');
            var homeController = this.controllerFor('home');
            if (homeController) {
               homeController.set('title', 'My Dashboard');
               homeController.set('subMenuIndex', -1);
               homeController.set('selectedMenuIndex', 0);
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      },
      newQuery: function newQuery(host, path, user_id) {
         return new _ember['default'].RSVP.Promise(function (resolve, reject) {
            var url = [host, path, user_id].join('/');
            _ember['default'].$.get({
               url: url,
               crossDomain: true,
               success: function success(results) {
                  if (results) {
                     resolve(results);
                  } else {
                     resolve(null);
                  }
               },
               error: function error(err) {
                  reject(err.responseText);
               }
            });
         });
      }
   });
});