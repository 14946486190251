define('frontend/controllers/home/profile/address', ['exports', 'ember', 'ember-cli-countries/enums/countries', 'frontend/types/timezones', 'frontend/config/environment'], function (exports, _ember, _emberCliCountriesEnumsCountries, _frontendTypesTimezones, _frontendConfigEnvironment) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      countriesList: _emberCliCountriesEnumsCountries['default'].toKeyValueJson(),
      timezonesList: _frontendTypesTimezones['default'].timezones,
      addressGroup: 'addressGroup',
      adminResult: false,
      invalidInput: false,
      isEditing: false,
      isNotEditing: _ember['default'].computed.not('isEditing'),
      mapProvider: 'google',
      position: '[Unknown]',
      googleMapsKey: _frontendConfigEnvironment['default'].googleMaps.siteKey,

      actions: {
         authComplete: function authComplete() {
            this.set('isEditing', this.get('adminResult'));
         },
         edit: function edit() {
            this.showAdminPopup();
         },
         cancel: function cancel() {
            this.resetToolbar();
         },
         save: function save() {
            this.saveModel();
         },
         goBack: function goBack() {
            this.transitionToRoute("home.profile.administrator");
         },
         goNext: function goNext() {
            this.transitionToRoute("home.profile.password");
         },
         updateLocation: function updateLocation() {
            //console.log("Update Map Location: [" + this.latitude + ", " + this.longitude + "]");
            //this.set('addressLocation', location);
         }
      },
      resetToolbar: function resetToolbar() {
         this.set('isEditing', false);
      },
      showAdminPopup: function showAdminPopup() {
         if (this.get('adminResult')) {
            this.set('isEditing', true);
         } else {
            _ember['default'].$("#dxPopupAdminContainer").dxPopup("instance").show();
         }
      },
      saveModel: function saveModel() {
         var self = this;
         var result = DevExpress.validationEngine.validateGroup(this.get('addressGroup'));

         if (result && result.isValid) {
            DevExpress.ui.notify("Saving Address Details ...", "info", 2500);
            var model = self.get('model');
            if (model) {
               (function () {
                  var loc = self.get('model.location');
                  var usr = self.get('model.user');

                  if (loc) {
                     loc.set('user', usr);
                     loc.save().then(function () {
                        /*if (!usr.location) {*/
                        usr.set('location', loc);
                        usr.save().then(function () {
                           DevExpress.ui.notify("Address Details Saved", "success", 2500);
                           self.set('isEditing', false);
                        }, function (error) {
                           console.log("Error Saving Address: " + error);
                           DevExpress.ui.notify("Error Saving Address: " + error, "error", 2500);
                        });
                        /*} else {
                           DevExpress.ui.notify("Address Details Saved", "success", 2500);
                           self.set('isEditing', false);
                        }*/
                     }, function (error) {
                        console.log("Error Saving Address: " + error);
                        DevExpress.ui.notify("Error Saving Address: " + error, "error", 2500);
                     });
                  } else {
                     console.log("Error Saving Address: Location is not defined");
                     DevExpress.ui.notify("Error Saving Address: Location is not defined", "error", 2500);
                  }
               })();
            } else {
               console.log("Error Saving Address: Model is not defined");
               DevExpress.ui.notify("Error Saving Address: Model is not defined", "error", 2500);
            }
         }
      },
      cityChanged: (function () {
         var city = this.get('model.location.city');
         if (city) {
            city = city.trim();
            if (city.includes('City')) {
               city = city.replace('City', '').trim();
            }
            if (city.includes('Airport')) {
               city = city.replace('Airport', '').trim();
            }
            var timezoneObj = this.timezonesList.findBy('key', city);
            if (timezoneObj) {
               var timezone = timezoneObj.value;
               if (timezone) {
                  this.set('model.location.timezone', timezone);
               }
            }
         }
      }).observes('model.location.city')
   });
});