define('frontend/models/notificationrecipient', ['exports', 'ember-data', 'ember-data/model', 'ember-data/relationships'], function (exports, _emberData, _emberDataModel, _emberDataRelationships) {
   // import attr from 'ember-data/attr';

   exports['default'] = _emberDataModel['default'].extend({
      first_name: _emberData['default'].attr('string'),
      last_name: _emberData['default'].attr('string'),
      address: _emberData['default'].attr('string'),
      email: _emberData['default'].attr('string'),
      callingCode: _emberData['default'].attr('string', {
         defaultValue: function defaultValue() {
            return '+61';
         } // International calling code
      }),
      number: _emberData['default'].attr('string'),
      alarm_type: _emberData['default'].attr('string', {
         defaultValue: function defaultValue() {
            return 'SMS';
         }
      }),
      alarm_repeat: _emberData['default'].attr('number-zero', {
         defaultValue: function defaultValue() {
            return 0;
         }
      }),
      notified: _emberData['default'].attr('boolean'),
      created: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      last_updated: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      last_notified: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      device: (0, _emberDataRelationships.belongsTo)('device'),

      /* Calculated properties */
      full_name: (function () {
         return this.get('first_name') + ' ' + this.get('last_name');
      }).property('first_name', 'last_name')
   });
});