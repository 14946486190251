define('frontend/components/dx-text-phone', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      isInvalid: false,
      isError: false,
      errorMessage: null,
      callingCode: '',
      phoneMask: '0000-000-000',
      attributeBindings: ['isInvalid', 'phoneMask'],

      _initialize: (function () {
         var self = this;
         var validationGroup = this.get('group');

         this.$("#dxPhoneContainer").dxTextBox({
            activeStateEnabled: true,
            inputAttr: { id: this.get('idAttr') },
            disabled: this.get('disabled'),
            readOnly: this.get('readOnly'),
            hint: this.get('hint'),
            placeholder: this.get('placeholder'),
            fontFamily: 'Arial',
            fontSize: 24,
            isMobile: true,
            mode: 'tel',
            mask: this.get('phoneMask'),
            maskRules: { "X": /[0-9]/ },
            maskInvalidMessage: 'Phone must have the correct format',
            useMaskedValue: false,
            validationMessageMode: 'always',
            value: this.get('value'),
            onInput: function onInput() {
               self.clearError();
            },
            onValueChanged: function onValueChanged(e) {
               self.clearError();
               self.set('value', e.value);
            },
            onFocusIn: function onFocusIn() {
               //let input = e.element.find("input").get(0);
               var input = self.$('#dxPhoneContainer .dx-texteditor-input').get(0);
               //let code = self.get('callingCode');
               var pos = 0;
               /*if (code) {
                  pos = code.length + 1;
               }*/
               setTimeout(function () {
                  self.setSelectionRange(input, pos, pos);
               }, 0);
            }
         }).dxValidator({
            validationGroup: validationGroup,
            validationRules: [{ type: 'required', message: 'Phone number is required' }, { type: 'pattern', pattern: /^\d{4}\s*\d{3}\s*\d{3}$/, message: 'Phone must have the correct pattern' },
            /*{type: 'pattern', pattern: /^\+\s*\d{1,6}\s*-\s*\d{4}\s*-\s*\d{3}\s*-\s*\d{3}$/, message: "Phone number must have the correct pattern"},*/
            /*{type: 'pattern', pattern: /^\+\s*61\s*\(\s*[02-9]\d{2}\)\s*\d{3}\s*-\s*\d{4}$/, message: "The phone number must have the correct pattern"}*/
            { type: 'custom', reevaluate: true, validationCallback: function validationCallback(options) {
                  if (self.get('isError')) {
                     options.rule.message = self.get('errorMessage');
                     return false;
                  } else {
                     options.rule.message = null;
                     return true;
                  }
               } }],
            onValidated: function onValidated(e) {
               try {
                  if (self.get('isDestroyed') || self.get('isDestroying')) {
                     return;
                  }
                  if (e.isValid) {
                     self.set('isInvalid', false);
                     self.$("#dxPhoneContainer.dx-texteditor .dx-texteditor-container").addClass("dx-valid");
                  } else {
                     self.set('isInvalid', true);
                     self.$("#dxPhoneContainer.dx-texteditor .dx-texteditor-container").removeClass("dx-valid");
                  }
               } catch (exp) {
                  console.log("Exception Error: " + exp);
               }
            }
         });
      }).on('didInsertElement'),

      setSelectionRange: function setSelectionRange(input, selectionStart, selectionEnd) {
         if (input) {
            if (input.setSelectionRange) {
               input.focus();
               input.setSelectionRange(selectionStart, selectionEnd);
            } else if (input.createTextRange) {
               var range = input.createTextRange();
               range.collapse(true);
               range.moveEnd('character', selectionEnd);
               range.moveStart('character', selectionStart);
               range.select();
            }
         }
      },

      _destroy: (function () {
         this.$("#dxPhoneContainer").remove();
      }).on('willDestroyElement'),

      clearError: function clearError() {
         if (this.get('isError')) {
            this.set('errorMessage', null);
            this.set('isError', false);
            this.$("#dxPhoneContainer").dxValidator('instance').validate();
         }
      },

      _disabledChanged: (function () {
         this.$("#dxPhoneContainer").dxTextBox('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _phoneMaskChanged: (function () {
         this.$("#dxPhoneContainer").dxTextBox('option', 'mask', this.get('phoneMask'));
      }).observes('phoneMask'),

      _valueChanged: (function () {
         var val = this.get('value');
         if (val) {
            this.$("#dxPhoneContainer").dxTextBox('option', 'value', val);
         } else {
            this.$("#dxPhoneContainer").dxValidator('instance').reset();
         }
      }).observes('value'),

      _isErrorChanged: (function () {
         if (this.get('isError')) {
            this.$("#dxPhoneContainer").dxValidator('instance').validate();
            this.$("#dxPhoneContainer").dxValidator('instance').focus();
         }
      }).observes('isError')
   });
});