define('frontend/models/card', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _emberDataAttr, _emberDataRelationships) {
   exports['default'] = _emberData['default'].Model.extend({
      card_id: (0, _emberDataAttr['default'])('string'), // Unique billing ID for object e.g. card_xxxx
      brand: (0, _emberDataAttr['default'])('string'), // e.g. 'Visa' or 'Unknown'
      name: (0, _emberDataAttr['default'])('string'), // Card-holder's Name
      country: (0, _emberDataAttr['default'])('string'), // e.g. 'AU' or 'US'
      post_code: (0, _emberDataAttr['default'])('string'), // Alphanumeric Zip/Postal Code
      cvc_check: (0, _emberDataAttr['default'])('string'), // 'pass', 'fail', 'unavailable', 'unchecked'
      exp_month: (0, _emberDataAttr['default'])('number'),
      exp_year: (0, _emberDataAttr['default'])('number'),
      fingerprint: (0, _emberDataAttr['default'])('string'), // Unique ID for card number to check duplicates
      last4: (0, _emberDataAttr['default'])('string'), // Only store last 4 digits of card number
      customer_id: (0, _emberDataAttr['default'])('string'),
      user: (0, _emberDataRelationships.belongsTo)('user')
   });
});