define('frontend/components/dx-popup-confirm', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      result: false,
      msgHead: "Are you sure?",
      msgBody: 'Message',
      msgNote: "NOTE: This action can't be undone",
      imgPath: '/assets/icons/help.png',

      _initialize: (function () {
         var self = this;

         this.$("#dxPopupConfirmContainer").dxPopup({
            fullScreen: false,
            closeOnOutsideClick: false,
            dragEnabled: false,
            showCloseButton: false,
            showTitle: true,
            minWidth: 320,
            minHeight: 320,
            maxWidth: '33%',
            height: 'auto',
            width: 'auto',
            titleTemplate: function titleTemplate(titleElement) {
               titleElement.append("<div class='row' style='max-height: 60px'>" + "<img align='left' class='pull-left' width='42' src='/assets/icons/glyphicons-195-question-sign@2x.png'>" + "<h2 class='center'>Confirmation Required</h2></div>");
            },
            contentTemplate: function contentTemplate(contentElement) {
               self.drawTemplate(contentElement);
            },
            animation: {
               show: { type: "pop", from: { opacity: 1, scale: 0 }, to: { scale: 1 } },
               hide: { type: "pop", from: { scale: 1 }, to: { scale: 0 } }
            },
            onShown: function onShown() {
               self.set('result', false);
            },
            onHidden: function onHidden() {
               self.sendAction('popupDone', self.get('result'));
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxPopupConfirmContainer").remove();
      }).on('willDestroyElement'),

      drawTemplate: function drawTemplate(contentElement) {
         var self = this;
         var msgHead = self.get('msgHead');
         var msgBody = self.get('msgBody');
         var msgNote = self.get('msgNote');

         contentElement.append("<div class='center top-margin-sm'><img src='/assets/images/pwron-logo-128.jpg' height='64' /></div>");
         contentElement.append("<h3 class='text-center bottom-margin-md'>" + msgHead + "</h3>");
         contentElement.append("<p class='text-center' style='font-size: larger'>" + msgBody + "</p>");
         contentElement.append("<h4 class='text-center text-danger top-margin-sm bottom-margin-sm'>" + msgNote + "</h4>");

         var buttons = _ember['default'].$("<div id='pop-confirm-btn-container' class='row top-margin bottom-margin'></div>");
         contentElement.append(buttons);

         var leftContainer = _ember['default'].$("<div class='col-xs-4 col-xs-offset-1'></div>");
         buttons.append(leftContainer);
         var rightContainer = _ember['default'].$("<div class='col-xs-4 col-xs-offset-1'></div>");
         buttons.append(rightContainer);

         leftContainer.append("<div id='pop-confirm-button-ok' class='btn-pwron-dx regular'></div>");
         var okButton = _ember['default'].$("#pop-confirm-button-ok").dxButton({
            text: "OK",
            onClick: function onClick() {
               self.complete(true);
            }
         });
         rightContainer.append("<div id='pop-confirm-button-cancel' class='btn-pwron-dx regular'></div>");
         var cancelButton = _ember['default'].$("#pop-confirm-button-cancel").dxButton({
            text: "Cancel",
            onClick: function onClick() {
               self.complete(false);
            }
         });
         leftContainer.append(okButton);
         rightContainer.append(cancelButton);
      },
      complete: function complete(result) {
         this.set('result', result);
         _ember['default'].$("#dxPopupConfirmContainer").dxPopup("instance").hide();
      },
      _disabledChanged: (function () {
         this.$("#dxPopupConfirmContainer").dxPopup('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});