define('frontend/components/dx-form-customer-help', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      isInvalid: false,
      attributeBindings: ['isInvalid'],
      _initialize: (function () {
         var userJSON = this.get('dataSource').toJSON();
         this.$("#dxFormCustomerHelpContainer").dxForm({
            disabled: this.get('disabled'),
            readOnly: this.get('readOnly'),
            formData: userJSON,
            alignLabels: true,
            showColonAfterLabel: false,
            labelLocation: "left",
            minColWidth: 300,
            colCount: 2,
            requiredMark: "*",
            items: [{
               colSpan: 1,
               caption: "Customer Information",
               itemType: "group",
               items: [
               /*"customer_id",*/
               {
                  dataField: 'first_name',
                  editorType: 'dxTextBox',
                  isRequired: true,
                  label: {
                     text: 'First Name'
                  },
                  editorOptions: {
                     hint: 'Enter your First Name',
                     placeholder: 'First Name'
                  }
               }, {
                  dataField: 'last_name',
                  editorType: 'dxTextBox',
                  isRequired: true,
                  label: {
                     text: 'Last Name'
                  },
                  editorOptions: {
                     hint: 'Enter your Last Name',
                     placeholder: 'Last Name'
                  }
               }, {
                  dataField: 'number',
                  editorType: 'dxTextBox',
                  isRequired: true,
                  label: {
                     text: 'Phone'
                  },
                  editorOptions: {
                     hint: 'Enter your Phone Number',
                     placeholder: 'Phone Number'
                  }
               }, {
                  dataField: 'email',
                  editorType: 'dxTextBox',
                  isRequired: true,
                  label: {
                     text: 'E-mail'
                  },
                  editorOptions: {
                     hint: 'Enter your E-mail Address',
                     placeholder: 'E-mail'
                  }
               }]
            }, {
               colSpan: 1,
               itemType: 'group',
               caption: "Device Information",
               items: ["Device Name", "Device Model"]
            }, {
               colSpan: 2,
               itemType: 'group',
               items: [{
                  dataField: 'notes',
                  editorType: 'dxTextArea',
                  label: {
                     text: 'Comments'
                  }
               }]
            }, {
               dataField: "Accepted",
               label: {
                  visible: false
               },
               editorType: 'dxCheckBox',
               editorOptions: {
                  text: "I agree to the Terms and Conditions"
               },
               validationRules: [{
                  type: "compare",
                  comparisonTarget: function comparisonTarget() {
                     return true;
                  },
                  message: "You must agree to the Terms and Conditions"
               }]
            }, {
               colSpan: 2,
               itemType: "button",
               horizontalAlignment: "right",
               buttonOptions: {
                  text: "Submit",
                  type: "success",
                  useSubmitBehavior: true,
                  height: 40,
                  width: 120
               }
            }]
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxFormCustomerHelpContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxFormCustomerHelpContainer").dxForm('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});