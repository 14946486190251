define('frontend/components/dx-tabs-devices', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      selectedIndex: 0,
      attributeBindings: ['selectedIndex'],
      _initialize: (function () {
         var self = this;
         this.$("#dxTabsDevicesContainer").dxTabs({
            disabled: self.get('disabled'),
            height: 48,
            hint: 'Select your device type',
            hoverStateEnabled: true,
            focusStateEnabled: false,
            repaintChangesOnly: true,
            selectedIndex: self.get('selectedIndex'),
            selectionMode: 'single',
            showNavButtons: true,
            items: self.get('tabItems'),
            onItemClick: function onItemClick(e) {
               self.set('selectedIndex', e.itemIndex);
            }
         });
      }).on('didInsertElement'),

      _disabledChanged: (function () {
         this.$("#dxTabsDevicesContainer").dxTabs('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _selectedIndexChanged: (function () {
         this.$("#dxTabsDevicesContainer").dxTabs('option', 'selectedIndex', this.get('selectedIndex'));
      }).observes('selectedIndex'),

      _destroy: (function () {
         this.$("#dxTabsDevicesContainer").remove();
      }).on('willDestroyElement')
   });
});