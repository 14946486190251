define('frontend/adapters/application', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

   _ember['default'].$.ajaxSetup({
      crossDomain: true
   });

   /**
    * this is the dynamic endpoint for dev reasons
    */
   var hostname = "";
   var vers = "/v2";

   if (window.location.hostname === "localhost") {
      hostname = "http://localhost:3000" + vers;
   } else {
      hostname = "//" + window.location.hostname + vers;
   }

   var ApplicationAdapter = _emberData['default'].RESTAdapter.extend({
      host: hostname
   });

   exports['default'] = ApplicationAdapter;
});