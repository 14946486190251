define('frontend/models/sensorlog', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _emberDataAttr, _emberDataRelationships) {
   exports['default'] = _emberData['default'].Model.extend({
      // Attributes ...
      battery_soc: (0, _emberDataAttr['default'])('number', {
         defaultValue: 0
      }),
      battery_volts: (0, _emberDataAttr['default'])('number', {
         defaultValue: 0
      }),
      current: (0, _emberDataAttr['default'])('number', {
         defaultValue: -999.9
      }),
      humidity: (0, _emberDataAttr['default'])('number', {
         defaultValue: -999.9
      }),
      temperature: (0, _emberDataAttr['default'])('number', {
         defaultValue: -999.9
      }),
      temperature_aux: (0, _emberDataAttr['default'])('number', {
         defaultValue: -999.9
      }),
      temperature_ext: (0, _emberDataAttr['default'])('number', {
         defaultValue: -999.9
      }),
      /*status: attr('number'),
      rtd_fault_reg: attr('number'),
      rtd_resistance: attr('number', {
         defaultValue: 0
      }),*/
      // Timestamps ...
      created: (0, _emberDataAttr['default'])('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      last_updated: (0, _emberDataAttr['default'])('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      // Relationships ...
      device: (0, _emberDataRelationships.belongsTo)('device')
   });
});