define('frontend/routes/home/device/recipients', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model(params) {
         var self = this;
         if (params && params.dvc) {
            return _ember['default'].RSVP.hash({
               device: self.store.findRecord('device', params.dvc).then(function (device) {
                  return device;
               }),
               /*recipients: self.store.findAll('notificationrecipient').then(function(recipients) {
                  if (recipients) {
                     return recipients;
                  } else {
                     return [];
                  }
               }).catch(function() {
                  return [];
               }),*/
               recipients: self.store.findRecord('device', params.dvc).then(function (device) {
                  if (device) {
                     var recipients = device.get('recipients');
                     var numRecipients = 0;
                     if (recipients) {
                        numRecipients = recipients.get('length');
                     }
                     console.log('RECS: Found %d Recipients', numRecipients);
                     return recipients;
                  } else {
                     return [];
                  }
               })['catch'](function () {
                  return [];
               })
            }).then(function (hash) {
               console.log('RECS: Fulfilled RSVP promises');
               return hash;
            });
         } else {
            return null;
         }
      },
      afterModel: function afterModel(model) {
         /*let controller = this.controllerFor('home.device.recipients');
         if (model) {
            if (model.device) {
               let name = model.device.get('name');
               if (name) {
                  controller.set('deviceName', name);
               }
            }
         }*/
         console.log('RECS: After Model Finished');
      },
      setupController: function setupController(controller, model) {
         // Call _super for default behavior - sets the model to the controller
         this._super(controller, model);

         if (model.device) {
            var _name = model.device.get('name');
            if (_name) {
               controller.set('deviceName', _name);
            }
         }
         //controller.updateProperties();
         /*if (controller.get('enableUpdates') !== true) {
            controller.set('enableUpdates', true);
         }*/
         console.log('RECS: Setup Controller Finished');
      },
      actions: {
         willTransition: function willTransition() {
            this.controllerFor('home.device.recipients').resetToolbar();
         },
         didTransition: function didTransition() {
            var controller = this.controllerFor('home.device.recipients');
            var homeController = this.controllerFor('home');
            if (homeController) {
               var _name2 = controller.get('deviceName');
               if (_name2) {
                  homeController.set('title', _name2);
               } else {
                  homeController.set('title', 'Notification Recipients');
               }
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});