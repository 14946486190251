define('frontend/controllers/users/forgot-email', ['exports', 'ember', 'ember-cli-countries/enums/calling-codes'], function (exports, _ember, _emberCliCountriesEnumsCallingCodes) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      queryParams: ['s'],
      s: null, // device hash

      callCodesList: _emberCliCountriesEnumsCallingCodes['default'].toKeyValueJson(),
      callingCode: "+61",
      phone: '',
      validationCode: '',
      user_id: null,

      invalidInput: true,
      codeNotSent: true,
      sendingCode: false,
      isKeyInvalid: true,

      isPhoneError: false,
      isCodeError: false,
      errorMessages: null,

      attributeBindings: ['phone', 'validationCode'],

      actions: {
         sendCode: function sendCode() {
            var self = this;
            this.resetError();
            this.resetFlags(false);
            this.set('sendingCode', true);
            DevExpress.ui.notify("Sending SMS verification code ...", "info", 2500);

            var data = this.getProperties('callingCode', 'phone');
            this.get('authManager').forgotEmail(data.callingCode, data.phone, function (result) {
               if (result === null) {
                  var msg = "Server Failure! (Error: No Response)";
                  DevExpress.ui.notify(msg, "error", 2500);
               } else if (result.statusText === "error") {
                  var msg = "Server Failure! (Error: No Response)";
                  DevExpress.ui.notify(msg, "error", 2500);
               } else if (result.status === "true") {
                  self.set('user_id', result._id);
                  self.set('codeNotSent', false);
                  DevExpress.ui.notify("SMS verification code sent", "success", 2500);
               } else {
                  var msg = result.reason;
                  DevExpress.ui.notify("Request Failed! Error: " + msg, "error", 2500);
                  self.set('isPhoneError', true);
                  self.set('errorMessages', msg);
               }
               self.resetButton('#btnSendSMS', 'Send My E-mail');
            });
         },
         resendCode: function resendCode() {
            var self = this;
            this.resetError();
            this.set('validationCode', '');
            this.set('codeNotSent', true);
            //this.set('sendingCode', true);
            DevExpress.ui.notify("Sending SMS Verification Code ...", "info", 2500);

            var data = this.getProperties('callingCode', 'phone');
            this.get('authManager').resendTokenUser(data.callingCode, data.phone, function (result) {
               if (result === null) {
                  DevExpress.ui.notify("Server Failure! (No Response)", "error", 2500);
                  self.resetFlags(false);
               } else if (result.setup_status === "true") {
                  DevExpress.ui.notify("SMS Verification Code Sent", "info", 2500);
                  self.set('codeNotSent', false);
                  self.set('isKeyInvalid', true);
               }
               self.resetButton('#btnResendCode', 'Resend Code');
            });
         },
         verifyCode: function verifyCode() {
            var self = this;
            DevExpress.ui.notify("Verifying Code ...", "info", 2500);
            var userID = self.get('user_id');
            var code = self.get('validationCode');

            this.get('authManager').verifyTokenEmail(userID, code, function (result) {
               if (result === null) {
                  var msg = "Server Failure! (Error: No Response)";
                  self.resetFlags(true);
                  DevExpress.ui.notify(msg, "error", 2500);
               } else if (result.status === "true") {
                  self.resetFlags(true);
                  DevExpress.ui.notify("SMS code verified", "success", 2500);
               } else {
                  self.set('errorMessages', 'Error verifying SMS code!');
                  self.set('isCodeError', true);
                  _ember['default'].$("#dxCodeContainer").dxValidator('instance').focus();
                  _ember['default'].$("#dxCodeContainer").dxValidator('instance').validate();
                  DevExpress.ui.notify("Error verifying SMS code! Please repeat the verification process.", "error", 2500);
               }
               self.resetButton('#btnVerifyCode', 'Verify Code');
            });
         }
      },
      resetFlags: function resetFlags(resetData) {
         this.set('user_id', null);
         this.set('validationCode', '');
         this.set('codeNotSent', true);
         this.set('sendingCode', false);
         if (resetData) {
            this.set('phone', '');
         }
      },
      resetError: function resetError() {
         this.set('errorMessages', null);
         this.set('isPhoneError', false);
         this.set('isCodeError', false);
      },
      resetButton: function resetButton(id, text) {
         var btn = _ember['default'].$(id + " > #dxButtonContainer").dxButton("instance");
         if (btn) {
            btn.option('text', text);
            btn.option('disabled', false);
            if (btn.loadingIndicator) {
               btn.loadingIndicator.option('visible', false);
            }
         }
      }
   });
});