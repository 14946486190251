define('frontend/components/dx-grid-alarms', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      isWideMode: true,
      group: 'all',
      mode: 'single',
      selectedAlarm: null,
      /*dataSource: [{
         sensor_type: 0,
         equality: 0,
         severity: 0,
         threshold: 4.0,
         value: -999.9,
         units: 'A',
         notification_type: 'SMS',
         enabled: true,
         triggered: false,
         created: Date.now(),
         last_updated: Date.now(),
         device: ''
      },{
         sensor_type: 1,
         equality: 1,
         severity: 1,
         threshold: 4.0,
         value: -999.9,
         units: '%RH',
         notification_type: 'Email',
         enabled: true,
         triggered: false,
         created: Date.now(),
         last_updated: Date.now(),
         device: ''
      },{
         sensor_type: 2,
         equality: 2,
         severity: 2,
         threshold: 4.0,
         value: -999.9,
         units: '°C',
         notification_type: 'Both',
         enabled: false,
         triggered: false,
         created: Date.now(),
         last_updated: Date.now(),
         device: ''
      },{
         sensor_type: 3,
         equality: 3,
         severity: 3,
         threshold: 4.0,
         value: -999.9,
         units: '°C',
         notification_type: 'SMS',
         enabled: true,
         triggered: false,
         created: Date.now(),
         last_updated: Date.now(),
         device: ''
      }],*/
      attributeBindings: ['dataSource', 'selectedAlarm'],

      _initialize: (function () {
         var self = this;
         this.$("#dxGridAlarmsContainer").dxDataGrid({
            //dataSource: self.get('dataSource'),
            dataSource: new DevExpress.data.DataSource({
               store: self.get('dataSource')
            }),
            disabled: self.get('disabled'),
            noDataText: 'No Alarms have been set!',
            columnAutoWidth: true,
            errorRowEnabled: false,
            loadPanel: {
               enabled: false,
               text: 'Refreshing Alarms ...'
            },
            editing: {
               mode: 'row',
               newRowPosition: 'last',
               useIcons: true,
               allowAdding: true,
               allowUpdating: true,
               allowDeleting: true,
               texts: {
                  addRow: 'Add an Alarm',
                  deleteRow: 'Delete Alarm',
                  editRow: 'Edit Alarm',
                  updateRow: 'Save Alarm'
               }
            },
            focusedRowEnabled: true,
            remoteOperations: {
               sorting: true,
               paging: true
            },
            paging: {
               pageSize: 10
            },
            pager: {
               showPageSizeSelector: true,
               allowedPageSizes: [5, 10, 15, 20],
               showInfo: true
            },
            searchPanel: {
               visible: self.get('isWideMode'),
               width: 150
            },
            selection: {
               mode: self.get('mode'),
               allowSelectAll: false
            },
            repaintChangesOnly: true,
            rowAlternationEnabled: false,
            showColumnLines: false,
            showRowLines: true,
            showBorders: false,
            hoverStateEnabled: true,
            toolbar: {
               visible: true
            },
            width: '100%',
            columns: [{
               caption: 'Enabled', dataField: 'enabled', dataType: 'boolean',
               alignment: 'center', cssClass: 'alarm-row-vert',
               minWidth: 60, width: 60,
               allowEditing: true,
               allowFiltering: false,
               allowFixing: false,
               allowGrouping: false,
               allowResizing: false,
               allowSearch: false,
               allowSorting: false,
               cellTemplate: function cellTemplate(container, options) {
                  if (options.data.enabled) {
                     self.$("<i class='fal fa-alarm-clock dx-icon-title small'></i>").appendTo(container);
                  } else {
                     self.$("<i class='small'></i>").appendTo(container);
                  }
               }
            }, {
               caption: 'Severity', dataField: 'severity',
               alignment: 'center', cssClass: 'alarm-row-vert',
               minWidth: 72, width: 96,
               allowEditing: true,
               allowFiltering: false,
               allowFixing: false,
               allowGrouping: true,
               allowResizing: false,
               allowSearch: true,
               allowSorting: false,
               cellTemplate: function cellTemplate(container, options) {
                  var severity = options.data.severity;
                  //console.log('Severity: %d', severity);
                  if (severity === 0) {
                     self.$("<i class='fas fa-info-circle dx-icon-title info'></i>").appendTo(container);
                  } else if (severity === 1) {
                     self.$("<i class='fas fa-check-circle dx-icon-title success'></i>").appendTo(container);
                  } else if (severity === 2) {
                     self.$("<i class='fas fa-exclamation-triangle dx-icon-title warning'></i>").appendTo(container);
                  } else {
                     //if (severity === 3) {
                     self.$("<i class='fas fa-exclamation-circle dx-icon-title error'></i>").appendTo(container);
                  }
               },
               lookup: {
                  dataSource: self.get('alarmSeverity'),
                  displayExpr: 'value',
                  valueExpr: 'key'
               },
               validationRules: [{ type: 'required' }],
               visible: true
            }, {
               dataField: 'sensor_type', caption: 'Sensor Type',
               alignment: 'left', cssClass: 'alarm-row-vert',
               allowEditing: true,
               allowGrouping: true,
               allowSearch: true,
               allowSorting: false,
               lookup: {
                  dataSource: self.get('sensorTypes'),
                  displayExpr: 'value',
                  valueExpr: 'key'
               },
               validationRules: [{ type: 'required' }],
               visible: true
            }, {
               dataField: 'equality', caption: 'Equality',
               alignment: 'right', cssClass: 'alarm-row-vert',
               minWidth: 160,
               allowEditing: true,
               allowGrouping: false,
               allowSearch: true,
               allowSorting: false,
               lookup: {
                  dataSource: self.get('alarmEquality'),
                  displayExpr: 'value',
                  valueExpr: 'key'
               },
               validationRules: [{ type: 'required' }],
               visible: true
            }, {
               dataField: 'threshold', caption: 'Value',
               alignment: 'center', cssClass: 'alarm-row-vert',
               minWidth: 64, width: 100,
               allowEditing: true,
               allowGrouping: false,
               allowSearch: true,
               allowSorting: false,
               dataType: 'number',
               format: {
                  type: 'fixedPoint',
                  precision: 1
               },
               validationRules: [{ type: 'required' }],
               visible: true
            }, {
               dataField: 'units', caption: 'Units',
               alignment: 'center', cssClass: 'alarm-row-vert',
               minWidth: 64, width: 64,
               allowEditing: false,
               allowGrouping: false,
               allowSearch: true,
               allowSorting: false,
               dataType: 'string',
               visible: true
            }, {
               dataField: 'notification_type', caption: 'Notify By',
               alignment: 'center', cssClass: 'alarm-row-vert',
               minWidth: 90, width: 120,
               allowEditing: true,
               allowGrouping: false,
               allowSearch: true,
               allowSorting: false,
               lookup: {
                  dataSource: self.get('notificationTypes'),
                  displayExpr: 'value',
                  valueExpr: 'key'
               },
               validationRules: [{ type: 'required' }],
               visible: true
            }, {
               caption: 'Actions', alignment: "center", cssClass: "alarm-row-vert",
               type: 'buttons',
               buttons: [{
                  name: 'edit',
                  text: 'Edit Alarm',
                  icon: 'fas fa-pencil-alt',
                  cssClass: 'alarm-row-button'
               }, {
                  name: 'delete',
                  text: 'Delete Alarm',
                  icon: 'fas fa-trash-alt',
                  cssClass: 'alarm-row-button'
               }, {
                  name: 'save',
                  text: 'Save Alarm',
                  icon: 'fas fa-save',
                  cssClass: 'alarm-row-button'
               }, {
                  name: 'cancel',
                  text: 'Cancel Edit',
                  icon: 'fas fa-undo-alt',
                  cssClass: 'alarm-row-button'
               }]
            }],
            /*onCellClick: function(e) {
               if (e.rowType === 'data') {
                  self.selectRow(e.rowIndex);
                  if (e.columnIndex === 0) {
                     let enabled = !e.data.enabled;
                     self.enableAlarm(enabled);
                     //console.log('ALARM #%d is %s', e.rowIndex, enabled ? 'enabled' : 'disabled');
                     //self.sendAction('enableAlarm', enabled);
                  }
               }
            },*/
            onCellClick: function onCellClick(e) {
               if (e.rowType === 'data') {
                  self.selectRow(e.rowIndex);
               }
            },
            onContentReady: function onContentReady(e) {
               /*let $noData = e.element.find(".dx-datagrid-nodata");
               if ($noData.length > 0) {
                  self.$("<i class='fas fa-exclamation-triangle dx-icon-title warning'></i>").appendTo($noData);
               }*/

               /*let scrollViewInstance = e.component.getScrollable();
               scrollViewInstance.option('onUpdated', function(e){
                  // your code to update 'No Data'
               });*/

               var selectedAlarm = self.get('selectedAlarm');
               if (selectedAlarm) {
                  e.component.selectRows(selectedAlarm, true);
               }
            },
            onFocusedRowChanged: function onFocusedRowChanged(e) {
               self.selectRow(e.rowIndex);
            },
            onRowInserting: function onRowInserting(e) {
               var sensorType = e.data.sensor_type;
               var units = getSensorUnits(sensorType);
               e.data.units = units;
               console.log('Units: %s', units);

               var alarmCount = getAlarmCount();
               console.log('Number of Alarms: %d', alarmCount);
            },
            onInitNewRow: function onInitNewRow(e) {
               e.data.enabled = true;
               e.data.equality = -1;
               e.data.severity = 2;
               e.data.sensor_type = 2;
               e.data.threshold = 4.0;
               e.data.units = getSensorUnits(e.data.sensor_type);
               e.data.notification_type = 'Both';
            },
            onRowInserted: function onRowInserted(e) {
               self.setSelectedAlarm(e.data);
               self.sendAction('insertAlarm');
               self.repaintDataGrid();
            },
            onRowPrepared: function onRowPrepared(e) {
               if (e.data) {
                  e.data.units = getSensorUnits(e.data.sensor_type);
                  //console.log('Units: %s', units);
               }
            },
            onRowUpdating: function onRowUpdating(e) {
               var units = getSensorUnits(e.newData.sensor_type);
               e.newData.units = units;
               console.log('Units: %s', units);
            },
            onRowUpdated: function onRowUpdated(e) {
               e.data.units = getSensorUnits(e.data.sensor_type);
               self.sendAction('updateAlarm');
            },
            onRowRemoving: function onRowRemoving(e) {
               //self.setSelectedAlarm(e.data);
               console.log('Removing Row: %s', e.data.id);
               self.sendAction('deleteAlarm');
            },
            onRowRemoved: function onRowRemoved() {
               self.repaintDataGrid();
            },
            onSelectionChanged: function onSelectionChanged(selectedItems) {
               if (selectedItems) {
                  var alarm = selectedItems.selectedRowsData[0];
                  self.setSelectedAlarm(alarm);
               } else {
                  self.setSelectedAlarm(null);
               }
            },
            onToolbarPreparing: function onToolbarPreparing(e) {
               var dataGrid = e.component;
               var toolbarItems = e.toolbarOptions.items;
               var alarmCount = getAlarmCount();
               var groupCount = getGroupCount('group');
               var alarmText = 'Alarms';
               var groupText = 'Groups';

               if (alarmCount === 1) {
                  alarmText = 'Alarm';
               }
               if (groupCount === 1) {
                  groupText = 'Group';
               }

               toolbarItems.unshift({
                  location: 'before',
                  locateInMenu: 'never',
                  template: function template() {
                     return _ember['default'].$("<div/>").addClass("informer").append(_ember['default'].$("<h3 />").addClass("device-count").text(alarmCount), _ember['default'].$("<span />").addClass("name").text(alarmText));
                  }
               }, {
                  location: 'before',
                  locateInMenu: 'auto',
                  widget: 'dxSelectBox',
                  options: {
                     width: 185,
                     items: [{
                        value: 'group',
                        text: 'All Alarms'
                     }, {
                        value: 'sensor_type',
                        text: 'Group by Sensor Type'
                     }, {
                        value: 'severity',
                        text: 'Group by Severity'
                     }],
                     displayExpr: 'text',
                     valueExpr: 'value',
                     value: 'group',
                     onValueChanged: function onValueChanged(e) {
                        dataGrid.clearGrouping();
                        dataGrid.columnOption(e.value, "groupIndex", 0);
                        groupCount = getGroupCount(e.value);
                        _ember['default'].$(".informer .total-count").text(groupCount);
                        if (e.value === 'sensor_type') {
                           groupText = 'Sensors';
                           if (groupCount === 1) {
                              groupText = 'Sensor';
                           }
                        } else if (e.value === 'severity') {
                           groupText = 'Severities';
                           if (groupCount === 1) {
                              groupText = 'Severity';
                           }
                        } else {
                           groupText = 'Groups';
                           if (groupCount === 1) {
                              groupText = 'Group';
                           }
                        }
                        _ember['default'].$(".informer .total-count + .name").text(groupText);
                     }
                  }
               }, {
                  location: "before",
                  locateInMenu: 'auto',
                  showText: 'inMenu',
                  widget: "dxButton",
                  widgetClass: "btn-primary",
                  options: {
                     hint: "Collapse All",
                     icon: "chevrondown",
                     text: "Collapse All",
                     onClick: function onClick(e) {
                        var expanding = e.component.option("icon") === "chevronnext";
                        dataGrid.option("grouping.autoExpandAll", expanding);
                        e.component.option({
                           icon: expanding ? "chevrondown" : "chevronnext",
                           hint: expanding ? "Collapse All" : "Expand All",
                           text: expanding ? "Collapse All" : "Expand All"
                        });
                     }
                  }
               }, {
                  location: "before",
                  locateInMenu: 'never',
                  template: function template() {
                     return _ember['default'].$("<div/>").addClass("informer").append(_ember['default'].$("<h3 />").addClass("total-count").text(groupCount), _ember['default'].$("<span />").addClass("name").text(groupText));
                  }
               }, {
                  location: "after",
                  locateInMenu: 'auto',
                  showText: 'inMenu',
                  widget: "dxButton",
                  options: {
                     icon: "refresh",
                     text: "Refresh",
                     onClick: function onClick() {
                        dataGrid.refresh();
                     }
                  }
               });
            }
         });
         function getAlarmCount() {
            var data = self.get('dataSource');
            if (data) {
               return DevExpress.data.query(data).toArray().length;
            } else {
               return 0;
            }
         }
         function getGroupCount(groupField) {
            var data = self.get('dataSource');
            if (data) {
               return DevExpress.data.query(data).groupBy(groupField).toArray().length;
            } else {
               return 0;
            }
         }
         function getSensorUnits(sensorType) {
            if (sensorType === 0) {
               return 'A';
            } else if (sensorType === 1) {
               return "%RH";
            } else if (sensorType >= 2 && sensorType <= 4) {
               return '°C';
            } else if (sensorType === 5) {
               return 'mV';
            } else if (sensorType === 6) {
               return '%SOC';
            } else {
               return null;
            }
         }
      }).on('didInsertElement'),

      selectRow: function selectRow(row) {
         if (row >= 0) {
            var grid = this.$("#dxGridAlarmsContainer").dxDataGrid('instance');
            if (grid) {
               grid.selectRowsByIndexes([row]).then(function () {
                  console.log('Selected row: %d', row);
               });
            }
         }
      },
      /*enableAlarm: function(enabled) {
         let alarm = this.get('selectedAlarm');
         if (alarm) {
            alarm.enabled = enabled;
            console.log('enable alarm: %s', enabled);
            this.refreshDataGrid();
         }
      },*/
      setSelectedAlarm: function setSelectedAlarm(alarm) {
         if (alarm) {
            this.set('selectedAlarm', alarm);
            console.log('Selected Alarm: %s', alarm.id);
         } else {
            this.set('selectedAlarm', null);
            console.log('Selected Alarm: null');
         }
      },
      refreshDataGrid: function refreshDataGrid() {
         var grid = this.$("#dxGridAlarmsContainer").dxDataGrid('instance');
         if (grid) {
            grid.refresh();
            grid.repaint();
         }
      },
      repaintDataGrid: function repaintDataGrid() {
         var grid = this.$("#dxGridAlarmsContainer").dxDataGrid('instance');
         if (grid) {
            grid.repaint();
         }
      },

      _destroy: (function () {
         this.$("#dxGridAlarmsContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxGridAlarmsContainer").dxDataGrid('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _dataSourceChanged: (function () {
         this.$("#dxGridAlarmsContainer").dxDataGrid('option', 'dataSource', this.get('dataSource'));
         this.refreshDataGrid();
      }).observes('dataSource'),

      _isWideModeChanged: (function () {
         var isWideMode = this.get('isWideMode');
         var grid = this.$("#dxGridAlarmsContainer").dxDataGrid('instance');
         if (grid) {
            grid.option('columns[1].visible', isWideMode);
            grid.option('columns[2].visible', isWideMode);
            grid.option('searchPanel.visible', isWideMode);
         }
      }).observes('isWideMode')
   });
});