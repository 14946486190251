define('frontend/transforms/number-zero', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

   var empty = _ember['default'].isEmpty;

   function isNumber(value) {
      return value === value && value !== Infinity && value !== -Infinity;
   }

   exports['default'] = _emberData['default'].Transform.extend({
      deserialize: function deserialize(serialized) {
         var transformed = undefined;

         if (empty(serialized)) {
            return 0;
         } else {
            transformed = Number(serialized);

            return isNumber(transformed) ? transformed : 0;
         }
      },

      serialize: function serialize(deserialized) {
         var transformed = undefined;

         if (empty(deserialized)) {
            return 0;
         } else {
            transformed = Number(deserialized);

            return isNumber(transformed) ? transformed : 0;
         }
      }
   });
});