define('frontend/components/dx-range-status', ['exports', 'ember'], function (exports, _ember) {
   //import moment from 'moment';

   exports['default'] = _ember['default'].Component.extend({
      showSensors: false,

      _initialize: (function () {
         var self = this;
         _ember['default'].$("#dxRangeContainer").dxRangeSelector({
            dataSource: self.get('dataSource'),
            margin: {
               top: 10
            },
            size: {
               height: 230
            },
            behavior: {
               animationEnabled: false
            },
            chart: {
               bottomIndent: 0.05,
               topIndent: 0.1,
               commonSeriesSettings: {
                  argumentField: "created"
               },
               series: [{ valueField: "status", color: "#48b04f", type: "steparea" }, { valueField: "temperature", color: "#d9534f", type: "line", visible: self.get('showSensors') }, { valueField: "humidity", color: "#6495ed", type: "line", visible: self.get('showSensors') }, { valueField: "current", color: "#505050", type: "line", visible: self.get('showSensors') }]
            },
            scale: {
               minorTickInterval: "hours",
               tickInterval: "days",
               valueType: "datetime",
               marker: { visible: true }
            },
            sliderMarker: {
               format: "day"
            },
            value: this.get('value'),
            title: {
               text: this.get('title'),
               font: {
                  family: 'Arial',
                  size: 18
               },
               margin: {
                  bottom: 10
               }
            },
            /*   subtitle: {
                  text: '[' + self.formatDate(this.get('value')[0], { time:'short' }) + ' to ' + self.formatDate(this.get('value')[1], { time:'short' }) + ']',
                  font: {
                     family: 'Arial',
                     size: 14,
                     weight: 500,
                  },
               },
            },*/
            onValueChanged: function onValueChanged(e) {
               self.set('value', e.value);
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxRangeContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxRangeContainer").dxRangeSelector('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _dataSourceChanged: (function () {
         this.$("#dxRangeContainer").dxRangeSelector('option', 'dataSource', this.get('dataSource'));
      }).observes('dataSource'),

      _showSensorsChanged: (function () {
         var showSensors = this.get('showSensors');
         var ranger = _ember['default'].$("#dxRangeContainer").dxRangeSelector('instance');
         if (ranger) {
            ranger.option('chart.series[1].visible', showSensors);
            ranger.option('chart.series[2].visible', showSensors);
            ranger.option('chart.series[3].visible', showSensors);
         }
      }).observes('showSensors'),

      _valueChanged: (function () {
         this.$("#dxRangeContainer").dxRangeSelector('option', 'value', this.get('value'));
      }).observes('value')

   });
});
/*formatDate: function(date, format) {
   if (typeof(format) !== 'string') {
      format = 'MMMM DD, YYYY';
   }
   if (date === undefined || date === null) {
      date = Date.now();
   }
   return moment(date).format(format);
}*/