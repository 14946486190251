define('frontend/models/user', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _emberDataAttr, _emberDataRelationships) {

   var user = _emberData['default'].Model.extend({
      first_name: (0, _emberDataAttr['default'])('string'), // First Name
      last_name: (0, _emberDataAttr['default'])('string'), // Last Name
      company: (0, _emberDataAttr['default'])('string'), // Company or Organisation
      image: (0, _emberDataAttr['default'])('string', {
         defaultValue: function defaultValue() {
            return '/assets/images/profile-male-md.png';
         }
      }),
      callingCode: (0, _emberDataAttr['default'])('string', {
         defaultValue: function defaultValue() {
            return '+61';
         } // International calling code
      }),
      number: (0, _emberDataAttr['default'])(), // Mobile phone number
      email: (0, _emberDataAttr['default'])('string'), // E-mail address
      customer_id: (0, _emberDataAttr['default'])('string'), // Stripe customer ID
      set_password: (0, _emberDataAttr['default'])('string'),
      confirm_password: (0, _emberDataAttr['default'])('string'),
      validation: (0, _emberDataAttr['default'])('string'), // Temporary key to validate User
      created: (0, _emberDataAttr['default'])('date', {
         defaultValue: function defaultValue() {
            return new Date();
         } // Date User account was created
      }),
      location: (0, _emberDataRelationships.belongsTo)('location'), // Geographical location of User

      setup_status: (0, _emberDataAttr['default'])(), // Returned to users/setup controller
      setup_message: (0, _emberDataAttr['default'])(),
      setup_token: (0, _emberDataAttr['default'])(),
      setup_reason: (0, _emberDataAttr['default'])(),
      setup_email: (0, _emberDataAttr['default'])(),

      /* Calculated properties */
      full_name: (function () {
         return this.get('first_name') + ' ' + this.get('last_name');
      }).property('first_name', 'last_name'),

      long_lat: (function () {
         var loc = this.get('location');
         if (loc) {
            return loc.longitude + ', ' + loc.latitude;
         } else {
            return 'lng, lat';
         }
      }).property('location')
   });

   exports['default'] = user;
});