define("frontend/helpers/battery-image", ["exports", "@ember/component/helper"], function (exports, _emberComponentHelper) {
   exports.batteryImage = batteryImage;

   function batteryImage(soc) {
      //, isCharging]) {
      var imageURL = "/assets/icons/battery/battery-00.png";

      if (soc === undefined || isNaN(soc) || soc < 0) {
         return imageURL;
      }
      /*if (isCharging === undefined || isCharging === null || isCharging !== true) {
         isCharging = false;
      }*/
      var isCharging = true;
      if (soc >= 0 && soc < 20) {
         imageURL = isCharging === true ? "/assets/icons/battery/battery-charging-00.png" : "/assets/icons/battery/battery-00.png";
      } else if (soc >= 20 && soc < 40) {
         imageURL = isCharging === true ? "/assets/icons/battery/battery-charging-020.png" : "/assets/icons/battery/battery-020.png";
      } else if (soc >= 40 && soc < 60) {
         imageURL = isCharging === true ? "/assets/icons/battery/battery-charging-040.png" : "/assets/icons/battery/battery-040.png";
      } else if (soc >= 60 && soc < 80) {
         imageURL = isCharging === true ? "/assets/icons/battery/battery-charging-060.png" : "/assets/icons/battery/battery-060.png";
      } else if (soc >= 80 && soc < 100) {
         imageURL = isCharging === true ? "/assets/icons/battery/battery-charging-080.png" : "/assets/icons/battery/battery-080.png";
      } else {
         imageURL = isCharging === true ? "/assets/icons/battery/battery-charging-100.png" : "/assets/icons/battery/battery-100.png";
      }
      return imageURL;
   }

   exports["default"] = (0, _emberComponentHelper.helper)(batteryImage);
});