define('frontend/models/notification', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _emberDataRelationships) {
   exports['default'] = _emberData['default'].Model.extend({
      code: _emberData['default'].attr('number'),
      /*notification_type: DS.attr('string', {
         defaultValue() { return 'Email'; }
      }),*/
      message: _emberData['default'].attr('string'),
      state: _emberData['default'].attr('number', {
         defaultValue: function defaultValue() {
            return 0;
         }
      }),
      value: _emberData['default'].attr('number', {
         defaultValue: function defaultValue() {
            return -999.9;
         }
      }),
      created: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      sent: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      alarm: (0, _emberDataRelationships.belongsTo)('alarm')
   });
});