define('frontend/controllers/home/device', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      /*isAnalyticsSelected: true,
      isSettingsSelected: false,
      isRecipientsSelected: false,*/
      //deviceID: null,
      /*actions: {
         gotoAnalytics: function () {
            this.transitionToRoute('home.device.analytics', {queryParams: { dvc: this.get('deviceID') }});
            this.resetButton("#dxButtonAnalytics", 'Analytics');
         },
         gotoSettings: function () {
            this.transitionToRoute('home.device.settings', {queryParams: { dvc: this.get('deviceID') }});
            this.resetButton('#dxButtonSettings', 'Settings');
         },
         gotoRecipients: function () {
            this.transitionToRoute('home.device.recipients', {queryParams: { dvc: this.get('deviceID') }});
            this.resetButton('#dxButtonRecipients', 'Recipients');
         }
      },*/
      resetButton: function resetButton(id, text) {
         var btn = _ember['default'].$(id + " #dxButtonContainer").dxButton("instance");
         if (btn) {
            btn.option('text', text);
            btn.option('disabled', false);
            if (btn.loadingIndicator) {
               btn.loadingIndicator.option('visible', false);
            }
         }
      }
   });
});
/*selectOption: function (selection, deviceID) {
   this.set('deviceID', deviceID);
   if (selection) {
      if (selection === 'analytics'){
         this.set('isAnalyticsSelected', true);
         this.set('isSettingsSelected', false);
         this.set('isRecipientsSelected', false);
      } else if (selection === 'settings'){
         this.set('isAnalyticsSelected', false);
         this.set('isSettingsSelected', true);
         this.set('isRecipientsSelected', false);
      } else if (selection === 'recipients'){
         this.set('isAnalyticsSelected', false);
         this.set('isSettingsSelected', false);
         this.set('isRecipientsSelected', true);
      }
   }
}*/