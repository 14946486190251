define('frontend/controllers/users/finddevice', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      store: _ember['default'].inject.service(),
      s: null,
      queryParams: ['s'],
      connecting: true,
      connectionError: false,
      counter: 0,
      maxCount: 15, // in double-seconds (i.e.
      timeoutId: 0,

      // NOTE: Don't use .on('init') here as multiple calls may result ...
      init: function init() {
         this._super();
         this.startLoop();
      },

      actions: {
         runLoop: function runLoop() {
            var self = this;
            var id = setTimeout(function () {
               var temp_uuid = self.get('s');
               var host = self.get('store').adapterFor('application').get('host');
               var postUrl = [host, 'devices/findTempUUID', temp_uuid].join('/');

               testUUID();

               function testUUID() {
                  var tid = self.get('timeoutId');
                  self.incrementProperty('counter');

                  if (self.get('counter') >= self.get('maxCount')) {
                     clearTimeout(tid);
                     self.complete();
                  } else if (temp_uuid === undefined || temp_uuid === null) {
                     clearTimeout(tid);
                     self.complete();
                  } else {
                     _ember['default'].$.get({
                        url: postUrl,
                        crossDomain: true,
                        success: function success(results) {
                           if (results._id !== '-1') {
                              // Device found, so redirect ...
                              self.transitionToRoute('users.adddevice', { queryParams: { s: results._id } });
                           } else {
                              var newId = setTimeout(testUUID, 2000);
                              self.set('timeoutId', newId);
                           }
                        }
                     });
                  }
               }
            }, 2000);
            self.set('timeoutId', id);
         },
         loginRedirect: function loginRedirect() {
            this.transitionToRoute("users.login", { queryParams: { addDevice: null } });
         },
         retryFindDevice: function retryFindDevice() {
            this.send("refreshModel");
            this.startLoop();
         }
      },
      complete: function complete() {
         this.set('connecting', false);
         this.set('connectionError', true);
         this.resetButton('#retryButton', 'Retry');
      },
      resetButton: function resetButton(id, text) {
         var btn = _ember['default'].$(id + " #dxButtonContainer").dxButton("instance");
         if (btn) {
            btn.option('text', text);
            btn.option('disabled', false);
            if (btn.loadingIndicator) {
               btn.loadingIndicator.option('visible', false);
            }
         }
      },
      startLoop: function startLoop() {
         this.set('counter', 0);
         this.set('connecting', true);
         this.set('connectionError', false);
         this.send('runLoop');
      }
   });
});