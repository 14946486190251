define('frontend/components/dx-toolbar-analytics', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      isChartShown: true,
      hasLogs: false,
      goBackHint: 'Go Back',
      goNextHint: 'Go Forward',
      attributeBindings: ['isChartShown', 'hasLogs', 'startDate', 'finishDate'],

      goBack: function goBack() {
         this.sendAction('goBack');
      },
      goNext: function goNext() {
         this.sendAction('goNext');
      },
      doRefresh: function doRefresh() {
         this.sendAction('doRefresh');
      },
      _initialize: (function () {
         var self = this;
         var caption = this.get('title');

         this.$("#dxToolbarAnalyticsContainer").dxToolbar({
            disabled: self.get('disabled'),
            dataSource: [{
               location: 'before',
               locateInMenu: 'never',
               widget: 'dxButton',
               visible: self.backOption,
               options: {
                  type: 'back',
                  text: 'Back',
                  hint: self.get('goBackHint'),
                  onClick: function onClick() {
                     self.goBack();
                  }
               }
            }, {
               location: 'before',
               locateInMenu: 'never',
               showText: 'inMenu',
               widget: 'dxDateBox',
               visible: self.get('hasLogs'),
               options: {
                  applyValueMode: 'useButtons',
                  text: 'Start',
                  height: 36,
                  width: 120,
                  hint: 'Select the Start Date',
                  icon: 'event',
                  displayFormat: 'dd/MM/yyyy',
                  value: self.get('startDate'),
                  onValueChanged: function onValueChanged(e) {
                     var finish = self.get('finishDate');
                     if (finish == null) {
                        DevExpress.ui.notify('Error: Finish date is undefined!', 'error', 2500);
                     } else if (e.value == null) {
                        DevExpress.ui.notify('Error: Start date is undefined!', 'error', 2500);
                     } else if (e.value <= finish) {
                        self.set('startDate', e.value);
                     } else {
                        DevExpress.ui.notify('Error: Start date cannot be after Finish date!', 'error', 2500);
                        self.set('startDate', finish);
                     }
                  }
               }
            }, {
               location: 'before',
               locateInMenu: 'never',
               showText: 'inMenu',
               widget: 'dxDateBox',
               visible: self.get('hasLogs'),
               options: {
                  applyValueMode: 'useButtons',
                  text: 'Finish',
                  height: 36,
                  width: 120,
                  hint: 'Select the Finish Date',
                  icon: 'event',
                  displayFormat: 'dd/MM/yyyy',
                  value: self.get('finishDate'),
                  onValueChanged: function onValueChanged(e) {
                     var start = self.get('startDate');
                     if (start == null) {
                        DevExpress.ui.notify('Error: Start date is undefined!', 'error', 2500);
                     } else if (e.value == null) {
                        DevExpress.ui.notify('Error: Finish date is undefined!', 'error', 2500);
                     } else if (e.value >= start) {
                        self.set('finishDate', e.value);
                     } else {
                        DevExpress.ui.notify('Error: Finish date cannot be before Start date!', 'error', 2500);
                        self.set('finishDate', start);
                     }
                  }
               }
            }, {
               location: 'after',
               locateInMenu: 'always',
               showText: 'inMenu',
               widget: 'dxButton',
               visible: self.get('hasLogs'),
               options: {
                  text: 'Last Week',
                  hint: 'Set date range to last 7 days',
                  icon: 'event',
                  onClick: function onClick() {
                     DevExpress.ui.notify("Setting date range to last 7 days", "info", 2500);
                     self.setLastNumDays(7);
                  }
               }
            }, {
               location: 'after',
               locateInMenu: 'always',
               showText: 'inMenu',
               widget: 'dxButton',
               visible: self.get('hasLogs'),
               options: {
                  text: 'Last Month',
                  hint: 'Set date range to last 30 days',
                  icon: 'event',
                  onClick: function onClick() {
                     DevExpress.ui.notify("Setting date range to last 30 days", "info", 2500);
                     self.setLastNumDays(30);
                  }
               }
            }, {
               location: 'after',
               locateInMenu: 'always',
               showText: 'inMenu',
               widget: 'dxButton',
               visible: self.get('hasLogs'),
               options: {
                  text: 'Last 3 Months',
                  hint: 'Set date range to last 90 days',
                  icon: 'event',
                  onClick: function onClick() {
                     DevExpress.ui.notify("Setting date range to last 90 days", "info", 2500);
                     self.setLastNumDays(90);
                  }
               }
            }, {
               location: 'after',
               locateInMenu: 'auto',
               showText: 'inMenu',
               widget: 'dxButton',
               visible: self.get('hasLogs'),
               options: {
                  text: 'Refresh',
                  hint: 'Reset the date range',
                  icon: 'refresh',
                  onClick: function onClick() {
                     _ember['default'].$("#dxToolbarAnalyticsContainer").dxToolbar("instance").repaint();
                     var chart = _ember['default'].$("#dxLineChartContainer").dxChart('instance');
                     var chartSensors = _ember['default'].$("#dxLineChartSensorsContainer").dxChart('instance');
                     if (chart || chartSensors) {
                        if (chart) {
                           chart.resetVisualRange();
                           //chart.option('argumentAxis.visualRange', [self.get('startDate'), self.get('finishDate')]);
                        } else if (chartSensors) {
                              //chartSensors.resetVisualRange();
                              chartSensors.option('argumentAxis.visualRange', [self.get('startDate'), self.get('finishDate')]);
                           }
                        self.doRefresh();
                        DevExpress.ui.notify("The date range has been reset", "info", 2500);
                     } else {
                        DevExpress.ui.notify("No charts to reset", "warning", 2500);
                     }
                  }
               }
            }, {
               location: 'after',
               locateInMenu: 'always',
               showText: 'inMenu',
               widget: 'dxButton',
               options: {
                  text: 'Graph',
                  hint: 'Show the Graph View',
                  icon: 'chart',
                  onClick: function onClick() {
                     self.set('isChartShown', true);
                     DevExpress.ui.notify("Showing 'Graph' View ...", "info", 2500);
                  }
               }
            }, {
               location: 'after',
               locateInMenu: 'always',
               showText: 'inMenu',
               widget: 'dxButton',
               options: {
                  text: 'List',
                  hint: 'Show the List View',
                  icon: 'menu',
                  onClick: function onClick() {
                     self.set('isChartShown', false);
                     DevExpress.ui.notify("Showing 'List' view ...", "info", 2500);
                  }
               }
            }, {
               location: 'after',
               locateInMenu: 'never',
               widget: 'dxButton',
               visible: self.nextOption,
               options: {
                  icon: 'chevronright',
                  type: 'Normal',
                  hint: self.get('goNextHint'),
                  onClick: function onClick() {
                     self.goNext();
                  }
               }
            }, {
               location: 'center',
               locateInMenu: 'never',
               showText: 'always',
               text: caption,
               template: function template(itemData, itemIndex, itemElement) {
                  itemElement.append("<div class='header-text'><h2>" + itemData.text + "</h2></div>");
               }
            }]
         });
      }).on('didInsertElement'),

      setLastNumDays: function setLastNumDays(days) {
         var finish = new Date();
         var start = new Date();
         start.setDate(start.getDate() - days); // Set start to X number of days ago
         this.set('finishDate', finish);
         this.set('startDate', start);
      },

      _startDateChanged: (function () {
         var startDate = this.get('startDate');
         var itemsArray = this.$("#dxToolbarAnalyticsContainer").dxToolbar('option', 'items');
         itemsArray[1].options.value = startDate;
         console.log('TOOLBAR-ANALYTICS: Setting Start Date: %s', startDate);
         _ember['default'].$("#dxToolbarAnalyticsContainer").dxToolbar("instance").repaint();
      }).observes('startDate'),

      _finishDateChanged: (function () {
         var finishDate = this.get('finishDate');
         var itemsArray = this.$("#dxToolbarAnalyticsContainer").dxToolbar('option', 'items');
         itemsArray[2].options.value = finishDate;
         console.log('TOOLBAR-ANALYTICS: Setting Finish Date: %s', finishDate);
         _ember['default'].$("#dxToolbarAnalyticsContainer").dxToolbar("instance").repaint();
      }).observes('finishDate'),

      _destroy: (function () {
         this.$("#dxToolbarAnalyticsContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxToolbarAnalyticsContainer").dxToolbar('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});