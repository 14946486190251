define('frontend/models/settings', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _emberDataRelationships) {
   exports['default'] = _emberData['default'].Model.extend({
      // Attributes ...
      comms_type: _emberData['default'].attr('string', {
         defaultValue: 'Wi-Fi'
      }),
      ping_sensitivity: _emberData['default'].attr('number-zero', {
         defaultValue: function defaultValue() {
            return 2;
         }
      }),
      post_frequency: _emberData['default'].attr('number-zero', {
         defaultValue: function defaultValue() {
            return 1;
         }
      }),
      show_sensors: _emberData['default'].attr('boolean', {
         defaultValue: function defaultValue() {
            return false;
         }
      }),
      stay_awake: _emberData['default'].attr('boolean', {
         defaultValue: function defaultValue() {
            return false;
         }
      }),
      // Timestamps ...
      created: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      last_updated: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      // Relationships ...
      device: (0, _emberDataRelationships.belongsTo)('device')
   });
});