define('frontend/controllers/home/profile/administrator', ['exports', 'ember', 'ember-cli-countries/enums/calling-codes'], function (exports, _ember, _emberCliCountriesEnumsCallingCodes) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      adminGroup: 'adminGroup',
      adminResult: false,
      resetUpload: false,
      invalidInput: false,
      isEditing: false,
      isNotEditing: _ember['default'].computed.not('isEditing'),
      isImageUploaded: false,
      callCodesList: _emberCliCountriesEnumsCallingCodes['default'].toKeyValueJson(),
      isPhoneError: false,
      isEmailError: false,
      errorMessages: null,

      // Hostname for uploading profile images
      hostname: _ember['default'].computed(function () {
         return this.get('store').adapterFor('application').get('host');
      }),

      actions: {
         authComplete: function authComplete() {
            this.set('isEditing', this.get('adminResult'));
         },
         edit: function edit() {
            this.showAdminPopup();
         },
         cancel: function cancel() {
            this.resetToolbar();
         },
         save: function save() {
            this.saveModel();
         },
         goBack: function goBack() {
            this.transitionToRoute("home.dashboard");
         },
         goNext: function goNext() {
            this.transitionToRoute("home.profile.address");
         }
      },
      resetToolbar: function resetToolbar() {
         this.set('resetUpload', true);
         this.set('isEditing', false);
      },
      showAdminPopup: function showAdminPopup() {
         if (this.get('adminResult')) {
            this.set('isEditing', true);
         } else {
            _ember['default'].$("#dxPopupAdminContainer").dxPopup("instance").show();
         }
      },
      saveModel: function saveModel() {
         var self = this;
         var result = DevExpress.validationEngine.validateGroup(this.get('adminGroup'));

         if (result && result.isValid) {
            DevExpress.ui.notify("Saving Administrator ...", "info", 2500);
            var model = this.get('model');
            if (model) {
               model.save().then(function () {
                  self.resetToolbar();
                  DevExpress.ui.notify("Administrator Saved", "success", 2500);
               })['catch'](function (error) {
                  if (error) {
                     var errorMsg = error.errors[0].detail;
                     var errMsg = errorMsg.toLowerCase();

                     if (errMsg.includes('email') || errMsg.includes('e-mail')) {
                        self.set('isEmailError', true);
                     } else if (errMsg.includes('phone') || errMsg.includes('mobile')) {
                        self.set('isPhoneError', true);
                     }
                     self.set('errorMessages', errorMsg);
                     DevExpress.ui.notify("Error Saving Administrator: " + errorMsg, "error", 2500);
                  }
               });
            }
         }
      },
      _isUploadedChanged: (function () {
         if (this.get('isImageUploaded')) {
            //this.get('model').reload();
            this.send("refreshModel");
         }
      }).observes('isImageUploaded')
   });
});