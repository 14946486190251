define('frontend/controllers/home', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      selectedMenuIndex: 0,
      subMenuIndex: -1,
      selectedTabIndex: 0,
      selectedDeviceIndex: -1,
      selectedDeviceID: null,
      selectedDeviceUUID: null,
      title: 'Home',
      actions: {
         logOut: function logOut() {
            var self = this;
            var result = DevExpress.ui.dialog.confirm("Are you sure you want to Logout?", "Confirm Logout");
            result.done(function (dialogResult) {
               if (dialogResult) {
                  self.transitionToRoute('users.logout');
               }
            });
         },
         gotoDashboard: function gotoDashboard() {
            var dvcID = this.get('selectedDeviceID');
            console.log('HOME: Goto Dashboard with ID: %s', dvcID);
            this.transitionToRoute('home.dashboard', { queryParams: { dvc: dvcID } });
         },
         gotoDevices: function gotoDevices() {
            var dvcID = this.get('selectedDeviceID');
            console.log('HOME: Goto Devices with ID: %s', dvcID);
            this.transitionToRoute('home.devices', { queryParams: { dvc: dvcID } });
         },
         gotoNotifications: function gotoNotifications() {
            var dvcID = this.get('selectedDeviceID');
            console.log('HOME: Goto Notifications with ID: %s', dvcID);
            this.transitionToRoute('home.notifications', { queryParams: { dvc: dvcID } });
         },
         gotoAdministrator: function gotoAdministrator() {
            this.transitionToRoute('home.profile.administrator');
         },
         gotoAddress: function gotoAddress() {
            this.transitionToRoute('home.profile.address');
         },
         gotoPassword: function gotoPassword() {
            this.transitionToRoute('home.profile.password');
         },
         gotoAccount: function gotoAccount() {
            this.transitionToRoute('home.account.details');
         },
         gotoBilling: function gotoBilling() {
            this.transitionToRoute('home.account.billing');
         },
         gotoSubscriptions: function gotoSubscriptions() {
            this.transitionToRoute('home.account.subscriptions');
         },
         gotoSettings: function gotoSettings() {
            this.transitionToRoute('home.appsettings');
         },
         gotoHelp: function gotoHelp() {
            this.transitionToRoute('home.help');
         },
         showProfileInfo: function showProfileInfo() {
            _ember['default'].$("#dxPopoverProfileContainer").dxPopup("instance").show();
         },
         showHelp: function showHelp() {
            _ember['default'].$("#dxPopupHelpContainer").dxPopup("instance").show();
         }
      },
      getDeviceIndex: function getDeviceIndex() {
         var index = this.get('selectedDeviceIndex');
         console.log('HOME: Get Device Index: ', index);
         return index;
      },
      getDeviceID: function getDeviceID() {
         var id = this.get('selectedDeviceID');
         console.log('HOME: Get Device ID: ', id);
         return id;
      },
      getDeviceUUID: function getDeviceUUID() {
         var uuid = this.get('selectedDeviceUUID');
         console.log('HOME: Get Device UUID: ', uuid);
         return uuid;
      },
      setDeviceIndex: function setDeviceIndex(index) {
         this.set('selectedDeviceIndex', index);
      },
      setDeviceID: function setDeviceID(id) {
         this.set('selectedDeviceID', id);
      },
      setDeviceUUID: function setDeviceUUID(uuid) {
         this.set('selectedDeviceUUID', uuid);
      },
      saveSettings: function saveSettings() {
         var settings = this.get('model.appsettings');
         if (settings) {
            settings.save();
         }
      },
      _selectedMenuIndexChanged: (function () {
         var menuIndex = this.get('selectedMenuIndex');
         var settings = this.get('model.appsettings');
         if (settings) {
            if (settings.menu_index !== menuIndex) {
               settings.menu_index = menuIndex;
               settings.save();
            }
         }
      }).observes('selectedMenuIndex'),

      _subMenuIndexChanged: (function () {
         var subMenuIndex = this.get('subMenuIndex');
         var settings = this.get('model.appsettings');
         if (settings) {
            if (settings.sub_menu_index !== subMenuIndex) {
               settings.sub_menu_index = subMenuIndex;
               settings.save();
            }
         }
      }).observes('subMenuIndex'),

      _tabIndexChanged: (function () {
         var tabIndex = this.get('selectedTabIndex');
         var settings = this.get('model.appsettings');
         if (settings) {
            if (settings.tab_index !== tabIndex) {
               settings.tab_index = tabIndex;
               settings.save();
            }
         }
      }).observes('selectedTabIndex'),

      _selectedDeviceIDChanged: (function () {
         var selectedDeviceID = this.get('selectedDeviceID');
         var selectedDeviceIndex = this.get('selectedDeviceIndex');
         var selectedDeviceUUID = this.get('selectedDeviceUUID');
         var settings = this.get('model.appsettings');
         if (settings) {
            settings.device_index = selectedDeviceIndex;
            settings.device_uuid = selectedDeviceUUID;
            settings.device_id = selectedDeviceID;
            settings.save();
            console.log('HOME: Device changed: Index: %d, ID: %s, UUID: %s', selectedDeviceIndex, selectedDeviceID, selectedDeviceUUID);
         }
      }).observes('selectedDeviceID')
   });
});