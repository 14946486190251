define('frontend/components/dx-load-panel', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].DxLoadPanelComponent = _ember['default'].Component.extend({
      _initialize: (function () {
         this.$("#dxLoadPanelContainer").dxLoadPanel({
            message: 'Loading...',
            showIndicator: true
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxLoadPanelContainer").remove();
      }).on('willDestroyElement')
   });
});