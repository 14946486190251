define('frontend/components/notification-group-item', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      classNames: ['list-group-item'],
      colId: (function () {
         return this.get('elementId') + "collapse";
      }).property('elementId'),

      actions: {
         slide: function slide() {
            var self = this;
            var selector = "." + this.get('colId');

            if (this.$(selector).hasClass('in')) {
               //slideup
               this.$(".menuClick").removeClass("glyphicon-menu-up");
               this.$(".menuClick").addClass("glyphicon-menu-down");
               this.$(selector).addClass("collapsing");
               this.$(selector).removeClass("collapse");
               this.$(selector).fadeOut(1, function () {
                  self.$(selector).removeClass("in");
                  self.$(selector).removeClass("collapsing");
                  self.$(selector).addClass("collapse");
               });
            } else {
               // slidedown
               this.$(selector).removeClass("collapse");
               this.$(selector).addClass("collapsing");
               this.$(selector).fadeIn(1, function () {
                  self.$(selector).addClass("in");
                  self.$(selector).addClass("collapse");
                  self.$(selector).removeClass("collapsing");
               });
               this.$(".menuClick").removeClass("glyphicon-menu-down");
               this.$(".menuClick").addClass("glyphicon-menu-up");
            }
         }
      }
   });
});