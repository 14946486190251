define('frontend/routes/home/profile/address', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model() {
         var self = this;
         var user_id = this.get('authManager').get('getUser.id');
         return _ember['default'].RSVP.hash({
            user: self.store.findRecord('user', user_id)['catch'](function () {
               return null;
            }),
            location: self.store.queryRecord('location', { user: user_id })['catch'](function () {
               // Location not found, so create a new record ...
               return self.store.createRecord('location');
            })
         });
      },
      afterModel: function afterModel(model) {
         var controller = this.controllerFor('home.profile.address');
         if (model) {
            controller.set('model', model);
         }
      },
      actions: {
         willTransition: function willTransition() {
            this.controllerFor('home.profile.address').resetToolbar();
         },
         didTransition: function didTransition() {
            var homeController = this.controllerFor('home');
            if (homeController) {
               homeController.set('title', 'Address Details');
               homeController.set('selectedMenuIndex', 3);
               homeController.set('subMenuIndex', 1);
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});