define('frontend/components/dx-text-email', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      isInvalid: false,
      isError: false,
      errorMessage: null,
      attributeBindings: ['isInvalid'],

      _initialize: (function () {
         var self = this;
         var validationGroup = this.get('group');

         this.$("#dxEmailContainer").dxTextBox({
            activeStateEnabled: true,
            inputAttr: { id: this.get('idAttr') },
            mode: 'email',
            disabled: this.get('disabled'),
            readOnly: this.get('readOnly'),
            hint: this.get('hint'),
            placeholder: this.get('placeholder'),
            fontFamily: 'Arial',
            fontSize: 24,
            validationMessageMode: 'always',
            value: this.get('value'),
            onInput: function onInput() {
               self.clearError();
            },
            onValueChanged: function onValueChanged(e) {
               self.clearError();
               self.set('value', e.value);
            },
            onFocusOut: function onFocusOut() {
               self.$("#dxEmailContainer").dxValidator('instance').validate();
            }
         }).dxValidator({
            validationGroup: validationGroup,
            validationRules: [{ type: 'required', reevaluate: true, message: 'E-mail is required' }, { type: 'email', reevaluate: true }, { type: 'custom', reevaluate: true, validationCallback: function validationCallback(options) {
                  if (self.get('isError')) {
                     options.rule.message = self.get('errorMessage');
                     return false;
                  } else {
                     options.rule.message = null;
                     return true;
                  }
               } }],
            onValidated: function onValidated(e) {
               try {
                  if (self.get('isDestroyed') || self.get('isDestroying')) {
                     return;
                  }
                  if (e.isValid) {
                     self.set('isInvalid', false);
                     self.$("#dxEmailContainer.dx-texteditor .dx-texteditor-container").addClass("dx-valid");
                  } else {
                     self.set('isInvalid', true);
                     self.$("#dxEmailContainer.dx-texteditor .dx-texteditor-container").removeClass("dx-valid");
                  }
               } catch (exp) {
                  console.log("Exception Error: " + exp);
               }
            }
         });
      }).on('didInsertElement'),

      clearError: function clearError() {
         if (this.get('isError')) {
            this.set('errorMessage', null);
            this.set('isError', false);
            this.$("#dxEmailContainer").dxValidator('instance').validate();
         }
      },

      _destroy: (function () {
         this.$("#dxEmailContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxEmailContainer").dxTextBox('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _isErrorChanged: (function () {
         if (this.get('isError')) {
            this.$("#dxEmailContainer").dxValidator('instance').validate();
            this.$("#dxEmailContainer").dxValidator('instance').focus();
         }
      }).observes('isError')
   });
});