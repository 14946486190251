define('frontend/components/dx-load-indicator', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      _initialize: (function () {
         this.$("#loadIndicatorContainer").dxLoadIndicator({
            disabled: false,
            visible: true
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#loadIndicatorContainer").remove();
      }).on('willDestroyElement')
   });
});