define('frontend/routes/home/appsettings', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model() {
         var self = this;
         var user_id = this.get('authManager').get('getUser.id');
         if (user_id) {
            return _ember['default'].RSVP.hash({
               user: self.store.findRecord('user', user_id),
               appsettings: self.store.queryRecord('appsettings', { user: user_id }).then(function (settings) {
                  if (settings) {
                     console.log('Application settings found');
                     return settings;
                  } else {
                     console.log('No Application settings found!');
                     return null;
                  }
               })['catch'](function (error) {
                  console.log('Find Application settings error: ' + error.message);
                  return null;
               })
            });
         } else {
            this.transitionTo('users.logout');
            return null;
         }
      },
      afterModel: function afterModel(model) {
         var controller = this.controllerFor('home.appsettings');
         if (model) {
            controller.set('model', model);
            if (model.devices) {
               controller.updateProperties();
            }
            controller.set('enableUpdates', true);
         }
      },
      actions: {
         willTransition: function willTransition() {
            var controller = this.controllerFor('home.appsettings');
            controller.set('enableUpdates', false);
         },
         didTransition: function didTransition() {
            var homeController = this.controllerFor('home');
            if (homeController) {
               homeController.set('title', 'Application Settings');
               homeController.set('subMenuIndex', -1);
               homeController.set('selectedMenuIndex', 5);
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});