define("frontend/helpers/alarm-repeat", ["exports", "ember"], function (exports, _ember) {
   exports.alarmRepeat = alarmRepeat;

   function alarmRepeat(params) {
      var key = params[0];

      if (key === 0) {
         return "Never";
      } else if (key === 1) {
         return "Every 10 mins";
      } else if (key === 2) {
         return "Every 30 mins";
      } else if (key === 3) {
         return "Every Hour";
      } else if (key === 4) {
         return "Every 3 Hours";
      } else if (key === 5) {
         return "Every 6 Hours";
      } else if (key === 6) {
         return "Every Day";
      } else {
         return "Unknown";
      }
   }

   exports["default"] = _ember["default"].Helper.helper(alarmRepeat);
});