define('frontend/components/dx-select-box', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      acceptCustomValue: false,
      valueExpr: 'value',
      isInvalid: false,
      attributeBindings: ['isInvalid'],

      _initialize: (function () {
         var self = this;
         var validationGroup = this.get('group');
         var dropDownButtonImage = _ember['default'].$("<img>", {
            src: "/assets/icons/glyphicons-174-down.png",
            'class': "select-icon"
         });
         var things = this.get('dataSource');

         this.$("#dxSelectContainer").addClass(this.get('iconClass'));

         this.$("#dxSelectContainer").dxSelectBox({
            acceptCustomValue: this.get('acceptCustomValue'),
            activeStateEnabled: true,
            inputAttr: { id: this.get('idAttr') },
            fontFamily: 'Arial',
            disabled: this.get('disabled'),
            hint: this.get('hint'),
            placeholder: this.get('placeholder'),
            dataSource: things,
            displayExpr: 'value',
            searchEnabled: true,
            searchMode: 'contains',
            valueExpr: this.get('valueExpr'),
            validationMessageMode: 'always',
            value: this.get('value'),
            onValueChanged: function onValueChanged(e) {
               self.set('value', e.value);
            },
            onCustomItemCreating: function onCustomItemCreating(data) {
               var newItem = { key: data.text, value: data.text };
               things.push(newItem);
               self.$("#dxSelectContainer").dxSelectBox('option', 'dataSource', things);
               self.$("#dxSelectContainer").dxSelectBox('option', 'value', newItem);
               //selectBox.set("items", things);
               return newItem;
            },
            dropDownButtonTemplate: function dropDownButtonTemplate() {
               return _ember['default'].$("<div class='select-icon-container'>").append(dropDownButtonImage);
            },
            itemTemplate: function itemTemplate(itemData, itemIndex, itemElement) {
               itemElement.append('<p class="dx-select-item"><b>' + itemData.value + '</b></p>');
               //itemElement.append('<p class="dx-select-item"><b>' + itemData.value + '</b> (' + itemData.key + ')</p>');
            }
         }).dxValidator({
            validationGroup: validationGroup,
            validationRules: [{ type: 'required', message: 'Selection is required' }],
            onValidated: function onValidated(e) {
               dropDownButtonImage.hide();
               try {
                  if (self.get('isDestroyed') || self.get('isDestroying')) {
                     return;
                  }
                  if (e.isValid) {
                     self.set('isInvalid', false);
                     self.$("#dxSelectContainer.dx-texteditor .dx-texteditor-container").addClass("dx-valid");
                  } else {
                     self.set('isInvalid', true);
                     self.$("#dxSelectContainer.dx-texteditor .dx-texteditor-container").removeClass("dx-valid");
                  }
               } catch (exp) {
                  console.log("Exception Error: " + exp);
               }
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxSelectContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxSelectContainer").dxSelectBox('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _valueChanged: (function () {
         this.$("#dxSelectContainer").dxSelectBox('option', 'value', this.get('value'));
      }).observes('value')
   });
});