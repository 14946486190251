define('frontend/initializers/ember-g-recaptcha', ['exports', 'frontend/config/environment', 'ember-g-recaptcha/configuration'], function (exports, _frontendConfigEnvironment, _emberGRecaptchaConfiguration) {
  exports['default'] = {

    name: 'ember-g-recaptcha',

    initialize: function initialize(registry) {
      var config = _frontendConfigEnvironment['default'].gReCaptcha || {};
      _emberGRecaptchaConfiguration['default'].setProperties(config);
    }

  };
});