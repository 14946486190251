define('frontend/routes/users/forgot-email', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model() {
         return null;
      },
      actions: {
         willTransition: function willTransition() {
            var controller = this.controllerFor('users.forgot-email');
            controller.resetFlags(true);
            controller.resetError();
         }
      }
   });
});