define('frontend/routes/home/notifications', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model() {
         var self = this;
         var user_id = this.get('authManager').get('getUser.id');
         if (user_id) {
            return _ember['default'].RSVP.hash({
               user: self.store.findRecord('user', user_id),
               devices: self.store.query('device', { user: user_id }).then(function (devices) {
                  if (devices) {
                     return devices;
                  } else {
                     console.log('NOTIFICATIONS: Devices Not Found!');
                     return [];
                  }
               })['catch'](function (error) {
                  console.log('NOTIFICATIONS: Find all Devices error: ' + error.message);
                  return null;
               }),
               /*notifications: self.store.query('device', {user: user_id}).then(function(devices) {
                  let nots = [];
                  if (devices) {
                     let numDevices = devices.get('length');
                     console.log('NOTIFICATIONS: Found %d devices', numDevices);
                     devices.forEach(function(device) {
                        let deviceId = device.get('id');
                        self.store.query('alarm', {device: deviceId}).then(function (alarms) {
                           if (alarms) {
                              let numAlarms = alarms.get('length');
                              console.log('NOTIFICATIONS: Found %d alarms for Device: %s', numAlarms, deviceId);
                              alarms.forEach(function(alarm) {
                                 let alarmId = alarm.get('id');
                                 self.store.query('notification', {alarm: alarmId}).then(function (notifications) {
                                    if (notifications) {
                                       let numNots = notifications.get('length');
                                       console.log('NOTIFICATIONS: Found %d notifications for Alarm: %s', numNots, alarmId);
                                       notifications.forEach(function(notification) {
                                          nots.push(notification);
                                       });
                                    } else {
                                       console.log('NOTIFICATIONS: No notifications found!');
                                    }
                                 }).catch(function (error) {
                                    console.log('NOTIFICATIONS: Find notifications error: %s', error.message);
                                 });
                              });
                           } else {
                              console.log('NOTIFICATIONS: No alarms found!');
                           }
                        }).catch(function (error) {
                           console.log('NOTIFICATIONS: Find alarms error: %s', error.message);
                        });
                     });
                  } else {
                     console.log("NOTIFICATIONS: No devices found!");
                  }
                  return nots;
               }).catch(function (error) {
                  console.log("NOTIFICATIONS: Find Devices error: " + error);
                  return null;
               }),*/
               notifications: self.store.query('notification', { user: user_id }).then(function (notifications) {
                  if (notifications) {
                     var numNots = notifications.get('length');
                     console.log('NOTIFICATIONS: Found %d notifications for User', numNots);
                     return notifications;
                  } else {
                     console.log('NOTIFICATIONS: Not Found!');
                     return [];
                  }
               })['catch'](function (error) {
                  console.log('NOTIFICATIONS: Find notifications error: %s', error.message);
               })
            }).then(function (hash) {
               console.log('NOTIFICATIONS: RSVP fulfilled all promises');
               return hash;
            });
         } else {
            this.transitionTo('users.logout');
            return null;
         }
      },
      afterModel: function afterModel(model) {
         if (model) {
            var numDevices = model.devices ? model.devices.get('length') : 0;
            var numNotifications = model.notifications ? model.notifications.get('length') : 0;
            console.log("NOTIFICATIONS: found %d Devices after Model", numDevices);
            console.log("NOTIFICATIONS: %d found after Model", numNotifications);
         }
         console.log('NOTIFICATIONS: After Model Complete');
      },
      setupController: function setupController(controller, model) {
         // Call _super for default behavior - sets the model to the controller
         this._super(controller, model);

         // Load the notifications and map to JSON ...
         //controller.UpdateJSONNotifications();

         // Custom controller setup ...
         controller.updateProperties();
         controller.refreshNotifications();

         // Enable periodic page refresh ...
         if (controller.get('enableUpdates') !== true) {
            controller.set('enableUpdates', true);
         }
         console.log('NOTIFICATIONS: Setup Controller Complete');
      },
      actions: {
         willTransition: function willTransition() {
            var controller = this.controllerFor('home.notifications');
            controller.set('enableUpdates', false);
            //controller.set('selectedDevice', null);
         },
         didTransition: function didTransition() {
            var homeController = this.controllerFor('home');
            if (homeController) {
               homeController.set('title', 'Notifications');
               homeController.set('subMenuIndex', -1);
               homeController.set('selectedMenuIndex', 2);
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});