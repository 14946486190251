define('frontend/components/dx-popup-privacy', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      result: false,
      title: 'Privacy Policy',
      filePath: '/assets/docs/PWRON_Privacy.html',

      _initialize: (function () {
         var self = this;

         this.$("#dxPopupPrivacyContainer").dxPopup({
            fullScreen: false,
            closeOnOutsideClick: true,
            dragEnabled: false,
            showCloseButton: false,
            showTitle: true,
            minWidth: 360,
            minHeight: 360,
            height: '80%',
            titleTemplate: function titleTemplate(titleElement) {
               titleElement.append("<div class='row' style='max-height: 60px'>" + "<img align='left' class='pull-left' width='42' src='/assets/icons/glyphicons-196-info-sign@2x.png'>" + "<h2 class='center'>" + self.get('title') + "</h2></div>");
            },
            contentTemplate: function contentTemplate(contentElement) {
               self.drawTemplate(contentElement);
            },
            animation: {
               show: { type: "pop", from: { opacity: 1, scale: 0 }, to: { scale: 1 } },
               hide: { type: "pop", from: { scale: 1 }, to: { scale: 0 } }
            }
         });
      }).on('didInsertElement'),

      drawTemplate: function drawTemplate(contentElement) {
         var self = this;
         var filePath = this.get('filePath');

         contentElement.append("<div class='center top-margin-sm'><img src='/assets/images/pwron-logo-128.jpg' height='60' /></div>");

         var textContent = _ember['default'].$("<div id='textContentPrivacy' class='text-scrollable'></div>");
         contentElement.append(textContent);
         _ember['default'].$("#textContentPrivacy").load(filePath);
         textContent.on("dxmousewheel", function (e) {
            e.stopPropagation();
         });

         var buttons = _ember['default'].$("<div id='pop-privacy-btn-container' class='row top-margin-md'></div>");
         contentElement.append(buttons);
         var leftContainer = _ember['default'].$("<div id='pop-privacy-left-container' class='col-xs-4 col-xs-offset-4 bottom-margin-md'></div>");
         buttons.append(leftContainer);

         leftContainer.append("<div id='pop-privacy-ok-container' class='btn-pwron-dx regular'></div>");
         var okButton = _ember['default'].$("#pop-privacy-ok-container").dxButton({
            text: "OK",
            onClick: function onClick() {
               self.complete();
            }
         });
         leftContainer.append(okButton);
      },

      complete: function complete() {
         _ember['default'].$("#dxPopupPrivacyContainer").dxPopup("instance").hide();
      },

      _destroy: (function () {
         this.$("#dxPopupPrivacyContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxPopupPrivacyContainer").dxPopup('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});