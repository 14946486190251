define('frontend/helpers/eq', ['exports', 'ember-truth-helpers/helpers/eq'], function (exports, _emberTruthHelpersHelpersEq) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberTruthHelpersHelpersEq['default'];
    }
  });
  Object.defineProperty(exports, 'equal', {
    enumerable: true,
    get: function get() {
      return _emberTruthHelpersHelpersEq.equal;
    }
  });
});