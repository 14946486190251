define('frontend/controllers/home/account/billing', ['exports', 'ember', 'ember-cli-countries/enums/calling-codes'], function (exports, _ember, _emberCliCountriesEnumsCallingCodes) {
   //import config from 'frontend/config/environment';

   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      adminResult: false,
      invalidInput: false,
      isAddMode: false,
      isEditing: false,
      isNotEditing: _ember['default'].computed.not('isEditing'),
      callCodesList: _emberCliCountriesEnumsCallingCodes['default'].toKeyValueJson(),
      isPhoneError: false,
      isEmailError: false,
      errorMessages: null,
      validationGroup: 'billingDetailsGroup',

      stripe: null,
      stripeToken: null,
      newCard: null,

      actions: {
         authComplete: function authComplete() {
            this.set('isEditing', this.get('adminResult'));
         },
         cancel: function cancel() {
            this.resetToolbar();
         },
         edit: function edit() {
            this.showAdminPopup();
         },
         save: function save() {
            this.resetToolbar();
         },
         goBack: function goBack() {
            this.transitionToRoute("home.account.details");
         },
         goNext: function goNext() {
            this.transitionToRoute("home.account.subscriptions");
         },
         addCard: function addCard() {
            var cards = this.get('model.cards');
            if (cards && cards.get('length') > 0) {
               DevExpress.ui.dialog.alert("Please remove your old Credit Card before adding a new one.", "Notification Message");
            } else {
               this.set('isAddMode', true);
            }
         },
         cancelAddCard: function cancelAddCard() {
            this.set('isAddMode', false);
         },
         updateCard: function updateCard(card) {
            var self = this;
            var user_id = this.get('authManager').get('getUser.id');
            var host = this.get('store').adapterFor('application').get('host');
            var url = [host, 'billing/cards', user_id].join('/');

            if (card) {
               var data = { card: card };

               _ember['default'].$.ajax({
                  url: url,
                  crossDomain: true,
                  data: data,
                  type: 'PUT',
                  success: function success() {
                     DevExpress.ui.notify("Card Updated Successfully", "success", 2500);
                     self.send("refreshModel");
                  },
                  error: function error(err) {
                     console.log('Update Card Failed: ' + err);
                     DevExpress.ui.notify("Update Card Failed: " + err, "error", 2500);
                  }
               });
            } else {
               console.log('Selected Card is not defined!');
            }
         },
         removeCard: function removeCard(card) {
            var self = this;
            var user_id = this.get('authManager').get('getUser.id');
            var host = this.get('store').adapterFor('application').get('host');
            var url = [host, 'billing/cards/delete', user_id].join('/');

            if (card) {
               var data = { customer_id: card.customer, card_id: card.id };

               _ember['default'].$.ajax({
                  url: url,
                  crossDomain: true,
                  data: data,
                  type: 'DELETE',
                  success: function success() {
                     DevExpress.ui.notify("Card Deleted Successfully", "success", 2500);
                     self.send("refreshModel");
                  },
                  error: function error(err) {
                     console.log('Delete Card Failed: ' + err);
                     DevExpress.ui.notify("Delete Card Failed: " + err, "error", 2500);
                  }
               });
            } else {
               DevExpress.ui.notify("No card has been selected!", "error", 2500);
            }
         },
         /*
         createCard: function() {
            let self = this;
            let stripe = this.get('stripe');
            let newCard = this.get('newCard');
             if (stripe && newCard) {
               //console.log('Creating Payment Card...');
                stripe.createToken(newCard).then(function(result) {
                  if (result.error) {
                     // Inform user if there was an error ...
                     let errorElement = Ember.$('#card-errors');
                     errorElement.textContent = result.error.message;
                  } else {
                     // Send the token to your server ...
                     //console.log('Payment Card Created');
                     self.stripeTokenHandler(result.token);
                  }
               });
            }
         },*/
         saveToken: function saveToken(token) {
            if (token) {
               this.set('stripeToken', token.id);
               this.send('submitForm');
            } else {
               this.set('stripeToken', null);
            }
         },
         submitForm: function submitForm() {
            var self = this;
            var user_id = this.get('authManager').get('getUser.id');
            var host = this.get('store').adapterFor('application').get('host');
            var postUrl = [host, 'billing/cards/create', user_id].join('/');
            var data = { token: this.get('stripeToken') };

            _ember['default'].$.post({
               url: postUrl,
               crossDomain: true,
               data: data,
               success: function success() {
                  DevExpress.ui.notify("New Card Added Successfully", "success", 2500);
                  self.send("refreshModel");
               },
               error: function error(err) {
                  var msg = err.responseText;
                  var formElement = _ember['default'].$('#stp-payment-form');
                  if (formElement) {
                     formElement.removeClass('submitting');
                     formElement.removeClass('submitted');
                  }
                  console.log('Add Card Form Submit Failed: ' + msg);
                  DevExpress.ui.notify("Add New Card Failed: " + msg, "error", 2500);
               }
            });
         }
      },
      resetToolbar: function resetToolbar() {
         this.set('isEditing', false);
         this.set('isAddMode', false);
      },
      showAdminPopup: function showAdminPopup() {
         if (this.get('adminResult')) {
            this.set('isEditing', true);
         } else {
            _ember['default'].$("#dxPopupAdminContainer").dxPopup("instance").show();
         }
      }
   });
});
/*
renderElements: function() {
   let stripeObj = new Stripe(config.stripe.publishableKey);
   let elements = stripeObj.elements();
   this.set('stripe', stripeObj);
    // Custom styling passed to options when creating an Element...
   let elemStyle = {
      base: {
         color: '#202020',
         lineHeight: '24px',
         fontFamily: 'Arial, Helvetica, sans-serif',
         fontSmoothing: 'antialiased',
         fontSize: '16px',
         '::placeholder': {
            color: '#a9a9a9'
         }
      },
      invalid: {
         color: '#d9534f',
         iconColor: '#d9534f'
      }
   };
    // Create an instance of the card Element
   let newCard = elements.create('card', {style: elemStyle});
    newCard.addEventListener('change', function(event) {
      let displayError = document.getElementById('card-errors');
      if (event.error) {
         displayError.textContent = event.error.message;
      } else {
         displayError.textContent = '';
      }
   });
   this.set('newCard', newCard);
    // Add an instance of card Element into `card-element` <div>
   let cardElement = Ember.$('#card-element');
   let formElement = Ember.$('#payment-form');
    if (cardElement) {
      newCard.mount('#card-element');
   }
   if (formElement) {
      formElement.removeClass('hidden');
   }
},*/
/*
hideElements: function() {
   let formElement = Ember.$('#payment-form');
   if (formElement) {
      formElement.addClass('hidden');
   }
},*/
/*
stripeTokenHandler: function(token) {
   // Insert token ID into the form so it gets submitted to server ...
   let formElement = Ember.$('#payment-form');
    this.set('stripeToken', token.id);
    if (formElement) {
      // Submit the form ...
      formElement.submit();
   }
},*/
/*
_addModeChanged: function() {
   // This needs to be done here because the elements aren't available on the form
   // until the conditionals for 'isAddMode' are resolved in the template ...
   if (this.get('isAddMode')) {
      this.renderElements();
   } else {
      this.hideElements();
   }
}.observes('isAddMode'),*/