define('frontend/components/setup-progress', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      isSetupMode: null,
      state1: 'disabled',
      state2: 'disabled',
      state3: 'disabled',
      state4: 'disabled',
      state5: 'disabled',
      title1: 'Connect Device',
      title2: 'Setup Wi-Fi',
      title3: 'Connect to Server',
      title4: 'Setup Account',
      title5: 'Add Device'
   });
});