define('frontend/routes/users/adddevice', ['exports', 'ember', 'frontend/config/environment'], function (exports, _ember, _frontendConfigEnvironment) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model(params) {
         var self = this;
         var routeName = this.get('routeName');
         var user_id = this.get('authManager').get('getUser.id');
         var host = this.get('store').adapterFor('application').get('host');

         if (params && params.s) {
            return _ember['default'].RSVP.hash({
               user: self.get('store').findRecord('user', user_id),
               device: self.store.findRecord('device', params.s).then(function (device) {
                  return device;
               })['catch'](function (error) {
                  console.log("%s: Find Device Error: %s", routeName, error.message);
                  return null;
               }),
               settings: self.store.queryRecord('settings', { device: params.s }).then(function (setting) {
                  console.log('%s: Found device settings', routeName);
                  return setting;
               })['catch'](function (error) {
                  console.log('%s: Find device settings error: %s', routeName, error.message);
                  /*let newSetting = self.store.createRecord('settings', {device: params.s});
                  if (newSetting) {
                     console.log('%s: Created new device settings', routeName);
                     return newSetting;
                  } else {*/
                  return null;
               }),
               devices: self.store.query('device', { user: user_id }).then(function (devices) {
                  if (devices) {
                     //let numDevices = devices.get('length');
                     //console.log("# of Devices (Add-Device): " + numDevices);
                     return devices;
                  }
                  return [];
               })['catch'](function (error) {
                  console.log("%s: Find All Devices Error: %s", routeName, error);
                  return [];
               }),
               cards: new _ember['default'].RSVP.Promise(function (resolve, reject) {
                  var url = [host, 'billing/cards', user_id].join('/');
                  _ember['default'].$.get({
                     url: url,
                     crossDomain: true,
                     success: function success(results) {
                        if (results) {
                           resolve(results.data);
                        } else {
                           resolve(null);
                        }
                     },
                     error: function error(err) {
                        reject(err.responseText);
                     }
                  });
               }).then(function (cards) {
                  return cards;
               })['catch'](function (err) {
                  console.log("Error getting Cards: " + err);
                  return null;
               }),
               plans: new _ember['default'].RSVP.Promise(function (resolve, reject) {
                  var url = [host, 'billing/plans'].join('/');
                  _ember['default'].$.get({
                     url: url,
                     crossDomain: true,
                     data: null,
                     success: function success(results) {
                        if (results) {
                           var data = results.data;
                           data.sort(function (a, b) {
                              if (a.amount < b.amount) {
                                 return -1;
                              } else if (a.amount > b.amount) {
                                 return 1;
                              } else {
                                 return 0;
                              }
                           });
                           resolve(data);
                        } else {
                           resolve(null);
                        }
                     },
                     error: function error(err) {
                        reject(err.responseText);
                     }
                  });
               }).then(function (plans) {
                  return plans;
               })['catch'](function (err) {
                  console.log("Error getting Plans: " + err);
                  return null;
               }),
               subscription: self.store.findRecord('subscription', params.s).then(function (subscription) {
                  if (subscription) {
                     return subscription;
                  }
               })['catch'](function () {
                  var newSubscription = self.store.createRecord('subscription');
                  if (newSubscription) {
                     return newSubscription;
                  }
               })
            });
         } else {
            return null;
         }
      },
      afterModel: function afterModel(model) {
         //let controller = this.controllerFor('users.adddevice');
         if (model) {
            /*controller.set('model', model);
            let defaultPlan = this.findItemById(model.plans, 'id', config.APP.DEFAULT_PLAN);
            if (defaultPlan) {
               controller.set('selectedPlan', defaultPlan);
            }*/

            if (model.device) {
               // Check if the device has NO model type ...
               var modelType = model.device.get('model_type');
               /*if (modelType == null) {
                  modelType = "POAS";
                  model.device.set('model_type', modelType);
               }*/
               // Check if there's a device to ADD with NO settings ...
               if (model.settings == null) {
                  var showSensors = false;
                  if (modelType && modelType.toUpperCase() === 'TALS') {
                     showSensors = true;
                  }
                  // New devices will not have a settings record in the database ...
                  model.settings = this.store.createRecord('settings', {
                     device: model.device,
                     show_sensors: showSensors,
                     ping_sensitivity: model.device.get('ping_sensitivity'),
                     post_frequency: model.device.get('post_frequency')
                  });
                  console.log('ADD-DEVICE: Created new Device Settings');
               }
            }
         }
      },
      setupController: function setupController(controller, model) {
         // Call _super for default behavior - sets the model to the controller
         this._super(controller, model);

         if (model) {
            var defaultPlan = this.findItemById(model.plans, 'id', _frontendConfigEnvironment['default'].APP.DEFAULT_PLAN);
            if (defaultPlan) {
               controller.set('selectedPlan', defaultPlan);
            }
         }
      },
      actions: {
         accountRedirect: function accountRedirect() {
            this.transitionTo("home.dashboard");
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      },
      findItemById: function findItemById(array, key, value) {
         if (array) {
            for (var i = 0; i < array.length; i++) {
               if (array[i][key] === value) {
                  return array[i];
               }
            }
         }
         return null;
      }
   });
});