define("frontend/models/appsettings", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships"], function (exports, _emberData, _emberDataAttr, _emberDataRelationships) {
   exports["default"] = _emberData["default"].Model.extend({
      // Attributes ...
      menu_index: (0, _emberDataAttr["default"])('number', {
         defaultValue: function defaultValue() {
            return 0;
         }
      }),
      sub_menu_index: (0, _emberDataAttr["default"])('number', {
         defaultValue: function defaultValue() {
            return -1;
         }
      }),
      tab_index: (0, _emberDataAttr["default"])('number', {
         defaultValue: function defaultValue() {
            return 0;
         }
      }),
      device_index: (0, _emberDataAttr["default"])('number', {
         defaultValue: function defaultValue() {
            return 0;
         }
      }),
      device_id: (0, _emberDataAttr["default"])('string'),
      device_uuid: (0, _emberDataAttr["default"])('string'),
      // Timestamps ...
      created: (0, _emberDataAttr["default"])('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      last_updated: (0, _emberDataAttr["default"])('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      // Relationships ...
      user: (0, _emberDataRelationships.belongsTo)('user')
   });
});