define('frontend/routes/home/account/invoices', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model() {
         var self = this;
         var user = this.get('authManager').get('getUser.id');
         return self.store.findRecord('user', user).get('location');
      },
      actions: {
         willTransition: function willTransition() {
            this.controllerFor('home.account.invoices').resetToolbar();
         },
         didTransition: function didTransition() {
            //this.controllerFor('home.account').selectOption('Invoices');
            var homeController = this.controllerFor('home');
            if (homeController) {
               homeController.set('title', 'Account Invoices');
               homeController.set('selectedMenuIndex', 4);
               homeController.set('subMenuIndex', 3);
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});