define('frontend/services/screen', ['exports', 'ember-screen'], function (exports, _emberScreen) {
   exports['default'] = _emberScreen['default'].extend({
      //isTiny: breakpoint('(max-width: 767px)'),
      /*isSmall: breakpoint('(min-width: 460px)'),
      isMedium: breakpoint('(min-width: 768px)'),
      isLarge: breakpoint('(min-width: 992px)'),
      isExtraLarge: breakpoint('(min-width: 1200px)')*/

      isSmallAndUp: (0, _emberScreen.breakpoint)('(min-width: 34em)'),
      isMediumAndUp: (0, _emberScreen.breakpoint)('(min-width: 48em)'),
      isLargeAndUp: (0, _emberScreen.breakpoint)('(min-width: 62em)'),
      isExtraLargeAndUp: (0, _emberScreen.breakpoint)('(min-width: 75em)'),

      isExtraSmallAndDown: (0, _emberScreen.breakpoint)('(max-width: 33.9999em)'),
      isSmallAndDown: (0, _emberScreen.breakpoint)('(max-width: 47.9999em)'),
      isMediumAndDown: (0, _emberScreen.breakpoint)('(max-width: 61.9999em)'),
      isLargeAndDown: (0, _emberScreen.breakpoint)('(max-width: 74.9999em)')
   });
});