define('frontend/components/dx-drawer-menu', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      opened: false,
      accountMenuOpened: false,
      profileMenuOpened: false,
      menuIndex: 0,
      subMenuIndex: -1,
      attributeBindings: ['opened', 'accountMenuOpened', 'profileMenuOpened'],
      gotoDashboard: function gotoDashboard() {
         this.sendAction('gotoDashboard');
      },
      gotoDevices: function gotoDevices() {
         this.sendAction('gotoDevices');
      },
      gotoNotifications: function gotoNotifications() {
         this.sendAction('gotoNotifications');
      },
      gotoAdministrator: function gotoAdministrator() {
         this.sendAction('gotoAdministrator');
      },
      gotoAddress: function gotoAddress() {
         this.sendAction('gotoAddress');
      },
      gotoPassword: function gotoPassword() {
         this.sendAction('gotoPassword');
      },
      gotoAccount: function gotoAccount() {
         this.sendAction('gotoAccount');
      },
      gotoBilling: function gotoBilling() {
         this.sendAction('gotoBilling');
      },
      gotoSubscriptions: function gotoSubscriptions() {
         this.sendAction('gotoSubscriptions');
      },
      gotoSettings: function gotoSettings() {
         this.sendAction('gotoSettings');
      },
      gotoHelp: function gotoHelp() {
         this.sendAction('gotoHelp');
      },
      showHelp: function showHelp() {
         this.sendAction('showHelp');
      },
      /*toggleSubMenu: function(itemIndex) {
         if (itemIndex === 3) {
            let profileOpened = this.get('profileMenuOpened');
            if (profileOpened) {
               this.closeProfileMenu();
            } else {
               this.openProfileMenu();
            }
         } else if (itemIndex === 4) {
            let accountOpened = this.get('accountMenuOpened');
            if (accountOpened) {
               this.closeAccountMenu();
            } else {
               this.openAccountMenu();
            }
         }
         console.log('DRAWER: Sub-Menu toggled: %d', itemIndex);
      },*/
      closeProfileMenu: function closeProfileMenu() {
         /*let profileOpened = this.get('profileMenuOpened');
         if (profileOpened) {*/
         _ember['default'].$('#dxListProfileMenu').dxList('option', 'visible', false);
         this.set('profileMenuOpened', false);
         console.log('DRAWER: Profile menu closed');
      },
      openProfileMenu: function openProfileMenu() {
         var profileOpened = this.get('profileMenuOpened');
         if (!profileOpened) {
            _ember['default'].$('#dxListProfileMenu').dxList('option', 'visible', true);
            this.set('profileMenuOpened', true);
            console.log('DRAWER: Profile menu opened');
         }
      },
      closeAccountMenu: function closeAccountMenu() {
         /*let accountOpened = this.get('accountMenuOpened');
         if (accountOpened) {*/
         _ember['default'].$('#dxListAccountMenu').dxList('option', 'visible', false);
         this.set('accountMenuOpened', false);
         console.log('DRAWER: Account menu closed');
      },
      openAccountMenu: function openAccountMenu() {
         var accountOpened = this.get('accountMenuOpened');
         if (!accountOpened) {
            _ember['default'].$('#dxListAccountMenu').dxList('option', 'visible', true);
            this.set('accountMenuOpened', true);
            console.log('DRAWER: Account menu opened');
         }
      },
      closeSubMenus: function closeSubMenus() {
         this.closeProfileMenu();
         this.closeAccountMenu();
      },
      _initialize: (function () {
         var self = this;
         this.$("#dxDrawerMenuContainer").dxDrawer({
            minSize: 70,
            width: 275,
            closeOnOutsideClick: true,
            disabled: self.get('disabled'),
            opened: self.get('opened'),
            openedStateMode: 'push',
            revealMode: 'expand',
            template: function template() {
               var $list = _ember['default'].$("<div id='dx-drawer-panel-list'>").addClass("panel-list");
               return $list.dxList({
                  dataSource: [{ id: 1, text: "Dashboard", icon: 'fas fa-home' }, { id: 2, text: "Devices", icon: 'fas fa-tablet-alt' }, { id: 3, text: "Notifications", icon: 'fas fa-bell' }, { id: 4, text: "Profile", icon: 'fas fa-user',
                     template: function template(data, index, element) {
                        var itemList = _ember['default'].$("<div id='dxListProfileMenu'>").addClass("list-item-top-border").dxList({
                           visible: self.get('profileMenuOpened'),
                           dataSource: [{ text: "Administrator", icon: 'fas fa-user-circle' }, { text: "Address", icon: 'fas fa-map-marker-alt' }, { text: "Password", icon: 'fas fa-lock' }],
                           hoverStateEnabled: true,
                           focusStateEnabled: false,
                           activeStateEnabled: false,
                           selectionMode: 'single',
                           onContentReady: function onContentReady(e) {
                              var list = e.component;
                              var menuIndex = self.get('menuIndex');
                              var subIndex = self.get('subMenuIndex');
                              if (list && menuIndex === 3 && subIndex >= 0 && subIndex <= 2) {
                                 //self.openProfileMenu();
                                 list.selectItem(subIndex);
                              }
                           },
                           onItemClick: function onItemClick(e) {
                              if (e.itemIndex === 0) {
                                 self.gotoAdministrator();
                              } else if (e.itemIndex === 1) {
                                 self.gotoAddress();
                              } else if (e.itemIndex === 2) {
                                 self.gotoPassword();
                              }
                           }
                        });
                        element.append("<div class='center-vert'><i id='dxListProfileMenuIcon' class='fas fa-user'/>" + "<label class='label-drawer-menu'>Profile</label></div>");
                        element.append(itemList);
                     }
                  }, { id: 5, text: "Account", icon: 'money',
                     template: function template(data, index, element) {
                        var itemList = _ember['default'].$("<div id='dxListAccountMenu'>").addClass("list-item-top-border").dxList({
                           visible: self.get('accountMenuOpened'),
                           dataSource: [{ text: "Details", icon: 'fas fa-list' }, { text: "Billing", icon: 'money' }, { text: "Subscriptions", icon: 'fas fa-shopping-cart' }],
                           hoverStateEnabled: true,
                           focusStateEnabled: false,
                           activeStateEnabled: false,
                           selectionMode: 'single',
                           onContentReady: function onContentReady(e) {
                              var list = e.component;
                              var menuIndex = self.get('menuIndex');
                              var subIndex = self.get('subMenuIndex');
                              if (list && menuIndex === 4 && subIndex >= 0 && subIndex <= 2) {
                                 //self.openAccountMenu();
                                 list.selectItem(subIndex);
                              }
                           },
                           onItemClick: function onItemClick(e) {
                              if (e.itemIndex === 0) {
                                 self.gotoAccount();
                              } else if (e.itemIndex === 1) {
                                 self.gotoBilling();
                              } else if (e.itemIndex === 2) {
                                 self.gotoSubscriptions();
                              }
                           }
                        });
                        element.append("<div class='center-vert'><i class='fas fa-credit-card'/>" + "<label class='label-drawer-menu left-shift'>Account</label></div>");
                        element.append(itemList);
                     }
                  }, { id: 6, text: "Settings", icon: 'fas fa-tools' }, { id: 7, text: "Help", icon: 'far fa-question-circle' }],
                  hoverStateEnabled: false,
                  focusStateEnabled: false,
                  activeStateEnabled: false,
                  selectionMode: 'single',
                  onInitialized: function onInitialized(e) {
                     var list = e.component;
                     if (list) {
                        var menuIndex = self.get('menuIndex');
                        if (menuIndex >= 0) {
                           list.selectItem(menuIndex);
                           if (menuIndex === 3) {
                              self.closeAccountMenu();
                              self.openProfileMenu();
                           } else if (menuIndex === 4) {
                              self.closeProfileMenu();
                              self.openAccountMenu();
                           }
                        }
                     }
                  },
                  onItemClick: function onItemClick(e) {
                     if (e.itemIndex >= 0) {
                        self.set('menuIndex', e.itemIndex);
                     }
                     var subIndex = self.get('subMenuIndex');
                     if (e.itemIndex === 0) {
                        self.closeSubMenus();
                        self.gotoDashboard();
                     } else if (e.itemIndex === 1) {
                        self.closeSubMenus();
                        self.gotoDevices();
                     } else if (e.itemIndex === 2) {
                        self.closeSubMenus();
                        self.gotoNotifications();
                     } else if (e.itemIndex === 3) {
                        self.closeAccountMenu();
                        self.openProfileMenu();
                        if (subIndex === -1) {
                           subIndex = 0;
                           self.set('subMenuIndex', 0);
                        }
                        if (subIndex === 0) {
                           self.gotoAdministrator();
                        } else if (subIndex === 1) {
                           self.gotoAddress();
                        } else if (subIndex === 2) {
                           self.gotoPassword();
                        }
                     } else if (e.itemIndex === 4) {
                        self.closeProfileMenu();
                        self.openAccountMenu();
                        if (subIndex === -1) {
                           subIndex = 0;
                           self.set('subMenuIndex', 0);
                        }
                        if (subIndex === 0) {
                           self.gotoAccount();
                        } else if (subIndex === 1) {
                           self.gotoBilling();
                        } else if (subIndex === 2) {
                           self.gotoSubscriptions();
                        }
                     } else if (e.itemIndex === 5) {
                        self.closeSubMenus();
                        self.gotoSettings();
                     } else if (e.itemIndex === 6) {
                        self.closeSubMenus();
                        self.gotoHelp();
                     }
                  }
               });
            }
         });
      }).on('didInsertElement'),

      selectMenuItem: function selectMenuItem(index, subIndex) {
         if (index >= 0) {
            this.$("#dx-drawer-panel-list").dxList('instance').selectItem(index);
            if (index === 3) {
               //this.openProfileMenu();
               if (subIndex >= 0 && subIndex <= 2) {
                  this.$("#dxListProfileMenu").dxList('instance').selectItem(subIndex);
               }
            } else if (index === 4) {
               //this.openAccountMenu();
               if (subIndex >= 0 && subIndex <= 2) {
                  this.$("#dxListAccountMenu").dxList('instance').selectItem(subIndex);
               }
            } else {
               this.$("#dxListProfileMenu").dxList('instance').unselectAll();
               this.$("#dxListAccountMenu").dxList('instance').unselectAll();
               this.closeSubMenus();
            }
         }
      },

      _destroy: (function () {
         this.$("#dxDrawerMenuContainer").remove();
      }).on('willDestroyElement'),

      _menuIndexChanged: (function () {
         var index = this.get('menuIndex');
         var subIndex = this.get('subMenuIndex');
         this.selectMenuItem(index, subIndex);
         console.log('DRAWER: Menu index changed: %d [%d]', index, subIndex);
      }).observes('menuIndex', 'subMenuIndex'),

      _openedChanged: (function () {
         this.$("#dxDrawerMenuContainer").dxDrawer('option', 'opened', this.get('opened'));
      }).observes('opened'),

      /*_profileMenuOpenedChanged: function() {
         this.$("#dxDrawerMenuContainer").dxDrawer('option', 'profileMenuOpened', this.get('profileMenuOpened'));
      }.observes('profileMenuOpened'),*/

      _disabledChanged: (function () {
         this.$("#dxDrawerMenuContainer").dxDrawer('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});