define("frontend/helpers/comms-type", ["exports", "@ember/component/helper"], function (exports, _emberComponentHelper) {
   exports.commsType = commsType;

   function commsType(params /*, hash*/) {
      var key = params[0];

      if (key === 0) {
         return "Wi-Fi";
      } else if (key === 1) {
         return "Cellular";
      } else {
         return "Unknown";
      }
   }

   exports["default"] = (0, _emberComponentHelper.helper)(commsType);
});