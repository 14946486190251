define('frontend/components/dx-text-auto', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      clearButton: false,
      isInvalid: false,
      attributeBindings: ['isInvalid'],

      _initialize: (function () {
         var self = this;
         var validationGroup = this.get('group');

         this.$("#dxTextAutoContainer").dxAutocomplete({
            activeStateEnabled: true,
            inputAttr: { id: this.get('idAttr') },
            dataSource: this.get('dataSource'),
            disabled: this.get('disabled'),
            readOnly: this.get('readOnly'),
            hint: this.get('hint'),
            fontFamily: 'Arial',
            showClearButton: this.get('clearButton'),
            placeholder: this.get('placeholder'),
            maxLength: 64,
            searchExpr: 'value',
            searchEnabled: true,
            searchMode: 'contains',
            valueExpr: 'value',
            validationMessageMode: 'always',
            value: this.get('value'),
            onValueChanged: function onValueChanged(e) {
               self.set('value', e.value);
            }
         }).dxValidator({
            validationGroup: validationGroup,
            validationRules: [{ type: 'required', message: 'Value is required' }, { type: 'stringLength', min: 3, max: this.maxLength, message: 'Text length must be at least 3 characters!' }],
            onValidated: function onValidated(e) {
               try {
                  if (self.get('isDestroyed') || self.get('isDestroying')) {
                     return;
                  }
                  if (e.isValid) {
                     self.set('isInvalid', false);
                     self.$("#dxTextAutoContainer.dx-texteditor .dx-texteditor-container").addClass("dx-valid");
                  } else {
                     self.set('isInvalid', true);
                     self.$("#dxTextAutoContainer.dx-texteditor .dx-texteditor-container").removeClass("dx-valid");
                  }
               } catch (exp) {
                  console.log("Validation Error (Exp): " + exp);
               }
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxTextAutoContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxTextAutoContainer").dxAutocomplete('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});