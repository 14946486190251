define('frontend/components/dx-line-chart', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      attributeBindings: ['startDate', 'finishDate'],

      _initialize: (function () {
         var self = this;

         this.$("#dxLineChartContainer").dxChart({
            disabled: self.get('disabled'),
            dataSource: self.get('dataSource'),
            /*scrollingMode: 'all',
            zoomingMode: 'all',*/
            zoomAndPan: {
               allowTouchGestures: false,
               dragToZoom: true,
               panKey: 'ctrl',
               argumentAxis: 'both',
               valueAxis: 'none'
            },
            adjustOnZoom: false,
            scrollBar: {
               visible: false
            },
            size: {
               height: 300
            },
            commonPaneSettings: {
               border: {
                  visible: true,
                  top: false,
                  right: false
               }
            },
            commonSeriesSettings: {
               argumentField: 'created'
            },
            legend: {
               visible: false
            },
            series: {
               type: 'stepline',
               valueField: 'status',
               name: 'Online Status'
            },
            title: {
               font: {
                  family: 'Arial',
                  size: 16
               },
               text: this.get('title')
            },
            tooltip: {
               enabled: true,
               customizeTooltip: function customizeTooltip(e) {
                  if (e.value === true) {
                     return {
                        text: e.argument + '\nOnline',
                        borderColor: '#48b04f',
                        fontColor: '#48b04f'
                     };
                  } else {
                     return {
                        text: e.argument + '\nOffline',
                        borderColor: '#d9534f',
                        fontColor: '#d9534f'
                     };
                  }
               }
            },
            argumentAxis: {
               argumentType: 'datetime',
               discreteAxisDivisionMode: 'crossLabels',
               type: 'continuous',
               position: 'bottom',
               tick: { visible: true },
               tickInterval: { hours: 6 },
               label: {
                  format: 'shortDateShortTime'
               },
               minorTick: { visible: true },
               minorTickInterval: { hours: 1 },
               visualRange: [self.get('startDate'), self.get('finishDate')],
               minValueMargin: 0.01,
               maxValueMargin: 0.01,
               grid: { visible: true },
               minorGrid: { visible: false }
            },
            valueAxis: {
               discreteAxisDivisionMode: 'crossLabels',
               tick: { visible: true },
               tickInterval: 1,
               minorTick: { visible: true },
               grid: { visible: false },
               label: { visible: false },
               minValueMargin: 0.05,
               maxValueMargin: 0.05,
               constantLines: [{
                  color: '#48b04f',
                  dashStyle: 'dash',
                  label: { text: 'online', position: "outside", visible: true, font: { color: '#48b04f', size: 14 } },
                  value: true }, //'online',
               {
                  color: '#d9534f',
                  dashStyle: 'dash',
                  label: { text: 'offline', position: "outside", visible: true, font: { color: '#d9534f', size: 14 } },
                  value: false }]
            }
         });
         /*this.$("#dxRangeSelectorContainer").dxRangeSelector({
            size: { height: 180 },
            margin: { left: 80 },
            scale: {
               tick: { visible: true },
               tickInterval: { hours: 1 },
               minorTick: { visible: true },
               minorTickInterval: { minute: 1 },
               minRange: { hours: 1 }
            },
            valueType: 'datetime',
            dataSource: self.get('dataSource'),
            chart: { series: {
                  valueField: 'status',
                  name: 'Online Status'
               },
            },
            behavior: {
               callValueChanged: "onMoving"
            },
            onValueChanged: function(e) {
               let zoomedChart = Ember.$("#dxLineChartContainer").dxChart('instance');
               zoomedChart.zoomArgument(e.value[0], e.value[1]);
            }
         });*/
      }). // 'offline',
      on('didInsertElement'),

      resetRange: function resetRange() {
         var chart = _ember['default'].$("#dxLineChartContainer").dxChart('instance');
         if (chart) {
            chart.resetVisualRange();
            chart.option('argumentAxis.visualRange', [this.get('startDate'), this.get('finishDate')]);
         }
      },

      _destroy: (function () {
         this.$("#dxLineChartContainer").remove();
         //this.$("#dxRangeSelectorContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxLineChartContainer").dxChart('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _startDateChanged: (function () {
         this.$("#dxLineChartContainer").dxChart('option', 'argumentAxis.visualRange', [this.get('startDate'), this.get('finishDate')]);
      }).observes('startDate'),

      _finishDateChanged: (function () {
         this.$("#dxLineChartContainer").dxChart('option', 'argumentAxis.visualRange', [this.get('startDate'), this.get('finishDate')]);
      }).observes('finishDate')
   });
});