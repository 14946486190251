define('frontend/helpers/country-name', ['exports', 'ember-cli-countries/helpers/country-name'], function (exports, _emberCliCountriesHelpersCountryName) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliCountriesHelpersCountryName['default'];
    }
  });
  Object.defineProperty(exports, 'countryName', {
    enumerable: true,
    get: function get() {
      return _emberCliCountriesHelpersCountryName.countryName;
    }
  });
});