define('frontend/models/device', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _emberDataRelationships) {
   exports['default'] = _emberData['default'].Model.extend({
      // Attributes ...
      uuid: _emberData['default'].attr('string'),
      temp_uuid: _emberData['default'].attr('string'),
      newDevice: _emberData['default'].attr(),
      name: _emberData['default'].attr('string'),
      mac_address: _emberData['default'].attr('string'),
      model_type: _emberData['default'].attr('string'),
      firmware_ver: _emberData['default'].attr('string'),
      group: _emberData['default'].attr('device-group', {
         defaultValue: function defaultValue() {
            return 'Default';
         }
      }),
      type: _emberData['default'].attr('device-type', {
         defaultValue: function defaultValue() {
            return 'Fridge';
         }
      }),
      number: _emberData['default'].attr('number-zero', {
         defaultValue: function defaultValue() {
            return 1;
         }
      }),
      num_resets: _emberData['default'].attr('number-zero', {
         defaultValue: function defaultValue() {
            return 0;
         }
      }),
      num_outages: _emberData['default'].attr('number-zero', {
         defaultValue: function defaultValue() {
            return 0;
         }
      }),
      ping_sensitivity: _emberData['default'].attr('number-zero', {
         defaultValue: function defaultValue() {
            return 2;
         }
      }),
      post_frequency: _emberData['default'].attr('number-zero', {
         defaultValue: function defaultValue() {
            return 1;
         }
      }),
      update_checks: _emberData['default'].attr('string', {
         defaultValue: function defaultValue() {
            return 'Weekly';
         }
      }),
      online: _emberData['default'].attr('boolean', {
         defaultValue: false
      }),
      // Timestamps ...
      created: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      last_online: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      last_offline: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      last_ping: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      last_fw_update: _emberData['default'].attr('date'),
      last_updated: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),

      // Miscellaneous Attributes ...
      battery_soc: _emberData['default'].attr('number', { defaultValue: 0 }),
      battery_volts: _emberData['default'].attr('number', { defaultValue: 0 }),
      current: _emberData['default'].attr('number-zero'),
      humidity: _emberData['default'].attr('number-zero'),
      temperature: _emberData['default'].attr('number-zero'),
      temperature_aux: _emberData['default'].attr('number-zero'),
      temperature_ext: _emberData['default'].attr('number-zero'),
      user: _emberData['default'].attr('string'),

      // Relationships ...
      settings: (0, _emberDataRelationships.belongsTo)('settings'),
      subscription: (0, _emberDataRelationships.belongsTo)('subscription'),

      //alarms: hasMany('alarm'),
      logs: (0, _emberDataRelationships.hasMany)('status'),
      sensorlogs: (0, _emberDataRelationships.hasMany)('sensorlog'),
      recipients: (0, _emberDataRelationships.hasMany)('notificationrecipient')

   });
});
// Functions - This works
/*didLoad: function() {
   let self = this;
   Ember.run.later(function() {
      self.reload();
      self.didLoad();
   }, 5000);
},*/