define('frontend/controllers/users/logout', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      init: function init() {
         this._super();
         _ember['default'].run.later(this, function () {
            this.get('authManager').reset();
         }, 500);
      }
   });
});