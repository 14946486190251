define('frontend/components/dx-accordion-recipients', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      editRecipient: false,
      notEditRecipient: _ember['default'].computed.not('editRecipient'),
      attributeBindings: ['editRecipient'],

      _initialize: (function () {
         var self = this;
         this.$("#dxAccordionContainerRecipients").dxAccordion({
            disabled: this.get('disabled'),
            dataSource: this.get('dataSource'),
            animationDuration: 300,
            collapsible: false,
            multiple: true,
            noDataText: 'No Recipients registered for this Device',
            selectedItems: [this.dataSource[0]],
            itemTitleTemplate: function itemTitleTemplate(itemData, itemIndex, itemElement) {
               itemElement.append("<div class='accordion-label-overview'><h3>Recipient #" + (itemIndex + 1) + " - " + self.dataSource[itemIndex].name + "</h3></div>");
            },
            itemTemplate: function itemTemplate(itemData, itemIndex, itemElement) {
               var recipientForm = _ember['default'].$("<div id='dxFormContainerRecipient'>").dxForm({
                  formData: itemData,
                  disabled: self.get('notEditRecipient'),
                  validationGroup: self.get('group'),
                  showColonAfterLabel: true,
                  labelLocation: "left",
                  minColWidth: 200,
                  colCount: "auto",
                  colCountByScreen: {
                     md: 2,
                     sm: 1
                  },
                  screenByWidth: function screenByWidth(width) {
                     return width < 720 ? "sm" : "md";
                  },
                  items: [{
                     dataField: "name",
                     colSpan: 2,
                     label: {
                        text: "Name "
                     },
                     editorOptions: {
                        placeholder: 'Recipient Name'
                     }
                  }, {
                     dataField: "email",
                     colSpan: 2,
                     label: {
                        text: "E-mail "
                     },
                     editorOptions: {
                        placeholder: 'E-mail Address'
                     }
                  }, {
                     dataField: "number",
                     colSpan: 2,
                     label: {
                        text: "Mobile "
                     },
                     editorOptions: {
                        placeholder: 'Phone Number'
                     }
                  }, {
                     dataField: "alarm_type",
                     editorType: "dxRadioGroup",
                     label: {
                        text: "Alarm Type "
                     },
                     editorOptions: {
                        layout: 'horizontal',
                        items: ["SMS", "E-mail", "Both"],
                        value: itemData.alarm_type
                     }
                  }, {
                     dataField: "alarm_repeat",
                     editorType: "dxSelectBox",
                     label: {
                        text: "Repeat Alarms "
                     },
                     editorOptions: {
                        value: itemData.alarm_repeat
                     }
                  }]
               });
               itemElement.append(recipientForm);
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxFormContainerRecipient").remove();
         this.$("#dxAccordionContainerRecipients").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxAccordionContainerRecipients").dxAccordion('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _editRecipientChanged: (function () {
         this.$("#dxFormContainerRecipient").dxForm('option', 'disabled', this.get('notEditRecipient'));
         this.$("#dxFormContainerRecipient").dxForm("instance").repaint();
      }).observes('editRecipient')
   });
});