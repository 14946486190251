define('frontend/components/dx-popover-profile', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      store: _ember['default'].inject.service(),
      title: 'Profile Information',
      actions: {
         logOut: function logOut() {
            this.get('logOut')();
         }
      },
      _initialize: (function () {
         var self = this;
         this.$("#dxPopoverProfileContainer").dxPopup({
            fullScreen: false,
            closeOnOutsideClick: true,
            dragEnabled: false,
            title: self.get('title'),
            showTitle: true,
            showCloseButton: false,
            minWidth: 360,
            minHeight: 360,
            height: 'auto',
            width: 'auto',
            titleTemplate: function titleTemplate(titleElement) {
               titleElement.append("<div><span><i class='glyphicon-xtralarge-left fas fa-info-circle'></i>" + "<h2 class='center'>" + self.get('title') + "</h2></span></div>");
            },
            contentTemplate: function contentTemplate(contentElement) {
               self.drawTemplate(contentElement);
            },
            animation: {
               show: { type: "pop", from: { opacity: 1, scale: 0 }, to: { scale: 1 } },
               hide: { type: "pop", from: { scale: 1 }, to: { scale: 0 } }
            }
         });
      }).on('didInsertElement'),

      drawTemplate: function drawTemplate(contentElement) {
         var self = this;
         var name = this.get('user.first_name');
         var image = this.get('user.image');
         var company = this.get('user.company');

         contentElement.append("<div class='panel panel-default panel-profile'>");
         contentElement.append("<div class='col-xs-5 col-sm-12'>");
         contentElement.append("<div class='center'><h2>Hi " + name + "</h2>");
         contentElement.append("<img class='img-profile img-responsive center' src='" + image + "'></div></div>");
         contentElement.append("<div class='col-xs-7 col-sm-12'>");
         if (company === '') {
            contentElement.append("<h3 class='center bottom-margin-md'>[No Company]</h3>");
         } else {
            contentElement.append("<h3 class='center bottom-margin-md'>" + company + "</h3>");
         }
         /*contentElement.append("<table class='profile-table'>");
         contentElement.append("<tr><td><label class='label-primary'>Account Status :</label></td>");
         contentElement.append("<td><span class='{{accountStatusClass}} text-large'>{{accountStatus}}</span></td></tr>");
         contentElement.append("<tr><td><label class='label-primary'>Smart-Plugs :</label></td>");
         contentElement.append("<td><label class='label-normal'>{{model.devices.length}}</label></td></tr>");
         contentElement.append("{{#if selectedDevice}}<tr>");
         contentElement.append("<td><label class='label-primary'>{{selectedDevice.subscription.plan_name}} :</label></td>");
         contentElement.append("<td><label class='label-normal'>{{cents-to-dollars selectedDevice.subscription.amount}} / Plug</label></td></tr>");
         contentElement.append("{{/if}}<tr><td><label class='label-primary'>Next payment :</label></td>");
         contentElement.append("<td><label class='label-normal text-danger'>{{cents-to-dollars nextPayment}}</label></td>");
         contentElement.append("</tr>");
         contentElement.append("<tr><td><label class='label-primary'>Due Date :</label></td>");
         contentElement.append("<td><label class='label-normal text-info'>{{daysDue}} Days</label></td></tr></table>");*/
         contentElement.append("<hr class='horiz-margin' />");
         contentElement.append("</div></div>");

         var btnContainer = _ember['default'].$("<div id='pop-help-btn-container' class='row dx-popup-btn-container'></div>");
         contentElement.append(btnContainer);

         btnContainer.append("<div id='pop-logout-btn' class='col-xs-4 col-xs-offset-1 btn-pwron btn-regular'></div>");
         var logoutButton = _ember['default'].$("#pop-logout-btn").dxButton({
            text: "Logout",
            onClick: function onClick() {
               self.logOut();
            }
         });
         btnContainer.append(logoutButton);

         btnContainer.append("<div id='pop-close-btn' class='col-xs-4 col-xs-offset-1 btn-pwron btn-regular'></div>");
         var closeButton = _ember['default'].$("#pop-close-btn").dxButton({
            text: "Close",
            onClick: function onClick() {
               self.complete();
            }
         });
         btnContainer.append(closeButton);
      },

      complete: function complete() {
         var popup = _ember['default'].$("#dxPopoverProfileContainer").dxPopup("instance");
         if (popup) {
            popup.hide();
         }
      },

      _destroy: (function () {
         this.$("#dxPopoverProfileContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxPopoverProfileContainer").dxPopup('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});