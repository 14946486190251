define('frontend/helpers/country-calling-code', ['exports', 'ember-cli-countries/helpers/country-calling-code'], function (exports, _emberCliCountriesHelpersCountryCallingCode) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliCountriesHelpersCountryCallingCode['default'];
    }
  });
  Object.defineProperty(exports, 'countryCallingCode', {
    enumerable: true,
    get: function get() {
      return _emberCliCountriesHelpersCountryCallingCode.countryCallingCode;
    }
  });
});