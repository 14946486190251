define('frontend/components/dx-line-chart-sensors', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
   exports['default'] = _ember['default'].Component.extend({
      allowExport: false,
      allowPrint: false,
      deviceName: null,
      showLegend: true,
      tickIntervalTime: 6,
      tickIntervalValue: 10,
      minorTickIntervalTime: 30,
      minorTickIntervalValue: 5,
      minValue: -50.0,
      maxValue: 110.0,
      period: 'week',
      attributeBindings: ['dataSource', 'startDate', 'finishDate'],
      _initialize: (function () {
         var self = this;
         this.$("#dxLineChartSensorsContainer").dxChart({
            disabled: self.get('disabled'),
            dataSource: self.get('dataSource'),
            series: self.get('series'),
            adjustOnZoom: false,
            zoomAndPan: {
               allowTouchGestures: false,
               dragToZoom: true,
               panKey: 'ctrl',
               argumentAxis: 'both',
               valueAxis: 'none'
            },
            'export': {
               enabled: this.get('allowExport'),
               printingEnabled: this.get('allowPrint'),
               fileName: 'SensorGraph_' + this.get('deviceName') + '_' + (0, _moment['default'])(new Date()).format('YYYY_MM_DD_HH_mm')
            },
            crosshair: {
               enabled: true,
               color: '#696969',
               width: 1,
               dashStyle: 'dash',
               label: {
                  visible: true,
                  backgroundColor: "#696969",
                  font: {
                     color: "#fff",
                     size: 12
                  }
               }
            },
            scrollBar: {
               color: '#48b04f',
               opacity: 0.6,
               visible: true,
               width: 6
            },
            size: {
               height: 300
            },
            commonPaneSettings: {
               border: {
                  visible: true,
                  top: false,
                  right: false
               }
            },
            commonSeriesSettings: {
               argumentField: 'created'
            },
            legend: {
               visible: self.get('showLegend'),
               verticalAlignment: 'bottom',
               horizontalAlignment: 'center',
               itemTextPosition: 'bottom'
            },
            title: {
               font: {
                  family: 'Arial',
                  size: 16
               },
               text: self.get('title'),
               subtitle: {
                  text: self.get('subtitle')
               }
            },
            tooltip: {
               enabled: true,
               font: {
                  size: 14,
                  family: 'Arial'
               },
               format: {
                  precision: 1,
                  type: 'fixedPoint'
               },
               customizeTooltip: function customizeTooltip(data) {
                  return { text: data.valueText + ' ' + self.get('units') };
               }
            },
            argumentAxis: {
               argumentType: 'datetime',
               discreteAxisDivisionMode: 'crossLabels',
               type: 'continuous',
               position: 'bottom',
               tick: { visible: true },
               tickInterval: { hours: self.get('tickIntervalTime') },
               minorTickInterval: self.get('minorTickIntervalTime'),
               label: {
                  format: 'shortDateShortTime'
               },
               length: self.get('period'),
               minorTick: { visible: true },
               visualRange: {
                  startValue: self.get('startDate'),
                  endValue: self.get('finishDate')
               },
               visualRangeUpdateMode: 'shift',
               minValueMargin: 0.01,
               maxValueMargin: 0.01,
               grid: { visible: true },
               minorGrid: { visible: false }
            },
            valueAxis: {
               discreteAxisDivisionMode: 'crossLabels',
               tick: { visible: true },
               tickInterval: self.get('tickIntervalValue'),
               minorTick: { visible: true },
               minorTickInterval: self.get('minorTickIntervalValue'),
               grid: { visible: true },
               label: { visible: true },
               wholeRange: {
                  startValue: -10000.0,
                  endValue: self.get('maxValue')
               },
               visualRange: {
                  startValue: self.get('minValue'),
                  endValue: self.get('maxValue')
               },
               visualRangeUpdateMode: 'keep',
               minValueMargin: 0,
               maxValueMargin: 0
            }
         });
         /*this.$("#dxRangeSelectorContainer").dxRangeSelector({
            size: { height: 180 },
            margin: { left: 80 },
            scale: {
               tick: { visible: true },
               tickInterval: { hours: 1 },
               minorTick: { visible: true },
               minorTickInterval: { minute: 1 },
               minRange: { hours: 1 }
            },
            valueType: 'datetime',
            dataSource: self.get('dataSource'),
            chart: { series: {
                  valueField: 'status',
                  name: 'Online Status'
               },
            },
            behavior: {
               callValueChanged: "onMoving"
            },
            onValueChanged: function(e) {
               let zoomedChart = Ember.$("#dxLineChartContainer").dxChart('instance');
               zoomedChart.zoomArgument(e.value[0], e.value[1]);
            }
         });*/
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxLineChartSensorsContainer").remove();
         //this.$("#dxRangeSelectorContainer").remove();
      }).on('willDestroyElement'),

      _dataSourceChanged: (function () {
         this.$("#dxLineChartSensorsContainer").dxChart('option', 'dataSource', this.get('dataSource'));
      }).observes('dataSource'),

      _disabledChanged: (function () {
         this.$("#dxLineChartSensorsContainer").dxChart('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _startDateChanged: (function () {
         this.$("#dxLineChartSensorsContainer").dxChart('option', 'argumentAxis.visualRange.startValue', this.get('startDate'));
      }).observes('startDate'),

      _finishDateChanged: (function () {
         this.$("#dxLineChartSensorsContainer").dxChart('option', 'argumentAxis.visualRange.endValue', this.get('finishDate'));
      }).observes('finishDate'),

      _minValueChanged: (function () {
         this.$("#dxLineChartSensorsContainer").dxChart('option', 'valueAxis.visualRange.startValue', this.get('minValue'));
      }).observes('minValue'),

      _maxValueChanged: (function () {
         this.$("#dxLineChartSensorsContainer").dxChart('option', 'valueAxis.visualRange.endValue', this.get('maxValue'));
      }).observes('maxValue')
   });
});