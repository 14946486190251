define('frontend/components/dx-popup-new-device', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      result: false,

      accountRedirect: function accountRedirect() {
         this.sendAction('accountRedirect');
      },
      _initialize: (function () {
         var self = this;

         this.$("#dxPopupNewDeviceContainer").dxPopup({
            fullScreen: false,
            closeOnOutsideClick: false,
            dragEnabled: false,
            showCloseButton: false,
            showTitle: true,
            minWidth: 320,
            minHeight: 320,
            height: 'auto',
            width: 'auto',
            titleTemplate: function titleTemplate(titleElement) {
               titleElement.append("<div class='row' style='max-height: 60px'>" + "<img align='left' class='pull-left' width='42' src='/assets/icons/glyphicons-196-info-sign@2x.png'>" + "<h2 class='center'>Congratulations!</h2></div>");
            },
            contentTemplate: function contentTemplate(contentElement) {
               self.drawTemplate(contentElement);
            },
            animation: {
               show: { type: "pop", from: { opacity: 1, scale: 0 }, to: { scale: 1 } },
               hide: { type: "pop", from: { scale: 1 }, to: { scale: 0 } }
            },
            onHidden: function onHidden() {
               self.accountRedirect();
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxPopupNewDeviceContainer").remove();
      }).on('willDestroyElement'),

      complete: function complete() {
         _ember['default'].$("#dxPopupNewDeviceContainer").dxPopup("instance").hide();
      },
      drawTemplate: function drawTemplate(contentElement) {
         var self = this;
         contentElement.append("<div class='center top-margin-sm'><img src='/assets/images/pwron-logo-128.jpg' height='64' /></div>");
         contentElement.append("<h3 class='text-center bottom-margin-md'>A new PwrOn Device has been added to your Account</h3>");
         contentElement.append("<p class='text-center'>Your Account will be initially credited with 25 free SMS upon subscription setup.</p>");
         contentElement.append("<p class='text-center'>Any additional SMS top-up credits you purchase will be spread out across all of your devices.</p>");
         contentElement.append("<p class='text-center'>A welcome SMS has been sent to your phone, along with a message sent to your E-mail account.</p>");
         contentElement.append("<h4 class='text-center text-danger top-margin-sm bottom-margin-sm'>NOTE: If you haven't already done so you must enter your billing details to receive any notifications.</h4>");

         var buttons = _ember['default'].$("<div id='pop-new-device-btn-container' class='row top-margin'></div>");
         contentElement.append(buttons);
         var leftContainer = _ember['default'].$("<div id='pop-new-device-left-container' class='col-xs-4 col-xs-offset-4 bottom-margin'></div>");
         buttons.append(leftContainer);

         leftContainer.append("<div id='pop-new-device-ok-container' class='btn-pwron-dx regular'></div>");
         var okButton = _ember['default'].$("#pop-new-device-ok-container").dxButton({
            text: "OK",
            onClick: function onClick() {
               self.complete();
            }
         });
         leftContainer.append(okButton);
      },

      _disabledChanged: (function () {
         this.$("#dxPopupNewDeviceContainer").dxPopup('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});