define('frontend/routes/users/logout', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model() {
         var self = this;
         var user_id = this.get('authManager').get('getUser.id');
         if (user_id) {
            return self.store.findRecord('user', user_id);
         } else {
            return null;
         }
      }
   });
});