define('frontend/controllers/home/account/details', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      invalidInput: false,
      isEditing: false,
      isNotEditing: _ember['default'].computed.not('isEditing'),
      isBillOverdue: false,
      amountOverdue: 0,
      amountDue: 0,
      amountLastBill: 0,
      dueDate: new Date(Date.now()),
      lastInvoice: _ember['default'].computed(function () {
         var invoices = this.get('model.invoices');
         if (invoices && invoices.length > 0) {
            var index = invoices.length - 1;
            return invoices[index];
         } else {
            return null;
         }
      }),
      latestInvoice: _ember['default'].computed(function () {
         var invoices = this.get('model.invoices');
         if (invoices && invoices.length > 0) {
            return invoices[0];
         } else {
            return null;
         }
      }),
      latestDeviceName: _ember['default'].computed(function () {
         var invoice = this.get('latestInvoice');
         if (invoice.lines) {
            var metadata = invoice.lines.data[0].metadata;
            return metadata['Device Name'];
         } else {
            return null;
         }
      }),
      nextDeviceName: _ember['default'].computed(function () {
         var invoice = this.get('model.nextInvoice');
         if (invoice.lines) {
            var metadata = invoice.lines.data[0].metadata;
            return metadata['Device Name'];
         } else {
            return null;
         }
      }),

      /*_init: function() {
         this.set('dueDate', this.addDays(this.get('dueDate'), 14));
      }.on('init'),*/

      actions: {
         logOut: function logOut() {
            this.transitionToRoute('users.logout');
         },
         getLatestInvoice: function getLatestInvoice() {
            var latestInvoice = this.get('latestInvoice');
            if (latestInvoice) {
               this.downloadInvoice(latestInvoice.id);
            } else {
               DevExpress.ui.notify("There is no Invoice to download!", 'error', 3000);
            }
         },
         getInvoice: function getInvoice(invoice_id) {
            this.downloadInvoice(invoice_id);
         },
         cancel: function cancel() {
            this.resetToolbar();
         },
         goBack: function goBack() {
            this.transitionToRoute("home.dashboard");
         },
         goNext: function goNext() {
            this.transitionToRoute("home.account.billing");
         }
      },
      resetToolbar: function resetToolbar() {
         this.set('isEditing', false);
      },
      addDays: function addDays(date, numDays) {
         var d = new Date();
         d.setDate(date.getDate() + parseInt(numDays));
         return d;
      },
      billingHistory: _ember['default'].computed(function () {
         var history = [];
         var invoices = this.get('model.invoices');
         var paid = 'Due';

         if (invoices) {
            invoices.forEach(function (invoice, index) {
               if (invoice) {
                  if (invoice.paid) {
                     paid = 'Paid';
                  } else {
                     paid = 'Overdue';
                  }
                  var newItem = {
                     'id': (index + 1).toString(),
                     'invoiceID': invoice.id,
                     'timestamp': new Date(_moment['default'].unix(invoice.date)),
                     'name': invoice.name,
                     'amount': invoice.subtotal / 100.0,
                     'amountDue': invoice.amount_due / 100.0,
                     'paid': paid
                  };
                  history.push(newItem);
               }
            });
         }
         return history;
      }),
      downloadInvoice: function downloadInvoice(invoice_id) {
         var host = this.get('store').adapterFor('application').get('host');
         var user_id = this.get('authManager').get('getUser.id');
         var url = [host, 'billing/invoices/download', user_id, invoice_id].join('/');

         DevExpress.ui.notify("Downloading Invoice (ID: " + invoice_id + ") ...", 'info', 3000);
         var req = new XMLHttpRequest();
         req.open("POST", url, true);
         req.responseType = "blob";
         req.onreadystatechange = function () {
            if (req.readyState === 4) {
               if (req.status === 200) {
                  var filename = invoice_id + ".pdf";
                  DevExpress.ui.notify('Invoice (ID: ' + invoice_id + ') Downloaded!', 'success', 3000);

                  // Trigger the save dialog based on browser ...
                  if (typeof window.chrome !== 'undefined') {
                     // Chrome version
                     var link = document.createElement('a');
                     link.href = window.URL.createObjectURL(req.response);
                     link.download = filename;
                     link.click();
                  } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
                     // IE version
                     var blob = new Blob([req.response], { type: 'application/pdf' });
                     window.navigator.msSaveBlob(blob, filename);
                  } else {
                     // Firefox version
                     var file = new File([req.response], filename, { type: 'application/force-download' });
                     window.open(URL.createObjectURL(file));
                  }
               } else {
                  var msg = 'Error Downloading Invoice (' + req.status + '): ' + req.statusText;
                  console.log(msg);
                  DevExpress.ui.notify(msg, 'error', 3000);
               }
            }
         };
         req.send();
      }
   });
});