define('frontend/routes/home', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      model: function model() {
         var self = this;
         var auth = this.get('authManager');
         if (!auth) {
            console.log('HOME: Auth Manager is undefined!');
            this.transitionTo('users.logout');
            return null;
         }
         var user_id = auth.get('getUser.id');
         if (user_id) {
            return _ember['default'].RSVP.hash({
               user: self.store.findRecord('user', user_id),
               appsettings: self.store.queryRecord('appsettings', { user: user_id }).then(function (setting) {
                  if (setting) {
                     console.log('HOME: Application settings found');
                     return setting;
                  } else {
                     console.log('HOME: No Application settings found!');
                     return null;
                  }
               })['catch'](function (error) {
                  console.log('HOME: Find Application settings error: ' + error.message);
                  return null;
               }),
               devices: self.store.findAll('device').then(function (devices) {
                  if (devices) {
                     return devices;
                  } else {
                     return [];
                  }
               })['catch'](function (error) {
                  console.log("HOME: Find All Devices Error: " + error.message);
                  return null;
               })
            });
         } else {
            console.log('HOME: User ID is undefined!');
            this.transitionTo('users.logout');
            return null;
         }
      },
      afterModel: function afterModel(model) {
         // If App Settings for this User don't exist, create defaults ...
         if (model && model.appsettings == null) {
            model.appsettings = this.store.createRecord('appsettings', {
               menu_index: 0,
               sub_menu_index: -1,
               tab_index: 0,
               device_index: 0,
               device_id: null,
               device_uuid: null,
               user: model.user
            });
            console.log('HOME: Created new Application Settings');
         }
      },
      setupController: function setupController(controller, model) {
         // Call _super for default behavior - sets the model to the controller
         this._super(controller, model);
         // Set the selected items to data from App Settings
         controller.set('selectedDeviceIndex', model.appsettings.get('device_index'));
         controller.set('selectedDeviceUUID', model.appsettings.get('device_uuid'));
         controller.set('selectedDeviceID', model.appsettings.get('device_id'));
         controller.set('selectedMenuIndex', model.appsettings.get('menu_index'));
         controller.set('subMenuIndex', model.appsettings.get('sub_menu_index'));
         controller.set('selectedTabIndex', model.appsettings.get('tab_index'));
         console.log('HOME: setupController finished');
      },
      actions: {
         willTransition: function willTransition() {
            /*let controller = this.controllerFor('home');
            controller.set('selectedDevice', null);*/
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});