define('frontend/components/device-list', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      isWideMode: true,
      showAltFields: true,
      showSensors: false,
      selectedDevice: null,
      dataSource: [],
      selectedTabIndex: 0,
      attributeBindings: ['dataSource', 'selectedDevice'],

      _initialize: (function () {
         var self = this;
         var showAltFields = this.get('showAltFields');

         this.$("#dxGridDevicesContainer").dxDataGrid({
            dataSource: this.get('dataSource'),
            noDataText: 'No PwrOn Devices found!',
            columnAutoWidth: true,
            errorRowEnabled: false,
            loadPanel: {
               enabled: true,
               text: 'Refreshing Devices...'
            },
            editing: {
               mode: "row",
               allowEditing: false,
               allowUpdating: false,
               allowDeleting: false
            },
            remoteOperations: {
               sorting: true,
               paging: true
            },
            paging: {
               pageSize: 10
            },
            pager: {
               showPageSizeSelector: true,
               allowedPageSizes: [5, 10, 15, 20],
               showInfo: true
            },
            searchPanel: {
               visible: self.get('isWideMode'),
               width: 150
            },
            selection: {
               mode: self.get('mode'),
               allowSelectAll: this.get('allowSelectAll')
            },
            rowAlternationEnabled: false,
            showColumnLines: false,
            showRowLines: true,
            showBorders: false,
            hoverStateEnabled: true,
            columns: [{
               dataField: "model_type", caption: "Model", cssClass: "row-vert",
               minWidth: 80,
               allowEditing: false,
               allowFiltering: false,
               allowFixing: false,
               allowGrouping: false,
               allowResizing: false,
               allowSearch: false,
               allowSorting: false,
               cellTemplate: function cellTemplate(container, options) {
                  var modelType = options.data.model_type;
                  var group = options.data.group;
                  var name = options.data.name;
                  var online = options.data.online;
                  //TODO: Remove check for group and name in release ...
                  if (modelType === 'TALS' || modelType === 'tals' || group === 'TALS' || name != null && name.includes('TALS')) {
                     if (online) {
                        self.$("<i class='fal fa-sensor-on dx-icon-title online'></i>").appendTo(container);
                     } else {
                        self.$("<i class='fal fa-sensor dx-icon-title offline'></i>").appendTo(container);
                     }
                  } else {
                     if (online) {
                        self.$("<i class='fal fa-outlet dx-icon-title online'></i>").appendTo(container);
                     } else {
                        self.$("<i class='fal fa-outlet dx-icon-title offline'></i>").appendTo(container);
                     }
                  }
               }
            }, {
               dataField: "name", caption: "Device Name", cssClass: "row-vert",
               minWidth: 180,
               allowEditing: false,
               sortOrder: 'asc'
            }, {
               dataField: "group", caption: "Group", alignment: "center", cssClass: "row-vert",
               allowEditing: false,
               groupIndex: 0,
               visible: showAltFields
            }, {
               dataField: "type", caption: "Type", alignment: "center", cssClass: "row-vert",
               allowEditing: false,
               visible: showAltFields
            }, {
               dataField: "temperature", alignment: "center", caption: "Temp.", cssClass: "row-vert",
               minWidth: 64,
               format: { type: 'fixedPoint', precision: 1 },
               allowEditing: false,
               allowFiltering: false,
               allowSorting: false,
               visible: self.get('showSensors') && self.get('selectedTabIndex') === 1,
               cellTemplate: function cellTemplate(container, options) {
                  var show = true;
                  if (options.data.settings) {
                     show = options.data.settings.show_sensors;
                  }
                  if (show) {
                     var displayVal = undefined;
                     if (options.value === undefined || isNaN(options.value) || options.value === -999.9) {
                        displayVal = "--";
                     } else {
                        displayVal = options.text;
                     }
                     if (displayVal === "--") {
                        _ember['default'].$("<span>" + displayVal + "</span>").appendTo(container);
                     } else {
                        _ember['default'].$("<img alt='Temp.' src='/assets/icons/icon-thermometer.png' class='item-icon'/><span>" + displayVal + " &#0176;C</span>").appendTo(container);
                     }
                  }
               }
            }, {
               dataField: "humidity", alignment: "center", cssClass: "row-vert",
               minWidth: 64,
               format: { type: 'fixedPoint', precision: 1 },
               allowEditing: false,
               allowFiltering: false,
               allowSorting: false,
               visible: self.get('showSensors') && self.get('selectedTabIndex') === 1,
               cellTemplate: function cellTemplate(container, options) {
                  var show = true;
                  if (options.data.settings) {
                     show = options.data.settings.show_sensors;
                  }
                  if (show) {
                     var displayVal = undefined;
                     if (options.value === undefined || isNaN(options.value) || options.value === -999.9) {
                        displayVal = "--";
                     } else {
                        displayVal = options.text;
                     }
                     if (displayVal === "--") {
                        _ember['default'].$("<span>" + displayVal + "</span>").appendTo(container);
                     } else {
                        _ember['default'].$("<img alt='Humidity' src='/assets/icons/icon-humidity.png' class='item-icon'/><span>" + displayVal + " %</span>").appendTo(container);
                     }
                  }
               }
            }, {
               dataField: "battery_soc", alignment: "center", caption: "Battery", cssClass: "row-vert",
               minWidth: 64,
               format: { type: 'fixedPoint', precision: 1 },
               allowEditing: false,
               allowFiltering: false,
               allowSorting: false,
               visible: self.get('showSensors') && self.get('selectedTabIndex') === 1,
               cellTemplate: function cellTemplate(container, options) {
                  var show = true;
                  //let settings = options.data.settings;
                  if (options.data.settings) {
                     show = options.data.settings.show_sensors;
                  }
                  if (show) {
                     var displayVal = undefined;
                     if (options.value === undefined || isNaN(options.value) || options.value === -999.9) {
                        displayVal = "--";
                     } else {
                        displayVal = options.text;
                     }
                     if (displayVal === "--") {
                        _ember['default'].$("<span>" + displayVal + "</span>").appendTo(container);
                     } else {
                        var imageSrc = undefined;
                        if (options.value >= 0 && options.value < 10) {
                           imageSrc = '/assets/icons/battery/battery-00.png';
                        } else if (options.value >= 10 && options.value < 30) {
                           imageSrc = '/assets/icons/battery/battery-020.png';
                        } else if (options.value >= 30 && options.value < 50) {
                           imageSrc = '/assets/icons/battery/battery-040.png';
                        } else if (options.value >= 50 && options.value < 70) {
                           imageSrc = '/assets/icons/battery/battery-060.png';
                        } else if (options.value >= 70 && options.value < 90) {
                           imageSrc = '/assets/icons/battery/battery-080.png';
                        } else {
                           imageSrc = '/assets/icons/battery/battery-100.png';
                        }
                        _ember['default'].$("<img alt='Battery' src=" + imageSrc + " class='item-icon'/><span>" + displayVal + " %</span>").appendTo(container);
                     }
                  }
               }
            }, {
               dataField: "online", caption: "Status", cssClass: "row-vert",
               allowEditing: false,
               allowFiltering: false,
               allowSorting: false,
               visible: self.get('selectedTabIndex') === 0,
               cellTemplate: function cellTemplate(container, options) {
                  if (options.value) {
                     _ember['default'].$("<img alt='Online' src='/assets/icons/icon-owl-green.png' class='item-owl-icon' title='Online'/>" + "<span class='status' title='Online'>Online</span>").appendTo(container);
                  } else {
                     _ember['default'].$("<img alt='Offline' src='/assets/icons/icon-owl-red.png' class='item-owl-icon' title='Offline'/>" + "<span class='status' title='Offline'>Offline</span>").appendTo(container);
                  }
               }
            }, {
               dataField: "num_outages", caption: "Outages", alignment: "center", cssClass: "row-vert",
               allowEditing: false,
               allowFiltering: false,
               allowSorting: false,
               visible: self.get('selectedTabIndex') === 0,
               cellTemplate: function cellTemplate(container, options) {
                  if (options.value === 1) {
                     _ember['default'].$("<img alt='Outage' src='/assets/icons/icon-outage.png' class='item-icon' title='1 Outage'/>" + "<span class='status' title='1 Outage'>1</span>").appendTo(container);
                  } else if (options.value > 1) {
                     _ember['default'].$("<img alt='Outages' src='/assets/icons/icon-outage.png' class='item-icon' title='" + options.value + " Outages'/><span class='status' title='" + options.value + " Outages'>" + options.value + "</span>").appendTo(container);
                  }
               }
            }, {
               width: 150, caption: 'Analytics', alignment: "center",
               cellTemplate: function cellTemplate(container, options) {
                  var index = options.rowIndex;
                  var span = _ember['default'].$("<div id='buttonContainer_" + index + "'></div>").appendTo(container);
                  var buttonContainer = self.$("#buttonContainer_" + index).appendTo(span);
                  var d1 = _ember['default'].$("<div class='btn-device-list'><button id='btnA_" + index + "' title='Device Analytics' /></div>").appendTo(buttonContainer);
                  var d2 = _ember['default'].$("<div class='btn-device-list'><button id='btnB_" + index + "' title='Change Settings' /></div>").appendTo(buttonContainer);
                  var d3 = _ember['default'].$("<div class='btn-device-list'><button id='btnC_" + index + "' title='Manage Recipients' /></div>").appendTo(buttonContainer);

                  _ember['default'].$('#btnA_' + index).addClass('btn-pwron btn-primary btn-icon btn-chart').on('dxclick', function () {
                     self.selectRow(options.rowIndex);
                     if (self.get('selectedDevice')) {
                        self.sendAction('gotoAnalytics');
                     }
                  }).appendTo(d1);
                  _ember['default'].$('#btnB_' + index).addClass('btn-pwron btn-primary btn-icon btn-settings').on('dxclick', function () {
                     self.selectRow(options.rowIndex);
                     if (self.get('selectedDevice')) {
                        self.sendAction('gotoSettings');
                     }
                  }).appendTo(d2);
                  _ember['default'].$('#btnC_' + index).addClass('btn-pwron btn-primary btn-icon btn-group').on('dxclick', function () {
                     self.selectRow(options.rowIndex);
                     if (self.get('selectedDevice')) {
                        self.sendAction('gotoRecipients');
                     }
                  }).appendTo(d3);
               }
            }, {
               width: 50, caption: 'Delete',
               cellTemplate: function cellTemplate(container, options) {
                  var index = options.rowIndex;
                  var span = _ember['default'].$("<div id='trashContainer_" + index + "'></div>").appendTo(container);
                  var buttonContainer = self.$("#trashContainer_" + index).appendTo(span);
                  var d4 = _ember['default'].$("<div style='position:relative;margin:1px 3px'><button id='btnD_" + index + "' title='Delete Device' /></div>").appendTo(buttonContainer);

                  _ember['default'].$('#btnD_' + index).addClass('btn-pwron btn-primary btn-icon btn-trash').on('dxclick', function () {
                     self.selectRow(options.rowIndex);
                     if (self.get('selectedDevice')) {
                        self.sendAction('removeDevice');
                     }
                  }).appendTo(d4);
               }
            }],
            masterDetail: {
               enabled: true,
               template: function template(container, options) {
                  var index = options.rowIndex;
                  container.addClass("internal-grid-container");
                  var subscription = options.data.subscription;

                  if (subscription) {
                     var main = _ember['default'].$("<div class='panel' style='margin: 16px 22px 16px 0'><div id='subTable_" + index + "' class='panel-body'></div></div>").appendTo(container);
                     var tableContainer = _ember['default'].$('#subTable_' + index).appendTo(main);
                     var tableData = _ember['default'].$("<table class='table top-margin-sm'>" + "<thead><tr><th>Subscription</th><th>Plan</th><th>Cost</th><th>SMS Credits</th><th>Status</th></tr></thead>" + "<tr id='subRow_" + index + "'></tr></table>").appendTo(tableContainer);
                     var rowData = _ember['default'].$("#subRow_" + index).appendTo(tableData);
                     _ember['default'].$("<td><label>" + subscription.get('name') + "</label></td>").appendTo(rowData);
                     _ember['default'].$("<td><label>" + subscription.get('plan_id') + "</label></td>").appendTo(rowData);
                     _ember['default'].$("<td><label>$" + subscription.get('amount') / 100.0 + "</label></td>").appendTo(rowData);
                     _ember['default'].$("<td><label class='label-info'>" + subscription.get('credits') + "</label></td>").appendTo(rowData);
                     var _status = subscription.get('status');
                     if (isSubscriptionValid(_status)) {
                        _ember['default'].$("<td><label class='label-success'>" + _status + "</label></td>").appendTo(rowData);
                     } else {
                        _ember['default'].$("<td><label class='label-danger'>" + _status + "</label></td>").appendTo(rowData);
                     }
                  } else {
                     _ember['default'].$("<h4>").text("No Subscription found for this Device!").appendTo(container);
                  }
               }
            },
            onContentReady: function onContentReady(e) {
               console.log('DEVICES: Content Ready');
               var selectedDevice = self.get('selectedDevice');
               if (selectedDevice) {
                  e.component.selectRows(selectedDevice, true);
               } else {
                  // Select first row (First data row index is 1. First group is 0) ...
                  self.selectRow(1);
               }
            },
            onSelectionChanged: function onSelectionChanged(selectedItems) {
               if (selectedItems) {
                  var device = selectedItems.selectedRowsData[0];
                  self.setSelectedDevice(device);
               } else {
                  self.setSelectedDevice(null);
               }
            },
            onCellClick: function onCellClick(e) {
               if (e.rowType === 'data') {
                  self.selectRow(e.rowIndex);
               }
            },
            onRowRemoved: function onRowRemoved() {
               self.refreshDataGrid();
            },
            onToolbarPreparing: function onToolbarPreparing(e) {
               var dataGrid = e.component;
               var deviceCount = getDeviceCount("group");
               var groupCount = getGroupCount("group");
               var deviceText = "Devices";
               if (deviceCount === 1) {
                  deviceText = "Device";
               }
               var groupText = "Groups";
               if (groupCount === 1) {
                  groupText = "Group";
               }

               e.toolbarOptions.items.unshift({
                  location: "before",
                  locateInMenu: 'never',
                  template: function template() {
                     return _ember['default'].$("<div/>").addClass("informer").append(_ember['default'].$("<h3 />").addClass("device-count").text(deviceCount), _ember['default'].$("<span />").addClass("name").text(deviceText));
                  }
               }, {
                  location: "before",
                  locateInMenu: 'auto',
                  widget: "dxSelectBox",
                  options: {
                     width: 195,
                     items: [{
                        value: "type",
                        text: "Group by Load Type"
                     }, {
                        value: "group",
                        text: "Group by Device Group"
                     }],
                     displayExpr: "text",
                     valueExpr: "value",
                     value: "group",
                     onValueChanged: function onValueChanged(e) {
                        dataGrid.clearGrouping();
                        dataGrid.columnOption(e.value, "groupIndex", 0);
                        groupCount = getGroupCount(e.value);
                        _ember['default'].$(".informer .total-count").text(groupCount);
                        if (e.value === 'type') {
                           groupText = "Types";
                           if (groupCount === 1) {
                              groupText = "Type";
                           }
                        } else {
                           groupText = "Groups";
                           if (groupCount === 1) {
                              groupText = "Group";
                           }
                        }
                        _ember['default'].$(".informer .total-count + .name").text(groupText);
                     }
                  }
               }, {
                  location: "before",
                  locateInMenu: 'auto',
                  showText: 'inMenu',
                  widget: "dxButton",
                  widgetClass: "btn-primary",
                  options: {
                     hint: "Collapse All",
                     icon: "chevrondown",
                     text: "Collapse All",
                     onClick: function onClick(e) {
                        var expanding = e.component.option("icon") === "chevronnext";
                        dataGrid.option("grouping.autoExpandAll", expanding);
                        e.component.option({
                           icon: expanding ? "chevrondown" : "chevronnext",
                           hint: expanding ? "Collapse All" : "Expand All",
                           text: expanding ? "Collapse All" : "Expand All"
                        });
                     }
                  }
               }, {
                  location: "before",
                  locateInMenu: 'never',
                  template: function template() {
                     return _ember['default'].$("<div/>").addClass("informer").append(_ember['default'].$("<h3 />").addClass("total-count").text(groupCount), _ember['default'].$("<span />").addClass("name").text(groupText));
                  }
               }, {
                  location: "after",
                  locateInMenu: 'auto',
                  showText: 'inMenu',
                  widget: "dxButton",
                  options: {
                     icon: "refresh",
                     text: "Refresh",
                     onClick: function onClick() {
                        self.refreshDataGrid();
                     }
                  }
               });
            }
         });
         function isSubscriptionValid(status) {
            if (!status) {
               return false;
            } else if (status === 'trialing' || status === 'active' || status === 'past_due') {
               return true;
            } else if (status === 'canceled' || status === 'unpaid') {
               return false;
            } else {
               return false;
            }
         }
         function getDeviceCount() {
            var data = self.get('dataSource');
            if (data) {
               return DevExpress.data.query(data).toArray().length;
            } else {
               return 0;
            }
         }
         function getGroupCount(groupField) {
            var data = self.get('dataSource');
            if (data) {
               return DevExpress.data.query(data).groupBy(groupField).toArray().length;
            } else {
               return 0;
            }
         }
      }).on('didInsertElement'),

      _refresh: (function () {
         // This has to run to refresh values (e.g. status, sensors) on load ...
         this.refreshDataGrid();
      }).on('didRender'),

      selectRow: function selectRow(row) {
         if (row > 0) {
            var grid = this.$("#dxGridDevicesContainer").dxDataGrid('instance');
            if (grid) {
               grid.selectRowsByIndexes([row]);
            }
         }
      },
      setSelectedDevice: function setSelectedDevice(device) {
         if (device) {
            this.set('selectedDevice', device);
         } else {
            this.set('selectedDevice', null);
         }
      },
      refreshDataGrid: function refreshDataGrid() {
         console.log("Refreshing Devices List ...");
         var grid = this.$("#dxGridDevicesContainer").dxDataGrid('instance');
         if (grid) {
            grid.refresh();
            grid.selectRows(this.get('selectedDevice'), true);
            grid.repaint();
         }
      },
      refreshSensorColumns: function refreshSensorColumns() {
         console.log("Refreshing Sensor Columns ...");
         var showSensors = this.get('showSensors');
         var grid = this.$("#dxGridDevicesContainer").dxDataGrid('instance');
         if (grid) {
            grid.option('columns[4].visible', showSensors);
            grid.option('columns[5].visible', showSensors);
            grid.option('columns[6].visible', showSensors);
            grid.refresh();
         }
      },

      _destroy: (function () {
         this.$("#dxGridDevicesContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxGridDevicesContainer").dxDataGrid('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _dataSourceChanged: (function () {
         this.$("#dxGridDevicesContainer").dxDataGrid('option', 'dataSource', this.get('dataSource'));
         this.refreshDataGrid();
      }).observes('dataSource'),

      _showSensorsChanged: (function () {
         this.refreshSensorColumns();
      }).observes('showSensors'),

      _isWideModeChanged: (function () {
         var isWideMode = this.get('isWideMode');
         var grid = this.$("#dxGridDevicesContainer").dxDataGrid('instance');
         if (grid) {
            grid.option('columns[3].visible', isWideMode);
            grid.option('columns[4].visible', isWideMode);
            grid.option('columns[5].visible', isWideMode);
            grid.option('columns[6].visible', isWideMode);
            grid.option('searchPanel.visible', isWideMode);
         }
      }).observes('isWideMode')
   });
});