define('frontend/components/dx-list-logs', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {

   /*let dataLogs = [];
   let listDataSource = new DevExpress.data.DataSource({
      store: {
         type: "array",
         data: dataLogs
      },
      paginate: true,
      pageSize: 10,
      /!*onChanged: function() {
         Ember.$("#pageForward").prop("disabled", listDataSource.isLastPage());
         Ember.$("#pageBackward").prop("disabled", listDataSource.pageIndex() === 0);
         Ember.$("#pageIndex").text("Page #" + (listDataSource.pageIndex() + 1));
         //Ember.render(["device", "created", "duration"], this.dataSource.items());
      },*!/
   });*/

   exports['default'] = _ember['default'].Component.extend({
      screen: _ember['default'].inject.service(),
      attributeBindings: ['dataLogs'],
      _initialize: (function () {
         var self = this;
         this.$("#dxListLogsContainer").dxList({
            activeStateEnabled: false,
            focusStateEnabled: false,
            pageLoadMode: 'scrollBottom',
            pageLoadingText: 'Loading Device Logs ...',
            dataSource: new DevExpress.data.DataSource({
               store: {
                  type: "array",
                  data: self.get('dataSource')
               },
               paginate: true,
               pageSize: 10
            }),
            itemTemplate: function itemTemplate(itemData, index, element) {
               var duration = self.formatTime(itemData.duration);
               var status = self.formatStatus(itemData.status);
               var created = 'on the ' + self.formatDate(itemData.created, 'DD/MM/YYYY') + ' at ' + self.formatDate(itemData.created, 'h:mm:ss a');

               var trData = self.$("<tr><div id='trData_" + index + "'></div><td style='min-width:60px;width:60px'><span>#" + (index + 1) + "</span>" + "<i class='far fa-arrow-right dx-icon-xs'></i></td><td><span>" + itemData.device + "</span></td>");
               if (itemData.status) {
                  trData.append(self.$("<td><div class='label-success'>" + status + "</div></td>"));
                  trData.append(self.$("<td><span>" + created + "</span></td>"));
                  trData.append(self.$("<td><div class='label-success label-date'>" + duration + "</div></td>"));
               } else {
                  trData.append(self.$("<td><div class='label-danger'>" + status + "</div></td>"));
                  trData.append(self.$("<td><span>" + created + "</span></td>"));
                  trData.append(self.$("<td><div class='label-danger label-date'>" + duration + "</div></td>"));
               }
               trData.append(self.$("</tr>"));
               element.append(trData);
            }
         });
      }).on('didInsertElement'),

      formatDate: function formatDate(date, format) {
         if (date === undefined || date === null) {
            return 'Unknown';
         }
         if (typeof format !== 'string') {
            format = 'DD/MM/YYYY hh:mm:ss a';
         }
         return (0, _moment['default'])(date).format(format);
      },
      formatStatus: function formatStatus(status) {
         if (status === undefined || status === null) {
            return 'Unknown';
         } else if (status) {
            return 'Online';
         } else {
            return 'Offline';
         }
      },
      formatTime: function formatTime(ticks) {
         if (ticks === undefined || ticks === null) {
            return 'error!';
         }
         var result = '';
         var duration = _moment['default'].duration(ticks, "ms");

         if (duration) {
            var days = duration.get('days');
            var hours = duration.get('hours');
            var minutes = duration.get('minutes');
            var seconds = duration.get('seconds');

            if (days > 0) {
               result = result + days + "d ";
            }
            if (hours > 0) {
               result = result + hours + "h ";
            }
            if (minutes > 0) {
               result = result + minutes + "m ";
            }
            if (seconds > 0) {
               result = result + seconds + "s";
            }
         }
         return result;
      },

      _dataSourceChanged: (function () {
         this.$('#dxListLogsContainer').dxList('option', 'dataSource').load();
         console.log('Log list dataSource changed');
      }).observes('dataSource')
   });

   /*
   function goToPage(index) {
      listDataSource.pageIndex(index);
      listDataSource.load();
   }
   function loadDataSource() {
      console.log('Loading DataSource');
      listDataSource.load();
   
      Ember.$("#pageForward").click(function () {
         goToPage(listDataSource.pageIndex() + 1);
      });
      Ember.$("#pageBackward").click(function () {
         goToPage(listDataSource.pageIndex() - 1);
      });
   }
   */
});