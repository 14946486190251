define('frontend/routes/home/device/analytics', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model(params) {
         var self = this;
         if (params && params.dvc) {
            return _ember['default'].RSVP.hash({
               device: this.store.findRecord('device', params.dvc).then(function (device) {
                  return device;
               })['catch'](function (error) {
                  console.log("ANALYTICS: Find Device error: %s", error.message);
                  return null;
               }),
               settings: self.store.queryRecord('settings', { device: params.dvc }).then(function (setting) {
                  return setting;
               })['catch'](function (error) {
                  console.log("ANALYTICS: Find Device settings error: %s", error.message);
                  return null;
               }),
               logs: this.store.query('status', { device: params.dvc }).then(function (statuses) {
                  return statuses;
               })['catch'](function (error) {
                  console.log("ANALYTICS: Find status logs error: %s", error.message);
                  return [];
               }),
               outageLogs: this.store.query('status', { device: params.dvc, status: false }).then(function (statuses) {
                  return statuses;
               })['catch'](function (error) {
                  console.log("ANALYTICS: Find offline logs error: %s", error.message);
                  return [];
               }),
               resetLogs: this.store.query('status', { device: params.dvc, status: true }).then(function (statuses) {
                  return statuses;
               })['catch'](function (error) {
                  console.log("ANALYTICS: Find online logs error: %s", error.message);
                  return [];
               }),
               lastSensorLog: self.store.query('sensorlog', { device: params.dvc, lastLog: true }, { reload: true }).then(function (sensorLogs) {
                  if (sensorLogs && sensorLogs.get('length') > 0) {
                     return sensorLogs.get('lastObject');
                  } else {
                     console.log("ANALYTICS: Last sensor log not found!");
                     return null;
                  }
               })['catch'](function (error) {
                  console.log("ANALYTICS: Find last sensor Log error: %s", error.message);
                  return null;
               }),
               sensorLogs: self.store.query('sensorlog', { device: params.dvc, startDate: self.get('startDate'), finishDate: self.get('finishDate') }, { reload: true }).then(function (sensorLogs) {
                  if (sensorLogs && sensorLogs.get('length') > 0) {
                     console.log("ANALYTICS: Sensor logs found: %d", sensorLogs.get('length'));
                     return sensorLogs;
                  } else {
                     console.log("ANALYTICS: No sensor logs found!");
                     return [];
                  }
               })['catch'](function (error) {
                  console.log("ANALYTICS: Find sensor logs error: %s", error.message);
                  return [];
               })
            });
         } else {
            console.log("ANALYTICS: Model parameters are invalid!");
            return null;
         }
      },
      afterModel: function afterModel(model) {
         // If the device has no model type, assume it's a POAS device
         if (model.device && model.device.get('model_type') == null) {
            model.device.set('model_type', 'POAS');
         }
      },
      setupController: function setupController(controller, model) {
         // Call _super for default behavior - sets the model to the controller
         this._super(controller, model);

         if (model.device) {
            var _name = model.device.get('name');
            var dvcModel = model.device.get('model_type');
            if (_name) {
               controller.set('deviceName', _name);
            }
            if (dvcModel === 'TALS' || dvcModel === 'tals') {
               controller.set('isSensorDevice', true);
            } else {
               controller.set('isSensorDevice', false);
            }
         }
         controller.calculateDurations(model.logs);
         controller.reMapLogs();
         controller.checkDateRange();
         controller.updateProperties();

         if (controller.get('enableUpdates') !== true) {
            controller.set('enableUpdates', true);
         }
      },
      actions: {
         willTransition: function willTransition() {
            var controller = this.controllerFor('home.device.analytics');
            if (controller) {
               controller.resetToolbar();
               controller.set('enableUpdates', false);
            }
         },
         didTransition: function didTransition() {
            var controller = this.controllerFor('home.device.analytics');
            var homeController = this.controllerFor('home');
            if (homeController) {
               var _name2 = controller.get('deviceName');
               if (_name2) {
                  homeController.set('title', _name2);
               } else {
                  homeController.set('title', 'Device Analytics');
               }
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});