define('frontend/controllers/home/devices', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      homeController: _ember['default'].inject.controller('home'),
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      isAnalyticsSelected: true,
      isSettingsSelected: false,
      isRecipientsSelected: false,
      selectedDevice: null,
      selectedTabIndex: 0,
      showSensors: false,
      smartPlugs: null,
      sensorDevices: null,
      enableUpdates: false,
      tabItems: [{ 'text': 'Smart Plugs', 'icon': 'fal fa-outlet' }, { 'text': 'Sensors', 'icon': 'fal fa-sensor-on' }],
      queryParams: ['dvc'],
      actions: {
         gotoAnalytics: function gotoAnalytics() {
            var dvcID = this.get('homeController.selectedDeviceID');
            this.transitionToRoute('home.device.analytics', { queryParams: { dvc: dvcID } });
            this.resetButton("#dxButtonAnalytics", 'Analytics');
         },
         gotoSettings: function gotoSettings() {
            var dvcID = this.get('homeController.selectedDeviceID');
            this.transitionToRoute('home.device.settings', { queryParams: { dvc: dvcID } });
            this.resetButton('#dxButtonSettings', 'Settings');
         },
         gotoRecipients: function gotoRecipients() {
            var dvcID = this.get('homeController.selectedDeviceID');
            this.transitionToRoute('home.device.recipients', { queryParams: { dvc: dvcID } });
            this.resetButton('#dxButtonRecipients', 'Recipients');
         },
         removeDevice: function removeDevice() {
            _ember['default'].$("#dxPopupConfirmContainer").dxPopup("instance").show();
         },
         popupDone: function popupDone(result) {
            if (result) {
               this.deleteDevice();
            }
         }
      },
      deleteDevice: function deleteDevice() {
         var self = this;
         var dvcToDelete = null;
         var devices = self.get('model.devices');

         if (devices) {
            var selDevice = self.get('selectedDevice');
            if (selDevice) {
               // Check the correct device is selected ...
               for (var i = 0; i < devices.get('length'); i++) {
                  var device = devices.objectAt(i);
                  if (device) {
                     if (selDevice.uuid === device.get('uuid')) {
                        dvcToDelete = device;
                        break;
                     }
                  }
               }
               // Delete the selected device ...
               if (dvcToDelete) {
                  (function () {
                     var sub = dvcToDelete.get('subscription');
                     var sub_id = sub.get('id');

                     // When device is deleted, back-end will remove all recipients and cancel subscription ...
                     devices.removeObject(dvcToDelete);
                     dvcToDelete.destroyRecord().then(function () {
                        DevExpress.ui.notify("Device deleted successfully", "success", 2500);
                        self.removeSubscription(sub_id);
                        self.selectFirstDevice();
                     })['catch'](function (error) {
                        DevExpress.ui.notify("Error deleting Device: " + error, "error", 2500);
                     });
                  })();
               }
            } else {
               DevExpress.ui.notify("Error: No Device selected", "error", 2500);
            }
         } else {
            DevExpress.ui.notify("Error: There are no Devices to delete!", "error", 2500);
         }
      },
      removeSubscription: function removeSubscription(sub_id) {
         var subs = this.get('model.subscriptions');
         if (subs) {
            for (var i = 0; i < subs.get('length'); i++) {
               var sub = subs.objectAt(i);
               if (sub) {
                  if (sub.get('id') === sub_id) {
                     subs.removeObject(sub);
                     break;
                  }
               }
            }
         }
      },
      resetButton: function resetButton(id, text) {
         var btn = _ember['default'].$(id + " #dxButtonContainer").dxButton("instance");
         if (btn) {
            btn.option('text', text);
            btn.option('disabled', false);
            if (btn.loadingIndicator) {
               btn.loadingIndicator.option('visible', false);
            }
         }
      },
      reloadDevices: function reloadDevices() {
         console.log("DEVICES: Reloading ...");
         var devices = this.get('model.devices');
         if (devices) {
            for (var i = 0; i < devices.get('length'); i++) {
               var dvc = devices.objectAt(i);
               if (dvc) {
                  dvc.reload();
               }
            }
            this.updateJSONDevices();
         }
      },
      reloadSubscriptions: function reloadSubscriptions() {
         var subs = this.get('model.subscriptions');
         if (subs) {
            for (var s = 0; s < subs.get('length'); s++) {
               var sub = subs.objectAt(s);
               if (sub) {
                  sub.reload();
               }
            }
         }
      },
      waitAndRefresh: function waitAndRefresh() {
         var self = this;
         _ember['default'].run.later(this, function () {
            self.reloadDevices();
            self.reloadSubscriptions();
         }, 3000); // after 3 seconds
      },
      startUpdateTimer: function startUpdateTimer() {
         var self = this;
         _ember['default'].run.later(this, function () {
            if (self.get('enableUpdates')) {
               self.reloadDevices();
               self.reloadSubscriptions();
               self.startUpdateTimer();
            }
         }, 180000); // every 3 minutes
      },
      /*processLogs: function(device) {
         let self = this;
         let logs = device.get('logs');
          if (logs) {
            // Convert Device Logs to JSON object array ...
            //console.log("Processing Logs...");
            let logsJSON = logs.map(function(log) {
               return log.toJSON();
            });
             if (logsJSON) {
               let dataArray = [];
               // Loop through all Logs.
               // Status needs to be 0 (false) to 100 (true) ...
               let lastStatus;
               for (let i = 0; i < logsJSON.get('length'); i++) {
                  let obj = logsJSON.objectAt(i);
                  let newJSON;
                  let dt = new Date(obj.created);
                  lastStatus = (obj.status * 100);
                  newJSON = {
                     created: moment(dt).format("YYYY-MM-DD HH:mm:ss"),
                     status: lastStatus,
                     temperature: 0,
                     humidity: 0.0,
                     current: 0.0
                  };
                  dataArray.push(newJSON);
               }
               // Add one more dummy entry (with last log's status) so graph lines continue to NOW...
               let lastJSON = {
                  created: moment().format("YYYY-MM-DD HH:mm:ss"),
                  status: lastStatus,
                  temperature: 0,
                  humidity: 0.0,
                  current: 0.0
               };
               dataArray.push(lastJSON);
               self.updateDeviceLogs(dataArray);
            }
         }
      },*/
      deselectDevice: function deselectDevice() {
         this.set('homeController.selectedDeviceIndex', -1);
         this.set('selectedDevice', null);
      },
      selectFirstDevice: function selectFirstDevice() {
         console.log("DEVICES: Selecting FIRST Device");
         var tabIndex = this.get('selectedTabIndex');
         if (tabIndex === 0) {
            var devices = this.get('smartPlugs');
            if (devices) {
               var device = devices.objectAt(0);
               this.set('homeController.selectedDeviceIndex', 0);
               this.set('selectedDevice', device);
            } else {
               this.deselectDevice();
            }
         } else if (tabIndex === 1) {
            var devices = this.get('sensorDevices');
            if (devices) {
               var device = devices.objectAt(0);
               this.set('homeController.selectedDeviceIndex', 0);
               this.set('selectedDevice', device);
            } else {
               this.deselectDevice();
            }
         } else {
            this.deselectDevice();
         }
      },
      getSelectedDevice: function getSelectedDevice() {
         var self = this;
         var found = false;
         var uuid = this.get('homeController.selectedDeviceUUID');
         var tabIndex = this.get('selectedTabIndex');

         console.log("DEVICES: Getting Device UUID: %s", uuid);
         if (!uuid) {
            self.selectFirstDevice();
            return;
         }
         // Need to check against UUID because device list uses JSON objects
         /*let devices = this.get('model.devices');
         for (let i = 0; i < devices.get('length'); i++) {
            let dvc = devices.objectAt(i);
            if (dvc && dvc.get('uuid') === uuid) {
               let dvcID = dvc.get('id');
               self.set('homeController.selectedDeviceID', dvcID);
               console.log('DEVICES: Found Device ID: %s', dvcID);
               break;
            }
         }*/

         if (tabIndex === 0) {
            var devices = this.get('smartPlugs');
            if (devices) {
               devices.forEach(function (device, index) {
                  if (device && device.uuid === uuid) {
                     found = true;
                     console.log('DEVICES: Found device UUID: %s, [index: %d]', uuid, index);
                     self.set('homeController.selectedDeviceIndex', index);
                     self.set('selectedDevice', device);
                  }
               });
            }
            if (found === false) {
               this.selectFirstDevice();
            }
         } else if (tabIndex === 1) {
            var devices = this.get('sensorDevices');
            if (devices) {
               devices.forEach(function (device, index) {
                  if (device && device.uuid === uuid) {
                     found = true;
                     console.log('DEVICES: Found device UUID: %s, [index: %d]', uuid, index);
                     self.set('homeController.selectedDeviceIndex', index);
                     self.set('selectedDevice', device);
                  }
               });
            }
            if (found === false) {
               this.selectFirstDevice();
            }
         } else {
            self.deselectDevice();
         }
      },
      /*checkSelectedDevice: function() {
         let dvcIndex = this.get('model.appsettings.device_index');
         console.log("Device Index: %d", dvcIndex);
         let tabIndex = this.get('selectedTabIndex');
          if (dvcIndex === undefined || dvcIndex === null) {
            dvcIndex = 0;
            this.set('model.appsettings.device_index', 0);
         }
         if (tabIndex === 0) {
            let devices = this.get('smartPlugs');
            if (devices) {
               if (dvcIndex >= 0 && dvcIndex < devices.get('length')) {
                  let dvc = devices.objectAt(dvcIndex);
                  if (dvc.model_type !== 'TALS' && dvc.model_type !== 'tals') {
                     this.set('selectedDevice', dvc);
                  } else {
                     // Select first Device in the list ...
                     this.set('model.appsettings.device_index', 0);
                     this.set('selectedDevice', devices.objectAt(0));
                  }
               } else {
                  // Select first Device in the list ...
                  this.set('model.appsettings.device_index', 0);
                  this.set('selectedDevice', devices.objectAt(0));
               }
            }
         } else if (tabIndex === 1) {
            let devices = this.get('sensorDevices');
            if (devices) {
               if (dvcIndex >= 0 && dvcIndex < devices.get('length')) {
                  let dvc = devices.objectAt(dvcIndex);
                  if (dvc.group === 'TALS' || dvc.model_type === 'TALS' || dvc.model_type === 'tals') {
                     this.set('selectedDevice', dvc);
                  } else {
                     // Select first Device in the list ...
                     this.set('model.appsettings.device_index', 0);
                     this.set('selectedDevice', devices.objectAt(0));
                  }
               } else {
                  // Select first Device in the list ...
                  this.set('model.appsettings.device_index', 0);
                  this.set('selectedDevice', devices.objectAt(0));
               }
            }
         } else {
            this.set('selectedDevice', null);
         }
      },*/
      setDeviceID: function setDeviceID() {
         var self = this;
         var selectedDevice = this.get('selectedDevice');
         var devices = this.get('model.devices');

         if (!selectedDevice || !devices) {
            // There is NO selected device, or devices in the MODEL ...
            self.set('homeController.selectedDeviceUUID', null);
            self.set('homeController.selectedDeviceID', null);
            console.log('DEVICES: No Device selected');
            return;
         }
         // Iterate through all devices in the MODEL ...
         for (var i = 0; i < devices.get('length'); i++) {
            var device = devices.objectAt(i);
            // Need to check against UUID because selected Device is a JSON object
            if (device && device.get('uuid') === selectedDevice.uuid) {
               var deviceID = device.get('id');
               self.set('homeController.selectedDeviceUUID', device.get('uuid'));
               self.set('homeController.selectedDeviceID', deviceID);
               console.log('DEVICES: Set Device ID: %s', deviceID);
               break;
            }
         }
      },
      /*populateDevices: function() {
         let devices = this.get('model').devices;
         if (!devices || devices.length === 0) {
            return;
         }
         let devicesJSON = devices.map(function (x) {
            let sub = x.get('subscription');
            let jsonX = x.toJSON();
            jsonX.subscription = sub.get('content');
            return jsonX;
         });
         // Populate sensor devices array ...
         let sensorDevices = this.get('sensorDevices');
         if (!sensorDevices || sensorDevices.length === 0) {
             this.set('sensorDevices', sensorsJSON);
         }
         // Populate smart-plugs array ...
         let smartPlugs = this.get('smartPlugs');
         if (!smartPlugs || smartPlugs.length === 0) {
            let smartPlugsJSON = devices.map(function (x) {
               let sub = x.get('subscription');
               let jsonX = x.toJSON();
               jsonX.subscription = sub.get('content');
               if (jsonX.model_type === undefined || jsonX.model_type === null || jsonX.model_type === 'POAS') {
                  return jsonX;
               }
            });
            this.set('smartPlugs', smartPlugsJSON);
         }
      },*/
      copyDevice: function copyDevice(dvc, dvcJ) {
         if (dvc && dvcJ) {
            // copy properties from dvcJ to dvc ...
            dvc.name = dvcJ.name;
            dvc.mac_address = dvcJ.mac_address;
            dvc.model_type = dvcJ.model_type;
            dvc.group = dvcJ.group;
            dvc.type = dvcJ.type;
            dvc.number = dvcJ.number;
            dvc.ping_sensitivity = dvcJ.ping_sensitivity;
            dvc.post_frequency = dvcJ.post_frequency;
            dvc.update_checks = dvcJ.update_checks;
            dvc.online = dvcJ.online;
            //dvc.show_sensors = dvcJ.show_sensors;
            dvc.last_online = dvcJ.last_online;
            dvc.last_offline = dvcJ.last_offline;
            dvc.last_ping = dvcJ.last_ping;
            dvc.last_fw_update = dvcJ.last_fw_update;
            dvc.last_updated = dvcJ.last_updated;
            dvc.num_resets = dvcJ.num_resets;
            dvc.num_outages = dvcJ.num_outages;
            dvc.battery_soc = dvcJ.battery_soc;
            dvc.battery_volts = dvcJ.battery_volts;
            dvc.current = dvcJ.current;
            dvc.humidity = dvcJ.humidity;
            dvc.temperature = dvcJ.temperature;
            dvc.temperature_aux = dvcJ.temperature_aux;
            dvc.temperature_ext = dvcJ.temperature_ext;
            //dvc.subscription = dvcJ.subscription;
            if (dvc.settings && dvcJ.settings) {
               dvc.settings.comms_type = dvcJ.settings.comms_type;
               dvc.settings.show_sensors = dvcJ.settings.show_sensors;
               dvc.settings.stay_awake = dvcJ.settings.stay_awake;
               dvc.settings.ping_sensitivity = dvcJ.settings.ping_sensitivity;
               dvc.settings.post_frequency = dvcJ.settings.post_frequency;
               dvc.settings.created = dvcJ.settings.created;
               dvc.settings.last_updated = dvcJ.settings.last_updated;
            }
         }
      },
      updateJSONDevices: function updateJSONDevices() {
         // Convert devices to array of JSON objects for Data-Grid ...
         var devices = this.get('model').devices;
         var showSensorCols = false;
         if (devices) {
            // Map model devices to JSON ...
            var devicesJ = devices.map(function (d) {
               var sub = d.get('subscription');
               var settings = d.get('settings');
               var deviceJSON = d.toJSON();
               if (sub) {
                  deviceJSON.subscription = sub.get('content');
               }
               if (settings) {
                  var settingsContent = settings.get('content');
                  if (settingsContent) {
                     deviceJSON.settings = settingsContent.toJSON();
                     if (deviceJSON.settings) {
                        // If even 1 device's showing sensors, need to show grid columns ...
                        showSensorCols = deviceJSON.settings.show_sensors;
                     }
                  }
               }
               return deviceJSON;
            });

            // Update Smart-Plugs ...
            var smartPlugs = this.get('smartPlugs');
            if (!smartPlugs) {
               // Create a new list of smart plugs ...
               smartPlugs = [];
               for (var i = 0; i < devicesJ.length; i++) {
                  var dvc = devicesJ.objectAt(i);
                  // TODO: Remove GROUP check for RELEASE
                  if (dvc.group !== 'TALS' && (dvc.model_type === undefined || dvc.model_type === null || dvc.model_type === 'POAS')) {
                     smartPlugs.push(dvc);
                  }
               }
               this.set('smartPlugs', smartPlugs);
            } else {
               // Update existing list of smart plugs ...
               for (var j = 0; j < smartPlugs.length; j++) {
                  var plug = smartPlugs.objectAt(j);
                  var foundPlug = false;
                  for (var k = 0; k < devicesJ.length; k++) {
                     var dvcJ = devicesJ.objectAt(k);
                     if (plug.uuid === dvcJ.uuid) {
                        this.copyDevice(plug, dvcJ);
                        foundPlug = true;
                        break;
                     }
                  }
                  if (!foundPlug) {
                     // Remove Smart-Plug from array ...
                     smartPlugs.splice(j, 1);
                  }
               }
            }

            // Update Sensor Devices ...
            var sensorDevices = this.get('sensorDevices');
            if (!sensorDevices) {
               // Create a new list of sensor devices ...
               sensorDevices = [];
               for (var r = 0; r < devicesJ.length; r++) {
                  var dvc = devicesJ.objectAt(r);
                  // TODO: Remove GROUP check for RELEASE
                  if (dvc.model_type === 'TALS' || dvc.group === 'TALS' || dvc.name.includes('TALS')) {
                     sensorDevices.push(dvc);
                  }
               }
               this.set('sensorDevices', sensorDevices);
            } else {
               // Update existing list of sensor devices ...
               for (var s = 0; s < sensorDevices.length; s++) {
                  var sensor = sensorDevices.objectAt(s);
                  var foundSensor = false;
                  for (var t = 0; t < devicesJ.length; t++) {
                     var dvcJ = devicesJ.objectAt(t);
                     if (sensor.uuid === dvcJ.uuid) {
                        this.copyDevice(sensor, dvcJ);
                        foundSensor = true;
                        break;
                     }
                  }
                  if (!foundSensor) {
                     // Remove Sensor Device from array ...
                     sensorDevices.splice(s, 1);
                  }
               }
            }
         } else {
            console.log("DEVICES: No Devices Found!");
            this.set('smartPlugs', null);
            this.set('sensorDevices', null);
         }
         this.set('showSensors', showSensorCols);
      },
      /*updateDeviceLogs(dataArray) {
         if (dataArray && dataArray.length > 0) {
            this.set('selectedDeviceLogs', dataArray);
             let dtStart = new Date();
            //let firstDay = new Date(dtStart.getFullYear(), dtStart.getMonth(), 1);  // Get first day of the month
            let aMonthAgo = new Date(new Date().setDate(dtStart.getDate() - 30));
            let firstLog = new Date(dataArray[0].created);
             if (firstLog > aMonthAgo) {
               dtStart = firstLog;
            } else {
               dtStart = aMonthAgo;
            }
            //let start = moment().subtract(30, 'days').format("YYYY-MM-DD HH:mm:ss");
            //let start = moment(dtStart).format("YYYY-MM-DD HH:mm:ss");
            //let finish = moment().add(10, 'minutes').format("YYYY-MM-DD HH:mm:ss");
             //Ember.$("#dxRangeContainer").dxRangeSelector('option', 'scale.startValue', start);
            //Ember.$("#dxRangeContainer").dxRangeSelector('option', 'scale.endValue', finish);
            //self.set('statusRange', [start, finish]);
         }
      },*/
      _devicesChanged: (function () {
         //console.log("Devices Changed");
         this.updateJSONDevices();
      }).observes('model.devices'),

      // Subsequent device Logs will be from selected device.
      _selectedDeviceChanged: (function () {
         var device = this.get('selectedDevice');
         if (device) {
            console.log('DEVICES: Device Changed [Name: %s, UUID %s]', device.name, device.uuid);
         } else {
            console.log('DEVICES: Device Changed: [NULL]');
         }
         this.setDeviceID();
      }).observes('selectedDevice'),

      _enableUpdatesChanged: (function () {
         if (this.get('enableUpdates') === true) {
            this.startUpdateTimer();
         }
      }).observes('enableUpdates'),

      _selectedTabIndexChanged: (function () {
         var tabIndex = this.get('selectedTabIndex');
         this.set('homeController.selectedTabIndex', tabIndex);
         if (tabIndex === 0) {
            this.set('showPlugs', true);
         } else {
            this.set('showPlugs', false);
         }
         this.getSelectedDevice();
      }).observes('selectedTabIndex')
   });
});