define('frontend/routes/users/login', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model(params) {
         if (params && params.addDevice !== null) {
            //this.transitionTo('users.setup',{queryParams: {s: params.addDevice}});
         }
      },
      actions: {
         didTransition: function didTransition() {
            // User shouldn't be logged in when transitioned here ...
            var auth = this.get('authManager');
            if (auth && auth.isAuthenticated()) {
               auth.reset();
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});