define('frontend/routes/application', ['exports', 'ember', 'frontend/config/environment'], function (exports, _ember, _frontendConfigEnvironment) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      environment: _frontendConfigEnvironment['default'].environment,
      model: function model() {
         return _ember['default'].RSVP.hash({
            environment: _frontendConfigEnvironment['default'].environment,
            isProduction: _ember['default'].computed.equal('environment', 'production')
         });
      },
      beforeModel: function beforeModel(transition) {
         var auth = this.get('authManager');
         if (!auth) {
            console.log('APP: Auth Manager is undefined!');
            return;
         }
         console.log("hostname: " + this.store.adapterFor('application').get('host'));
         console.log('Transition Target: %s', transition.targetName);

         if (auth.isAuthenticated()) {
            if (transition.targetName === 'index' || transition.targetName === 'application' || transition.targetName === 'home.index' || transition.targetName === 'users.index') {
               this.transitionTo('home.dashboard');
            } else if (transition.targetName === 'home.device.index') {
               this.transitionTo('home.device.analytics', { queryParams: { dvc: null } });
            } else if (transition.targetName === 'home.profile.index') {
               this.transitionTo('home.profile.administrator');
            } else if (transition.targetName === 'home.account.index') {
               this.transitionTo('home.account.details');
            } else if (transition.targetName === 'users.reset-password') {
               // Do nothing.
            } else if (transition.targetName.startsWith('users.setup')) {
                  // Do nothing.
               } else if (transition.targetName.startsWith('users.adddevice') || transition.targetName.startsWith('users.finddevice')) {
                     // Do nothing.
                  } else if (transition.targetName.startsWith('users')) {
                        this.transitionTo('home.dashboard');
                     }
         } else {
            if (transition.targetName === 'users.setup') {
               this.transitionTo('users.setup');
            } else if (transition.targetName === 'users.forgot-password') {
               this.transitionTo('users.forgot-password');
            } else if (transition.targetName === 'users.forgot-email') {
               this.transitionTo('users.forgot-email');
            } else if (transition.targetName === 'users.setup') {
               // Do nothing.
            } else {
                  this.transitionTo('users.login', { queryParams: { addDevice: null } });
               }
         }
      },
      actions: {
         error: function error(_error, transition) {
            console.log("Application (Route): " + transition.targetName);
            console.log("Application (" + _error.name + "): " + _error.message);

            if (_error.errors && _error.errors[0].status === 401) {
               var auth = this.get('authManager');
               if (auth) {
                  auth.reset();
               }
            }
         }
      }
   });
});