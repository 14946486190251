define('frontend/controllers/home/notifications', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({
      appController: _ember['default'].inject.controller('application'),
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      alarmSeverity: null,
      selectedNotification: null,
      enableUpdates: false,
      notificationsJSON: [],

      init: function init() {
         this._super();
         /*this.set('groupList', this.get('appController.deviceGroups'));
         this.set('typeList', this.get('appController.deviceTypes'));
         this.set('commsTypeList', this.get('appController.commsTypes'));
         this.set('notificationTypes', this.get('appController.notificationTypes'));
         this.set('pingSensitivityList', this.get('pingSensitivities'));
         this.set('postFrequencyList', this.get('appController.postFrequencies'));
         this.set('updateCheckList', this.get('appController.updateChecks'));
         this.set('alarmEquality', this.get('appController.alarmEquality'));
         this.set('sensorTypes', this.get('appController.sensorTypes'));*/
         this.set('alarmSeverity', this.get('appController.alarmSeverity'));
      },
      actions: {
         deleteNotification: function deleteNotification() {
            var _this = this;

            var selectedNotification = this.get('selectedNotification');
            if (selectedNotification) {
               (function () {
                  console.log('Deleting Notification: %s', selectedNotification.id);
                  var notification = _this.getNotificationFromModel(selectedNotification.id);
                  var msg = 'Notification Deleted';
                  var alarm = notification.get('alarm');
                  if (alarm) {
                     msg = 'Notification for ' + alarm.get('sensor_name') + ' alarm was deleted';
                  }
                  if (notification) {
                     notification.destroyRecord().then(function () {
                        DevExpress.ui.notify(msg, "success", 2500);
                     })['catch'](function (error) {
                        DevExpress.ui.notify("Error deleting Notification: " + error, "error", 5000);
                     });
                  } else {
                     console.log('Delete Notification Failed: Not Found in Model');
                  }
               })();
            } else {
               console.log('Delete Notification Failed: Nothing selected!');
            }
         }
      },
      UpdateJSONNotifications: function UpdateJSONNotifications() {
         var notifications = this.get('model.notifications');
         if (notifications) {
            var notificationsJ = notifications.map(function (notification) {
               var alarm = notification.get('alarm');
               var notificationJSON = notification.toJSON({ includeId: true });
               if (alarm) {
                  var alarmContent = alarm.get('content');
                  //console.log("Alarm Content: " + JSON.stringify(alarmContent));
                  if (alarmContent) {
                     notificationJSON.alarm = alarmContent.toJSON({ includeId: true });
                     var device = alarm.get('device');
                     if (device) {
                        var deviceContent = device.get('content');
                        if (deviceContent) {
                           notificationJSON.alarm.device = deviceContent.toJSON({ includeId: true });
                        }
                     }
                  }
               }
               return notificationJSON;
            });
            this.set('notificationsJSON', notificationsJ);
            //console.log("DataSource: " + JSON.stringify(notificationsJ));
         }
      },
      getNotificationFromModel: function getNotificationFromModel(id) {
         var foundNotification = null;
         var notifications = this.get('model.notifications');
         if (notifications) {
            notifications.forEach(function (notification) {
               if (notification && notification.get('id') === id) {
                  console.log('NOTIFICATIONS: Found notification in model: %s', id);
                  foundNotification = notification;
               }
            });
         }
         return foundNotification;
      },
      refreshNotifications: function refreshNotifications() {
         // Need to do this 1 second delay, because for some reasone the notification
         // has an Alarm ID, but the Alarm has a NULL device ID after the model loads
         // until the second call to UpdateJSONNotifications() ...
         var self = this;
         _ember['default'].run.later(this, function () {
            self.UpdateJSONNotifications();
         }, 1000); // run after 1 second
      },
      reloadNotifications: function reloadNotifications() {
         var notifications = this.get('model.notifications');
         if (notifications) {
            for (var i = 0; i < notifications.get('length'); i++) {
               var notification = notifications.objectAt(i);
               if (notification) {
                  notification.reload();
               }
            }
         }
      },
      updateProperties: function updateProperties() {
         //TODO: Add properties to update periodically
      },
      startUpdateTimer: function startUpdateTimer() {
         var self = this;
         _ember['default'].run.later(this, function () {
            if (self.get('enableUpdates')) {
               self.UpdateJSONNotifications();
               self.updateProperties();
               self.startUpdateTimer();
            }
         }, 60000); // every 1 minute
      },
      _notificationsChanged: (function () {
         console.log("Notifications Changed");
         this.UpdateJSONNotifications();
      }).observes('model.notifications'),

      _enableUpdatesChanged: (function () {
         if (this.get('enableUpdates') === true) {
            this.startUpdateTimer();
         }
      }).observes('enableUpdates')
   });
});