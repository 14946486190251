define('frontend/routes/users/finddevice', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      model: function model() {
         return null;
      },
      actions: {
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});