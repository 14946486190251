define('frontend/components/dx-gauge', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].DxGaugeComponent = _ember['default'].Component.extend({
      labelVisible: false,

      click: function click() {
         var act = this.get('clickAction');
         if (act && this.get('value')) {
            act();
         }
      },

      _initialize: (function () {
         var self = this;

         this.$("#dxGaugeContainer").dxBarGauge({
            barSpacing: 2,
            relativeInnerRadius: 0.667,
            size: {
               height: this.get('height'),
               width: this.get('width')
            },
            scale: {
               startValue: 0,
               tickInterval: 1
            },
            endValue: this.get('maxValue'),
            tooltip: {
               enabled: true,
               font: {
                  size: 14,
                  weight: 600,
                  family: 'Arial'
               },
               format: {
                  formatter: function formatter() {
                     return self.get('hint') + ": " + self.get('value');
                  }
               }
            },
            title: {
               text: this.get('title'),
               margin: {
                  bottom: 20
               },
               font: {
                  family: 'Arial',
                  size: 16,
                  weight: 600
               }
            },
            label: {
               indent: 16,
               format: {
                  type: "fixedPoint",
                  precision: 0
               },
               position: 'bottom',
               visible: self.get('labelVisible')
            },
            palette: [this.get('color')],
            values: [this.get('value')]
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxGaugeContainer").remove();
      }).on('willDestroyElement'),

      _valueChanged: (function () {
         this.$("#dxGaugeContainer").dxBarGauge('option', 'values', [this.get('value')]);
      }).observes('value', 'maxValue')
   });
});