define('frontend/components/subscription-item', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      classNames: ['list-group-item'],
      canEdit: false,
      isEditing: false,
      isExpanded: false,
      selectedPlan: null,
      plans: [],
      attributeBindings: ['selectedPlan'],
      cardsKVP: _ember['default'].computed(function () {
         var cards = this.get('cards');
         var newCards = [];
         for (var i = 0; i < cards.length; i++) {
            var val = undefined;
            var _name = cards[i].name;
            if (_name) {
               val = _name + ' XXXX-XXXX-XXXX-' + cards[i].last4;
            } else {
               val = 'XXXX-XXXX-XXXX-' + cards[i].last4;
            }
            newCards.push({ key: cards[i].id, value: val });
         }
         return newCards;
      }),
      selectedCard: _ember['default'].computed(function () {
         var cards = this.get('cardsKVP');
         if (cards) {
            return cards[0];
         }
         return null;
      }),
      price: _ember['default'].computed(function () {
         var sub = this.get('item');
         if (sub) {
            return (sub.get('amount') / 100.0).toFixed(2).replace(/(\d)(?=(\d{3})+$)/g, "$1,");
         }
         return 0.0;
      }),
      isActive: _ember['default'].computed(function () {
         var sub = this.get('item');
         if (sub) {
            var _status = sub.get('status');
            if (_status === 'trialing' || _status === 'active' || _status === 'past_due') {
               return true;
            } else if (_status === 'canceled' || _status === 'unpaid') {
               return false;
            } else {
               return false;
            }
         } else {
            return false;
         }
      }),

      subNumber: _ember['default'].computed(function () {
         return this.get('index') + 1;
      }),

      actions: {
         cancel: function cancel() {
            this.set('isEditing', false);
         },
         edit: function edit() {
            this.set('isEditing', true);
         },
         remove: function remove(item) {
            this.set('isEditing', false);
            this.sendAction('remove', item);
         },
         save: function save(item) {
            var plan = this.get('selectedPlan');
            if (plan) {
               item.set('plan_id', plan.id);
               item.set('plan_name', plan.name);
               item.set('amount', plan.amount);
            }
            item.save().then(function () {
               console.log("Saved Item: " + JSON.stringify(item));
               this.sendAction('save');
            });
            this.set('isEditing', false);
         },
         slide: function slide(number) {
            if (_ember['default'].$("#item-sub" + number).hasClass('in')) {
               _ember['default'].$("#header-sub" + number + " .menuClick").removeClass("glyphicon-menu-up");
               _ember['default'].$("#header-sub" + number + " .menuClick").addClass("glyphicon-menu-down");
               _ember['default'].$("#header-sub" + number + " .lg-header-status").removeClass("hide");
            } else {
               _ember['default'].$("#header-sub" + number + " .menuClick").removeClass("glyphicon-menu-down");
               _ember['default'].$("#header-sub" + number + " .menuClick").addClass("glyphicon-menu-up");
               _ember['default'].$("#header-sub" + number + " .lg-header-status").addClass("hide");
            }
         }
      },
      _canEditChanged: (function () {
         if (this.get('canEdit') === false) {
            this.send('cancel');
         }
      }).observes('canEdit')
   });
});