define('frontend/models/location', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _emberDataAttr, _emberDataRelationships) {
   exports['default'] = _emberData['default'].Model.extend({
      address: (0, _emberDataAttr['default'])('string'), // Street number and name
      suburb: (0, _emberDataAttr['default'])('string'), // Address suburb
      city: (0, _emberDataAttr['default'])('string'), // Address city
      postcode: (0, _emberDataAttr['default'])('string'), // Address postcode
      region: (0, _emberDataAttr['default'])('string'), // Address state/region
      country: (0, _emberDataAttr['default'])('string', {
         defaultValue: function defaultValue() {
            return 'AU';
         } // Country of registration
      }),
      timezone: (0, _emberDataAttr['default'])('string'), // Timezone for localised date/time in status logs
      latitude: (0, _emberDataAttr['default'])('number'), // For map location
      longitude: (0, _emberDataAttr['default'])('number'), // For map location
      user: (0, _emberDataRelationships.belongsTo)('user') });
});
// Address/location belongs to a single user