define('frontend/components/dx-switch', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      value: false,
      attributeBindings: ['value'],
      _initialize: (function () {
         var self = this;
         this.$("#dxSwitchContainer").dxSwitch({
            disabled: self.get('disabled'),
            switchedOnText: self.get('onText'),
            switchedOffText: self.get('offText'),
            width: 54,
            value: self.get('value'),
            visible: true,
            onInitialized: function onInitialized() {
               console.log('Switch Initialized');
            },
            onValueChanged: function onValueChanged(e) {
               self.set('value', e.value);
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxSwitchContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxSwitchContainer").dxSwitch('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _valueChanged: (function () {
         this.$("#dxSwitchContainer").dxSwitch('option', 'value', this.get('value'));
         //console.log('Switch Value Changed');
      }).observes('value')
   });
});