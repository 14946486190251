define('frontend/controllers/home/dashboard', ['exports', 'ember', 'moment', 'frontend/config/environment'], function (exports, _ember, _moment, _frontendConfigEnvironment) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      appController: _ember['default'].inject.controller('application'),
      homeController: _ember['default'].inject.controller('home'),
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      initComplete: false,
      showPlugs: true,
      selectedDevice: null,
      dvc: null,
      selectedDeviceID: null,
      selectedDeviceName: '',
      selectedTabIndex: 0,
      statusRange: null,
      startDate: new Date().getDate() - 30,
      finishDate: new Date(),
      selectedDeviceLogs: [{ created: "2017-01-01", status: 0, temperature: 0, humidity: 0, current: 0 }, { created: "2017-01-31", status: 0, temperature: 0, humidity: 0, current: 0 }],
      showSensors: false,
      humidity: 0,
      temperature: 0.0,
      temperature_aux: 0.0,
      temperature_ext: 0.0,
      battery_current: 0.0,
      battery_millivolts: 0.0,
      battery_volts: 0.0,
      battery_soc: 0,
      battery_charging: false,
      battery_image: '/assets/icons/battery/battery-00.png',
      temperatureUnits: '°C', //'&deg;C',
      temperatureTitle: _ember['default'].computed(function () {
         var units = this.get('temperatureUnits');
         return 'Temperature Readings (' + units + ')';
      }),
      isHumidityDisabled: _ember['default'].computed('humidity', function () {
         var humidity = this.get('humidity');
         return humidity === undefined || isNaN(humidity) || humidity <= 0.0;
      }),
      isTempDisabled: _ember['default'].computed('temperature', function () {
         var temperature = this.get('temperature');
         return temperature === undefined || isNaN(temperature) || temperature <= -242.0;
      }),
      isTempAuxDisabled: _ember['default'].computed('temperature_aux', function () {
         var temperatureAux = this.get('temperature_aux');
         return temperatureAux === undefined || isNaN(temperatureAux) || temperatureAux <= -242.0;
      }),
      isTempExtDisabled: _ember['default'].computed('temperature_ext', function () {
         var temperatureExt = this.get('temperature_ext');
         return temperatureExt === undefined || isNaN(temperatureExt) || temperatureExt <= -242.0;
      }),
      smartPlugs: null,
      sensorDevices: null,
      sensorLogsJSON: null,
      totalDevices: 0,
      maxDevices: 0,
      devicesOnline: 0,
      devicesOffline: 0,
      powerOutages: 0,
      totalSMS: 0,
      smsRemaining: 0,
      enableUpdates: false,
      plan: null,
      accountStatus: 'In-Active',
      accountStatusClass: 'label-danger',
      nextPayment: 0,
      daysDue: 0,
      attributeBindings: ['statusRange', 'sensorLogsJSON', 'subValuesCurrent', 'subValuesHumidity', 'subValuesTemperature', 'subValuesTemperatureAUX', 'subValuesTemperatureEXT', 'subValuesBatterySOC'],
      seriesTemperature: [{ valueField: "temperature", name: "Internal Temperature", color: "#48B04F" }, { valueField: "temperature_aux", name: "Probe Temperature", color: "royalblue" }, { valueField: "temperature_ext", name: "External Temperature", color: "skyblue" }],
      seriesHumidity: [{ valueField: "humidity", name: "Humidity (%RH)", color: "royalBlue" }],
      seriesBattery: [{ valueField: "battery_soc", name: "Battery Charge (%)", color: "#48B04F" }],
      seriesVoltage: [{ valueField: "battery_volts", name: "Battery Voltage (mV)", color: "Violet" }],
      seriesCurrent: [{ valueField: "current", name: "Current (A)", color: "crimson" }],
      subValuesCurrent: [],
      subValuesHumidity: [],
      subValuesTemperature: [],
      subValuesTemperatureAUX: [],
      subValuesTemperatureEXT: [],
      subValuesBatterySOC: [],
      tabItems: [{ 'text': 'Smart Plugs', 'icon': 'fal fa-outlet' }, { 'text': 'Sensors', 'icon': 'fal fa-sensor-on' }],
      queryParams: ['dvc'],
      actions: {
         gotoAnalytics: function gotoAnalytics() {
            var dvcID = this.get('selectedDeviceID');
            this.transitionToRoute('home.device.analytics', { queryParams: { dvc: dvcID } });
         },
         gotoSettings: function gotoSettings() {
            var dvcID = this.get('selectedDeviceID');
            this.transitionToRoute('home.device.settings', { queryParams: { dvc: dvcID } });
         },
         gotoRecipients: function gotoRecipients() {
            var dvcID = this.get('selectedDeviceID');
            this.transitionToRoute('home.device.recipients', { queryParams: { dvc: dvcID } });
         },
         previousDevice: function previousDevice() {
            var self = this;
            var devices = undefined;
            var deviceIndex = this.get('homeController.selectedDeviceIndex'); // model.appsettings.device_index');
            var tabIndex = this.get('selectedTabIndex');

            if (tabIndex === 0) {
               devices = this.get('smartPlugs');
            } else {
               devices = this.get('sensorDevices');
            }
            if (devices) {
               var numDevices = devices.get('length');
               if (numDevices > 1) {
                  if (deviceIndex <= 0) {
                     deviceIndex = numDevices - 1;
                     self.set('selectedDevice', devices.objectAt(deviceIndex));
                  } else if (deviceIndex > 0 && deviceIndex < numDevices) {
                     deviceIndex -= 1;
                     self.set('selectedDevice', devices.objectAt(deviceIndex));
                  }
                  console.log('Setting Device Index: %d', deviceIndex);
                  self.set('homeController.selectedDeviceIndex', deviceIndex); // model.appsettings.device_index', deviceIndex);
                  self.setDeviceID();
                  self.send('refreshModel');
               }
            }
         },
         nextDevice: function nextDevice() {
            var self = this;
            var devices = undefined;
            var deviceIndex = this.get('homeController.selectedDeviceIndex'); // model.appsettings.device_index');
            var tabIndex = this.get('selectedTabIndex');

            if (tabIndex === 0) {
               devices = this.get('smartPlugs');
            } else {
               devices = this.get('sensorDevices');
            }
            if (devices) {
               var numDevices = devices.get('length');
               if (numDevices > 1) {
                  if (deviceIndex >= numDevices - 1) {
                     deviceIndex = 0;
                     self.set('selectedDevice', devices.objectAt(0));
                  } else if (deviceIndex >= 0 && deviceIndex < numDevices - 1) {
                     deviceIndex += 1;
                     self.set('selectedDevice', devices.objectAt(deviceIndex));
                  }
                  console.log('Setting Device Index: %d', deviceIndex);
                  self.set('homeController.selectedDeviceIndex', deviceIndex); // model.appsettings.device_index', deviceIndex);
                  self.setDeviceID();
                  self.send('refreshModel');
               }
            }
         },
         refresh: function refresh() {
            this.refresh();
         },
         showSubscriptionCredits: function showSubscriptionCredits() {
            _ember['default'].$("#dxPopoverCreditsContainer").dxPopover("instance").show();
         },
         logOut: function logOut() {
            var self = this;
            var result = DevExpress.ui.dialog.confirm("Are you sure you want to Logout?", "Confirm Logout");
            result.done(function (dialogResult) {
               if (dialogResult) {
                  self.transitionToRoute('users.logout');
               } else {
                  self.resetButton('Logout');
               }
            });
         }
      },
      _init: (function () {
         var finish = this.get('finishDate');
         //let start = new Date(finish.getFullYear(), finish.getMonth(), 1);  // Get first day of the month
         var start = new Date().setDate(finish.getDate() - 30); // Last 30 days of logs
         this.set('startDate', start);
         if (!this.get('enableUpdates')) {
            this.set('enableUpdates', true);
         }
         this.set('initComplete', true);
      }).on('init'),

      getDeviceIndex: function getDeviceIndex() {
         var devices = undefined;
         var selectedDevice = this.get('selectedDevice');
         if (!selectedDevice) {
            return -1;
         }
         if (selectedDevice.model_type === 'TALS' || selectedDevice.model_type === 'tals') {
            devices = this.get('sensorDevices');
         } else {
            devices = this.get('smartPlugs');
         }
         if (!devices) {
            return -1;
         }
         for (var i = 0; i < devices.get('length'); i++) {
            var device = devices.objectAt(i);
            if (device && device.uuid === selectedDevice.uuid) {
               return i;
            }
         }
      },
      checkAlarmThresholds: function checkAlarmThresholds() {
         var self = this;
         var alarms = this.get('model.alarms');
         var subValuesCurrent = self.get('subValuesCurrent');
         var subValuesHumidity = self.get('subValuesHumidity');
         var subValuesTemperature = self.get('subValuesTemperature');
         var subValuesTemperatureAUX = self.get('subValuesTemperatureAUX');
         var subValuesTemperatureEXT = self.get('subValuesTemperatureEXT');
         var subValuesBatterySOC = self.get('subValuesBatterySOC');

         console.log("DASH: Check Alarm Thresholds");
         subValuesCurrent.clear();
         subValuesHumidity.clear();
         subValuesTemperature.clear();
         subValuesTemperatureAUX.clear();
         subValuesTemperatureEXT.clear();
         subValuesBatterySOC.clear();

         /*subValuesCurrent = [];
         subValuesHumidity = [];
         subValuesTemperature = [];
         subValuesTemperatureAUX = [];
         subValuesTemperatureEXT = [];
         subValuesBatterySOC = [];*/

         if (alarms) {
            alarms.forEach(function (alarm) {
               if (alarm) {
                  var threshold = alarm.get('threshold');
                  if (threshold > -242.0 && threshold <= 100.0) {
                     var sensorType = alarm.get('sensor_type');
                     switch (sensorType) {
                        case 0:
                           subValuesCurrent.push(threshold);
                           break;
                        case 1:
                           subValuesHumidity.push(threshold);
                           break;
                        case 2:
                           subValuesTemperature.push(threshold);
                           break;
                        case 3:
                           subValuesTemperatureAUX.push(threshold);
                           break;
                        case 4:
                           subValuesTemperatureEXT.push(threshold);
                           break;
                        case 5:
                           subValuesBatterySOC.push(threshold);
                           break;
                        default:
                           break;
                     }
                  }
               }
            });
         }
      },
      setDateRangeLast30Days: function setDateRangeLast30Days() {
         var finish = new Date();
         var finishMinus30 = new Date();
         finishMinus30.setDate(finishMinus30.getDate() - 30);

         var sensorlogs = this.get('sensorLogsJSON');
         if (sensorlogs) {
            var first = sensorlogs.objectAt(0);
            var last = sensorlogs.objectAt(sensorlogs.get('length') - 1);

            // Check the date of the last log ...
            if (last) {
               var lastDate = new Date(last.created);
               if (lastDate <= finish) {
                  this.set('finishDate', lastDate);
               } else {
                  this.set('finishDate', finish);
               }
            } else {
               this.set('finishDate', finish);
            }
            // Refresh the last date ...
            finish = this.get('finishDate');
            finishMinus30 = new Date(finish);
            finishMinus30.setDate(finishMinus30.getDate() - 30);

            // Check the date of the first log ...
            if (first) {
               var firstDate = new Date(first.created);
               if (firstDate >= finishMinus30 && firstDate <= finish) {
                  this.set('startDate', firstDate);
               } else {
                  this.set('startDate', finishMinus30);
               }
            } else {
               this.set('startDate', finishMinus30);
            }
         }
      },
      deselectDevice: function deselectDevice() {
         this.set('homeController.selectedDeviceIndex', -1); // model.appsettings.device_index', -1);
         this.set('selectedDevice', null);
         //this.set('homeController.selectedDeviceID', null);
      },
      selectFirstDevice: function selectFirstDevice() {
         console.log("DASH: Selecting FIRST Device");
         var tabIndex = this.get('selectedTabIndex');
         if (tabIndex === 0) {
            var devices = this.get('smartPlugs');
            if (devices) {
               var device = devices.objectAt(0);
               this.set('homeController.selectedDeviceIndex', 0); // model.appsettings.device_index', 0);
               this.set('selectedDevice', device);
               //this.set('homeController.selectedDeviceID', device.get('id'));     // Get Device ID from MODEL Device
            } else {
                  this.deselectDevice();
               }
         } else if (tabIndex === 1) {
            var devices = this.get('sensorDevices');
            if (devices) {
               var device = devices.objectAt(0);
               this.set('homeController.selectedDeviceIndex', 0); // model.appsettings.device_index', 0);
               this.set('selectedDevice', device);
               //this.set('homeController.selectedDeviceID', device.get('id'));     // Get Device ID from MODEL Device
            } else {
                  this.deselectDevice();
               }
         } else {
            this.deselectDevice();
         }
      },
      getSelectedDevice: function getSelectedDevice() {
         var self = this;
         var found = false;
         var homeController = self.get('homeController');
         var uuid = homeController.get('selectedDeviceUUID');
         var tabIndex = this.get('selectedTabIndex');

         console.log("DASH: Getting Device UUID: %s", uuid);
         if (!uuid) {
            self.selectFirstDevice();
            return;
         }
         // Need to check against UUID because device list uses JSON objects
         /*let devices = this.get('model.devices');
         for (let i = 0; i < devices.get('length'); i++) {
            let dvc = devices.objectAt(i);
            if (dvc && dvc.get('uuid') === uuid) {
               let dvcID = dvc.get('id');
               self.set('homeController.selectedDeviceID', dvcID);
               console.log('DEVICES: Found Device ID: %s', dvcID);
               break;
            }
         }*/
         if (tabIndex === 0) {
            var devices = this.get('smartPlugs');
            if (devices) {
               devices.forEach(function (device, index) {
                  if (device && device.uuid === uuid) {
                     found = true;
                     console.log('DEVICES: Found device UUID: %s, [index: %d]', uuid, index);
                     homeController.set('selectedDeviceIndex', index);
                     self.set('selectedDevice', device);
                  }
               });
            }
            if (found === false) {
               this.selectFirstDevice();
            }
         } else if (tabIndex === 1) {
            var devices = this.get('sensorDevices');
            if (devices) {
               devices.forEach(function (device, index) {
                  if (device && device.uuid === uuid) {
                     found = true;
                     console.log('DEVICES: Found device UUID: %s, [index: %d]', uuid, index);
                     homeController.set('selectedDeviceIndex', index);
                     self.set('selectedDevice', device);
                  }
               });
            }
            if (found === false) {
               this.selectFirstDevice();
            }
         } else {
            self.deselectDevice();
         }
      },
      /*checkSelectedDevice: function() {
         let dvcIndex = this.get('model.appsettings.device_index');
         console.log("Device Index: %d", dvcIndex);
         let tabIndex = this.get('selectedTabIndex');
          if (dvcIndex === undefined || dvcIndex === null) {
            dvcIndex = 0;
            this.set('model.appsettings.device_index', 0);
         }
         if (tabIndex === 0) {
            let devices = this.get('smartPlugs');
            if (devices) {
               if (dvcIndex >= 0 && dvcIndex < devices.get('length')) {
                  let dvc = devices.objectAt(dvcIndex);
                  if (dvc.model_type !== 'TALS' && dvc.model_type !== 'tals') {
                     this.set('selectedDevice', dvc);
                  } else {
                     // Select first Device in the list ...
                     this.set('model.appsettings.device_index', 0);
                     this.set('selectedDevice', devices.objectAt(0));
                  }
               } else {
                  // Select first Device in the list ...
                  this.set('model.appsettings.device_index', 0);
                  this.set('selectedDevice', devices.objectAt(0));
               }
            }
         } else if (tabIndex === 1) {
            let devices = this.get('sensorDevices');
            if (devices) {
               if (dvcIndex >= 0 && dvcIndex < devices.get('length')) {
                  let dvc = devices.objectAt(dvcIndex);
                  // TODO: Remove GROUP check for RELEASE
                  if (dvc.group === 'TALS' || dvc.model_type === 'TALS' || dvc.model_type === 'tals') {
                     this.set('selectedDevice', dvc);
                  } else {
                     // Select first Device in the list ...
                     this.set('model.appsettings.device_index', 0);
                     this.set('selectedDevice', devices.objectAt(0));
                  }
               } else {
                  // Select first Device in the list ...
                  this.set('model.appsettings.device_index', 0);
                  this.set('selectedDevice', devices.objectAt(0));
               }
            }
         } else {
            this.set('selectedDevice', null);
         }
      },*/
      refreshSelectedDevice: function refreshSelectedDevice() {
         console.log('DASH: Refreshing Selected Device ...');
         var device = this.get('selectedDevice');
         var devices = this.get('model.devices');
         if (device && devices) {
            for (var i = 0; i < devices.get('length'); i++) {
               var dvc = devices.objectAt(i);
               if (dvc && dvc.get('uuid') === device.uuid) {
                  dvc.reload();
               }
            }
         }
      },
      refreshSensorLogs: function refreshSensorLogs() {
         console.log('Refreshing Sensor Logs ...');
         var sensorLogs = this.get('model.sensorLogs');
         if (sensorLogs) {
            for (var i = 0; i < sensorLogs.get('length'); i++) {
               var log = sensorLogs.objectAt(i);
               if (log) {
                  log.reload();
               }
            }
         }
      },
      reloadDevices: function reloadDevices() {
         console.log('DASH: Reloading Models ...');
         this.get('model').reload();
         this.setDeviceID();
      },
      reloadSensorLogs: function reloadSensorLogs() {
         console.log('DASH: Reloading Sensor Logs ...');
         var sensorLogs = this.get('model.sensorLogs');
         if (sensorLogs) {
            sensorLogs.reload();
         }
      },
      reloadLastSensorLog: function reloadLastSensorLog() {
         console.log('DASH: Reloading Last Sensor Log ...');
         this.get('model.lastSensorLog').reload();
      },
      reloadSubscriptions: function reloadSubscriptions() {
         console.log('DASH: Reloading Subscriptions ...');
         var subs = this.get('model.subscriptions');
         subs.reload();
         /*if (subs) {
            for (let s = 0; s < subs.get('length'); s++) {
               let sub = subs.objectAt(s);
               if (sub) {
                  sub.reload();
               }
            }
         }*/
      },
      resetButton: function resetButton(text) {
         var btn = _ember['default'].$('#dxButtonContainer').dxButton('instance');
         if (btn) {
            btn.option('text', text);
            btn.option('disabled', false);
            if (btn.loadingIndicator) {
               btn.loadingIndicator.option('visible', false);
            }
         }
      },
      refreshPage: function refreshPage() {
         this.refreshSelectedDevice();
         /*this.refreshSensorLogs();
         this.reloadLastSensorLog();*/
         this.updateProperties();
         this.checkAlarmThresholds();
      },
      reloadPage: function reloadPage() {
         this.reloadDevices();
         this.reloadSensorLogs();
         this.reloadLastSensorLog();
         this.reloadSubscriptions();
         this.updateProperties();
         this.checkAlarmThresholds();
      },
      startUpdateTimer: function startUpdateTimer() {
         _ember['default'].run.later(this, function () {
            if (this.get('enableUpdates')) {
               this.refreshPage();
               this.startUpdateTimer();
            }
         }, 180000); // every 3 minutes
      },
      /*_valueChanged: function () {
         let value = this.get('statusRange');
         console.log("Value Changed: " + value);
         //this.setStatusRange(value);
      }.observes('statusRange'),*/
      /*setStatusRange: function(value) {
         Ember.$('#dxTextContainerRange').text('Range: [' +
                 this.formatDate(value[0], { time:'short' }) +
                 ' to ' + this.formatDate(value[1], { time:'short' }) + ']');
      },*/
      /*formatDate: function(date, format) {
         if (typeof(format) !== 'string') {
            format = 'MMMM DD, YYYY';
         }
         if (date === undefined || date === null) {
            return moment().format(format);
         }
         return moment(date).format(format);
      },*/

      updateProperties: function updateProperties() {
         this.set('totalDevices', this.getTotalDevices());
         this.set('maxDevices', this.getMaxDevices());
         this.set('devicesOnline', this.getNumDevices(true));
         this.set('devicesOffline', this.getNumDevices(false));
         this.set('powerOutages', this.getTotalOutages());
         this.set('totalSMS', this.getTotalSMS());
         this.set('smsRemaining', this.getTotalCredits());
         this.set('accountStatus', this.getAccountStatus());
         this.set('current', this.getSensorValue('current'));
         this.set('humidity', this.getSensorValue('humidity'));
         this.set('temperature', this.getSensorValue('temperature'));
         this.set('temperature_aux', this.getSensorValue('temperature_aux'));
         this.set('temperature_ext', this.getSensorValue('temperature_ext'));
         this.set('battery_millivolts', this.getSensorValue('battery_millivolts'));
         this.set('battery_volts', this.getSensorValue('battery_volts'));
         this.set('battery_soc', this.getSensorValue('battery_soc'));
         this.set('battery_image', this.getBatteryImageSrc());
      },
      getAccountStatus: function getAccountStatus() {
         var status = "In-Active";
         var cards = this.get('model.cards');
         var subscriptions = this.get('model.subscriptions');

         if (!cards || cards.get('length') <= 0) {
            status = 'No Card';
            this.set('accountStatusClass', 'label-danger');
         } else if (subscriptions) {
            var canceledCount = 0;
            var unpaid = false;
            var past_due = false;
            var num = subscriptions.get('length');

            for (var i = 0; i < num; i++) {
               var sub = subscriptions.objectAt(i);

               if (sub) {
                  var subStatus = sub.get('status');
                  if (subStatus === 'unpaid') {
                     unpaid = true;
                  } else if (subStatus === 'past_due') {
                     past_due = true;
                  } else if (subStatus === 'canceled') {
                     canceledCount++;
                  }
               }
            }
            if (unpaid) {
               status = 'Unpaid';
               this.set('accountStatusClass', 'label-warning');
            } else if (past_due) {
               status = 'Past Due';
               this.set('accountStatusClass', 'label-danger');
            } else if (canceledCount >= num) {
               status = 'Canceled';
               this.set('accountStatusClass', 'label-danger');
            } else {
               status = 'Active';
               this.set('accountStatusClass', 'label-success');
            }
            //console.log("Account Status: " + status + " Length: " + num + " Canceled Count: " + canceledCount);
         }
         return status;
      },
      getBatteryImageSrc: function getBatteryImageSrc() {
         //let soc = this.get('model.lastSensorLog.battery_soc');
         var device = this.get('selectedDevice');
         var soc = 0;

         if (device) {
            soc = device.battery_soc;
         }
         //console.log('SOC: %s', soc);
         var isCharging = this.get('battery_charging');
         var imageURL = "/assets/icons/battery/battery-00.png";

         if (soc === undefined || isNaN(soc) || soc < 0) {
            return imageURL;
         }
         if (soc >= 0 && soc < 10) {
            imageURL = isCharging ? "/assets/icons/battery/battery-charging-00.png" : "/assets/icons/battery/battery-00.png";
         } else if (soc >= 10 && soc < 30) {
            imageURL = isCharging ? "/assets/icons/battery/battery-charging-020.png" : "/assets/icons/battery/battery-020.png";
         } else if (soc >= 30 && soc < 50) {
            imageURL = isCharging ? "/assets/icons/battery/battery-charging-040.png" : "/assets/icons/battery/battery-040.png";
         } else if (soc >= 50 && soc < 70) {
            imageURL = isCharging ? "/assets/icons/battery/battery-charging-060.png" : "/assets/icons/battery/battery-060.png";
         } else if (soc >= 70 && soc < 90) {
            imageURL = isCharging ? "/assets/icons/battery/battery-charging-080.png" : "/assets/icons/battery/battery-080.png";
         } else {
            imageURL = isCharging ? "/assets/icons/battery/battery-charging-100.png" : "/assets/icons/battery/battery-100.png";
         }
         return imageURL;
      },
      getSensorValue: function getSensorValue(valueType) {
         var device = this.get('selectedDevice');
         var nullValue = -999.9;
         var precision = 1;
         var value = -999.9;

         switch (valueType) {
            case 'current':
               if (device) {
                  value = device.current;
               }
               nullValue = 0.0;
               precision = 2;
               break;
            case 'humidity':
               if (device) {
                  value = device.humidity;
               }
               nullValue = 0.0;
               break;
            case 'temperature':
               if (device) {
                  value = device.temperature;
               }
               break;
            case 'temperature_aux':
               if (device) {
                  value = device.temperature_aux;
               }
               break;
            case 'temperature_ext':
               if (device) {
                  value = device.temperature_ext;
               }
               break;
            case 'battery_soc':
               if (device) {
                  value = device.battery_soc * 1000.0 / 1000.0;
               }
               break;
            case 'battery_millivolts':
               if (device) {
                  value = device.battery_volts * 1000.0 / 1000.0;
               }
               nullValue = 0.0;
               break;
            case 'battery_volts':
               if (device) {
                  value = device.battery_volts / 1000.0;
               }
               nullValue = 0.0;
               break;
         }
         if (value === undefined || isNaN(value) || value <= nullValue) {
            return nullValue;
         } else {
            return value.toFixed(precision);
         }
      },
      getTotalDevices: function getTotalDevices() {
         var devices = this.get('model.devices');
         if (devices) {
            return devices.get('length');
         } else {
            return 0;
         }
      },
      getMaxDevices: function getMaxDevices() {
         // NOTE: Always need at least 1 to render Gauges correctly ...
         var total = this.get('totalDevices');
         if (total <= 0) {
            return 1;
         } else {
            //return (parseInt((total - 1) / 10) + 1) * 10;
            return total;
         }
      },
      getNumDevices: function getNumDevices(online) {
         // Get number of devices currently ONLINE or OFFLINE ...
         var devices = this.get('smartPlugs');
         var num = 0;

         if (devices && devices.length > 0) {
            for (var i = 0; i < devices.length; i++) {
               var device = devices[i];
               if (device && device.online === online) {
                  num++;
               }
            }
         }
         return num;
      },
      getTotalOutages: function getTotalOutages() {
         // Get total number of 'Outage' logs (i.e. device was OFFLINE) ...
         var outages = 0;
         var devices = this.get('model.devices');
         if (devices) {
            for (var i = 0; i < devices.get('length'); i++) {
               var dvc = devices.objectAt(i);
               if (dvc && dvc.get('num_outages') > 0) {
                  outages = outages + dvc.get('num_outages');
               }
            }
         }
         return outages;
      },
      getTotalSMS: function getTotalSMS() {
         var totalDevices = this.get('totalDevices');
         var total = 1;

         if (totalDevices > 0) {
            total = totalDevices * _frontendConfigEnvironment['default'].APP.MAX_SMS_CREDITS;
         }
         return total;
      },
      getTotalCredits: function getTotalCredits() {
         var credits = 0;
         var subscriptions = this.get('model.subscriptions');

         if (subscriptions) {
            for (var i = 0; i < subscriptions.get('length'); i++) {
               var sub = subscriptions.objectAt(i);
               if (sub && this.isSubscriptionValid(sub.get('status'))) {
                  credits = credits + sub.get('credits');
               }
            }
         }
         return credits;
      },
      isSubscriptionValid: function isSubscriptionValid(status) {
         if (!status) {
            return false;
         } else if (status === 'trialing' || status === 'active' || status === 'past_due') {
            return true;
         } else if (status === 'canceled' || status === 'unpaid') {
            return false;
         } else {
            return false;
         }
      },
      processLogs: function processLogs(device) {
         var self = this;
         var logs = device.get('logs');

         if (logs) {
            // Convert Device Logs to JSON object array ...
            //console.log("Processing Logs...");
            var logsJSON = logs.map(function (log) {
               return log.toJSON();
            });

            if (logsJSON) {
               var dataArray = [];
               // Loop through all Logs.
               // Status needs to be 0 (false) to 100 (true) ...
               var lastStatus = undefined;
               for (var i = 0; i < logsJSON.get('length'); i++) {
                  var obj = logsJSON.objectAt(i);
                  var newJSON = undefined;
                  var dt = new Date(obj.created);
                  lastStatus = obj.status * 100;
                  newJSON = {
                     created: (0, _moment['default'])(dt).format("YYYY-MM-DD HH:mm:ss"),
                     status: lastStatus,
                     temperature: 0,
                     humidity: 0.0,
                     current: 0.0
                  };
                  dataArray.push(newJSON);
               }
               // Add one more dummy entry (with last log's status) so graph lines continue to NOW...
               var lastJSON = {
                  created: (0, _moment['default'])().format("YYYY-MM-DD HH:mm:ss"),
                  status: lastStatus,
                  temperature: 0,
                  humidity: 0.0,
                  current: 0.0
               };
               dataArray.push(lastJSON);
               self.updateDeviceLogs(dataArray);
            }
         }
         // Process Sensor Logs ...
         var sensorLogs = this.get('model.sensorLogs');
         if (sensorLogs) {
            var sensorLogsJSON = sensorLogs.map(function (log) {
               //let JSONLog = JSON.stringify(log);
               //console.log("Log: %s", JSONLog);
               return log.toJSON();
            });
            this.set('sensorLogsJSON', sensorLogsJSON);
            console.log("SensorLogs Processed");
         }
      },
      setDeviceBill: function setDeviceBill() {
         var device = this.get('selectedDevice');
         if (device) {
            var sub = device.subscription;
            if (sub) {
               //let period_start = moment(sub.get('current_period_start'));
               var period_end = (0, _moment['default'])(sub.get('current_period_end'));
               var d = period_end.diff((0, _moment['default'])(), 'days');
               //console.log('Start: ' + period_start.format("DD/MM/YYYY HH:mm:ss"));
               //console.log('End: ' + period_end.format("DD/MM/YYYY HH:mm:ss"));
               //console.log("Amount: " + JSON.stringify(sub.get('amount')));
               this.set('nextPayment', sub.get('amount'));
               this.set('daysDue', d);
            }
         }
      },
      setDeviceID: function setDeviceID() {
         var self = this;
         var homeController = self.get('homeController');
         var selectedDevice = this.get('selectedDevice');
         var devices = this.get('model.devices');

         if (!selectedDevice || !devices) {
            // There is NO selected device, or devices in the MODEL ...
            homeController.set('selectedDeviceUUID', null);
            homeController.set('selectedDeviceID', null);
            self.set('selectedDeviceID', null);
            self.set('dvc', null);
            console.log('DASH: No Device selected');
            return;
         }
         // Iterate through all devices in the MODEL ...
         for (var i = 0; i < devices.get('length'); i++) {
            var device = devices.objectAt(i);
            // Need to check against UUID because selected Device is a JSON object
            if (device && device.get('uuid') === selectedDevice.uuid) {
               var deviceID = device.get('id'); // Get Device ID from MODEL Device
               homeController.set('selectedDeviceUUID', device.get('uuid'));
               homeController.set('selectedDeviceID', deviceID);
               self.set('selectedDeviceID', deviceID);
               self.set('dvc', deviceID);
               console.log('DASH: Set Device ID: %s', deviceID);

               self.processLogs(device);
               break;
            }
         }
      },
      copyDevice: function copyDevice(dvc, dvcJ) {
         if (dvc && dvcJ) {
            // copy properties from dvcJ to dvc ...
            dvc.name = dvcJ.name;
            dvc.mac_address = dvcJ.mac_address;
            dvc.model_type = dvcJ.model_type;
            dvc.group = dvcJ.group;
            dvc.type = dvcJ.type;
            dvc.number = dvcJ.number;
            dvc.ping_sensitivity = dvcJ.ping_sensitivity;
            dvc.post_frequency = dvcJ.post_frequency;
            dvc.update_checks = dvcJ.update_checks;
            dvc.online = dvcJ.online;
            dvc.last_online = dvcJ.last_online;
            dvc.last_offline = dvcJ.last_offline;
            dvc.last_ping = dvcJ.last_ping;
            dvc.last_fw_update = dvcJ.last_fw_update;
            dvc.last_updated = dvcJ.last_updated;
            dvc.num_resets = dvcJ.num_resets;
            dvc.num_outages = dvcJ.num_outages;
            dvc.battery_soc = dvcJ.battery_soc;
            dvc.battery_volts = dvcJ.battery_volts;
            dvc.current = dvcJ.current;
            dvc.humidity = dvcJ.humidity;
            dvc.temperature = dvcJ.temperature;
            dvc.temperature_aux = dvcJ.temperature_aux;
            dvc.temperature_ext = dvcJ.temperature_ext;
            //dvc.subscription = dvcJ.subscription;
            if (dvc.settings && dvcJ.settings) {
               dvc.settings.comms_type = dvcJ.settings.comms_type;
               dvc.settings.show_sensors = dvcJ.settings.show_sensors;
               dvc.settings.stay_awake = dvcJ.settings.stay_awake;
               dvc.settings.ping_sensitivity = dvcJ.settings.ping_sensitivity;
               dvc.settings.post_frequency = dvcJ.settings.post_frequency;
               dvc.settings.created = dvcJ.settings.created;
               dvc.settings.last_updated = dvcJ.settings.last_updated;
            }
         }
      },
      updateJSONDevices: function updateJSONDevices() {
         // Convert devices to array of JSON objects for Data-Grid ...
         var devices = this.get('model').devices;
         if (devices && devices.get('length') > 0) {
            // Map model devices to JSON format ...
            var devicesJ = devices.map(function (d) {
               var sub = d.get('subscription');
               var settings = d.get('settings');
               var deviceJSON = d.toJSON();
               if (sub) {
                  deviceJSON.subscription = sub.get('content');
               }
               if (settings) {
                  deviceJSON.settings = settings.get('content');
               }
               return deviceJSON;
            });

            // Update Smart-Plugs ...
            var smartPlugs = this.get('smartPlugs');
            if (!smartPlugs) {
               smartPlugs = [];
               for (var i = 0; i < devicesJ.length; i++) {
                  var dvc = devicesJ.objectAt(i);
                  if (dvc.group !== 'TALS' && (dvc.model_type === undefined || dvc.model_type === null || dvc.model_type === 'POAS')) {
                     smartPlugs.push(dvc);
                  }
               }
               this.set('smartPlugs', smartPlugs);
            } else {
               for (var j = 0; j < smartPlugs.length; j++) {
                  var plug = smartPlugs.objectAt(j);
                  var foundPlug = false;
                  for (var k = 0; k < devicesJ.length; k++) {
                     var dvcJ = devicesJ.objectAt(k);
                     if (plug.uuid === dvcJ.uuid) {
                        this.copyDevice(plug, dvcJ);
                        foundPlug = true;
                        break;
                     }
                  }
                  if (!foundPlug) {
                     // Remove Smart-Plug from array ...
                     smartPlugs.splice(j, 1);
                  }
               }
            }
            // Update Sensor Devices ...
            var sensorDevices = this.get('sensorDevices');
            if (!sensorDevices) {
               sensorDevices = [];
               for (var r = 0; r < devicesJ.length; r++) {
                  var dvc = devicesJ.objectAt(r);
                  // TODO: Remove the group and name check for production ...
                  if (dvc.model_type === 'TALS' || dvc.group === 'TALS' || dvc.name.includes('TALS')) {
                     sensorDevices.push(dvc);
                  }
               }
               this.set('sensorDevices', sensorDevices);
            } else {
               for (var s = 0; s < sensorDevices.length; s++) {
                  var sensor = sensorDevices.objectAt(s);
                  var foundSensor = false;
                  for (var t = 0; t < devicesJ.length; t++) {
                     var dvcJ = devicesJ.objectAt(t);
                     if (sensor.uuid === dvcJ.uuid) {
                        this.copyDevice(sensor, dvcJ);
                        foundSensor = true;
                        break;
                     }
                  }
                  if (!foundSensor) {
                     // Remove Sensor Device from array ...
                     sensorDevices.splice(s, 1);
                  }
               }
            }
         } else {
            console.log("DASH: No Devices Found!");
            this.set('smartPlugs', null);
            this.set('sensorDevices', null);
            this.set('selectedDevice', null);
         }
      },
      updateDeviceLogs: function updateDeviceLogs(dataArray) {
         if (dataArray && dataArray.length > 0) {
            this.set('selectedDeviceLogs', dataArray);

            var dtStart = new Date();
            //let firstDay = new Date(dtStart.getFullYear(), dtStart.getMonth(), 1);  // Get first day of the month

            // If first log is more recent than a month ago, set start-date to
            // the first log creation date, else start-date should be a month ago.
            var aMonthAgo = new Date(new Date().setDate(dtStart.getDate() - 30));
            var firstLog = new Date(dataArray[0].created);
            if (firstLog > aMonthAgo) {
               dtStart = firstLog;
            } else {
               dtStart = aMonthAgo;
            }
            //let start = moment().subtract(30, 'days').format("YYYY-MM-DD HH:mm:ss");
            //let start = moment(dtStart).format("YYYY-MM-DD HH:mm:ss");
            //let finish = moment().add(10, 'minutes').format("YYYY-MM-DD HH:mm:ss");

            //Ember.$("#dxRangeContainer").dxRangeSelector('option', 'scale.startValue', start);
            //Ember.$("#dxRangeContainer").dxRangeSelector('option', 'scale.endValue', finish);
            //self.set('statusRange', [start, finish]);
         }
      },

      _devicesChanged: (function () {
         this.updateJSONDevices();
      }).observes('model.devices'),

      // Initial Device Logs will be from first Device (Model). Have to wait for promise.
      // This is needed for correct loading of graphs etc when page loads.
      //_deviceLogsChanged: function() {
      //this.setDeviceLogs();
      //}.observes('model.deviceLogs'),

      // Subsequent device Logs will be from selected device.
      _selectedDeviceChanged: (function () {
         var device = this.get('selectedDevice');
         if (device) {
            this.set('selectedDeviceName', device.name);
            console.log('DASH: Device Changed [Name: %s, UUID: %s]', device.name, device.uuid);
            _ember['default'].$("#dxRangeContainer").dxRangeSelector('option', 'title.text', device.name);
         } else {
            this.set('selectedDeviceName', '');
            console.log('DASH: Device Changed [NULL]');
            _ember['default'].$("#dxRangeContainer").dxRangeSelector('option', 'title.text', 'Unknown');
         }
         this.setDeviceID();
         this.setDeviceBill();
         //this.send('refreshModel');
      }).observes('selectedDevice'),

      _enableUpdatesChanged: (function () {
         if (this.get('enableUpdates') === true) {
            this.updateProperties();
            this.startUpdateTimer();
         }
      }).observes('enableUpdates'),

      _selectedTabIndexChanged: (function () {
         var tabIndex = this.get('selectedTabIndex');
         /*let settings = this.get('model.appsettings');
         if (settings) {
            if (settings.tab_index !== tabIndex) {
               settings.tab_index = tabIndex;
               //settings.save();
            }
         }*/
         this.set('homeController.selectedTabIndex', tabIndex);
         if (tabIndex === 0) {
            this.set('showPlugs', true);
         } else {
            this.set('showPlugs', false);
         }
         this.getSelectedDevice();
         /*if (settings) {
            settings.save();
         }*/
      }).observes('selectedTabIndex')
   });
});