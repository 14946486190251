define('frontend/components/dx-popup-admin', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      isInvalid: false,
      isError: false,
      errorMessage: null,
      result: false,
      email: null,
      password: null,
      attempts: 3,
      timeout: 30000,
      closeTimer: null,
      attributeBindings: ['email', 'result', 'attempts'],

      _initialize: (function () {
         var self = this;

         this.$("#dxPopupAdminContainer").dxPopup({
            fullScreen: false,
            closeOnOutsideClick: false,
            dragEnabled: false,
            showCloseButton: false,
            showTitle: true,
            minWidth: 320,
            maxWidth: 560,
            minHeight: 300,
            height: 'auto',
            maxHeight: 320,
            title: "Confirm Administrator Password",
            contentTemplate: function contentTemplate(contentElement) {
               self.drawTemplate(contentElement);
            },
            onHiding: function onHiding() {
               self.set('password', '');
               self.stopTimer();
            },
            /*onShowing: function() {
               if (self.get('result')) {
                  self.complete(true, false);
               }
            },*/
            onShown: function onShown() {
               self.resetPopup();
            },
            animation: {
               show: { type: "pop", from: { opacity: 1, scale: 0 }, to: { scale: 1 } },
               hide: { type: "pop", from: { scale: 1 }, to: { scale: 0 } }
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxPopupAdminContainer").remove();
      }).on('willDestroyElement'),

      hidePopup: function hidePopup() {
         var popup = _ember['default'].$("#dxPopupAdminContainer").dxPopup("instance");
         if (popup) {
            popup.hide();
         }
      },
      complete: function complete(result, logout) {
         this.set('result', result);
         this.sendAction('authComplete');
         this.hidePopup();
         if (logout) {
            this.get('authManager').reset();
         }
      },
      clearError: function clearError() {
         if (this.get('isError')) {
            this.set('errorMessage', null);
            this.set('isError', false);
            _ember['default'].$("#dxAdminPasswordContainer").dxValidator('instance').validate();
         }
      },
      drawTemplate: function drawTemplate(contentElement) {
         var self = this;
         contentElement.append("<div>");
         contentElement.append("<h4 class='text-center'>Please confirm your password to enable editing</h4>");
         contentElement.append("<div class='horiz-margin-md'><label class='input-label' for='dxAdminPassword'>Administrator Password</label></div>");
         contentElement.append("<div id='dxAdminPasswordContainer' class='bottom-margin-md horiz-margin-md'></div>");

         var textPassword = _ember['default'].$("#dxAdminPasswordContainer").dxTextBox({
            idAttr: 'dxAdminPassword',
            mode: 'password',
            placeholder: 'Administrator Password',
            hint: 'Please enter your Password',
            value: self.get('password'),
            onInput: function onInput() {
               self.clearError();
            },
            onValueChanged: function onValueChanged(e) {
               self.clearError();
               self.set('password', e.value);
            }
         }).dxValidator({
            validationGroup: 'passkeyGroup',
            validationRules: [{ type: 'required', message: 'Password is required' }, { type: 'custom', reevaluate: true, validationCallback: function validationCallback(options) {
                  if (self.get('isError')) {
                     options.rule.message = self.get('errorMessage');
                     return false;
                  } else {
                     options.rule.message = null;
                     return true;
                  }
               } }],
            onValidated: function onValidated(e) {
               try {
                  if (self.get('isDestroyed') || self.get('isDestroying')) {
                     return;
                  }
                  if (e.isValid) {
                     self.set('isInvalid', false);
                     self.$("#dxAdminPasswordContainer.dx-texteditor .dx-texteditor-container").addClass("dx-valid");
                  } else {
                     self.set('isInvalid', true);
                     self.$("#dxAdminPasswordContainer.dx-texteditor .dx-texteditor-container").removeClass("dx-valid");
                  }
               } catch (exp) {
                  console.log("Exception Error: " + exp);
               }
            }
         });
         contentElement.append(textPassword);
         contentElement.append("<h4 class='center'>Attempts Remaining: <span id='attemptsLeft'>" + self.get('attempts') + "</span></h4>");

         var buttons = _ember['default'].$("<div id='pop-admin-btn-container' class='row top-margin'></div>");
         contentElement.append(buttons);
         var leftContainer = _ember['default'].$("<div id='pop-admin-left-container' class='col-xs-4 col-xs-offset-2 col-sm-3 col-sm-offset-3'></div>");
         buttons.append(leftContainer);
         var rightContainer = _ember['default'].$("<div id='pop-admin-right-container' class='col-xs-4 col-sm-3'></div>");
         buttons.append(rightContainer);

         leftContainer.append("<div id='pop-admin-ok-container' class='btn-pwron-dx regular'></div>");
         var okButton = _ember['default'].$("#pop-admin-ok-container").dxButton({
            text: "OK",
            validationGroup: 'passkeyGroup',
            onClick: function onClick(e) {
               self.confirmAdmin(e.validationGroup);
            }
         });
         leftContainer.append(okButton);

         rightContainer.append("<div id='pop-admin-cancel-container' class='btn-pwron-dx regular'></div>");
         var cancelButton = _ember['default'].$("#pop-admin-cancel-container").dxButton({
            text: "Cancel",
            onClick: function onClick() {
               self.complete(false, false);
            }
         });
         rightContainer.append(cancelButton);
      },
      confirmAdmin: function confirmAdmin(group) {
         var self = this;
         var email = this.get('email');
         var pass = this.get('password');

         // Check that a value was actually entered ...
         var validResult = group.validate();
         if (validResult && validResult.isValid === false) {
            _ember['default'].$("#dxAdminPasswordContainer").dxTextBox('instance').focus();
            return;
         }
         // Try to Authenticate the password ...
         this.get('authManager').tryReAuthenticate(email, pass, function (response) {
            if (response && response.status === "true") {
               // Authentication passed ...
               self.complete(true, false);
            } else {
               var att = self.get('attempts');
               if (att === 0) {
                  // Attempts exhausted, so Logout ...
                  self.complete(false, true);
               } else {
                  // Decrement attempt count ...
                  att = att - 1;
                  self.set('attempts', att);
                  _ember['default'].$('#attemptsLeft').html(att);
                  // Validate wrong password ...
                  if (att > 0) {
                     self.set('errorMessage', "Wrong Password!");
                     self.set('isError', true);
                     validResult = group.validate();
                     if (validResult.isValid === false) {
                        _ember['default'].$("#dxAdminPasswordContainer").dxTextBox('instance').focus();
                     }
                  } else {
                     // Attempts exhausted, so Logout ...
                     self.complete(false, true);
                  }
               }
            }
         });
      },
      stopTimer: function stopTimer() {
         if (this.closeTimer) {
            clearTimeout(this.closeTimer);
         }
      },
      resetPopup: function resetPopup() {
         var self = this;
         this.stopTimer();
         this.set('result', false);
         this.set('password', '');
         this.set('attempts', 3);
         this.$('#attemptsLeft').html(this.get('attempts'));

         var validator = _ember['default'].$("#dxAdminPasswordContainer").dxValidator('instance');
         if (validator) {
            validator.reset();
            validator.focus();
         }
         this.closeTimer = setTimeout(function () {
            // Timed out so NOT authenticated (don't logout) ...
            self.complete(false, false);
         }, self.get('timeout'));
      },

      _disabledChanged: (function () {
         this.$("#dxPopupAdminContainer").dxPopup('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});