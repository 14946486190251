define('frontend/components/dx-button-login', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].DxButtonComponent = _ember['default'].Component.extend({
      _initialize: (function () {
         var self = this;
         var btnClass = this.get('class');
         var busyText = this.get('busy');
         var loadingIndicator = undefined;

         if (btnClass) {
            this.$("#dxButtonContainer").addClass(btnClass);
         }
         this.$("#dxButtonContainer").dxButton({
            disabled: this.get('disabled'),
            hint: this.get('hint'),
            icon: this.get('icon'),
            text: this.get('text'),
            validationGroup: this.get('group'),

            template: function template(data, container) {
               _ember['default'].$("<span class='" + data.icon + "'/><div class='dx-button-indicator'></div><span class='dx-button-text'>" + data.text + "</span>").appendTo(container);
               loadingIndicator = container.find(".dx-button-indicator").dxLoadIndicator({ visible: false }).dxLoadIndicator("instance");
            },
            onClick: function onClick(e) {
               // Disable button to avoid double-click ...
               this.option("disabled", true);

               // Validate the associated group ...
               if (e.validationGroup) {
                  var result = e.validationGroup.validate();
                  if (result && result.isValid) {
                     this.option("text", busyText); // This line (i.e. options) MUST go first for some reason
                     loadingIndicator.option("visible", true);
                     self.clickAction();
                  } else {
                     // Failed - Hide indicator and go back to old text ...
                     DevExpress.ui.notify("Validation Failed!", "error", 2500);
                     this.option("text", self.get('text'));
                     this.option("disabled", false);
                     loadingIndicator.option("visible", false);
                  }
               } else {
                  this.option("text", busyText);
                  loadingIndicator.option("visible", true);
                  self.clickAction();
               }
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxButtonContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxButtonContainer").dxButton('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});