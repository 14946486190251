define('frontend/initializers/on-error', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = {
      name: 'on-error',
      initialize: function initialize(app) {
         _ember['default'].onerror = function (error) {
            if (error.errors && error.errors[0].status === "401") {
               var auth = app.lookup("service:auth-manager");
               if (auth) {
                  auth.reset();
               }
            }
         };
      }
   };
});