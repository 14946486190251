define('frontend/controllers/users/setup', ['exports', 'ember', 'ember-cli-countries/enums/countries', 'ember-cli-countries/enums/calling-codes', 'frontend/types/timezones'], function (exports, _ember, _emberCliCountriesEnumsCountries, _emberCliCountriesEnumsCallingCodes, _frontendTypesTimezones) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      queryParams: ['s', 'newAccount'],
      s: null, // device hash
      newAccount: false, // flag for when setting up a new account when already logged in.
      countriesList: _emberCliCountriesEnumsCountries['default'].toKeyValueJson(),
      timezonesList: _frontendTypesTimezones['default'].timezones,
      cityPredictions: null,
      countryCode: 'AU',
      callingCode: '+61',
      phoneMask: '+61-0000-000-000',
      setupGroup: 'setupGroup',

      showMode: 'password',
      showPassword: false,
      showStrength: false,
      pwdStrength: 0,
      pwdStrengthLabel: "Undefined",
      pwdStrengthClass: 'password-blank',

      validationCode: '',
      enterDetails: true,
      enterPassword: true,
      enterBilling: false,
      hasAgreedToTerms: false,
      codeNotSent: true,
      isKeyInvalid: true,
      stripeToken: null,

      invalidInput: false,
      isEmailError: false,
      isPhoneError: false,
      isPasswordError: false,
      errorMessages: null,

      actions: {
         slide: function slide() {
            var selector = "#pass-guide";
            if (_ember['default'].$(selector).hasClass('in')) {
               _ember['default'].$(".menuClick").removeClass("glyphicon-menu-up");
               _ember['default'].$(".menuClick").addClass("glyphicon-menu-down");
            } else {
               _ember['default'].$(".menuClick").removeClass("glyphicon-menu-down");
               _ember['default'].$(".menuClick").addClass("glyphicon-menu-up");
            }
         },
         resendCode: function resendCode() {
            var self = this;
            this.set('codeNotSent', true);
            DevExpress.ui.notify("Sending Verification Code ...", "info", 2500);

            var phone = this.get('model.user.number');
            var callingCode = this.get('model.user.callingCode');

            this.get('authManager').resendTokenUser(callingCode, phone, function (data) {
               if (data === null) {
                  DevExpress.ui.notify("Server Failure! (No Response)", "error", 2500);
                  self.resetData(false);
               } else if (data.setup_status === "true") {
                  DevExpress.ui.notify("Verification Code Sent", "info", 2500);
                  self.set('codeNotSent', false);
                  self.set('isKeyInvalid', true);
               }
               self.resetButton('#btnResendCode', 'Resend Code');
            });
         },
         verifyCode: function verifyCode() {
            var self = this;
            DevExpress.ui.notify("Verifying Code ...", "info", 2500);
            var validation = this.get('model.user.validation');
            var code = this.get('validationCode');

            this.get('authManager').verifyTokenUser(validation, code, function (data) {
               if (data === null) {
                  DevExpress.ui.notify("Server Failure! (No Response)", "error", 2500);
                  self.resetData(false);
               } else if (data.setup_status === "true") {
                  (function () {
                     DevExpress.ui.notify("SMS code verified", "success", 2500);

                     // Authenticate the new user session ...
                     self.get('authManager').authenticate({
                        user_email: data.setup_email,
                        id: data._id,
                        token: data.setup_token
                     });

                     // Save user location. Need to be authorized to do findRecord (get) ...
                     var location = self.get('model.location');

                     self.store.findRecord('user', data._id, { reload: true }).then(function (newUser) {
                        if (newUser) {
                           console.log("User: " + JSON.stringify(newUser));

                           if (location) {
                              DevExpress.ui.notify("Saving your details...", "info", 2500);
                              location.set('user', newUser);
                              location.save().then(function (newLocation) {
                                 newUser.set('location', newLocation);
                                 newUser.save().then(function () {
                                    DevExpress.ui.notify("Your location details have been saved.", "success", 2500);
                                    self.set('isKeyInvalid', false);
                                    self.set('enterDetails', false);
                                    self.set('enterPassword', false);
                                    self.set('enterBilling', true);
                                 })['catch'](function (err) {
                                    DevExpress.ui.notify("Error saving the User: " + err, "error", 2500);
                                 });
                              })['catch'](function (err) {
                                 DevExpress.ui.notify("Error saving your Location: " + err, "error", 2500);
                              });
                           } else {
                              DevExpress.ui.notify("Error saving Location: Record not created!", "error", 2500);
                           }
                        }
                     })['catch'](function (err) {
                        DevExpress.ui.notify("Error reloading User data: " + err, "error", 2500);
                     });
                  })();
               } else {
                  DevExpress.ui.notify("Error verifying SMS code! Please repeat the verification process.", "error", 2500);
                  self.set('validationCode', '');
                  self.set('errorMessages', 'Error verifying SMS code!');
                  self.set('isCodeError', true);
                  _ember['default'].$("#dxCodeContainer").dxValidator('instance').focus();
                  _ember['default'].$("#dxCodeContainer").dxValidator('instance').validate();
               }
               self.resetButton('#btnVerifyCode', 'Verify Code');
            });
         },
         saveToken: function saveToken(token) {
            if (token) {
               this.set('stripeToken', token.id);
               DevExpress.ui.notify("Card Created Successfully", "success", 2500);
            } else {
               this.set('stripeToken', null);
               console.log("Unable to save Token");
               DevExpress.ui.notify("Error: Unable to save Token!", "error", 2500);
            }
         },
         setupBilling: function setupBilling() {
            var self = this;
            DevExpress.ui.notify("Verifying Billing Details ...", "info", 2500);

            if (this.get('stripeToken')) {
               var user_id = this.get('authManager').get('getUser.id');
               var host = this.store.adapterFor('application').get('host');
               var postUrl = [host, 'billing/cards/create', user_id].join('/');
               var data = { token: this.get('stripeToken') };

               _ember['default'].$.post({
                  url: postUrl,
                  crossDomain: true,
                  data: data,
                  success: function success() {
                     DevExpress.ui.notify("Billing Details Verified", "success", 2500);
                     self.set('stripeToken', null);
                     self.set('enterBilling', false);
                     self.transition();
                  },
                  error: function error(err) {
                     console.log('Billing Form Submit Failed: ' + err.responseText);
                     DevExpress.ui.notify("Verifying Billing Details Failed: " + err.responseText, "error", 3000);
                     var formElement = _ember['default'].$('#stp-payment-form');
                     if (formElement) {
                        formElement.removeClass('submitting');
                        formElement.removeClass('submitted');
                     }
                     //self.set('stripeToken', null);
                     self.resetButton('#btnSetupBilling', 'Confirm Billing');
                  }
               });
            } else {
               DevExpress.ui.notify("Error: Card hasn't been created yet!", "error", 2500);
               self.resetButton('#btnSetupBilling', 'Confirm Billing');
            }
         },
         submit: function submit() {
            var self = this;
            self.resetError();

            this.set('codeNotSent', true);

            // Save user data and find device ...
            var user = self.get('model.user');
            if (user) {
               user.save().then(function () {
                  self.set('enterPassword', false);
                  self.set('isKeyInvalid', true);
                  self.set('codeNotSent', false);
               }, function (data) {
                  if (data) {
                     if (data.errors) {
                        var msg = data.errors[0].detail;
                        var msgLower = msg.toLowerCase();

                        if (msgLower.includes('e-mail') || msgLower.includes('email') || msgLower.includes('account')) {
                           self.set('isEmailError', true);
                        } else if (msgLower.includes('phone') || msgLower.includes('mobile')) {
                           self.set('isPhoneError', true);
                        } else if (msgLower.includes('password')) {
                           self.set('isPasswordError', true);
                        }
                        self.set('errorMessages', msg);
                        DevExpress.ui.notify("Unable to create account: " + msg, "error", 2500);
                     }
                  }
               });
            } else {
               DevExpress.ui.notify("Unable to create account: Model not defined!", "error", 2500);
            }
            self.resetButton('#btnCreateAccount', 'Create Account');
         },
         loginRedirect: function loginRedirect() {
            this.transitionToRoute("users.login", { queryParams: { addDevice: this.get('s') } });
         }
      },
      resetData: function resetData(resetKey) {
         this.set('codeNotSent', true);
         this.set('invalidInput', false);
         this.set('enterBilling', false);
         if (resetKey) {
            this.set('isKeyInvalid', true);
            this.set('enterPassword', true);
         }
      },
      resetError: function resetError() {
         this.set('errorMessages', null);
         this.set('isEmailError', false);
         this.set('isPhoneError', false);
         this.set('isPasswordError', false);
      },
      resetButton: function resetButton(id, text) {
         var btn = _ember['default'].$(id + " > #dxButtonContainer").dxButton("instance");
         if (btn) {
            btn.option('text', text);
            btn.option('disabled', false);
            if (btn.loadingIndicator) {
               btn.loadingIndicator.option('visible', false);
            }
         }
      },
      transition: function transition() {
         var dvcID = this.get('s');

         if (dvcID) {
            this.transitionToRoute('users.finddevice', { queryParams: { s: dvcID } });
         } else {
            this.transitionToRoute('home.dashboard');
         }
      },
      callCodeToMask: function callCodeToMask(callingCode) {
         /*let callCode = "";
          // Need to escape all '0' and '9' characters for mask ...
         for (let i = 0; i < callingCode.length; i++) {
            let c = callingCode[i];
            if (c === '0' || c === '9') {
               c = ("\\" + c);
            }
            callCode = callCode + c;
         }
         let mask = (callCode + '-XXXX-XXX-XXX');*/
         var mask = 'XXXX-XXX-XXX';

         this.set('phoneMask', mask);
         this.set('callingCode', callingCode);
         this.set('model.user.callingCode', callingCode);
         return mask;
      },
      countryChanged: (function () {
         var countryCode = this.get('model.location.country');
         if (countryCode) {
            this.set('countryCode', countryCode);
            var callingCode = _emberCliCountriesEnumsCallingCodes['default'][countryCode];
            if (callingCode) {
               this.callCodeToMask(callingCode);
            }
         }
      }).observes('model.location.country'),

      cityChanged: (function () {
         var city = this.get('model.location.city');
         if (city) {
            city = city.trim();
            if (city.includes('City')) {
               city = city.replace('City', '').trim();
            }
            if (city.includes('Airport')) {
               city = city.replace('Airport', '').trim();
            }
            var timezoneObj = this.timezonesList.findBy('key', city);
            if (timezoneObj) {
               var timezone = timezoneObj.value;
               if (timezone) {
                  this.set('model.location.timezone', timezone);
               }
            }
         }
      }).observes('model.location.city'),

      showPasswordChanged: (function () {
         if (this.showPassword) {
            this.set('showMode', 'text');
         } else {
            this.set('showMode', 'password');
         }
      }).observes('showPassword'),

      pwdStrengthChanged: (function () {
         var str = this.get('pwdStrength');
         if (str === 0) {
            this.set('showStrength', false);
            this.set('pwdStrengthLabel', "Undefined");
            this.set('pwdStrengthClass', 'password-blank');
         } else if (str === 1) {
            this.set('pwdStrengthLabel', "Bad");
            this.set('pwdStrengthClass', 'password-bad');
         } else if (str === 2) {
            this.set('pwdStrengthLabel', "Weak");
            this.set('pwdStrengthClass', 'password-weak');
         } else if (str === 3) {
            this.set('pwdStrengthLabel', "Fair");
            this.set('pwdStrengthClass', 'password-fair');
         } else if (str === 4) {
            this.set('pwdStrengthLabel', "Medium");
            this.set('pwdStrengthClass', 'password-medium');
         } else if (str === 5) {
            this.set('pwdStrengthLabel', "Good");
            this.set('pwdStrengthClass', 'password-good');
         } else if (str === 6) {
            this.set('pwdStrengthLabel', "Strong");
            this.set('pwdStrengthClass', 'password-strong');
         } else if (str === 7) {
            this.set('pwdStrengthLabel', "Excellent");
            this.set('pwdStrengthClass', 'password-excellent');
         }
         if (str > 0) {
            this.set('showStrength', true);
         }
      }).observes('pwdStrength')
   });
});