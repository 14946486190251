define("frontend/controllers/application", ["exports", "ember"], function (exports, _ember) {

   /* Constant values for drop-down selection boxes */
   var deviceGroups = [{ key: "Default", value: "Default" }, { key: "Sensors", value: "Sensors" }];

   var deviceTypes = [{ key: 'Fridge', value: "Fridge" }, { key: 'Freezer', value: "Freezer" }, { key: 'Light', value: "Light" }, { key: 'Oven', value: "Oven" }, { key: 'Pump', value: "Pump" }, { key: 'Cool-Room', value: "Cool-Room" }];

   var commsTypes = [{ key: "Wi-FI", value: "Wi-Fi" }, { key: "Cellular", value: "Cellular" }];

   var updateChecks = [{ key: "Daily", value: "Daily" }, { key: "Weekly", value: "Weekly" }, { key: "Monthly", value: "Monthly" }];

   var alarmEquality = [{ key: -1, value: "Less-Than" }, { key: 0, value: "Equals" }, { key: 1, value: "Greater-Than" }];
   //{key: 2, value: "Less-Than or Equals"},
   //{key: 3, value: "Greater-Than or Equals"}];

   var notificationTypes = [{ key: "SMS", value: "SMS" }, { key: "Email", value: "E-mail" }, { key: "Both", value: "SMS + E-mail" }];

   var notificationStates = [{ key: 0, value: "Normal" }, { key: 1, value: "Notified" }, { key: 2, value: "Cleared" }];

   var alarmRepeats = [{ key: 0, value: "Never" }, { key: 1, value: "Every 10 Min" }, { key: 2, value: "Every 30 Min" }, { key: 3, value: "Every Hour" }, { key: 4, value: "Every 3 Hours" }, { key: 5, value: "Every 6 Hours" }, { key: 6, value: "Every 12 Hours" }];

   var alarmSeverity = [{ key: 0, value: "Info" }, { key: 1, value: "Success" }, { key: 2, value: "Warning" }, { key: 3, value: "Error" }];

   var notifyCarriers = [{ key: 0, value: "None" }, // Notify in Web Application only
   { key: 1, value: "SMS" }, // Send SMS to Recipients
   { key: 2, value: "E-mail" }, // Send Email to Recipients
   { key: 4, value: "Push" }]; // Send Mobile Push Notifications

   var sensorTypes = [{ key: 0, value: "Current" }, { key: 1, value: "Humidity" }, { key: 2, value: "Temperature" }, { key: 3, value: "Temperature (AUX)" }, { key: 4, value: "Temperature (EXT)" }, { key: 5, value: "Battery Volts" }, { key: 6, value: "Battery SOC" }, { key: 7, value: "Battery Charger" }];

   var postFrequencies = [{ key: 0, label: "Fast", value: "5 min" }, { key: 1, label: "", value: "10 min" }, { key: 2, label: "", value: "15 min" }, { key: 3, label: "", value: "30 min" }, { key: 4, label: "", value: "1 hr" }, { key: 5, label: "", value: "2 hrs" }, { key: 6, label: "", value: "3 hrs" }, { key: 7, label: "Slow", value: "4 hrs" }];

   exports["default"] = _ember["default"].Controller.extend({
      authManager: _ember["default"].inject.service('auth-manager'),
      screen: _ember["default"].inject.service(),
      isValidated: false,
      isSetupMode: false,
      deviceGroups: deviceGroups,
      deviceTypes: deviceTypes,
      commsTypes: commsTypes,
      updateChecks: updateChecks,
      alarmEquality: alarmEquality,
      notificationTypes: notificationTypes,
      alarmRepeats: alarmRepeats,
      alarmSeverity: alarmSeverity,
      notifyCarriers: notifyCarriers,
      sensorTypes: sensorTypes,
      postFrequencies: postFrequencies,

      actions: {
         showTerms: function showTerms() {
            _ember["default"].$("#dxPopupTermsContainer").dxPopup("instance").show();
         },
         showPolicy: function showPolicy() {
            _ember["default"].$("#dxPopupPrivacyContainer").dxPopup("instance").show();
         },
         showEULA: function showEULA() {
            _ember["default"].$("#dxPopupEULAContainer").dxPopup("instance").show();
         },
         logOut: function logOut() {
            this.get('authManager').reset();
         }
      }
   });
});