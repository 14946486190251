define('frontend/components/dx-circular-gauge', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].DxGaugeComponent = _ember['default'].Component.extend({
      click: function click() {
         var act = this.get('clickAction');
         if (act && this.get('value')) {
            act();
         }
      },
      subValues: null,
      attributeBindings: ['value', 'maxValue', 'subValues'],

      _initialize: (function () {
         var self = this;
         this.$("#dxCircularGaugeContainer").dxCircularGauge({
            disabled: self.get('disabled'),
            size: {
               height: this.get('height'),
               width: this.get('width')
            },
            scale: {
               startValue: this.get('minValue'),
               endValue: this.get('maxValue'),
               tickInterval: 10,
               minorTickInterval: 2,
               /*label: {
                  useRangeColors: true,
                  customizeText: function (arg) {
                     return arg.valueText + self.get('units');
                  }
               },*/
               minorTick: {
                  color: "#9c9c9c",
                  visible: this.get('showTicks')
               },
               tick: {
                  color: "#9c9c9c",
                  visible: this.get('showTicks')
               }
            },
            tooltip: {
               enabled: true,
               font: {
                  size: 14,
                  family: 'Arial'
               },
               /*format: {
                  formatter: function() {
                     return self.get('hint') + ": " + self.get('value') + " " + self.get('units');
                  }
               },*/
               customizeTooltip: function customizeTooltip(arg) {
                  return {
                     text: self.get('hint') + " " + arg.value + " " + self.get('units')
                  };
               }
            },
            title: {
               text: this.get('title'),
               margin: {
                  bottom: 10
               },
               font: {
                  family: 'Arial',
                  size: 16,
                  weight: 600
               }
            },
            subtitle: {
               text: this.get('subTitle'),
               margin: {
                  bottom: 10
               }
            },
            rangeContainer: {
               orientation: 'inside',
               offset: 7,
               palette: this.get('palette'),
               width: 5,
               ranges: [{ startValue: -20, endValue: 0, color: '#87CEEB7F' }, // skyblue
               { startValue: 0, endValue: 10, color: '#4169E17F' }, // royalblue
               { startValue: 10, endValue: 20, color: '#48B04F7F' }, // PwrOn Light Green
               { startValue: 20, endValue: 30, color: '#228B227F' }, // forestgreen
               { startValue: 30, endValue: 40, color: '#FF8C007F' }, // darkorange
               { startValue: 40, endValue: 50, color: '#FF45007F' }, // orangered
               { startValue: 50, endValue: 70, color: '#DC143C7F' }, // crimson
               { startValue: 70, endValue: 90, color: '#FF00007F' }, // red
               { startValue: 90, endValue: 100, color: '#8B00007F' } // darkred
               ]
            },
            value: self.get('value'),
            //subvalues: [this.get('subValueMin'), this.get('subValueMax')],
            subvalues: self.get('subValues'),
            subvalueIndicator: {
               type: this.get('subValueType'),
               color: 'royalBlue',
               secondColor: '#48b04f',
               size: this.get('subValueSize'),
               text: {
                  font: {
                     size: 12,
                     family: 'Arial'
                  }
               }
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxCircularGaugeContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxCircularGaugeContainer").dxCircularGauge('option', 'disabled', this.get('disabled'));
      }).observes('disabled'),

      _valueChanged: (function () {
         this.$("#dxCircularGaugeContainer").dxCircularGauge('option', 'value', this.get('value'));
      }).observes('value', 'maxValue'),

      _subValuesChanged: (function () {
         console.log("GAUGE: subValues Changed");
         this.$("#dxCircularGaugeContainer").dxCircularGauge('option', 'subvalues', this.get('subValues'));
         //this.$("#dxCircularGaugeContainer").dxCircularGauge('instance').repaint();
      }).observes('subValues')
   });
});