define('frontend/routes/home/profile/administrator', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model() {
         var user_id = this.get('authManager').get('getUser.id');
         return this.store.findRecord('user', user_id).then(function (user) {
            return user;
         })['catch'](function () {
            return null;
         });
      },
      actions: {
         willTransition: function willTransition() {
            this.controllerFor('home.profile.administrator').resetToolbar();
         },
         didTransition: function didTransition() {
            var homeController = this.controllerFor('home');
            if (homeController) {
               homeController.set('title', 'Administrator Details');
               homeController.set('selectedMenuIndex', 3);
               homeController.set('subMenuIndex', 0);
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});