define('frontend/models/payment', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _emberDataRelationships) {
   exports['default'] = _emberData['default'].Model.extend({
      status: _emberData['default'].attr('string'), // pending, accepted, refused
      comment: _emberData['default'].attr('string'), // e.g. reason payment refused
      amount: _emberData['default'].attr('number', {
         defaultValue: 0.0
      }),
      created: _emberData['default'].attr('date', {
         defaultValue: function defaultValue() {
            return new Date();
         }
      }),
      user: _emberData['default'].attr(),
      bill: (0, _emberDataRelationships.belongsTo)('bill')
   });
});