define('frontend/controllers/home/profile/password', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      store: _ember['default'].inject.service(),
      queryParams: ['s'],
      s: null, // device hash
      passkeyGroup: 'passkeyGroup',
      set_password: null,
      confirm_password: null,
      adminResult: false,
      invalidInput: true,
      isEditing: false,
      isNotEditing: _ember['default'].computed.not('isEditing'),
      isPasswordError: false,
      errorMessages: null,
      showStrength: false,
      showPassword: false,
      showMode: 'password',
      pwdStrength: 0,
      pwdStrengthLabel: "Undefined",
      pwdStrengthClass: 'password-blank',

      // Todo: Work out how to reset model on page refresh and 'back' navigation
      // Todo: Encrypt passwords before sending to back-end

      actions: {
         slide: function slide() {
            var selector = "#pass-guide";
            if (_ember['default'].$(selector).hasClass('in')) {
               _ember['default'].$(".menuClick").removeClass("glyphicon-menu-up");
               _ember['default'].$(".menuClick").addClass("glyphicon-menu-down");
            } else {
               _ember['default'].$(".menuClick").removeClass("glyphicon-menu-down");
               _ember['default'].$(".menuClick").addClass("glyphicon-menu-up");
            }
         },
         authComplete: function authComplete() {
            this.set('isEditing', this.get('adminResult'));
         },
         edit: function edit() {
            this.showAdminPopup();
         },
         cancel: function cancel() {
            this.resetToolbar();
         },
         save: function save() {
            var self = this;
            var spass = this.get('set_password');
            var cpass = this.get('confirm_password');

            if (spass && cpass) {
               if (spass !== cpass) {
                  this.set('errorMessages', 'Passwords do NOT match!');
                  this.set('isPasswordError', true);
               } else {
                  self.saveModel(spass, cpass);
               }
            } else {
               this.set('errorMessages', 'Passwords are not valid!');
               this.set('isPasswordError', true);
            }
         },
         goBack: function goBack() {
            this.transitionToRoute("home.profile.address");
         }
      },
      resetToolbar: function resetToolbar() {
         this.clearPasswords();
         this.clearErrors();
         this.set('isEditing', false);
      },
      clearErrors: function clearErrors() {
         this.set('isPasswordError', false);
         this.set('errorMessages', null);
      },
      clearPasswords: function clearPasswords() {
         this.set('set_password', null);
         this.set('confirm_password', null);
         this.set('showPassword', false);
         this.set('pwdStrength', 0);
      },
      showAdminPopup: function showAdminPopup() {
         if (this.get('adminResult')) {
            this.set('isEditing', true);
         } else {
            _ember['default'].$("#dxPopupAdminContainer").dxPopup("instance").show();
         }
      },
      saveModel: function saveModel(spass, cpass) {
         var self = this;
         var result = DevExpress.validationEngine.validateGroup(this.get('passkeyGroup'));

         if (result && result.isValid) {
            DevExpress.ui.notify("Saving New Password ...", "info", 2500);
            var model = this.get('model');
            if (model) {
               this.set('model.set_password', spass);
               this.set('model.confirm_password', cpass);

               model.save().then(function () {
                  self.resetToolbar();
                  DevExpress.ui.notify("New Password Saved", "success", 2500);
               })['catch'](function (error) {
                  var errMsg = error.errors[0].detail;
                  var errMsgLower = errMsg.toLowerCase();

                  if (errMsgLower.includes('passkey') || errMsgLower.includes('password')) {
                     self.set('isPasswordError', true);
                  }
                  self.set('errorMessages', errMsg);
                  DevExpress.ui.notify("Error Saving Password: " + errMsg, "error", 2500);
               });
            } else {
               DevExpress.ui.notify("Error Saving Password: No User Found!", "error", 2500);
            }
         }
      },
      showPasswordChanged: (function () {
         if (this.showPassword) {
            this.set('showMode', 'text');
         } else {
            this.set('showMode', 'password');
         }
      }).observes('showPassword'),

      pwdStrengthChanged: (function () {
         var str = this.get('pwdStrength');
         if (str === 0) {
            this.set('showStrength', false);
            this.set('pwdStrengthLabel', "Undefined");
            this.set('pwdStrengthClass', 'password-blank');
         } else if (str === 1) {
            this.set('pwdStrengthLabel', "Bad");
            this.set('pwdStrengthClass', 'password-bad');
         } else if (str === 2) {
            this.set('pwdStrengthLabel', "Weak");
            this.set('pwdStrengthClass', 'password-weak');
         } else if (str === 3) {
            this.set('pwdStrengthLabel', "Fair");
            this.set('pwdStrengthClass', 'password-fair');
         } else if (str === 4) {
            this.set('pwdStrengthLabel', "Medium");
            this.set('pwdStrengthClass', 'password-medium');
         } else if (str === 5) {
            this.set('pwdStrengthLabel', "Good");
            this.set('pwdStrengthClass', 'password-good');
         } else if (str === 6) {
            this.set('pwdStrengthLabel', "Strong");
            this.set('pwdStrengthClass', 'password-strong');
         } else if (str === 7) {
            this.set('pwdStrengthLabel', "Excellent");
            this.set('pwdStrengthClass', 'password-excellent');
         }
         if (str > 0) {
            this.set('showStrength', true);
         }
      }).observes('pwdStrength')
   });
});