define('frontend/components/recipient-list-item', ['exports', 'ember', 'ember-cli-countries/enums/calling-codes'], function (exports, _ember, _emberCliCountriesEnumsCallingCodes) {

   var alarmTypes = [{ key: "SMS", value: "SMS" }, { key: "Email", value: "E-mail" }, { key: "Both", value: "SMS + E-mail" }];

   var alarmRepeats = [{ key: 0, value: "Never" }, { key: 1, value: "Every 10 Min" }, { key: 2, value: "Every 30 Min" }, { key: 3, value: "Every Hour" }, { key: 4, value: "Every 3 Hours" }, { key: 5, value: "Every 6 Hours" }, { key: 6, value: "Every 12 Hours" }];

   exports['default'] = _ember['default'].Component.extend({
      classNames: ['list-group-item'],
      isNewRecipient: false,
      canEdit: false,
      isExpanded: false,
      isEditing: false,
      isNotEditing: _ember['default'].computed.not('isEditing'),
      invalidInput: true,
      callCodesList: _emberCliCountriesEnumsCallingCodes['default'].toKeyValueJson(),
      recipientsGroup: 'recipientsGroup',
      alarmTypeList: alarmTypes,
      alarmRepeatList: alarmRepeats,

      recFirstName: null,
      recLastName: null,
      recCallingCode: '+61',
      recPhone: null,
      recEmail: null,
      recAlarmType: 'SMS',
      recAlarmRepeat: 2,

      isPhoneError: false,
      phoneErrorMessage: null,

      recNumber: _ember['default'].computed(function () {
         return this.get('recIndex') + 1;
      }),

      actions: {
         edit: function edit() {
            this.set('isEditing', true);
            this.set('isExpanded', true);
         },
         cancel: function cancel() {
            this.set('isEditing', false);
            this.set('isExpanded', false);
            this.sendAction('cancel');
         },
         save: function save() {
            var self = this;
            DevExpress.ui.notify("Validating Recipient Details...", "info", 1000);
            var result = DevExpress.validationEngine.validateGroup(this.get('recipientsGroup'));

            if (result && result.isValid && !self.checkPhoneExists()) {
               self.set('isEditing', false);
               self.sendAction('save');
            }
         },
         remove: function remove() {
            //console.log("Rec Number: " + this.get('recNumber') + ", is New: " + this.isNewRecipient);
            if (this.get('recNumber') > 1 && this.isNewRecipient === false) {
               this.confirmDelete();
            }
         },
         slide: function slide(number) {
            if (_ember['default'].$("#item-recipient" + number).hasClass('in')) {
               _ember['default'].$("#header-recipient" + number + " .menuClick").removeClass("glyphicon-menu-up");
               _ember['default'].$("#header-recipient" + number + " .menuClick").addClass("glyphicon-menu-down");
               _ember['default'].$("#header-recipient" + number + " .lg-header-status").removeClass("hide");
            } else {
               _ember['default'].$("#header-recipient" + number + " .menuClick").removeClass("glyphicon-menu-down");
               _ember['default'].$("#header-recipient" + number + " .menuClick").addClass("glyphicon-menu-up");
               _ember['default'].$("#header-recipient" + number + " .lg-header-status").addClass("hide");
            }
         }
      },
      checkPhoneExists: function checkPhoneExists() {
         var recipients = this.get('recs');
         var recIndex = this.get('recIndex');
         var newPhone = this.get('recPhone');
         var found = false;

         if (recipients) {
            recipients.forEach(function (recipient, index) {
               var phone = recipient.get('number');
               if (index !== recIndex && phone === newPhone) {
                  found = true;
               }
            });
         }
         if (found === true) {
            var msg = "Another Recipient is already using this mobile number!";
            this.set('phoneErrorMessage', msg);
            this.set('isPhoneError', true);
            DevExpress.ui.notify(msg, "error", 2500);
         }
         return found;
      },
      confirmDelete: function confirmDelete() {
         var self = this;
         var result = DevExpress.ui.dialog.confirm("Are you sure you want to delete this Recipient?", "Confirm Delete");
         result.done(function (dialogResult) {
            if (dialogResult) {
               (function () {
                  DevExpress.ui.notify("Removing Recipient...", "info", 2500);
                  var recs = self.get('recs');
                  var rec = self.get('rec');
                  if (rec) {
                     rec.destroyRecord().then(function () {
                        DevExpress.ui.notify("Recipient Removed", "success", 2500);
                        recs.removeObject(rec);
                     })['catch'](function (error) {
                        DevExpress.ui.notify("Error Removing Recipient: " + error, "error", 2500);
                     });
                  }
                  self.set('isNewRecipient', false);
                  self.set('isEditing', false);
               })();
            }
         });
      },
      _canEditChanged: (function () {
         if (this.get('canEdit') === false) {
            this.send('cancel');
         }
      }).observes('canEdit')
   });
});