define('frontend/controllers/users/reset-password', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      screen: _ember['default'].inject.service(),
      newPassword: null,
      confirmPassword: null,
      invalidInput: true,
      isPasswordError: false,
      errorMessages: null,
      showStrength: false,
      showPassword: false,
      showMode: 'password',
      pwdStrength: 0,
      pwdStrengthLabel: "Undefined",
      pwdStrengthClass: 'password-blank',

      actions: {
         slide: function slide() {
            var selector = "#pass-guide";
            if (_ember['default'].$(selector).hasClass('in')) {
               _ember['default'].$(".menuClick").removeClass("glyphicon-menu-up");
               _ember['default'].$(".menuClick").addClass("glyphicon-menu-down");
            } else {
               _ember['default'].$(".menuClick").removeClass("glyphicon-menu-down");
               _ember['default'].$(".menuClick").addClass("glyphicon-menu-up");
            }
         },
         resetPasskey: function resetPasskey() {
            var self = this;
            var spass = this.get('newPassword');
            var cpass = this.get('confirmPassword');

            if (spass && cpass) {
               if (spass !== cpass) {
                  this.set('errorMessages', 'Passwords do NOT match!');
                  this.set('isPasswordError', true);
               } else {
                  self.saveModel(spass, cpass);
               }
            } else {
               this.set('errorMessages', 'Passwords are not valid!');
               this.set('isPasswordError', true);
            }
            self.resetButton('#btnResetPassword', 'Reset Password');
         }
      },
      clearPasswords: function clearPasswords() {
         this.set('newPassword', null);
         this.set('confirmPassword', null);
         this.set('showPassword', false);
         this.set('pwdStrength', 0);
      },
      clearErrors: function clearErrors() {
         this.set('isPasswordError', false);
         this.set('errorMessages', null);
      },
      resetButton: function resetButton(id, text) {
         var btn = _ember['default'].$(id + " > #dxButtonContainer").dxButton("instance");
         if (btn) {
            btn.option('text', text);
            btn.option('disabled', false);
            if (btn.loadingIndicator) {
               btn.loadingIndicator.option('visible', false);
            }
         }
      },
      saveModel: function saveModel(spass, cpass) {
         var self = this;
         var result = DevExpress.validationEngine.validateGroup(this.get('passkeyGroup'));

         if (result && result.isValid) {
            DevExpress.ui.notify("Resetting Password ...", "info", 2500);
            var model = this.get('model');
            if (model) {
               this.set('model.set_password', spass);
               this.set('model.confirm_password', cpass);

               model.save().then(function () {
                  self.clearPasswords();
                  self.clearErrors();
                  DevExpress.ui.notify("New Password Saved", "success", 2500);
                  self.transitionToRoute("users.login");
               })['catch'](function (error) {
                  //console.log("Error Saving Password: " + error);
                  var errMsg = error.errors[0].detail;
                  var errMsgLower = errMsg.toLowerCase();

                  if (errMsgLower.includes('passkey') || errMsgLower.includes('password')) {
                     self.set('isPasswordError', true);
                  }
                  self.set('errorMessages', errMsg);
                  DevExpress.ui.notify("Error Saving Password: " + errMsg, "error", 2500);
                  self.resetButton('#btnResetPassword', 'Reset Password');
               });
            } else {
               DevExpress.ui.notify("Error Saving Password: No User Found!", "error", 2500);
               self.resetButton('#btnResetPassword', 'Reset Password');
            }
         }
      },
      showPasswordChanged: (function () {
         if (this.showPassword) {
            this.set('showMode', 'text');
         } else {
            this.set('showMode', 'password');
         }
      }).observes('showPassword'),

      pwdStrengthChanged: (function () {
         var str = this.get('pwdStrength');
         if (str === 0) {
            this.set('showStrength', false);
            this.set('pwdStrengthLabel', "Undefined");
            this.set('pwdStrengthClass', 'password-blank');
         } else if (str === 1) {
            this.set('pwdStrengthLabel', "Bad");
            this.set('pwdStrengthClass', 'password-bad');
         } else if (str === 2) {
            this.set('pwdStrengthLabel', "Weak");
            this.set('pwdStrengthClass', 'password-weak');
         } else if (str === 3) {
            this.set('pwdStrengthLabel', "Fair");
            this.set('pwdStrengthClass', 'password-fair');
         } else if (str === 4) {
            this.set('pwdStrengthLabel', "Medium");
            this.set('pwdStrengthClass', 'password-medium');
         } else if (str === 5) {
            this.set('pwdStrengthLabel', "Good");
            this.set('pwdStrengthClass', 'password-good');
         } else if (str === 6) {
            this.set('pwdStrengthLabel', "Strong");
            this.set('pwdStrengthClass', 'password-strong');
         } else if (str === 7) {
            this.set('pwdStrengthLabel', "Excellent");
            this.set('pwdStrengthClass', 'password-excellent');
         }
         if (str > 0) {
            this.set('showStrength', true);
         }
      }).observes('pwdStrength')
   });
});