define('frontend/routes/home/account/subscriptions', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      model: function model() {
         var self = this;
         var host = this.get('store').adapterFor('application').get('host');
         var user_id = this.get('authManager').get('getUser.id');

         return _ember['default'].RSVP.hash({
            user: self.get('store').findRecord('user', user_id),
            subscriptions: self.get('store').findAll('subscription').then(function (subscriptions) {
               if (subscriptions) {
                  var numSubscriptions = subscriptions.get('length');
                  if (numSubscriptions > 0) {
                     return subscriptions;
                  }
               }
               console.log("No Subscriptions found!");
               return [];
            })['catch'](function (error) {
               console.log("Error finding Subscriptions: " + error.message);
               return null;
            }),
            devices: self.get('store').findAll('device').then(function (devices) {
               if (devices) {
                  var numDevices = devices.get('length');
                  if (numDevices > 0) {
                     return devices;
                  }
               }
               console.log("No Devices found!");
               return [];
            })['catch'](function (error) {
               if (error && error.errors) {
                  console.log("Find All Devices Error: " + error.errors[0].detail);
               }
               return null;
            }),
            plans: new _ember['default'].RSVP.Promise(function (resolve, reject) {
               var url = [host, 'billing/plans'].join('/');
               _ember['default'].$.get({
                  url: url,
                  crossDomain: true,
                  success: function success(results) {
                     if (results) {
                        resolve(results.data);
                     } else {
                        resolve(null);
                     }
                  },
                  error: function error(err) {
                     reject(err.responseText);
                  }
               });
            }).then(function (plans) {
               return plans;
            })['catch'](function (err) {
               console.log("Error getting Plans: " + err);
               return null;
            }),
            cards: new _ember['default'].RSVP.Promise(function (resolve, reject) {
               var url = [host, 'billing/cards', user_id].join('/');

               _ember['default'].$.get({
                  url: url,
                  crossDomain: true,
                  success: function success(results) {
                     if (results) {
                        resolve(results.data);
                     } else {
                        resolve(null);
                     }
                  },
                  error: function error(err) {
                     reject(err.responseText);
                  }
               });
            }).then(function (cards) {
               return cards;
            })['catch'](function (err) {
               console.log("Error getting Cards: " + err);
               return null;
            })
         });
      },
      afterModel: function afterModel(model) {
         // Wait for asynchronous calls to back-end to resolve ...
         var controller = this.controllerFor('home.account.subscriptions');
         if (model) {
            controller.set('model', model);
         }
      },
      actions: {
         willTransition: function willTransition() {
            this.controllerFor('home.account.subscriptions').resetToolbar();
         },
         didTransition: function didTransition() {
            var homeController = this.controllerFor('home');
            if (homeController) {
               homeController.set('title', 'Device Subscriptions');
               homeController.set('selectedMenuIndex', 4);
               homeController.set('subMenuIndex', 2);
            }
         },
         refreshModel: function refreshModel() {
            this.refresh();
         }
      }
   });
});